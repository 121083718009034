import styled from "@emotion/styled/macro";
import { NavLink } from "react-router-dom";

import { Card } from "components";
import { ReactComponent as User } from "assets/CarbonCredit-SVG/User.svg";
import { ReactComponent as ChartPie } from "assets/CarbonCredit-SVG/ChartPie.svg";
import { ReactComponent as Archive } from "assets/CarbonCredit-SVG/Archive.svg";
import { ReactComponent as FileText } from "assets/CarbonCredit-SVG/FileText.svg";
import { ReactComponent as CreditCard } from "assets/CarbonCredit-SVG/CreditCard.svg";
import { ReactComponent as Refresh } from "assets/CarbonCredit-SVG/Refresh.svg";
import { ReactComponent as LockKey } from "assets/CarbonCredit-SVG/LockKey.svg";

import { mediaQuery } from "utils/mediaQueries";
import { config } from "config";

const { path } = config;

const SideBarManageAccountContainer = styled(Card)`
  padding: 16px 8px;
  position: sticky;
  top: 0;
  overflow-y: auto;
  margin-bottom: 32px;
  min-width: fit-content;
  ${mediaQuery("tablet")} {
    padding: 40px 24px;
    min-width: 88px;
  }
  ${mediaQuery("large")} {
    width: 100%;
    max-width: 300px;
    padding: 40px;
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  text-decoration: none;
  margin-bottom: 24px;
  padding: 8px;
  color: ${(props) => props.theme.textColor};
  transition: color 0.3s;
  align-items: center;
  font-weight: 600;
  > div {
    display: none;
  }
  svg {
    display: inline-block;
    width: 24px;
    height: 24px;
    transition: stroke 0.3s;
    flex-shrink: 0;
  }
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
  &.active {
    font-weight: bold;
    color: ${(props) => props.theme.primaryColor};
    &:hover {
      color: ${(props) => props.theme.lightblue600};
    }
  }
  ${mediaQuery("tablet")} {
    svg {
      margin-right: 8px;
    }
  }
  ${mediaQuery("large")} {
    > div {
      display: block;
    }
  }
`;

const PathName = styled.div``;

const pageList = [
  {
    path: path.manageAccInfo,
    label: "Account",
    logo: <User />,
  },
  // {
  //   path: path.manageAccPortfolio,
  //   label: "Portfolio",
  //   logo: <ChartPie />,
  // },
  {
    path: path.manageAccOrders,
    label: "Orders",
    logo: <FileText style={{ strokeWidth: 2 }} />,
  },
  {
    path: path.manageAccInventory,
    label: "Inventory",
    logo: <Archive />,
  },
  {
    path: path.manageAccPayment,
    label: "Payment Details",
    logo: <CreditCard />,
  },
  {
    path: path.manageAccMembership,
    label: "Registry Membership",
    logo: <Refresh />,
  },
  {
    path: path.manageAccSecurity,
    label: "Security",
    logo: <LockKey />,
  },
];

const SideBarManageAccount = () => (
  <SideBarManageAccountContainer>
    {pageList.map((page) => (
      <StyledNavLink
        className={({ isActive }) => (isActive ? "active" : "")}
        to={page.path}
        key={page.path}
      >
        {page.logo}
        <PathName>{page.label}</PathName>
      </StyledNavLink>
    ))}
  </SideBarManageAccountContainer>
);

export default SideBarManageAccount;
