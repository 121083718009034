import styled from "@emotion/styled/macro";

import background from "assets/Log-in.svg";
import esco from "assets/esco.png";
import { Button } from "components";
import { mediaQuery } from "utils/mediaQueries";
import { useRegisterContext } from "providers/RegisterProvider";

const Container = styled.div`
  width: 100%;
  overflow-y: auto;
  font-family: Nunito Sans;
  display: flex;
`;

const Background = styled.img`
  position: fixed;
  right: 0;
  bottom: 0;
`;

const MenuContainer = styled.div`
  max-width: 1366px;
  margin: 0 auto;
  padding: 24px 0;
  text-align: center;
  position: relative;
  z-index: ${(props) => +props.theme.defaultZIndex + 1};
  height: auto;
  min-height: 800px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  flex: 1;
  ${mediaQuery("tablet")} {
    padding: 0;
    justify-content: center;
  }
`;

const Header = styled.h2`
  font-weight: 900;
  margin-bottom: 48px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  width: 100%;
  min-width: 264px;
  max-width: min(70vw, 80vh, 1000px);
  margin: 0 auto;
  place-items: center;
  ${mediaQuery("tablet")} {
    grid-row-gap: 32px;
    grid-column-gap: 32px;
    /* grid-template-columns: 1fr 1fr; */
  }
`;

const MenuCard = styled.div`
  background: #ffffff;
  border: 1px solid #e7eaf1;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 4px rgba(217, 225, 233, 0.1);
  border-radius: ${(props) => props.theme.borderRadiusBase};
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 12%;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${mediaQuery("tablet")} {
    max-width: 50%;
    padding: 5%;
  }
`;

const TradeLogo = styled.img`
  width: 100%;
`;

const TradeName = styled.h2`
  margin: 10% 0;
`;

const StyledButton = styled(Button)`
  margin-top: auto;
`;

const Menu = () => {
  const {
    state: { page },
    dispatch,
  } = useRegisterContext();

  const handleSelectBroker = (brokerId: number) => {
    dispatch({ type: "SET_BROKER_ID", payload: brokerId });
    dispatch({
      type: "SET_PAGE",
      payload: page + 1,
    });
  };

  return (
    <Container>
      <Background src={background} />
      <MenuContainer>
        <Header>Select Brokers</Header>
        <Grid>
          <MenuCard>
            <TradeLogo src={esco} />
            <TradeName>Escopolis</TradeName>
            <StyledButton
              variant="secondary"
              block
              onClick={() => handleSelectBroker(2)}
            >
              Select
            </StyledButton>
          </MenuCard>
        </Grid>
      </MenuContainer>
    </Container>
  );
};

export default Menu;
