import { ChangeEvent, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import dayjs from "dayjs";
import useSWR from "swr";

import { ReactComponent as ArrowLeft } from "assets/CarbonCredit-SVG/ArrowLeft.svg";
import { ReactComponent as ArrowRight } from "assets/CarbonCredit-SVG/ArrowRight.svg";
import { AllTransactionType } from "models/wallet";
import { convertObjToUrl } from "utils";
import { apiFetcher } from "providers";
import { DropdownSearch } from "components";
import { TransactionList } from "features/Admin";

const UserTitles = styled.div`
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1f2c57;
`;

const Calendar = styled.div`
  width: 250px;
  background-color: white;
  margin-left: auto;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  border: none;
  display: flex;
  margin-right: 16px;
  div {
    display: flex;
    height: 22px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin: auto;
    color: #1f2c57;
    align-items: center;
  }
`;
const CustomCalendarInput = styled.input`
  appearance: none;
  box-sizing: border-box;
  border-color: transparent;
  :focus-visible {
    outline: 0;
  }

  ::-webkit-datetime-edit {
    display: none;
  }
  ::-webkit-calendar-picker-indicator {
    margin: 0;
    margin-top: 2px;
  }
`;

const ShowDate = styled.p`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #1f2c57;
  margin: 0;
  padding: 0 12px;
`;
const TransactionHistory = () => {
  const [searchParams, setSearch] = useState<{
    column: string;
    searchBar: string | number;
  }>({ column: "", searchBar: "" });
  const [date, setDate] = useState(dayjs());
  const limit = 10;
  const params = {
    currency: "THB",
    limit,
    cursor: 0,
    date: date?.toISOString(),
    column: searchParams?.column,
    search_bar: searchParams?.searchBar,
  };

  const {
    data: query,
    error,
    mutate,
  } = useSWR<AllTransactionType>(
    `/wallet/all_transaction_history?${convertObjToUrl(params)}`,
    { revalidateOnFocus: false }
  );
  const histories = useMemo(() => {
    if (!query?.transaction_histories) return [];
    return query?.transaction_histories;
  }, [query]);
  const nextCursor = query?.next_cursor;
  // const fetch = () => {
  //   if (histories && nextCursor !== 0) {
  //     const getNewData = async () => {
  //       const response: AllTransactionType = await apiFetcher({
  //         url: "/wallet/all_transaction_history",
  //         params: {
  //           ...params,
  //           cursor: nextCursor,
  //         },
  //       });
  //       const newData = {
  //         next_cursor: response?.next_cursor,
  //         transaction_histories: [
  //           ...histories,
  //           ...response.transaction_histories,
  //         ],
  //       };
  //       mutate(newData, false);
  //     };
  //     getNewData();
  //   }
  // };
  const onChangeDate = (event: ChangeEvent<HTMLInputElement>) => {
    setDate(dayjs(`${event?.target?.value}${dayjs().format("THH:mm:ss")}`));
  };
  const changeDate = (isNextDay: boolean, num: number) => {
    if (isNextDay) {
      setDate((prev) => prev.add(num, "day"));
    } else {
      setDate((prev) => prev.subtract(num, "day"));
    }
  };
  const searchOption = [
    ["time", "Time"],
    ["displayName", "Display Name"],
    ["transactionID", "Transaction ID"],
    ["transaction", "Transaction"],
    ["amount", "Amount"],
  ];
  const handleSearch = (column: string, searchBar: string) => {
    setSearch({ column, searchBar });
  };

  if (error) return <div>Error!</div>;
  return (
    <div
      style={{ padding: "48px 40px 48px 49px", height: "calc(100% - 65px)" }}
    >
      <div
        style={{
          width: "100%",
          height: "56px",
          display: "flex",
          marginBottom: "24px",
        }}
      >
        <UserTitles>Transaction History</UserTitles>
        <Calendar>
          <div>
            <ArrowLeft
              id="arrow-left"
              style={{
                margin: "auto 8px auto auto",
                cursor: "pointer",
                fill: "none",
                stroke: "#9DAAC6",
              }}
              onClick={() => changeDate(false, 1)}
            />
            <ShowDate id="date-field">
              {dayjs(date).format("D MMM YYYY")}
            </ShowDate>
            <CustomCalendarInput type="date" onChange={onChangeDate} />
            <ArrowRight
              id="arrow-right"
              style={{ margin: "auto auto auto 8px", cursor: "pointer" }}
              onClick={() => changeDate(true, 1)}
            />
          </div>
        </Calendar>
        <DropdownSearch
          onSubmit={handleSearch}
          searchOption={searchOption}
          defaultValue="transactionID"
        />
      </div>
      {/* <TransactionList
        data={histories}
        isHasTransaction={histories?.length !== 0}
        searchWord={searchParams?.searchBar}
        fetch={fetch}
      /> */}
      Not yet implemented
    </div>
  );
};
export default TransactionHistory;
