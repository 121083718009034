import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useReducer,
} from "react";
import axios from "axios";

import { PinCode } from "components/OTPInput/PinCode";
import { verifyPin } from "api/users";
import { APIError } from "models/generic";

interface Dispatch {
  type: "SET_SHOW" | "SET_ERROR" | "SET_MODAL_STATE" | "RESET";
  payload?: any;
}

interface State {
  onClose?: () => void;
  onConfirm?: (pin: string, clear?: (() => void) | undefined) => void;
  onVerifySuccess?: (success: boolean, nonce: string) => void;
  isError: boolean;
  errorMessage: string;
  resetError?: () => void;
  isPinLoading: boolean;
  show: boolean;
  description: string;
  shouldVerify: boolean;
}

interface PinError {
  isError: boolean;
  errorMessage: string;
}

const initState: State = {
  onClose: undefined,
  onConfirm: undefined,
  onVerifySuccess: undefined,
  isError: false,
  errorMessage: "",
  resetError: undefined,
  isPinLoading: false,
  show: false,
  description: "Enter your current pin",
  shouldVerify: false,
};

const reducer = (state: State, action: Dispatch) => {
  switch (action.type) {
    case "SET_SHOW":
      return {
        ...state,
        show: action.payload,
      };
    case "SET_ERROR":
      return {
        ...state,
        isError: action.payload.isError,
        errorMessage: action.payload.errorMessage,
      };
    case "SET_MODAL_STATE":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET":
      return initState;
    default:
      return state;
  }
};

export const PinContext = createContext({
  showPin: (_state: Partial<State>) => {},
  setPinError: (_errorState: PinError) => {},
  closePin: () => {},
  state: initState,
});

export const usePin = () => useContext(PinContext);

export const PinProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, initState);
  const {
    onClose,
    onConfirm,
    onVerifySuccess,
    isError,
    errorMessage,
    resetError,
    isPinLoading,
    show,
    description,
    shouldVerify,
  } = state;

  const showPin = (state: Partial<State>) =>
    dispatch({ type: "SET_MODAL_STATE", payload: state });

  const setPinError = (errorState: PinError) =>
    dispatch({ type: "SET_ERROR", payload: errorState });

  const closePin = () => dispatch({ type: "RESET", payload: false });

  const memoedValue = useMemo(
    () => ({
      showPin,
      setPinError,
      closePin,
      state,
    }),
    [state]
  );

  const handleClose =
    onClose || (() => dispatch({ type: "RESET", payload: false }));

  // todo fetch verify pin by default which can be toggled off with shouldVerify prop
  const handleConfirm =
    onConfirm ||
    (async (pin: string, clear?: () => void) => {
      if (shouldVerify) {
        dispatch({
          type: "SET_MODAL_STATE",
          payload: {
            resetError: () =>
              dispatch({
                type: "SET_ERROR",
                payload: { isError: false, errorMessage: "" },
              }),
          },
        });
        try {
          const res = await verifyPin({
            pin,
          });
          onVerifySuccess && onVerifySuccess(res.data.success, res.data.nonce);
        } catch (err) {
          clear && clear();
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            dispatch({
              type: "SET_ERROR",
              payload: { isError: true, errorMessage: error.message },
            });
          } else {
            dispatch({
              type: "SET_ERROR",
              payload: {
                isError: true,
                errorMessage: "Something went wrong, please try again",
              },
            });
          }
        }
      } else {
        dispatch({ type: "SET_SHOW", payload: false });
      }
    });

  return (
    <PinContext.Provider value={memoedValue}>
      <PinCode
        onClose={handleClose}
        onConfirm={handleConfirm}
        isError={isError}
        errorMessage={errorMessage}
        setError={resetError}
        loading={isPinLoading}
        show={show}
        description={description}
      />
      {children}
    </PinContext.Provider>
  );
};
