import { CSSProperties, useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

import {
  OutlineSelect,
  Table,
  TextField,
  TabsBar,
  Tab,
  Card,
  Badge,
  DatePicker,
  Button,
  Key,
} from "components";
import { ReactComponent as Vector } from "assets/CarbonCredit-SVG/Vector.svg";
import { application } from "models/application";
import { convertObjToUrl } from "utils";
import { useDebounceValue, usePagination, useSelectedDate } from "hooks";
import {
  RegistrationRequestHistoryRes,
  RegistrationRequestParams,
  RegistrationRequestRes,
  RequestStatus,
  statusVariant,
} from "models/registration";

const keys: Key<application>[] = [
  {
    label: "Created Date",
    name: "created_at",
    customRenderer: (data) => <>{dayjs(data).format("DD MMM YYYY HH:mm")}</>,
  },
  {
    label: "Applicant ID",
    name: "id",
  },
  {
    label: "Account Name",
    name: "name",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Account Ownership",
    name: "owner",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
  },
  {
    label: "Status",
    name: "status",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => (
      <Badge variant={statusVariant(data as RequestStatus)}>
        {(data as string).toLowerCase()}
      </Badge>
    ),
  },
];

const Container = styled(Card)`
  .modal div {
    backdrop-filter: initial;
  }
  margin-bottom: 32px;
`;
const TabHeader = styled.h2`
  color: inherit;
  line-height: 30px;
`;

const labelCss: CSSProperties = {
  width: "200px",
};

const IconButton = styled.div`
  cursor: pointer;
  width: fit-content;
  margin: auto;
`;

const initialFilterData = {
  ownership: "",
  status: "",
};
export const TraderRequest = () => {
  const [selectedTab, setSelectedTab] = useState("Pending");
  const navigate = useNavigate();
  const [manageAppTotal, setManageAppTotal] = useState(0);
  const [manageAppHistoryTotal, setManageAppHistoryTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [filterData, setFilterData] = useState(initialFilterData);
  const { selectedDate, selectingDate, onChangeDate, resetDate } =
    useSelectedDate();

  const {
    pagination,
    reset,
    setPage,
    setLimit,
    showPerPageOptions,
    totalPages,
  } = usePagination(
    selectedTab === "Pending" ? manageAppTotal : manageAppHistoryTotal
  );

  const debouncedParams = useDebounceValue(
    {
      ...pagination,
      ...filterData,
      from_date: dayjs(selectedDate[0]).startOf("date")?.toISOString() || "",
      to_date: dayjs(selectedDate[1]).endOf("date")?.toISOString() || "",
      search,
    } as RegistrationRequestParams,
    300
  );

  const debouncedSelectedTab = useDebounceValue(selectedTab, 300);

  const { data: manageAppData } = useSWR<RegistrationRequestRes>(
    debouncedSelectedTab === "Pending"
      ? `/registration-request/pending?${convertObjToUrl(debouncedParams)}`
      : null,
    {
      onSuccess: (data) => {
        if (data) {
          setManageAppTotal(data.data.total);
        }
      },
    }
  );
  const { data: manageAppDataHistory } = useSWR<RegistrationRequestHistoryRes>(
    debouncedSelectedTab === "History"
      ? `/registration-request/history?${convertObjToUrl(debouncedParams)}`
      : null,
    {
      onSuccess: (data) => {
        if (data) {
          setManageAppHistoryTotal(data.data.total);
        }
      },
    }
  );

  const pendingData = useMemo(
    () =>
      manageAppData?.data.requests.map((data) => ({
        created_at: data.created_at,
        id: data.id,
        owner: data.account_ownership,
        name: data.name,
        email: data.email,
        status: data.status,
      })) as application[],
    [manageAppData]
  );

  const historyData = useMemo(
    () =>
      manageAppDataHistory?.data.requests.map((data) => ({
        created_at: data.created_at,
        id: data.id,
        owner: data.account_ownership,
        name: data.name,
        email: data.email,
        status: data.status,
      })) as application[],
    [manageAppDataHistory]
  );

  const onReset = useCallback(() => {
    resetDate();
    setSearch("");
    setFilterData(initialFilterData);
  }, [resetDate]);

  const tableActions = (rowData: application) => (
    <IconButton onClick={() => navigate(`id?${rowData.id}`)}>
      <Vector />
    </IconButton>
  );

  const tableFilter = useCallback(
    (isPending: boolean) => (
      <>
        <TextField
          containerStyle={labelCss}
          placeholder="Search"
          label="Search"
          onChange={(e) => setSearch(e.target.value)}
        />
        <DatePicker
          label="Date"
          style={{ width: "200px", minWidth: "200px" }}
          startDate={selectingDate[0]}
          endDate={selectingDate[1]}
          onChange={(_, update) => onChangeDate(update as [Date, Date])}
          selectsRange
          maxDate={new Date()}
        />
        <OutlineSelect
          style={labelCss}
          label="Account Ownership"
          value={filterData.ownership}
          onChange={(e) =>
            setFilterData((prev) => ({ ...prev, ownership: e.target.value }))
          }
        >
          <option value="">All</option>
          <option value="company">Company</option>
        </OutlineSelect>
        {!isPending && (
          <OutlineSelect
            style={labelCss}
            label="Status"
            value={filterData.status}
            onChange={(e) =>
              setFilterData((prev) => ({ ...prev, status: e.target.value }))
            }
          >
            <option value="">All Status</option>
            <option value={RequestStatus.Approve}>Approved</option>
            <option value={RequestStatus.Rejected}>Rejected</option>
            <option value={RequestStatus.Suspended}>Suspended</option>
            <option value={RequestStatus.Closed}>Closed</option>
          </OutlineSelect>
        )}
        <Button variant="link" onClick={onReset}>
          Reset
        </Button>
      </>
    ),
    [
      filterData.ownership,
      filterData.status,
      onChangeDate,
      onReset,
      selectingDate,
    ]
  );

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <TabsBar
        loading={false}
        mode="horizontal"
        onSelect={(key) => {
          reset();
          setSelectedTab(key);
        }}
      >
        <Tab label={<TabHeader>Pending</TabHeader>} key="Pending">
          <Table
            actions={tableActions}
            noDataMessage="No data"
            data={pendingData}
            keys={keys}
            currentPage={pagination.page}
            show={pagination.limit}
            showPerPageOptions={showPerPageOptions}
            totalPages={totalPages}
            filters={tableFilter(true)}
            onPageChange={setPage}
            onShowChange={setLimit}
            loading={!manageAppData}
          />
        </Tab>
        <Tab label={<TabHeader>History</TabHeader>} key="History">
          <Table
            actions={tableActions}
            noDataMessage="No data"
            data={historyData}
            keys={keys}
            currentPage={pagination.page}
            show={pagination.limit}
            showPerPageOptions={showPerPageOptions}
            totalPages={totalPages}
            filters={tableFilter(false)}
            onPageChange={setPage}
            onShowChange={setLimit}
            loading={!manageAppDataHistory}
          />
        </Tab>
      </TabsBar>
    </Container>
  );
};
