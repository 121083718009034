import { config } from "config";

const { maxDbSafeInt } = config;

type priceNumberOptions = {
  min?: number;
  max?: number;
  currency?: string;
  signDisplay?: "auto" | "never" | "always" | "exceptZero";
  currencyDisplay?: "symbol" | "narrowSymbol" | "code" | "name";
};

const priceNumberDefaultOption: priceNumberOptions = {
  min: 2,
  max: 2,
  currency: "",
  signDisplay: "auto",
  currencyDisplay: "narrowSymbol",
};

export const priceNumber = (
  number: number,
  options?: priceNumberOptions
): string => {
  const newOptions = { ...priceNumberDefaultOption, ...options };
  const { min, max, currency, signDisplay, currencyDisplay } = newOptions;
  let newNum = number;
  const abs = Math.abs(number);
  if (Number.isNaN(abs)) newNum = 0;
  return new Intl.NumberFormat("en", {
    maximumFractionDigits: max,
    minimumFractionDigits: min,
    style: currency ? "currency" : "decimal",
    currency: currency || undefined,
    signDisplay,
    currencyDisplay,
  }).format(newNum);
};

export const dbSafePriceNumber = (
  number: number,
  options?: priceNumberOptions
): string => (number >= maxDbSafeInt ? "-" : priceNumber(number, options));

type percentNumberOptions = {
  min?: number;
  max?: number;
  signDisplay?: "auto" | "never" | "always" | "exceptZero";
};

const percentNumberDefaultOption: percentNumberOptions = {
  min: 2,
  max: 2,
  signDisplay: "auto",
};

export const percentNumber = (
  number: number,
  options?: percentNumberOptions
): string => {
  const newOptions = { ...percentNumberDefaultOption, ...options };
  const { min, max, signDisplay } = newOptions;
  let newNum = number;
  const abs = Math.abs(number);
  if (Number.isNaN(abs)) newNum = 0;
  return new Intl.NumberFormat("en", {
    style: "percent",
    minimumFractionDigits: min,
    minimumSignificantDigits: min,
    maximumFractionDigits: (max || 0) + 2, // to prevent rounding when divided by 100
    maximumSignificantDigits: (max || 0) + 2,
    signDisplay,
  }).format(newNum / 100);
};
