import { HTMLAttributes, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { ReactComponent as ArrowLeft } from "assets/CarbonCredit-SVG/ArrowLeft.svg";

export interface IBackbtn extends HTMLAttributes<HTMLDivElement> {
  redirect?: boolean;
  label?: ReactNode;
  goBack?: () => void;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;
  &:focus,
  &:hover {
    > svg {
      background-color: #c3cbdd;
    }
  }
`;

const StyledArrowLeft = styled(ArrowLeft)`
  fill: none;
  stroke: #000;
  border-radius: 50%;
  background-color: #f3f6fb;
  height: 36px;
  width: 36px;
  padding: 10px;
  margin-right: 16px;
  transition: background 0.3s;
`;

export const BackButton = ({
  redirect = true,
  goBack,
  label = "Back",
  ...rest
}: IBackbtn) => {
  const navigate = useNavigate();
  return (
    <Container
      onClick={() => (redirect ? navigate(-1) : goBack && goBack())}
      {...rest}
    >
      <StyledArrowLeft />
      {label && <div>{label}</div>}
    </Container>
  );
};
