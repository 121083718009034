import styled from "@emotion/styled/macro";

import BG from "assets/CarbonCredit-SVG/Gideon-BG-Logo.svg";
import { ProgressTabs, BackButton } from "components";
import { isKycOnly } from "config";
import {
  // RegisterForm,
  Agreement,
  Profile,
  RegisterContainer,
  Success,
  JuristicInfo,
  Contact,
  Authorize,
  Director,
  Payment,
  PDPAPage,
  BrokerAgreement,
  Attachment,
  DelegateAttachments,
  Summary,
  BrokerSelection,
} from "features/Register";
import {
  RegisterProvider,
  useRegisterContext,
} from "providers/RegisterProvider";
import { mediaQuery } from "utils/mediaQueries";

const Container = styled.div`
  background: url(${BG});
  background-repeat: no-repeat;
  background-position: right;
  width: 100vw;
  height: 95vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  ${mediaQuery("desktop")} {
    height: 100vh;
  }
`;

const StyledProgressTabs = styled(ProgressTabs)`
  margin-top: 32px;
`;

export const Register = () => {
  const {
    state: { page, pages, furthestPage, registered },
    dispatch,
  } = useRegisterContext();
  const renderPageState = () => {
    const currentPage = pages[page];
    switch (currentPage) {
      case "agreement":
        return <Agreement />;
      case "profile":
        return <Profile />;
      case "brokerSelection":
        return <BrokerSelection />;
      case "kyc":
        return <JuristicInfo />;
      case "contact":
        return <Contact />;
      case "director":
        return <Director />;
      case "authorized":
        return <Authorize />;
      case "payment":
        return <Payment />;
      case "brokerAgreement":
        return <BrokerAgreement />;
      case "pdpa":
        return <PDPAPage />;
      case "attachments":
        return <Attachment />;
      case "delegateAttachments":
        return <DelegateAttachments />;
      case "summary":
        return <Summary />;
      default:
        break;
    }
  };

  const setPage = (index: number) => {
    dispatch({
      type: "SET_PAGE",
      payload: index,
    });
  };

  return (
    <Container>
      <RegisterContainer>
        {registered ? (
          <Success />
        ) : (
          <>
            {(!isKycOnly || page !== 0) && (
              <BackButton
                redirect={page === 0 || registered}
                goBack={() => setPage(page - 1)}
              />
            )}
            {renderPageState()}
            <StyledProgressTabs
              pages={pages}
              active={page}
              lastClickablePage={furthestPage}
              onClick={setPage}
            />
          </>
        )}
      </RegisterContainer>
    </Container>
  );
};

export const RegisterWithProvider = () => (
  <RegisterProvider>
    <Register />
  </RegisterProvider>
);
