import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { useAuth } from "providers";
import { Button, Card } from "components";

const Container = styled.div`
  padding: 88px 28px 42px 28px;
  background-color: #f3f6fb;
  gap: 08px 8px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const CardView = styled(Card)`
  width: 100%;
  min-height: 320px;
  height: 100%;
  padding: 25px 32px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  flex: 1;
`;

const ErrorMessage = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #9daac6;
`;

const BackBtn = styled(Button)`
  margin: 32px 0;
`;

const ErrorPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  useEffect(() => {
    if (location.state?.logout === true) {
      logout();
    }
  }, [location, logout]);

  return (
    <Container>
      <CardView>
        <ErrorMessage>Sorry!</ErrorMessage>
        <ErrorMessage>
          {location.state?.defaultSymbol
            ? "Symbol not found"
            : "Something went wrong"}
        </ErrorMessage>
        <BackBtn
          size="small"
          onClick={() =>
            navigate(location.state?.defaultSymbol ? "/market/TVER" : "/")
          }
        >
          Back
        </BackBtn>
      </CardView>
    </Container>
  );
};

export default ErrorPage;
