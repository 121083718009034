/* eslint-disable class-methods-use-this */
import * as debug from "debug";
// import * as supportsColor from "supports-color"; // only for NodeJS to enable more colors

// to enable write on console: localStorage.debug = 'blockfint:*'
const NS_BASE = "blockfint";
// TODO create namespaces per app name? we can't read app dir on filesystem and we want to avoid missing logs due to include order (from another lib using this class)

class Log {
  // https://www.npmjs.com/package/debug#formatters

  public trace(formatter: any, ...args: any[]): void {}

  public verbose(formatter: any, ...args: any[]): void {}

  public info(formatter: any, ...args: any[]): void {}

  public warn(formatter: any, ...args: any[]): void {}

  public error(formatter: any, ...args: any[]): void {}

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################
}

const logger = new Log();

// @ts-ignore
logger.trace = debug(`${NS_BASE}:trace`);
// @ts-ignore
logger.verbose = debug(`${NS_BASE}:verbose`);
// @ts-ignore
logger.info = debug(`${NS_BASE}:info`);
// @ts-ignore
logger.warn = debug(`${NS_BASE}:warn`);
// @ts-ignore
logger.error = debug(`${NS_BASE}:error`);

export { logger };
