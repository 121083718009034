import * as colors from "./colors";

export const Theme = {
  // colors
  ...colors,
  primaryColor: colors.lightblue400,
  secondaryColor: colors.darkgray900,
  darkgray: colors.darkgray200,
  gray: colors.darkgray100,
  lightgray: colors.darkgray50,
  errorColor: colors.red2,
  successColor: colors.green,
  warningColor: colors.orange,

  // baseStyle
  borderRadiusBase: "8px",
  boxShadowBase: "0px 4px 8px 4px rgba(217, 225, 233, 0.1)",
  dropShadowBase: "0px 4px 4px rgba(217, 225, 233, 0.25)",
  contentMaxWidth: "1366px",
  loginMaxWidth: "540px",

  // background
  baseBackgroundColor: colors.lightgray50,
  loginBackgroundColor: colors.white2,
  componentBackgroundColor: colors.white,

  // text
  fontFamily: "Nunito Sans, Kanit , Arial, Helvetica, sans-serif",
  fontSizeBase: "16px",
  lineHeightBase: "1.375",
  textColor: colors.darkgray900,
  textColorSecondary: colors.black,
  textColorDisabled: colors.darkgray200,
  textColorLight: colors.white,

  // header
  headingColor: colors.darkgray900,
  headingLineHeight: "1.375",
  h1Size: "2rem",
  h1Weight: "900",
  h2Size: "1.375rem",
  h2Weight: "900",
  h3Size: "1rem",
  h3Weight: "800",
  h4Size: "1rem",
  h4Weight: "600",
  h5Size: ".83rem", // default html
  h5Weight: "bold", // default html
  h6Size: ".67rem", // default html
  h6Weight: "bold", // default html

  // buttons
  buttonFontWeight: "700",
  buttonPaddingSmall: "10px",
  buttonPaddingMedium: "12px",
  buttonPaddingLarge: "14px",
  buttonBorderRadius: "6px",
  buttonFontSize: "0.875rem",
  // primary buttons
  primaryButtonHover: colors.lightblue,
  primaryButtonActive: colors.lightblue600,
  primaryButtonDisable: colors.darkgray50,
  // secondary buttons
  secondaryButtonHover: colors.lightblue50,
  secondaryButtonActive: colors.lightblue100,
  secondaryButtonDisableBorder: colors.darkgray100,
  // link buttons
  linkButtonActive: colors.lightblue500,
  // success buttons
  successButton: colors.green,
  successButtonHover: colors.green2,
  successButtonActive: colors.darkgreen,
  successButtonDisabled: colors.green3,
  // danger buttons
  dangerButton: colors.red,
  dangerButtonHover: colors.red3,
  dangerButtonActive: colors.crimson,
  dangerButtonDisabled: colors.red4,
  // warning buttons
  warningButton: colors.orange,
  warningButtonHover: colors.orange2,
  warningButtonActive: colors.darkorange,
  warningButtonDisabled: colors.orange3,

  // input
  inputFontSize: "0.875rem",
  inputPadding: "16px",
  inputBorderRadius: "8px",
  inputDisableBackground: colors.lightgray50,
  inputBorderColor: colors.lightgray200,
  inputPlaceholderColor: colors.gray,

  // login
  boxShadowLogin: "0 0 14px 8px rgba(217, 225, 233, 0.24)",

  // breakpoints
  breakpointMobile: "576px",
  breakpointTablet: "768px",
  breakpointDesktop: "992px",
  breakpointLargeDesktop: "1200px",

  // z-index
  defaultZIndex: "1",
  sidebarZIndex: "5",
  navbarZIndex: "10",
  dropdownZIndex: "50",
  modalZIndex: "100",
};
