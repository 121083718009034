import { CSSProperties, useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import {
  OutlineSelect,
  Table,
  TextField,
  Card,
  Badge,
  DatePicker,
  Button,
  Key,
} from "components";

import { ReactComponent as Vector } from "assets/CarbonCredit-SVG/Vector.svg";
import { MockTrader } from "models/trader";
import { useSelectedDate } from "hooks";

const keys: Key<MockTrader>[] = [
  {
    label: "Created Date",
    name: "created_at",
    customRenderer: (data) => <>{dayjs(data).format("DD MMM YYYY HH:mm")}</>,
  },
  {
    label: "Account ID",
    name: "id",
  },
  {
    label: "Account Name",
    name: "name",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Account Ownership",
    name: "owner",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
  },
  {
    label: "Status",
    name: "status",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => <Badge>{data}</Badge>,
  },
];

const mockTableData = [...new Array(10)].map((_, idx) => ({
  created_at: new Date().toISOString(),
  id: idx,
  owner: "Company",
  name: "Blockfint",
  email: "blockfint@gmail.com",
  status: "Approved",
})) as MockTrader[];

const Container = styled(Card)`
  .modal div {
    backdrop-filter: initial;
  }
  margin-bottom: 32px;
`;

const labelCss: CSSProperties = {
  width: "200px",
};

const IconButton = styled.div`
  cursor: pointer;
  width: fit-content;
  margin: auto;
`;
export const ManageTrader = () => {
  const navigate = useNavigate();
  const { selectedDate, selectingDate, onChangeDate, resetDate } =
    useSelectedDate();

  const tableActions = (rowData: MockTrader) => (
    <IconButton onClick={() => navigate(rowData.id)}>
      <Vector />
    </IconButton>
  );

  const onReset = () => {
    resetDate();
  };
  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <Table
        actions={tableActions}
        noDataMessage="No data"
        data={mockTableData}
        keys={keys}
        currentPage={1}
        totalPages={1}
        filters={
          <>
            <TextField
              containerStyle={labelCss}
              placeholder="Search"
              label="Search"
            />
            <DatePicker
              label="Date"
              style={{ width: "200px", minWidth: "200px" }}
              startDate={selectingDate[0]}
              endDate={selectingDate[1]}
              onChange={(_, update) => onChangeDate(update as [Date, Date])}
              selectsRange
            />
            <OutlineSelect style={labelCss} label="Account Ownership">
              <option>All</option>
            </OutlineSelect>
            <OutlineSelect style={labelCss} label="Status">
              <option>All Status</option>
              <option>Approved</option>
              <option>Rejected</option>
              <option>Suspended</option>
              <option>Closed</option>
            </OutlineSelect>
            <Button variant="link" onClick={onReset}>
              Reset
            </Button>
          </>
        }
      />
    </Container>
  );
};
