import { CSSProperties, useCallback, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import dayjs from "dayjs";
import useSWR from "swr";
import axios from "axios";

import { APIError } from "models/generic";
import {
  OutlineSelect,
  Table,
  TabsBar,
  Tab,
  Card,
  Badge,
  DatePicker,
  Button,
  Key,
} from "components";
import { apiErrorToast, convertObjToUrl, priceNumber } from "utils";
import { useDebounceValue, usePagination, useSelectedDate } from "hooks";
import {
  TransactionStatus,
  transactionStatusVariant,
  EntryType,
} from "models/transaction";
import { Transaction, TransactionReqRes } from "models/transactionRequest";

import { ReactComponent as Vector } from "assets/CarbonCredit-SVG/Vector.svg";

import { updateTransactionRequest } from "api/admin";
import { CreditTransactionModal } from "features/Admin";

const keys: Key<Transaction>[] = [
  {
    label: "Created Date",
    name: "created_at",
    customRenderer: (data) => <>{dayjs(data).format("DD MMM YYYY HH:mm")}</>,
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Transaction ID",
    name: "transaction_id",
  },
  {
    label: "Entry Type",
    name: "entry_type",
  },
  {
    label: "Amount (Credit)",
    name: "amount",
    customRenderer: (data) => (
      <>{priceNumber(parseInt(data as string, 10), { min: 0, max: 0 })}</>
    ),
  },
  {
    label: "Status",
    name: "status",
    style: {
      textAlign: "center" as const,
    },
    headerStyle: {
      textAlign: "center" as const,
    },
    customRenderer: (data) => (
      <Badge variant={transactionStatusVariant(data as TransactionStatus)}>
        {(data as string).replace("_", " ").toLowerCase()}
      </Badge>
    ),
  },
];

const Container = styled(Card)`
  .modal div {
    backdrop-filter: initial;
  }
  margin-bottom: 32px;
  position: relative;
`;

const TabHeader = styled.h2`
  color: inherit;
  line-height: 30px;
`;

const DepositButton = styled(Button)`
  width: 120px;
  height: 40px;
  position: absolute;
  top: 28px;
  right: 32px;
  z-index: ${(props) => props.theme.defaultZIndex};
`;

const IconButton = styled.button`
  width: fit-content;
  background: transparent;
  border: none;
`;

const labelCss: CSSProperties = {
  width: "200px",
};

const initialFilterData = {
  currency_type: "credit",
  entry_type: "",
  status: "",
};

const debounceTime = 300;

export const CreditTransactionRequest = () => {
  const { selectedDate, selectingDate, onChangeDate, resetDate } =
    useSelectedDate();
  const [totalTransaction, setTotalTransaction] = useState(0);
  const {
    pagination,
    setPage,
    setLimit,
    showPerPageOptions,
    totalPages,
    reset: resetPagination,
  } = usePagination(totalTransaction);

  const [filterData, setFilterData] = useState(initialFilterData);
  const [selectedTab, setSelectedTab] = useState("Pending");
  const [transactionId, setTransactionId] = useState(-1);

  const params = useMemo(
    () => ({
      ...pagination,
      ...filterData,
      from_date: dayjs(selectedDate[0]).startOf("date")?.toISOString() || "",
      to_date: dayjs(selectedDate[1]).endOf("date")?.toISOString() || "",
    }),
    [filterData, pagination, selectedDate]
  );

  const debouncedParam = useDebounceValue(params, debounceTime);

  const debouncedSelectedTab = useDebounceValue(selectedTab, debounceTime);

  const { data: transactionPending, mutate } = useSWR<TransactionReqRes>(
    debouncedSelectedTab === "Pending"
      ? `/transaction-request/pending?${convertObjToUrl(debouncedParam)}`
      : null,
    {
      onSuccess: (res) => {
        setTotalTransaction(res.data.total);
      },
    }
  );

  const { data: transactionHistory } = useSWR<TransactionReqRes>(
    debouncedSelectedTab === "Pending"
      ? null
      : `/transaction-request/history?${convertObjToUrl(debouncedParam)}`,
    {
      onSuccess: (res) => {
        setTotalTransaction(res.data.total);
      },
    }
  );

  const pendingData: Transaction[] = useMemo(() => {
    if (!transactionPending) return [];
    return transactionPending?.data.requests;
  }, [transactionPending]);

  const historyData = useMemo(() => {
    if (!transactionHistory) return [];
    return transactionHistory?.data.requests;
  }, [transactionHistory]);

  const onCloseTransaction = () => {
    setTransactionId(-1);
  };

  const onUpdateTransaction = useCallback(
    async (transactionId: number, status: TransactionStatus) => {
      try {
        await updateTransactionRequest({
          transaction_id: transactionId,
          status,
        });
        await mutate();
        setTransactionId(-1);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
      }
    },
    [mutate]
  );

  const onReset = useCallback(() => {
    setFilterData(initialFilterData);
    resetDate();
  }, [resetDate]);

  const tableFilter = useMemo(
    () => (
      <>
        <DatePicker
          label="Date"
          style={{ width: "200px", minWidth: "200px" }}
          startDate={selectingDate[0]}
          endDate={selectingDate[1]}
          onChange={(_, update) => onChangeDate(update as [Date, Date])}
          selectsRange
          maxDate={new Date()}
        />
        <OutlineSelect
          style={labelCss}
          label="Entry Type"
          onChange={(e) =>
            setFilterData({ ...filterData, entry_type: e.target.value })
          }
          value={filterData.entry_type}
        >
          <option value="">All Entry Type</option>
          <option value={EntryType.Deposit}>Deposit</option>
          <option value={EntryType.Withdraw}>Withdraw</option>
        </OutlineSelect>
        <OutlineSelect
          onChange={(e) =>
            setFilterData({ ...filterData, status: e.target.value })
          }
          value={filterData.status}
          style={labelCss}
          label="Status"
        >
          <option value="">All Status</option>
          {selectedTab === "Pending" ? (
            <>
              <option value={TransactionStatus.Pending}>Pending</option>
              <option value={TransactionStatus.InProcess}>In Process</option>
            </>
          ) : (
            <>
              <option value={TransactionStatus.Success}>Success</option>
              <option value={TransactionStatus.Rejected}>Rejected</option>
              <option value={TransactionStatus.Cancelled}>Cancelled</option>
            </>
          )}
        </OutlineSelect>
        <Button variant="link" onClick={onReset}>
          Reset
        </Button>
      </>
    ),
    [filterData, onChangeDate, onReset, selectedTab, selectingDate]
  );

  const tableAction = (rowData: Transaction) => {
    // render when status is Pending or InProcess Only
    const renderAction =
      rowData.status === TransactionStatus.Pending ||
      rowData.status === TransactionStatus.InProcess;
    if (renderAction)
      return (
        <IconButton onClick={() => setTransactionId(rowData.transaction_id)}>
          <Vector />
        </IconButton>
      );
    return null;
  };

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <CreditTransactionModal
        show={transactionId !== -1}
        transactionId={transactionId}
        onClose={onCloseTransaction}
        updateTransaction={onUpdateTransaction}
      />
      <TabsBar
        loading={false}
        mode="horizontal"
        onSelect={(key) => {
          setFilterData(initialFilterData);
          setSelectedTab(key);
          resetPagination();
        }}
      >
        <Tab label={<TabHeader>Pending</TabHeader>} key="Pending">
          <Table
            tbodyTestId="credit-pending__table"
            noDataMessage="No data"
            data={pendingData}
            keys={keys}
            currentPage={pagination.page}
            show={pagination.limit}
            showPerPageOptions={showPerPageOptions}
            totalPages={totalPages}
            filters={tableFilter}
            onPageChange={setPage}
            onShowChange={setLimit}
            rowPrimaryKey="created_at"
            actions={tableAction}
            loading={!transactionPending}
          />
        </Tab>
        <Tab label={<TabHeader>History</TabHeader>} key="History">
          <Table
            tbodyTestId="credit-history__table"
            noDataMessage="No data"
            data={historyData}
            keys={keys}
            currentPage={pagination.page}
            show={pagination.limit}
            showPerPageOptions={showPerPageOptions}
            totalPages={totalPages}
            filters={tableFilter}
            onPageChange={setPage}
            onShowChange={setLimit}
            rowPrimaryKey="created_at"
            loading={!transactionHistory}
          />
        </Tab>
      </TabsBar>
    </Container>
  );
};
