import { useState } from "react";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { bool, array, object, string } from "yup";

import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import { Button, Label, ConfirmModal, RadioGroup, TextField } from "components";
import { AuthorizedPersonInfo } from "models/user";

import { Form, RegisterComponentGrid, Desc, NormalRadio } from "../components";

type FieldValues = {
  authorized_persons_info: AuthorizedPersonInfo[];
};

export enum Related {
  yes = "YES",
  no = "NO",
}

const Header = styled.div`
  margin: 32px auto;
`;

const TextGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const schema = object().shape({
  authorized_persons_info: array().of(
    object().shape({
      full_name: string().required("Full name is required"),
      address: string().required("Address is required"),
      is_related_to_politician: bool(),
      additional_info: string().when("is_related_to_politician", {
        is: true,
        then: string().required("Additional info is required"),
      }),
    })
  ),
});

const defaultInfo = {
  full_name: "",
  address: "",
  is_related_to_politician: false,
  additional_info: "",
};

const maxLength = 20;

export const Authorize = () => {
  const {
    state: { page, authorizedPersons },
    dispatch,
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      authorized_persons_info:
        authorizedPersons.length === 0 ? [defaultInfo] : authorizedPersons,
    },
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "authorized_persons_info",
  });

  const [deleteAuthorized, setDeleteAuthorized] = useState<number | null>(null);

  const handleAddAuthorized = () => {
    append(defaultInfo);
  };

  const onConfirmDelete = (index: number | null) => {
    if (index !== null) {
      remove(index);
    }
    setDeleteAuthorized(null);
  };

  const onSubmit = (data: FieldValues) => {
    onSubmitted();
    dispatch({
      type: "SET_AUTHORIZED_PERSONS",
      payload: data.authorized_persons_info,
    });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <RegisterComponentGrid>
      <ConfirmModal
        show={!!deleteAuthorized}
        title="Delete?"
        desc="Are you sure you want to delete this item?"
        onClose={() => setDeleteAuthorized(null)}
        onConfirm={() => onConfirmDelete(deleteAuthorized)}
        textConfirm="Delete"
      />
      <Header>
        <Desc>
          ข้อมูลบุคคลที่ได้รับมอบอำนาจ ทอดสุดท้าย ในการซื้อขายเเทนบริษัท
        </Desc>
      </Header>
      <Form id="director" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={field.id}>
            <TextGroup>
              <Label>{`(ท่านที่ ${index + 1})`}</Label>
              {index !== 0 && (
                <Button
                  type="button"
                  variant="link"
                  block
                  onClick={() => setDeleteAuthorized(index)}
                  style={{ width: "max-content", padding: 0 }}
                >
                  ลบ
                </Button>
              )}
            </TextGroup>
            <TextField
              label="ชื่อ - นามสกุล"
              required
              placeholder="กรอกชื่อ - นามสกุล"
              errorWarn={!!errors?.authorized_persons_info?.[index]?.full_name}
              errorMessage={
                errors?.authorized_persons_info?.[index]?.full_name?.message
              }
              {...register(`authorized_persons_info.${index}.full_name`)}
            />
            <TextField
              label="ที่อยู่ปัจจุบัน"
              required
              placeholder="กรอกที่อยู่ปัจจุบัน"
              errorWarn={!!errors?.authorized_persons_info?.[index]?.address}
              errorMessage={
                errors?.authorized_persons_info?.[index]?.address?.message
              }
              {...register(`authorized_persons_info.${index}.address`)}
            />
            <Label>
              ท่านเป็นผู้มีสถานภาพทางการเมืองหรือเป็นสมาชิกในครอบครัวหรือใกล้ชิด
              กับบุคคลผู้มีสถานภาพทางการเมืองหรือไม่ (ถ้ามี
              กรุณาระบุที่ช่องอื่นๆ)
            </Label>

            <Controller
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value ? Related.yes : Related.no}
                  onChange={(val) => {
                    field.onChange(val === Related.yes);
                  }}
                  column
                  equalGridSpace
                  block
                >
                  <NormalRadio label="ไม่มี" value={Related.no} />
                  <NormalRadio label="มี" value={Related.yes} />
                </RadioGroup>
              )}
              control={control}
              name={`authorized_persons_info.${index}.is_related_to_politician`}
            />

            <TextField
              placeholder="กรุณาระบุ"
              disabled={
                !watch(
                  `authorized_persons_info.${index}.is_related_to_politician`
                )
              }
              errorWarn={
                !!errors?.authorized_persons_info?.[index]?.additional_info
              }
              errorMessage={
                errors?.authorized_persons_info?.[index]?.additional_info
                  ?.message
              }
              {...register(`authorized_persons_info.${index}.additional_info`)}
            />
          </div>
        ))}
        {fields.length < maxLength && (
          <Button
            type="button"
            variant="link"
            block
            onClick={handleAddAuthorized}
            style={{ textDecoration: "underline" }}
          >
            + เพิ่มบุคคลที่ได้รับมอบอำนาจ
          </Button>
        )}
      </Form>
      <Button type="submit" form="director" block>
        Next
      </Button>
    </RegisterComponentGrid>
  );
};
