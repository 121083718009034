import axios from "axios";
import { backendURL } from "config";
import { APIResponse } from "models/generic";
import { LoginBody, LoginResponse, RefreshTokenResponse } from "models/user";
import { client } from "./client";

export const login = async (payload: LoginBody) => {
  const response = await client.post<APIResponse<LoginResponse>>(
    `${backendURL}/user/login`,
    payload
    // {
    //   withCredentials: true,
    // }
  );
  return response;
};

export async function logout() {
  const response = await client.post(
    `${backendURL}/user/logout`,
    {}
    // {
    //   withCredentials: true,
    // }
  );

  return response;
}

export async function refreshAccessToken() {
  // this will be the only request that uses axios directly
  // because we dont want to use the interceptor
  return axios.post<APIResponse<RefreshTokenResponse>>(
    `${backendURL}/user/refresh-token`,
    {}
    // {
    //   withCredentials: true,
    // }
  );
}
