import { useState } from "react";
import styled from "@emotion/styled";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { array, object, string } from "yup";

import { useRegisterContext } from "providers/RegisterProvider";
import { Button, Label, TextField, ConfirmModal } from "components";
import { DirectorInfo } from "models/user";

import { Form, RegisterComponentGrid, Desc } from "../components";

type FieldValues = {
  directors: DirectorInfo[];
};

const Header = styled.div`
  margin: 32px auto;
`;

const TextGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const schema = object().shape({
  directors: array().of(
    object().shape({
      full_name: string().required("Full name is required"),
      id_card: string()
        .required("Passport number is required")
        .matches(/^[a-zA-Z0-9]+$/, "Only alphanumeric characters are allowed"),
    })
  ),
});

const defaultInfo = {
  full_name: "",
  id_card: "",
};

const maxLength = 20;

export const Director = () => {
  const {
    state: { page, directors },
    dispatch,
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      directors: directors.length === 0 ? [defaultInfo] : directors,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "directors",
  });

  const [deleteDirector, setDeleteDirector] = useState<number | null>(null);

  const handleAddDirector = () => {
    append(defaultInfo);
  };

  const onConfirmDelete = (index: number | null) => {
    if (index !== null) {
      remove(index);
    }
    setDeleteDirector(null);
  };

  const onSubmit = (data: FieldValues) => {
    dispatch({ type: "SET_DIRECTORS", payload: data.directors });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <RegisterComponentGrid>
      <ConfirmModal
        show={!!deleteDirector}
        title="Delete?"
        desc="Are you sure you want to delete this item?"
        onClose={() => setDeleteDirector(null)}
        onConfirm={() => onConfirmDelete(deleteDirector)}
        textConfirm="Delete"
      />
      <Header>
        <Desc>กรรมการผู้มีอำนาจลงนามเเทนนิติบุคคล</Desc>
      </Header>
      <Form id="director" onSubmit={handleSubmit(onSubmit)}>
        {fields.map((field, index) => (
          <div key={field.id}>
            <TextGroup>
              <Label>{`(ท่านที่ ${index + 1})`}</Label>
              {index !== 0 && (
                <Button
                  type="button"
                  variant="link"
                  block
                  onClick={() => setDeleteDirector(index)}
                  style={{ width: "max-content", padding: 0 }}
                >
                  ลบ
                </Button>
              )}
            </TextGroup>
            <TextField
              label="ชื่อ - นามสกุล"
              required
              placeholder="กรอกชื่อ - นามสกุล"
              errorWarn={!!errors.directors?.[index]?.full_name}
              errorMessage={errors.directors?.[index]?.full_name?.message}
              {...register(`directors.${index}.full_name`)}
            />
            <TextField
              label="เลขบัตรประชาชน / หนังสือเดินทาง"
              required
              placeholder="กรอกเลขบัตรประชาชน / หนังสือเดินทาง*"
              errorWarn={!!errors.directors?.[index]?.id_card}
              errorMessage={errors.directors?.[index]?.id_card?.message}
              maxLength={13}
              {...register(`directors.${index}.id_card`)}
            />
          </div>
        ))}
        {fields.length < maxLength && (
          <Button
            type="button"
            variant="link"
            block
            onClick={handleAddDirector}
            style={{ textDecoration: "underline" }}
          >
            + เพิ่มผู้มีอำนาจลงนามเเทนนิติบุคคล
          </Button>
        )}
      </Form>
      <Button type="submit" form="director" block>
        Next
      </Button>
    </RegisterComponentGrid>
  );
};
