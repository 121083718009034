import { APIResponse } from "./generic";
import { File } from "./user";

export enum RequestStatus {
  Pending = "PENDING",
  Approve = "APPROVED",
  Rejected = "REJECTED",
  Suspended = "SUSPENDED",
  Closed = "CLOSED",
}

export const statusVariant = (key: RequestStatus) => {
  switch (key) {
    case RequestStatus.Pending:
      return "warning";
    case RequestStatus.Approve:
      return "success";
    case RequestStatus.Rejected:
      return "danger";
    case RequestStatus.Suspended:
      return "secondary";
    case RequestStatus.Closed:
      return "disabled";
    default:
      return "warning";
  }
};

export interface RegistrationRequestParams {
  search?: string;
  from_date?: string;
  to_date?: string;
  accountOwnership?: string;
  limit: number;
  page: number;
}
export interface RegistrationRequestData {
  created_at: string;
  id: number;
  name: string;
  email: string;
  account_ownership: string;
  status: RequestStatus;
}

export interface RegistrationRequests {
  requests: RegistrationRequestData[];
  total: number;
}

export type RegistrationRequestRes = APIResponse<RegistrationRequests>;

export type RegistrationRequestHistory = RegistrationRequests;

export type RegistrationRequestHistoryRes = RegistrationRequestRes;

export interface RegistrationRequestById {
  account_ownership: string;
  address: string;
  bank_account_name: string;
  bank_account_number: string;
  bank_account_type: string;
  bank_branch: string;
  bank_code: string;
  created_at: string;
  email: string;
  files: File[];
  id: number;
  id_number: string;
  kyc: string;
  name: string;
  mobile_number: string;
  status: RequestStatus;
  updated_at: string;
  updated_by: string;
  office_branch?: string;
}

export type RegistrationRequestByIdRes = APIResponse<RegistrationRequestById>;
