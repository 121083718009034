import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";

import { Card, IconButton, TextTitle } from "components";
import { priceNumber, percentNumber } from "utils";
import { useOrder } from "providers";
import { TickerRes } from "models/WebsocketClients";
import { useLocation, useNavigate } from "react-router-dom";
import { APIError } from "models/generic";

import { ReactComponent as ArrowLeft } from "assets/CarbonCredit-SVG/ArrowLeft.svg";
import { config } from "config";

const { path } = config;

type Side = "positive" | "negative" | "neutral";

const CardView = styled(Card)`
  padding: 16px;
  display: flex;
  width: 100%;
  height: 100%;
  align-content: center;
  align-items: center;
  flex-flow: row wrap;
`;

const Title = styled.h3`
  margin-right: 24px;
`;

const Price = styled(Title)<{ side?: Side }>(
  `
  text-align: center;
  margin: auto 0px auto 0px;
`,
  ({ side, theme }) => {
    switch (side) {
      case "positive":
        return `
        color: ${theme.successColor};
      `;
      case "negative":
        return `
        color: ${theme.errorColor};
      `;
      case "neutral":
      default:
        return `
        color: ${theme.textColor};
      `;
    }
  }
);

const Top = styled.div`
  font-size: 10px;
  margin-bottom: 8px;
  color: #9daac6;
`;

const Bottom = styled(TextTitle)<{ side?: Side }>(
  `
  font-size: 12px;
  font-weight: bold;
  color: #1f2c57;
`,
  ({ side, theme }) => {
    switch (side) {
      case "positive":
        return `
    color: ${theme.successColor};
  `;
      case "negative":
        return `
    color: ${theme.errorColor};
  `;
      case "neutral":
      default:
        return `
    color: ${theme.textColor};
  `;
    }
  }
);

const InfoDiv = styled.div`
  margin-left: 32px;
`;

const BackButton = styled(IconButton)`
  margin: 0 1rem 0 0.5rem;
  svg {
    stroke: ${(props) => props.theme.darkgray};
    transition: stroke 0.3s;
  }

  &:hover svg {
    stroke: ${(props) => props.theme.textColor};
  }
`;

const Box = ({
  Value,
  Title,
  style,
  side,
}: {
  Title: string;
  Value: string;
  style?: any;
  side?: Side;
}) => (
  <InfoDiv style={style}>
    <Top>{Title}</Top>
    <Bottom side={side}>{Value}</Bottom>
  </InfoDiv>
);

const Info = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState<TickerRes | null>(null);

  const {
    state: { ticker, isOpen },
    dispatch,
  } = useOrder();

  const handleData = (data: TickerRes) => {
    setData(data);
  };

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in info: ", error);
    },
    [navigate]
  );

  useEffect(() => {
    if (ticker) {
      setData(null);
      ticker.subscribe();
      ticker.onData = handleData;
      ticker.onError = handleError;
    }
    return () => {
      if (ticker) {
        ticker.unsubscribe();
      }
    };
  }, [handleError, ticker]);

  useEffect(() => {
    if (data?.is_open !== isOpen) {
      dispatch({
        type: "SET_IS_OPEN",
        payload: data?.is_open,
      });
    }
  }, [data, dispatch, isOpen]);

  const dayChange = useMemo(() => {
    if (!data) return "";
    const percentChange = data?.percent_change || 0;
    return `${priceNumber(data?.change || 0, {
      signDisplay: "exceptZero",
    })} (${percentNumber(percentChange, {
      signDisplay: "exceptZero",
    })})
    `;
  }, [data]);

  const getPriceSide = (percentChange: number) => {
    if (percentChange > 0) return "positive";
    if (percentChange < 0) return "negative";
    return "neutral";
  };

  return (
    <CardView>
      <BackButton icon={<ArrowLeft />} onClick={() => navigate(-1)} />
      <Title>{location.pathname.split("/")[2].split("_")[0] || "-"}</Title>
      <Price side={getPriceSide(data?.percent_change || 0)}>
        {priceNumber(data?.last_price || 0)}
      </Price>
      <Box
        side={getPriceSide(data?.percent_change || 0)}
        style={{ marginLeft: "auto" }}
        Title="Change"
        Value={dayChange}
      />
      <Box Title="High" Value={priceNumber(data?.high || 0)} />
      <Box Title="Low" Value={priceNumber(data?.low || 0)} />
      <Box Title="Volume" Value={priceNumber(data?.volume || 0)} />
      <Box Title="Value" Value={priceNumber(data?.value || 0)} />
      <Box Title="FTI-G" Value={data?.is_open ? "Open" : "Closed"} />
    </CardView>
  );
};

export default Info;
