import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import ReactMarkdown from "react-markdown";

import { Button, Checkbox } from "components";
import { useRegisterContext } from "providers/RegisterProvider";
import markdown from "assets/agreements/Escopolis_Agreement.md";
import { Spinner } from "react-bootstrap";

const AgreementContainer = styled.div`
  label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 20px;
  }
  .container-check .checkmark {
    padding: 8px;
  }
`;

const Header = styled.div`
  text-align: center;
  margin: 40px 0;
`;

const InfoBox = styled.div`
  padding: 32px;
  background-color: #fff;
  width: 100%;
  height: 30vh;
  overflow: auto;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  margin-bottom: 40px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const BrokerAgreement = () => {
  const {
    state: { page, brokerTnc },
    dispatch,
  } = useRegisterContext();
  const [markdownText, setMarkdownText] = useState("");

  const [checked, setChecked] = useState(brokerTnc);

  // useEffect with an empty dependency array (`[]`) runs only once
  useEffect(() => {
    fetch(markdown)
      .then((response) => response.text())
      .then((text) => {
        setMarkdownText(text);
      });
  }, []);

  const onToggle = () => {
    setChecked((prev) => !prev);
  };

  const goNext = () => {
    dispatch({ type: "SET_BROKER_TNC", payload: checked });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <AgreementContainer>
      <Header>สัญญาแต่งตั้งบริษัทฯ เป็นนายหน้าซื้อ-ขายผลิตภัณฑ์</Header>
      <InfoBox>
        {markdown ? (
          <ReactMarkdown>{markdownText}</ReactMarkdown>
        ) : (
          <SpinnerContainer>
            <Spinner
              style={{ display: "flex", margin: "0 auto" }}
              animation="border"
              variant="info"
            />
          </SpinnerContainer>
        )}
      </InfoBox>
      <Checkbox
        checked={checked}
        onClick={onToggle}
        type="box"
        label="ข้าพเจ้าได้อ่านและเข้าใจเนื้อหาของสัญญา และตกลงตามเนื้อหาของสัญญาทุกประการ"
        containerClassname="container-check"
      />
      <Button block disabled={!checked} onClick={goNext}>
        Next
      </Button>
    </AgreementContainer>
  );
};
