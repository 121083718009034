import { ReactNode } from "react";
import { Routes, Navigate, Route, Outlet, useLocation } from "react-router-dom";
import styled from "@emotion/styled/macro";

import {
  Login,
  Trade,
  Market,
  Admin,
  ErrorPage,
  Menu,
  ForgetPassword,
  ResetPassword,
  RegisterWithProvider,
  // Portfolio,
  TraderRequest,
  TransactionRequest,
  AccountInformation,
  Security,
  Inventory,
  CreditInfo,
  PaymentDetail,
  Membership,
  ManageAdmin,
  Report,
  TraderInfo,
  TransactionHistory,
  SecurityVerification,
  Orders,
  ManageTrader,
  AccountSuspended,
  CreditTransactionRequest,
} from "page";
import { OrderProvider, useAuth, UserSocketProvider } from "providers";
import { SideBar } from "features/Login";
import { NavbarLayout } from "features/Trader";
import { NavbarAdmin, SideBarAdmin } from "features/Admin";
import { SideBarManageAccount } from "features/AccountManagement";
import { SideBarSuperAdmin } from "features/SuperAdmin";
import { config, recaptchaSiteKey, isKycOnly } from "config";
import { mediaQuery } from "utils/mediaQueries";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { LoadingModal } from "components/Modal/LoadingModal";

const { path, rolePermission } = config;

const LayoutFlex = styled.div`
  display: flex;
  overflow: auto;
`;
const LayoutFlexLogin = styled(LayoutFlex)`
  display: flex;
  flex-direction: column-reverse;
  ${mediaQuery("desktop")} {
    flex-direction: row;
  }
`;

const LayoutMarket = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
`;

const NavBar = styled(NavbarLayout)`
  z-index: ${(props) => props.theme.navbarZIndex};
  position: fixed;
  width: 100%;
`;

const LayoutManageAccount = styled(LayoutFlex)`
  background: ${(props) => props.theme.baseBackgroundColor};
  height: 100vh;
  overflow: auto;
  padding-top: 67px;
`;

const AdminBody = styled.div`
  background-color: ${(props) => props.theme.baseBackgroundColor};
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* overflow-x: scroll; */
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  overflow-y: auto;
  padding: 24px 0 0;
  ${mediaQuery("tablet")} {
    padding: 24px 32px 0;
  }
`;

const AdminContent = styled.div`
  padding: 32px 32px 0;
  min-width: fit-content;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  margin: 16px 12px 0;
  ${mediaQuery("tablet")} {
    margin: 36px 32px 0;
  }
`;

export const LoginLayout = ({ children }: { children?: ReactNode }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={recaptchaSiteKey}
    scriptProps={{
      defer: false, // optional, default to false
    }}
  >
    <LayoutFlexLogin id="layout">
      <SideBar />
      <Outlet />
      {children}
    </LayoutFlexLogin>
  </GoogleReCaptchaProvider>
);

export const AdminLayout = ({ children }: { children?: ReactNode }) => (
  <LayoutFlex id="layout">
    <SideBarAdmin />
    <AdminBody>
      <NavbarAdmin />
      <AdminContent>
        <Outlet />
        {children}
      </AdminContent>
    </AdminBody>
  </LayoutFlex>
);

export const SuperAdminLayout = ({ children }: { children?: ReactNode }) => (
  <LayoutFlex id="layout">
    <SideBarSuperAdmin />
    <AdminBody>
      <NavbarAdmin />
      <AdminContent>
        <Outlet />
        {children}
      </AdminContent>
    </AdminBody>
  </LayoutFlex>
);

export const MainLayout = ({ children }: { children?: ReactNode }) => {
  if (isKycOnly) {
    return <Navigate to={path.default} replace />;
  }

  return (
    <LayoutMarket id="layout">
      <NavBar />
      <Outlet />
      {children}
    </LayoutMarket>
  );
};

export const MarketLayout = ({ children }: { children?: ReactNode }) => {
  if (isKycOnly) {
    return <Navigate to={path.default} replace />;
  }

  return (
    <UserSocketProvider>
      <OrderProvider>
        {/* MainLayout contain outlet, so we don't need to put another one here */}
        <MainLayout>{children}</MainLayout>
      </OrderProvider>
    </UserSocketProvider>
  );
};

export const ManageAccountLayout = ({ children }: { children?: ReactNode }) => (
  <GoogleReCaptchaProvider
    reCaptchaKey={recaptchaSiteKey}
    scriptProps={{
      defer: false, // optional, default to false
    }}
  >
    <UserSocketProvider>
      <LayoutManageAccount id="layout">
        <NavBar />
        <Body>
          <SideBarManageAccount />
          <Content>
            <Outlet />
            {children}
          </Content>
        </Body>
      </LayoutManageAccount>
    </UserSocketProvider>
  </GoogleReCaptchaProvider>
);

function RedirectRoute() {
  const {
    state: { user, isLoading },
  } = useAuth();

  if (isLoading) {
    return <LoadingModal show />;
  }
  if (user) {
    const { role } = user;
    if (rolePermission.admin.includes(role)) {
      return (
        <Navigate to={`${path.adminBase}/${path.adminManageApp}`} replace />
      );
    }
    if (rolePermission.superadmin.includes(role)) {
      return (
        <Navigate
          to={`${path.superAdminBase}/${path.superAdminManageApp}`}
          replace
        />
      );
    }
    return <Navigate to={path.default} replace />;
  }
  return <Navigate to={path.default} replace />;
}

function RequireAuth({ requiredRoles }: { requiredRoles?: string[] }) {
  const {
    state: { user, isLoading },
  } = useAuth();

  if (isLoading) {
    return <LoadingModal show />;
  }
  if (!user) return <Navigate to={path.login} replace />;
  if (requiredRoles) {
    const { role } = user;
    if (!requiredRoles.includes(role)) return <Navigate to={path.login} />;
  }
  return <Outlet />;
}

function RequireUnAuth() {
  const {
    state: { user, isLoading },
  } = useAuth();

  const { pathname } = useLocation();

  if (isLoading && pathname !== path.login) {
    return <LoadingModal show />;
  }
  if (user) {
    const { role } = user;
    if (rolePermission.admin.includes(role)) {
      return (
        <Navigate to={`${path.adminBase}/${path.adminManageApp}`} replace />
      );
    }
    if (rolePermission.superadmin.includes(role)) {
      return (
        <Navigate
          to={`${path.superAdminBase}/${path.superAdminManageApp}`}
          replace
        />
      );
    }
    return <Navigate to={path.default} replace />;
  }

  return <Outlet />;
}

const Router = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route element={<RequireUnAuth />}>
        <Route path={path.login} element={<Login />} />
        <Route path={path.register} element={<RegisterWithProvider />} />
        <Route path={path.forgetPw} element={<ForgetPassword />} />
        <Route path={path.resetPw} element={<ResetPassword />} />
      </Route>
      <Route element={<RequireAuth />}>
        <Route
          path={path.securityVerification}
          element={<SecurityVerification />}
        />
        <Route path={path.accountSuspended} element={<AccountSuspended />} />
      </Route>
    </Route>
    <Route element={<MarketLayout />}>
      <Route path={path.tradeBase}>
        <Route path={path.trade} element={<Trade />} />
      </Route>
      <Route path={path.market} element={<Market />} />
    </Route>
    <Route element={<MainLayout />}>
      <Route path={path.menu} element={<Menu />} />
      <Route path={path.error} element={<ErrorPage />} />
    </Route>
    <Route element={<RequireAuth requiredRoles={rolePermission.admin} />}>
      {/* /admin */}
      <Route path={path.adminBase} element={<AdminLayout />}>
        <Route path={path.adminOld} element={<Admin />} />
        <Route
          path={path.adminOldTransactionHistory}
          element={<TransactionHistory />}
        />
        <Route path={path.adminManageApp}>
          <Route path={path.adminManageAppInfo} element={<TraderInfo />} />
          <Route index element={<TraderRequest />} />
        </Route>
        <Route path={path.adminManageTrader}>
          <Route path={path.adminManageTraderInfo} element={<TraderInfo />} />
          <Route index element={<ManageTrader />} />
        </Route>
        <Route path={path.adminReport} element={<Report />} />
        <Route path={path.adminAccInfo} element={<AccountInformation />} />
        <Route path={path.adminSecurity} element={<Security />} />
        <Route
          path={path.adminTransactionReq}
          element={<TransactionRequest />}
        />
        <Route
          path={path.adminCreditTransactionReq}
          element={<CreditTransactionRequest />}
        />
        <Route index element={<RedirectRoute />} />
      </Route>
    </Route>
    <Route element={<RequireAuth requiredRoles={rolePermission.superadmin} />}>
      {/* /super admin */}
      <Route path={path.superAdminBase} element={<SuperAdminLayout />}>
        <Route
          path={path.superAdminTransactionReq}
          element={<TransactionRequest />}
        />
        <Route path={path.superAdminManageApp}>
          <Route path={path.superAdminManageAppInfo} element={<TraderInfo />} />
          <Route index element={<TraderRequest />} />
        </Route>
        <Route path={path.superAdminManageTrader}>
          <Route
            path={path.superAdminManageTraderInfo}
            element={<TraderInfo />}
          />
          <Route index element={<ManageTrader />} />
        </Route>
        <Route path={path.superAdminManageAdmin}>
          <Route
            path={path.superAdminManageAdminInfo}
            element={<AccountInformation />}
          />
          <Route index element={<ManageAdmin />} />
        </Route>
        <Route path={path.superAdminReport} element={<Report />} />
        <Route path={path.superAdminAccInfo} element={<AccountInformation />} />
        <Route path={path.superAdminSecurity} element={<Security />} />
        <Route
          path={path.superAdminTransactionReq}
          element={<TransactionRequest />}
        />
        <Route index element={<RedirectRoute />} />
      </Route>
    </Route>
    <Route element={<RequireAuth requiredRoles={rolePermission.user} />}>
      {/* /account */}
      <Route path={path.manageAccBase} element={<ManageAccountLayout />}>
        <Route path={path.manageAccInfo} element={<AccountInformation />} />
        {/* <Route path={path.manageAccPortfolio} element={<Portfolio />} /> */}
        <Route path={path.manageAccOrders} element={<Orders />} />
        <Route path={path.manageAccInventory} element={<Inventory />} />
        <Route path={path.manageAccCreditInfo} element={<CreditInfo />} />
        <Route path={path.manageAccPayment} element={<PaymentDetail />} />
        <Route path={path.manageAccMembership} element={<Membership />} />
        <Route path={path.manageAccSecurity} element={<Security />} />
        <Route index element={<RedirectRoute />} />
      </Route>
    </Route>
    <Route path="*" element={<RedirectRoute />} />
  </Routes>
);

export default Router;
