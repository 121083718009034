// WebSocketOpcode allows wrapping WebSocket data as array
// with minimal overhead: [OPCODE, payload) - see ClientSocket.send()
// Currently not used for better compatibility with other clients (root arrays are ugly).
export const enum WebSocketOpcode {
  // general opcodes
  FATAL_ERROR = 1, // an error that closes the connection
  ERROR = 2,
  WARNING = 3,
  CLOSE = 4,
  PING = 5,
  AUTH = 6,

  // application opcodes
  STATUS = 10,
  LOG = 11,
  TRADINGVIEW = 18,
  ORDERBOOK = 19,
  TRADES = 20,
  TICKER = 21,
  MYORDER = 22,
  MARKET = 23,
  MYTRADE = 24,
  WALLET = 25,
}

export type WebSocketError = "Unauthorized" | "UnauthorizedPremium";

export type JSONFormat = "JSON" | "EJSON";
export const AppJSONFormat: JSONFormat = "JSON";
