import { APIError } from "models/generic";
import { ClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { TradesReq, TradesRes, WsTrade } from "./TradesData";

/**
 * Trades is a websocket client receiving live trades from the server.
 */
export class Trades extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.TRADES;

  private currencyPair: string = "";

  constructor(socket: ClientSocket, pair: string) {
    super(socket);
    this.currencyPair = pair;
  }

  public onData(data: TradesRes): void {
    console.log("TRADES DATA", data);
    let trades: WsTrade[] = [];
    if (data.init !== undefined) {
      // on first connect
      trades = data.init;
    } else if (data.update !== undefined) {
      // new live trades
      trades = data.update;
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );

    // TODO Max display section 4 on UI
    // use trades[0].rate, trades[0].amount
    // make sure to remove old trades again (don't store them forever in array or DOM tree)
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe() {
    this.socket.subscribe(this);
    const reqData: TradesReq = {
      client_id: this.subsciberID,
      pair_name: this.currencyPair,
    };
    this.send(reqData);
  }

  public unsubscribe() {
    this.send({ unsubscribe_client_id: this.subsciberID });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: TradesReq) {
    return super.sendInternal({
      market_trade: data,
      channel_id: this.subsciberID,
    });
  }
}
