import styled from "@emotion/styled/macro";

import { Card } from "components";

const CreditInfoContainer = styled(Card)``;

const Title = styled.h2``;

const Content = styled.div`
  margin-top: 16px;
`;

const Line = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 24px 8px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.lightgray50};
  }
`;

const CreditInfo = () => (
  <CreditInfoContainer
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
    }}
    transition={{
      duration: 0.3,
    }}
  >
    <Title>Credit Information</Title>
    <Content>
      <Line>
        <div>Total fiat balance (THB)</div>
        <div>10,000.00</div>
      </Line>
      <Line>
        <div>Available fiat balance (THB)</div>
        <div>10,000.00</div>
      </Line>
      <Line>
        <div>Cash credit limit (THB)</div>
        <div>10,000.00</div>
      </Line>
      <Line>
        <div>Available cash credit balance (THB)</div>
        <div>10,000.00</div>
      </Line>
    </Content>
  </CreditInfoContainer>
);

export default CreditInfo;
