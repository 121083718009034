import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { unionBy } from "lodash-es";
import { Spinner } from "react-bootstrap";
import { object, lazy, string } from "yup";

import { Button, UploadInput } from "components";
import { FileTypes } from "models/file";
import { DelegateAttachmentFiles, DelegateAttachment } from "models/user";
import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import { calculateMd5, fileRequiredSchema } from "utils";
import { APIError } from "models/generic";

import { Form, RegisterComponentGrid, Desc, ErrorText } from "../components";

type uploadedFiles = {
  originalFileHash: string;
  minioName: string;
};

type fileType =
  | "proof_of_delegation"
  | "revenue_stamp"
  | "assignee_signature"
  | "assignee_id_card"
  | "assignee_house_registration";

const listFileType: fileType[] = [
  "proof_of_delegation",
  "revenue_stamp",
  "assignee_signature",
  "assignee_id_card",
  "assignee_house_registration",
];

const FILE_SIZE = 2 * 1024 * 1024; // 2MB

const maxSizeFileSchema = fileRequiredSchema.test(
  "fileSize",
  "Maximum File Size is 2MB",
  (file: FileList) => file && (file.item(0)?.size ?? 0) <= FILE_SIZE
);

const lazySchema = lazy((value) => {
  switch (typeof value) {
    case "string":
      return string().required("This field is required");
    default:
      return maxSizeFileSchema;
  }
});

const schema = object({
  proof_of_delegation: lazySchema,
  revenue_stamp: lazySchema,
  assignee_signature: lazySchema,
  assignee_id_card: lazySchema,
  assignee_house_registration: lazySchema,
});
export const DelegateAttachments = () => {
  const [uploadedFiles, setUploadedFiles] = useState<uploadedFiles[]>([]);
  const {
    state: { page, delegateAttachments, isLoading, error },
    dispatch,
  } = useRegisterContext();

  const localStorageValues = useMemo(
    () =>
      delegateAttachments.map((data) => ({
        [data.file_type.toLowerCase() as fileType]: data.file_name,
      })),
    [delegateAttachments]
  );

  const defaultValues = Object.assign({}, ...localStorageValues);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    watch,
    setError,
  } = useForm<DelegateAttachmentFiles>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const fileName = (name: fileType) => {
    // check value is localStorage or inputFile
    if (typeof watch(name) === "string") {
      return watch(name) as string;
    }
    const data = watch(name) as FileList;
    return data?.item(0)?.name;
  };

  const onSubmit = async (data: DelegateAttachmentFiles) => {
    try {
      dispatch({ type: "LOADING", payload: true });
      // check has new data coming
      const checkFileLocal = listFileType.map((type) => {
        if (typeof data?.[type] !== "string" && data?.[type]?.length !== 0) {
          return {
            name: type,
            file: data?.[type] as FileList,
          };
        }
        return undefined;
      });

      const uploadedFileHashes = uploadedFiles.map(
        (file) => file.originalFileHash
      );

      const files: {
        name: fileType;
        file: FileList;
        hash: string;
      }[] = [];

      // create array of file name and url
      const fileUrls: DelegateAttachment = [];

      // filter data from localStorage out
      await Promise.all(
        checkFileLocal.map(async (data) => {
          // hash file content
          const fileInput = data?.file?.item(0);
          const fileBlob = new Blob([fileInput as Blob]);
          const fileHash = await calculateMd5(fileBlob);
          if (data?.file !== undefined) {
            if (!uploadedFileHashes.includes(fileHash ?? "")) {
              files.push({
                ...data,
                hash: fileHash,
              });
            } else {
              const file = uploadedFiles.find(
                (file) => file.originalFileHash === fileHash
              );
              // if file is already uploaded, just add use minioName
              if (file) {
                fileUrls.push({
                  file_name: file.minioName,
                  file_type: data.name.toUpperCase() as FileTypes,
                });
              }
            }
          }
        })
      );

      const newUploadedFiles: uploadedFiles[] = [];

      // upload every file
      const uploadFiles = files.map((fileObj) => {
        const fileInput = fileObj.file?.item(0);

        const blob = new Blob([fileInput as Blob]);
        const contentType = fileInput?.type;
        const formdata = new FormData();

        formdata.append("file_name", fileInput?.name || "");
        formdata.append("upload_file", blob);
        formdata.append("content_type", contentType || "");

        return axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/user/upload-file`,
            formdata
          )
          .then((res) => {
            newUploadedFiles.push({
              originalFileHash: fileObj.hash,
              minioName: res.data.file_name,
            });
            return res;
          })
          .catch((err) => {
            // do not throw error, just show error message
            setError(fileObj.name, {
              type: "custom",
              message: err.response.data.message,
            });
            return err;
          });
      });

      const uploadUrlResponses = await Promise.all(uploadFiles);

      dispatch({ type: "LOADING", payload: false });

      setUploadedFiles((oldFiles) => [...oldFiles, ...newUploadedFiles]);

      // create array of file name and url
      uploadUrlResponses.forEach((response, index) => {
        if (response.data) {
          fileUrls.push({
            file_type: files[index].name.toUpperCase() as FileTypes,
            file_name: response.data.data.file_name,
          });
        } else {
          throw new Error(response.message);
        }
      });

      // update data to localStorage
      const updatedData = unionBy(fileUrls, delegateAttachments, "file_type");

      onSubmitted();
      dispatch({
        type: "SET_DELEGATE_ATTACHMENTS",
        payload: updatedData,
      });
      dispatch({ type: "SET_PAGE", payload: page + 1 });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        dispatch({ type: "ERROR", payload: error });
      }
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <RegisterComponentGrid>
      <Desc>กรณีมอบอำนาจให้ผู้อื่นกระทำแทน</Desc>
      <Form id="delegate-attachment" onSubmit={handleSubmit(onSubmit)}>
        <UploadInput
          id="proof_of_delegation"
          type="file"
          label="2.1 หนังสือมอบอำนาจ"
          required
          errorWarn={!!errors.proof_of_delegation}
          errorMessage={errors.proof_of_delegation?.message}
          placeholder={fileName("proof_of_delegation") || "Please Attach File"}
          inputStyle={{ color: fileName("proof_of_delegation") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("proof_of_delegation")}
        />
        <UploadInput
          id="revenue_stamp"
          type="file"
          label="2.2 อากรเเสตมป์ 30 บาท (สำหรับ หนังสือมอบอำนาจ)"
          required
          errorWarn={!!errors.revenue_stamp}
          errorMessage={errors.revenue_stamp?.message}
          placeholder={fileName("revenue_stamp") || "Please Attach File"}
          inputStyle={{ color: fileName("revenue_stamp") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("revenue_stamp")}
        />
        <UploadInput
          id="assignee_signature"
          type="file"
          label="2.3 ตัวอย่างลายเซ็น ผู้รับมอบอำนาจ"
          required
          errorWarn={!!errors.assignee_signature}
          errorMessage={errors.assignee_signature?.message}
          placeholder={fileName("assignee_signature") || "Please Attach File"}
          inputStyle={{ color: fileName("assignee_signature") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("assignee_signature")}
        />
        <UploadInput
          id="assignee_id_card"
          type="file"
          label="2.4 สำเนาบัตรประชาชนผู้รับมอบอำนาจ (ไม่หมดอายุ)"
          required
          errorWarn={!!errors.assignee_id_card}
          errorMessage={errors.assignee_id_card?.message}
          placeholder={fileName("assignee_id_card") || "Please Attach File"}
          inputStyle={{ color: fileName("assignee_id_card") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("assignee_id_card")}
        />
        <UploadInput
          id="assignee_house_registration"
          type="file"
          label="2.5 สำเนาทะเบียนบ้านผู้รับมอบอำนาจ
        (หน้าที่มีเลขบ้าน และชื่อผู้รับมอบอำนาจ)"
          required
          errorWarn={!!errors.assignee_house_registration}
          errorMessage={errors.assignee_house_registration?.message}
          placeholder={
            fileName("assignee_house_registration") || "Please Attach File"
          }
          inputStyle={{
            color: fileName("assignee_house_registration") && "#1F2C57",
          }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("assignee_house_registration")}
        />
      </Form>
      {error && <ErrorText>{error.message}</ErrorText>}
      <Button
        form="delegate-attachment"
        type="submit"
        block
        disabled={isLoading}
      >
        {isLoading ? (
          <Spinner
            style={{ display: "block" }}
            animation="border"
            variant="light"
          />
        ) : (
          "Next"
        )}
      </Button>
    </RegisterComponentGrid>
  );
};
