import { useTheme } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { useState } from "react";

import { Button, Checkbox, Modal } from "components";

interface IConfirmSwitchPayment {
  show: boolean;
  type: "fiat" | "credit";
  onCancel: () => void;
  onConfirm: (dontShowAgain: boolean) => void;
}

const StyledModal = styled(Modal)`
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 8px;
`;

const Desc = styled.div`
  margin-bottom: 8px;
`;

const Footer = styled.div`
  display: flex;
`;

export const ConfirmSwitchPayment = ({
  show,
  type,
  onCancel,
  onConfirm,
}: IConfirmSwitchPayment) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const theme = useTheme();
  return (
    <StyledModal show={show} onBackgroundClick={onCancel}>
      <Title>Pay with {type === "fiat" ? "Fiat" : "Credit"}</Title>
      <Desc>Press “confirm” to pay with {type} on your next transaction.</Desc>
      <Checkbox
        block={false}
        checkedColor={theme.primaryColor}
        checked={dontShowAgain}
        onClick={() => setDontShowAgain((state) => !state)}
        type="box"
        label="Don’t show me this message again"
      />
      <Footer>
        <Button
          style={{ marginRight: "8px" }}
          onClick={onCancel}
          variant="secondary"
          block
        >
          Cancel
        </Button>
        <Button onClick={() => onConfirm(dontShowAgain)} block>
          Confirm
        </Button>
      </Footer>
    </StyledModal>
  );
};
