import styled from "@emotion/styled";
import dayjs from "dayjs";
import axios from "axios";

import { Button } from "components";
import { useRegisterContext } from "providers/RegisterProvider";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { APIError } from "models/generic";
import {
  apiErrorToast,
  captureErrorSentry,
  recaptchaInitErrorToast,
} from "utils";

const SummaryContainer = styled.div`
  label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 20px;
  }
  .container-check .checkmark {
    padding: 8px;
  }
`;

const Header = styled.h2`
  text-align: center;
  margin: 40px 0;
`;

const InfoBox = styled.div`
  padding: 32px;
  background-color: #fff;
  width: 100%;
  height: 55vh;
  overflow: auto;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  margin-bottom: 40px;
`;

const SectionHeader = styled.h3`
  text-align: center;
  margin: 1em 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
`;

const Lable = styled.h4``;

const Info = styled.p`
  margin: 0;
`;

export const Summary = () => {
  const { state, signUpUser } = useRegisterContext();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleSignup = async () => {
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    const recaptchaToken = await executeRecaptcha("register");
    try {
      await signUpUser(state, recaptchaToken);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error);
        captureErrorSentry(error, err, { message: "Register error" });
      }
    }
  };

  const individualOrJuristicInfo =
    state.profile.account_ownership === "individual"
      ? state.individual
      : state.company;

  const renderIndividualSummary = () => <div>Not yet implemented</div>;

  const renderCompanySummary = () => (
    <>
      <Row>
        <Lable>Company Branch</Lable>
        <Info>{state.company.office_branch ?? "สำนักงานใหญ่"}</Info>
      </Row>
      <Row>
        <Lable>Place of Registration</Lable>
        <Info>{state.company.place_of_registration ?? "TH"}</Info>
      </Row>
      <Row>
        <Lable>Registration No.</Lable>
        <Info>{state.company.registration_number}</Info>
      </Row>
      <Row>
        <Lable>Reg Date</Lable>
        <Info>
          {dayjs(state.company.registration_date.toString()).format(
            "DD MMM YYYY"
          )}
        </Info>
      </Row>
      <Row>
        <Lable>Juristic Type</Lable>
        <Info>{state.company.juristic_person_type}</Info>
      </Row>
      <Row>
        <Lable>Business Type</Lable>
        <Info>{state.company.business_type}</Info>
      </Row>
      <Row>
        <Lable>Office Address</Lable>
        <Info>{state.company.office_address}</Info>
      </Row>
      <Row>
        <Lable>Zipcode</Lable>
        <Info>{state.company.zip_code}</Info>
      </Row>
      <Row>
        <Lable>Contact Person</Lable>
        <Info>{state.company.contact_person}</Info>
      </Row>
      <Row>
        <Lable>Phone No.</Lable>
        <Info>{state.company.company_phone}</Info>
      </Row>
      <Row>
        <Lable>Fax No.</Lable>
        <Info>{state.company.company_fax ?? "-"}</Info>
      </Row>
      <Row>
        <Lable>Email</Lable>
        <Info>{state.company.company_email}</Info>
      </Row>
      {!state.company.is_contact_address_same_as_office_address && (
        <>
          <SectionHeader>Contact Address</SectionHeader>
          <Row>
            <Lable>Contact Address</Lable>
            <Info>{state.contactAddress.contact_address}</Info>
          </Row>
          <Row>
            <Lable>Zipcode</Lable>
            <Info>{state.contactAddress.contact_zip_code}</Info>
          </Row>
          <Row>
            <Lable>Phone No.</Lable>
            <Info>{state.contactAddress.contact_phone}</Info>
          </Row>
          <Row>
            <Lable>Fax No.</Lable>
            <Info>{state.contactAddress.contact_fax ?? "-"}</Info>
          </Row>
          <Row>
            <Lable>Email.</Lable>
            <Info>{state.contactAddress.contact_email ?? "-"}</Info>
          </Row>
        </>
      )}
      <SectionHeader>Director</SectionHeader>
      {state.directors.map((director, index) => (
        <div key={director.id_card}>
          <Lable>Director {index + 1}</Lable>
          <Row>
            <Lable>Full Name</Lable>
            <Info>{director.full_name}</Info>
          </Row>
          <Row>
            <Lable>ID Card</Lable>
            <Info>{director.id_card}</Info>
          </Row>
        </div>
      ))}
      <SectionHeader>Authorized Person</SectionHeader>
      {state.authorizedPersons.map((authorizedPerson, index) => (
        <div key={authorizedPerson.full_name}>
          <Lable>Authorized Person {index + 1}</Lable>
          <Row>
            <Lable>Full Name</Lable>
            <Info>{authorizedPerson.full_name}</Info>
          </Row>
          <Row>
            <Lable>Address</Lable>
            <Info>{authorizedPerson.address}</Info>
          </Row>
        </div>
      ))}
    </>
  );

  return (
    <SummaryContainer>
      <Header>Summary</Header>
      <InfoBox>
        <SectionHeader>Profile</SectionHeader>
        <Row>
          <Lable>Ownership</Lable>
          <Info>{state.profile.account_ownership}</Info>
        </Row>
        <Row>
          <Lable>Email</Lable>
          <Info>{state.profile.email}</Info>
        </Row>
        <Row>
          <Lable>Password</Lable>
          <Info>{state.profile.password.replace(/./g, "*")}</Info>
        </Row>
        <Row>
          <Lable>Mobile Number</Lable>
          <Info>{state.profile.mobile_number}</Info>
        </Row>
        <SectionHeader>
          {state.profile.account_ownership === "individual"
            ? "Personal "
            : "Juristic "}
          Information
        </SectionHeader>
        <Row>
          <Lable>Full Name(TH)</Lable>
          <Info>{individualOrJuristicInfo.th_name}</Info>
        </Row>
        <Row>
          <Lable>Full Name(EN)</Lable>
          <Info>{individualOrJuristicInfo.en_name ?? "-"}</Info>
        </Row>
        {state.profile.account_ownership === "individual"
          ? renderIndividualSummary()
          : renderCompanySummary()}
        <SectionHeader>Payment Method</SectionHeader>
        <Row>
          <Lable>Bank Name</Lable>
          <Info>{state.paymentMethod.bank_code}</Info>
        </Row>
        <Row>
          <Lable>Bank Branch</Lable>
          <Info>{state.paymentMethod.bank_branch}</Info>
        </Row>
        <Row>
          <Lable>Account Type</Lable>
          <Info>{state.paymentMethod.bank_account_type}</Info>
        </Row>
        <Row>
          <Lable>Account No.</Lable>
          <Info>{state.paymentMethod.bank_account_number}</Info>
        </Row>
        <Row>
          <Lable>Account Name</Lable>
          <Info>{state.paymentMethod.bank_account_name}</Info>
        </Row>
        <SectionHeader>PDPA</SectionHeader>
        <Row>
          <Lable>Allow Collect Data</Lable>
          <Info>{state.pdpa.is_collectable ? "Yes" : "No"}</Info>
        </Row>
        <Row>
          <Lable>Allow Sharing Data</Lable>
          <Info>{state.pdpa.is_shareable ? "Yes" : "No"}</Info>
        </Row>
        <Row>
          <Lable>Allow Researching Data</Lable>
          <Info>{state.pdpa.is_researchable ? "Yes" : "No"}</Info>
        </Row>
      </InfoBox>
      <Button block onClick={handleSignup}>
        Submit
      </Button>
    </SummaryContainer>
  );
};
