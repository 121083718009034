/* eslint-disable max-classes-per-file */
import { APIError } from "models/generic";
import { v4 } from "uuid";
import * as TradingView from "lib/charting_library";
import {
  CandleBarArray,
  ChartSymbolUpdate,
  RealtimeUpdate,
  TradingViewDataReq,
  TradingViewDataRes,
} from "./TradingViewData";
import { WebSocketOpcode } from "./Opcode";
import { ClientSocketReceiver } from "./ClientSocket";

interface TradingViewCallbacks {
  onSuccess: Function;
  onError?: Function;
}
class CallbackMap extends Map<string, TradingViewCallbacks> {
  // (request function name, callback functions)
  // constructor() {
  //   super(); // TODO throws error in WebPack 5 https://github.com/facebook/hermes/issues/86
  // }
}

export type CallbackFunctionName =
  | "onReady"
  | "resolveSymbol"
  | "getBars-"
  | "getServerTime";

/**
 * This implements the WebSocket connection required by IDatafeedChartApi for the charting library.
 */
export class TradingViewDatafeed
  extends ClientSocketReceiver
  implements TradingView.IDatafeedChartApi
{
  public readonly opcode = WebSocketOpcode.TRADINGVIEW;

  protected callbacks = new CallbackMap();

  protected realtimeCallbacks = new Map<
    string,
    /* Function */ TradingView.SubscribeBarsCallback
  >(); // (subscription ID, callback function)

  protected currencyPair: string = "";

  protected baseCurrency: string = "";

  protected hasData = false;

  protected candleSize: number = 0; // current resolution of the chart

  protected serverTimeSec: number = 0;

  protected uids: Record<string, string> = {};

  // constructor(socket: ClientSocket) {
  //   super(socket);
  // }

  /**
   * Gets called with WebSocket JSON data from the server.
   * @param data
   */
  public onData(data: TradingViewDataRes) {
    // data from the server
    if (data.resolution && data.resolution > 0)
      return this.respondWithCallback("onReady", data.resolution);
    if (data.symbol && data.symbol.name !== "")
      return this.respondWithCallback("resolveSymbol", data.symbol);
    if (Array.isArray(data.barArr) === true)
      // bars might be empty on new symbol
      return this.respondWithCallback(
        `getBars-${data.reqMs}` as CallbackFunctionName,
        data.barArr,
        data.nextTime,
        data.noData
      );
    if (data.realtime && data.realtime.client_id !== "")
      return this.updateRealtimeCandle(data.realtime);
    if (data.timeSec !== undefined && data.timeSec !== 0)
      return this.respondWithCallback("getServerTime", data.timeSec);
    console.warn(
      "Received %s unknown response from server:",
      this.className,
      data
    );
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  /**
   * Gets called when the HTML of the charting lib is ready.
   * @param callback
   */
  public onReady(callback: TradingView.OnReadyCallback): void {
    const dataReady = (resolution: number) => {
      callback({
        exchanges: [], // don't filter by exchanges in view
        symbols_types: [],
        // supported_resolutions: ["1", "15", "240", "D", "6M"],
        supported_resolutions: [
          "1",
          "3",
          "5",
          "15",
          "30",
          "60",
          "120",
          "180",
          "240",
          "1440",
          "10080",
        ] as TradingView.ResolutionString[],
        supports_marks: false, // TODO add marks for stops etc...
        supports_timescale_marks: false,
        supports_time: true,
        // futures_regex: null // not yet present in types + not used by us
      });
    };
    this.callbacks.set("onReady", { onSuccess: dataReady });
    this.send({ initPair: this.currencyPair });
  }

  // eslint-disable-next-line class-methods-use-this
  public searchSymbols(
    userInput: string,
    exchange: string,
    symbolType: string,
    onResultReadyCallback: TradingView.SearchSymbolsCallback
  ): void {
    setTimeout(() => {
      onResultReadyCallback([]); // our symbol search is currently hidden
    }, 0);
  }

  public resolveSymbol(
    symbolName: string,
    onSymbolResolvedCallback: TradingView.ResolveCallback,
    onResolveErrorCallback: TradingView.ErrorCallback
  ): void {
    const dataReady = (symbol: ChartSymbolUpdate) => {
      this.candleSize = symbol.resolution;
      onSymbolResolvedCallback({
        name: symbol.name,
        full_name: symbol.name,
        description: symbol.name,
        type: "equity",
        // session: "0000-2400",
        session: symbol.tradingSession, // https://github.com/tradingview/charting_library/wiki/Trading-Sessions
        exchange: symbol.exchange,
        listed_exchange: symbol.exchange,
        timezone: symbol.timezone, // GMT0
        pricescale: 100, // show 2 decimals
        minmov: 1,
        has_intraday: true,
        supported_resolutions: symbol.resolutions.map(
          (res) => res.toString() as TradingView.ResolutionString
        ),
        has_seconds: true,
        has_empty_bars: false, // generate missing bars. should be done by server
        volume_precision: 4,
        data_status: "streaming",
        currency_code: symbol.quoteCurrency,
        format: "price",
      });
    };
    this.callbacks.set("resolveSymbol", {
      onSuccess: dataReady,
      onError: onResolveErrorCallback,
    });
    this.send({
      resolve:
        this
          .currencyPair /* , configNr: this.configNr, strategy: this.strategyName */,
    });
  }

  public getBars(
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: TradingView.ResolutionString,
    periodParams: TradingView.PeriodParams,
    onHistoryCallback: TradingView.HistoryCallback,
    onErrorCallback: TradingView.ErrorCallback
  ): void {
    const now = Date.now();
    const dataReady = (
      barArr: number[][],
      nextTime?: number,
      noData?: boolean
    ) => {
      if (this.hasData === false && barArr.length !== 0) this.hasData = true;
      const bars = this.fromBarArray(barArr);
      const meta: TradingView.HistoryMetadata = { noData: false };
      if (nextTime !== undefined) meta.nextTime = nextTime;
      if (noData !== undefined) meta.noData = noData;
      // onHistoryCallback(bars, {noData: this.hasData === false})
      onHistoryCallback(bars, meta);
    };
    this.callbacks.set(`getBars-${now}`, {
      onSuccess: dataReady,
      onError: onErrorCallback,
    });
    this.send({
      bars: {
        candleSize: parseInt(resolution, 10),
        currencyPair: this.currencyPair,
        from: periodParams.from,
        to: periodParams.to,
        first: periodParams.firstDataRequest,
        countBack: periodParams.countBack,
      },
      reqMs: now,
    });
  }

  public subscribeBars(
    symbolInfo: TradingView.LibrarySymbolInfo,
    resolution: TradingView.ResolutionString,
    onRealtimeCallback: TradingView.SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void
  ): void {
    const uid = v4();
    // create uids map { [subscriberUID]: uid }
    this.uids[subscriberUID] = uid;
    const reqData = {
      realtime: {
        pair_name: this.currencyPair,
        // id: this.configNr + "-" + this.currencyPair + "-" + this.strategyName
        resolution,
        client_id: uid,
      },
      // strategy: this.strategyName
    };
    this.realtimeCallbacks.set(uid, onRealtimeCallback);
    this.send(reqData);
  }

  public unsubscribeBars(subscriberUID: string): void {
    // sometime it come in CAR_THB_#_THB_#_5 pattern, other its a uuid generated above
    const uid = this.uids[subscriberUID] ?? subscriberUID;
    // unsubscribe live updates. the channel ID might still be needed because charting library can request other data
    this.realtimeCallbacks.delete(uid);
    this.send({ unsubscribe_client_id: uid });
  }

  /*
    public calculateHistoryDepth(resolution: TradingView.ResolutionString, resolutionBack: TradingView.ResolutionBackValues, intervalBack: number): TradingView.HistoryDepth | undefined {
        // optionally change the history depth we request from server
        return undefined;
    }
     */

  // eslint-disable-next-line class-methods-use-this
  public getMarks(
    symbolInfo: TradingView.LibrarySymbolInfo,
    startDate: number,
    endDate: number,
    onDataCallback: TradingView.GetMarksCallback<TradingView.Mark>,
    resolution: TradingView.ResolutionString
  ): void {
    // optional, currently disabled
  }

  // eslint-disable-next-line class-methods-use-this
  public getTimescaleMarks(
    symbolInfo: TradingView.LibrarySymbolInfo,
    startDate: number,
    endDate: number,
    onDataCallback: TradingView.GetMarksCallback<TradingView.TimescaleMark>,
    resolution: TradingView.ResolutionString
  ): void {
    // optional, currently disabled
  }

  public getServerTime(callback: TradingView.ServerTimeCallback): void {
    const dataReady = (serverTimeSec: number) => {
      this.serverTimeSec = serverTimeSec;
      callback(serverTimeSec);
    };
    this.callbacks.set("getServerTime", { onSuccess: dataReady });
    this.send({ time: true });
  }

  // TODO add trading terminal API functions

  public setCurrencyPair(currencyPairStr: string) {
    this.currencyPair = currencyPairStr;
    // eslint-disable-next-line prefer-destructuring
    this.baseCurrency = currencyPairStr.split("_")[0];
    this.hasData = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const cb of this.realtimeCallbacks) this.unsubscribeBars(cb[0]);
  }

  public resetCurrencyPair() {
    this.currencyPair = "";
    this.baseCurrency = "";
    this.hasData = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const cb of this.realtimeCallbacks) this.unsubscribeBars(cb[0]);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected respondWithCallback(
    reqFunctionName: CallbackFunctionName,
    ...params: any[]
  ): void {
    const callbacks = this.callbacks.get(reqFunctionName);
    if (callbacks) {
      // TODO could there be a situation where we want to queue multiple events with the same name? events should use request ms as key then
      this.callbacks.delete(reqFunctionName);
      callbacks.onSuccess(...params);
    } else
      console.error(
        `Error: TradingView callback not found: ${reqFunctionName}`,
        params
      );
  }

  protected respondWithError(reqFunctionName: string, error: string): void {
    const callbacks = this.callbacks.get(reqFunctionName);
    if (callbacks) {
      this.callbacks.delete(reqFunctionName);
      if (typeof callbacks.onError === "function") callbacks.onError(error);
      else
        console.error(
          `Error: TradingView error callback not set: ${reqFunctionName}`
        );
    } else
      console.error(
        `Error: TradingView error callback not found: ${reqFunctionName}`
      );
  }

  protected updateRealtimeCandle(update: RealtimeUpdate) {
    const bars = this.fromBarArray(update.candles);
    const callback = this.realtimeCallbacks.get(update.client_id);
    if (callback) callback(bars[0]);
    else
      console.error(
        `Error: TradingView error realtime callback not found: ${update.client_id}`
      );
  }

  /*
    protected getCurrencyPair(symbolInfo: TradingView.LibrarySymbolInfo) {
        let pair = symbolInfo.name.split(" ");
        return pair[0];
    }

    protected getStrategyName(symbolInfo: TradingView.LibrarySymbolInfo) {
        let pair = symbolInfo.name.split(" ");
        return pair[1];
    }
    */

  // eslint-disable-next-line class-methods-use-this
  protected fromBarArray(barArr: CandleBarArray): TradingView.Bar[] {
    const bars: TradingView.Bar[] = [];
    for (let i = 0; i < barArr.length; i++) {
      bars.push({
        time: barArr[i][0],
        open: barArr[i][1],
        high: barArr[i][2],
        low: barArr[i][3],
        close: barArr[i][4],
        volume: barArr[i][5],
      });
    }
    return bars;
  }

  protected send(data: TradingViewDataReq) {
    return super.sendInternal({
      trading_view: data,
      channel_id: this.subsciberID,
    });
  }
}
