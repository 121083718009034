import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { loadTranslations } from "./i18n";
import { logger } from "./utils/Log";
import { setupCustomConfig } from "./config";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] })],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: +(process.env.REACT_APP_SENTRY_SAMPLE_RATE || ""),
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
});

const startUp = async () => {
  logger.info("starting up app...");
  setupCustomConfig(window.appConf);
  await loadTranslations();
  logger.info("App startup done");

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
};
startUp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
