import { HTMLAttributes, useMemo } from "react";
import styled from "@emotion/styled/macro";

export interface IProgressTabs
  extends Omit<HTMLAttributes<HTMLDivElement>, "onClick"> {
  pages: string[];
  active?: number;
  lastClickablePage?: number;
  onClick?: (index: number) => void;
}

const ProgressTabsContainer = styled.div`
  display: flex;
  justify-content: center;
  filter: ${(props) => `drop-shadow(${props.theme.dropShadowBase})`};
`;

const Box = styled.div<{ active: boolean; disabled: boolean }>`
  height: 4px;
  width: 20px;
  background-color: ${(props) =>
    props.active ? props.theme.lightblue400 : props.theme.lightgray};
  transition: background-color 0.5s ease;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  margin: 4px;
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
`;

const ProgressTabs = ({
  pages,
  active = -1,
  lastClickablePage = pages.length - 1,
  onClick,
  ...rest
}: IProgressTabs) => {
  const renderBoxs = useMemo(
    () =>
      pages.map((page, index) => {
        const disabled = index > lastClickablePage;

        return (
          <Box
            onClick={() =>
              onClick && index <= lastClickablePage && onClick(index)
            }
            title={page}
            active={active >= index}
            disabled={disabled}
          />
        );
      }),
    [active, lastClickablePage, onClick, pages]
  );

  return <ProgressTabsContainer {...rest}>{renderBoxs}</ProgressTabsContainer>;
};

export default ProgressTabs;
