import styled from "@emotion/styled/macro";
import { Block } from "./interfaces";

export const RadioContainer = styled.div<Block>`
  display: ${(props) => (props.block ? "flex" : " inline-flex")};
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  &:active {
    background: transparent;
  }
  &:hover {
    background: transparent;
  }
  label {
    padding-left: 0.5rem;
  }
`;

export const RadioGroupContainer = styled.div<{ block?: boolean }>`
  display: ${(props) => (props.block ? "block" : "inline-block")};
  width: ${(props) => (props.block ? "100%" : "auto")};
`;

export const RadioGroupContent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 10px;
`;

export const Label = styled.label`
  color: ${(props) => props.theme.gray};
`;
