import { useCallback, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { OverlayTrigger } from "react-bootstrap";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

import { APIError } from "models/generic";
import eye from "assets/CarbonCredit-SVG/ShowPassword.svg";
import {
  captureErrorSentry,
  apiErrorToast,
  emailSchema,
  formatMobileNumberPrefix,
  mobileSchema,
  passwordSchema,
  recaptchaInitErrorToast,
} from "utils";
import {
  requestNewUserOTP,
  validateEmail,
  validatePhone,
  verifyNewUserOTP,
} from "api/users";
import { BaseProfile } from "models/user";
import * as errorCodes from "models/apiErrorCodes";

import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import {
  PasswordPopover,
  OutlineSelect,
  EmailVerification,
  PinSetupAndConfirm,
  Button,
  Label,
  TextField,
  PhoneVerification,
  PhoneInput,
} from "components";
import {
  Title,
  TextDiv,
  ErrorText,
  RegisterComponentGrid,
  Form,
} from "../components";

import "react-phone-input-2/lib/style.css";

const ProfileForm = () => {
  const [openEmailVerify, setOpenEmailVerify] = useState(false);
  const [openPhoneVerify, setOpenPhoneVerify] = useState(false);
  const [openSetupPin, setOpenSetupPin] = useState(false);
  const [showPassword, setShowPasswords] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validEmail, setValidEmail] = useState<boolean | null>(null);
  const [refCode, setRefCode] = useState("");
  const [verifyError, setVerifyError] = useState(false);
  const [emailOTPLoading, setEmailOTPLoading] = useState(false);
  const [phoneOTPLoading, setPhoneOTPLoading] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const schema = Yup.object().shape({
    account_ownership: Yup.string().required("Account Ownership is required"),
    email: emailSchema,
    password: passwordSchema,
    confirm_password: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
    mobile_number: mobileSchema,
  });

  const {
    state: { page, profile, pin },
    dispatch,
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    setValue,
    watch,
    control,
    setError,
  } = useForm<BaseProfile>({
    resolver: yupResolver(schema),
    defaultValues: profile,
    shouldUnregister: true,
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const passwordValue = watch("password");

  const togglePasswordVisiblity = () => {
    setShowPasswords(!showPassword);
  };

  const toggleConfirmPasswordVisiblity = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onConfirmEmail = async (pin: string) => {
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    const recaptchaToken = await executeRecaptcha("request_otp");
    try {
      await verifyNewUserOTP({
        type: "email",
        value: watch("email"),
        reference_code: refCode,
        otp: pin,
      });
      setOpenEmailVerify(false);
      setVerifyError(false);
      setOpenPhoneVerify(true);
      setPhoneOTPLoading(true);
      const mobileRequestOTPRes = await requestNewUserOTP({
        type: "mobile_number",
        value: formatMobileNumberPrefix(watch("mobile_number")),
        recaptcha_token: recaptchaToken,
      });
      setRefCode(mobileRequestOTPRes.data.reference_code);
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.RecaptchaVerificationError) {
          recaptchaInitErrorToast();
        } else {
          apiErrorToast(error);
        }
      }
      setVerifyError(true);
    } finally {
      setPhoneOTPLoading(false);
    }
  };

  const onSuccess = useCallback(() => {
    onSubmitted();
    dispatch({
      type: "SET_PAGE",
      payload: page + 1,
    });
  }, [dispatch, onSubmitted, page]);

  const onConfirmPhone = async (newPin: string) => {
    try {
      await verifyNewUserOTP({
        type: "mobile_number",
        value: formatMobileNumberPrefix(watch("mobile_number")),
        reference_code: refCode,
        otp: newPin,
      });
      if (!pin) {
        setOpenPhoneVerify(false);
        setOpenSetupPin(true);
      } else {
        onSuccess();
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.RecaptchaVerificationError) {
          recaptchaInitErrorToast();
        } else {
          apiErrorToast(error);
        }
      }
      setVerifyError(true);
    }
  };

  const onConfirmPin = (pin: string) => {
    setOpenSetupPin(false);
    dispatch({
      type: "SET_PIN",
      payload: pin,
    });
    onSuccess();
  };

  const onSubmitProfile = useCallback(
    async (values: any) => {
      if (!isDirty) {
        onSuccess();
        return;
      }
      if (!executeRecaptcha) {
        recaptchaInitErrorToast();
        return;
      }
      const recaptchaToken = await executeRecaptcha("request_otp");
      try {
        const emailIsUniqueRes = await validateEmail(values.email);
        const phoneIsUniqueRes = await validatePhone(
          formatMobileNumberPrefix(values.mobile_number)
        );

        if (!emailIsUniqueRes.data.is_unique) {
          setError("email", {
            type: "validate",
            message: "An account with this email has already existed.",
          });
        }
        if (!phoneIsUniqueRes.data.is_unique) {
          setError("mobile_number", {
            type: "validate",
            message: "An account with this mobile number has already existed.",
          });
        }

        if (
          emailIsUniqueRes.data.is_unique &&
          phoneIsUniqueRes.data.is_unique
        ) {
          setOpenEmailVerify(true);
          setEmailOTPLoading(true);
          const requestOTPRes = await requestNewUserOTP({
            type: "email",
            value: values.email,
            recaptcha_token: recaptchaToken,
          });
          setRefCode(requestOTPRes.data.reference_code);
          dispatch({
            type: "SET_PROFILE",
            payload: {
              ...values,
              mobile_number: values.mobile_number,
            },
          });
          setVerifyError(false);
        }
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          if (error.code === errorCodes.RecaptchaVerificationError) {
            recaptchaInitErrorToast();
          } else {
            apiErrorToast(error);
          }
          if (error.code === errorCodes.DatabaseError) {
            captureErrorSentry(error, err);
          }
        }
        setVerifyError(true);
      } finally {
        setEmailOTPLoading(false);
      }
    },
    [dispatch, executeRecaptcha, isDirty, onSuccess, setError]
  );

  const resendEmailOTP = async () => {
    setEmailOTPLoading(true);
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }

    const recaptchaToken = await executeRecaptcha("request_otp");
    try {
      const requestOTPRes = await requestNewUserOTP({
        type: "email",
        value: watch("email"),
        recaptcha_token: recaptchaToken,
      });
      setRefCode(requestOTPRes.data.reference_code);
      setVerifyError(false);
    } catch (err) {
      setVerifyError(true);
    } finally {
      setEmailOTPLoading(false);
    }
  };

  const resendPhoneOTP = async () => {
    setPhoneOTPLoading(true);
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }

    const recaptchaToken = await executeRecaptcha("request_otp");
    try {
      const requestOTPRes = await requestNewUserOTP({
        type: "mobile_number",
        value: formatMobileNumberPrefix(watch("mobile_number")),
        recaptcha_token: recaptchaToken,
      });
      setRefCode(requestOTPRes.data.reference_code);
      setVerifyError(false);
    } catch (err) {
      setVerifyError(true);
    } finally {
      setPhoneOTPLoading(false);
    }
  };

  return (
    <RegisterComponentGrid>
      <EmailVerification
        onClose={() => setOpenEmailVerify(false)}
        onConfirm={onConfirmEmail}
        email={watch("email")}
        show={openEmailVerify}
        isError={verifyError}
        setError={setVerifyError}
        refCode={refCode}
        loading={emailOTPLoading}
        resendFn={resendEmailOTP}
      />
      <PhoneVerification
        onClose={() => setOpenPhoneVerify(false)}
        onConfirm={onConfirmPhone}
        phoneNo={formatMobileNumberPrefix(watch("mobile_number"))}
        show={openPhoneVerify}
        isError={verifyError}
        setError={setVerifyError}
        refCode={refCode}
        loading={phoneOTPLoading}
        resendFn={resendPhoneOTP}
      />
      <PinSetupAndConfirm
        show={openSetupPin}
        onClose={() => setOpenSetupPin(false)}
        onConfirm={onConfirmPin}
        loading={false}
      />
      <Title>Profile</Title>
      <Form
        id="profile"
        onSubmit={handleSubmit(onSubmitProfile)}
        autoComplete="off"
      >
        <OutlineSelect
          label="Account Ownership"
          {...(register("account_ownership"),
          {
            disabled: true,
            defaultValue: "company",
          })}
        >
          <option value="individual">Individual</option>
          <option value="company">Company</option>
        </OutlineSelect>
        <TextField
          label="Email"
          placeholder="Email"
          type="text"
          errorWarn={!!errors.email}
          errorMessage={errors.email?.message}
          defaultValue={profile.email}
          {...register("email")}
          onChange={(e) => {
            if (validEmail === false) {
              setValidEmail(null);
            }
            setValue("email", e.target.value);
          }}
        />
        <OverlayTrigger
          placement="auto-end"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) =>
            PasswordPopover({ password: passwordValue, ...props })
          }
          trigger="focus"
        >
          <TextDiv>
            <TextField
              label="Password"
              placeholder="Password"
              type={showPassword ? "text" : "password"}
              errorWarn={!!errors.password}
              errorMessage={errors.password?.message}
              defaultValue={profile.password}
              {...register("password")}
              icon={eye}
              onIconClick={togglePasswordVisiblity}
            />
          </TextDiv>
        </OverlayTrigger>
        <TextField
          label="Confirm Password"
          placeholder="Confirm Password"
          type={showConfirmPassword ? "text" : "password"}
          errorWarn={!!errors.confirm_password}
          errorMessage={errors.confirm_password?.message}
          defaultValue={profile.confirm_password}
          {...register("confirm_password")}
          icon={eye}
          onIconClick={toggleConfirmPasswordVisiblity}
        />
        <Label htmlFor="phone">Mobile Number</Label>
        <Controller
          control={control}
          name="mobile_number"
          defaultValue={profile.mobile_number}
          render={({ field: { onChange, value } }) => (
            <PhoneInput
              value={value}
              onChange={onChange}
              errorWarn={!!errors.mobile_number}
              errorMessage={errors.mobile_number?.message}
            />
          )}
        />
      </Form>
      <Button block form="profile" type="submit">
        {/* {loading ? (
                    <Spinner
                      style={{ display: "block" }}
                      animation="border"
                      variant="light"
                    />
                  ) : ( */}
        Next
        {/* )} */}
      </Button>
    </RegisterComponentGrid>
  );
};

export default ProfileForm;
