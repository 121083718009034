import { captureException } from "@sentry/react";
import { Extras } from "@sentry/types";
import { AxiosError } from "axios";
import { APIError } from "models/generic";

export const captureErrorSentry = (
  apiError: APIError,
  axiosError: AxiosError,
  options?: {
    message?: string;
    extraData?: Extras;
  }
) => {
  const customMessage = options?.message ? `${options.message} =>` : "";

  const errorMessage = `${customMessage} code: ${apiError.code} ${apiError.message}`;

  return captureException(
    new Error(errorMessage, {
      cause: axiosError,
    }),
    { extra: options?.extraData }
  );
};
