import { ReactNode } from "react";
import styled from "@emotion/styled/macro";

import { ReactComponent as Success } from "assets/CarbonCredit-SVG/Success.svg";
import { Modal, IModal } from "../Modal";
import { Button } from "../../Buttons";

export interface ISuccessModal extends IModal {
  title: string;
  description?: string;
  onClose: () => void;
  icon?: ReactNode;
}

const StyledModal = styled(Modal)`
  .content-container {
    max-width: 540px;
    min-width: 400px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 8px;
`;

const Desc = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const StyledSuccessIcon = styled(Success)`
  display: block;
  margin: 0 auto 16px;
  stroke: ${(props) => props.theme.primaryColor};
`;

const SuccessModal = ({
  title,
  description,
  onClose,
  icon,
  ...rest
}: ISuccessModal) => (
  <StyledModal {...rest}>
    {icon || <StyledSuccessIcon />}
    {title && <Title>{title}</Title>}
    {description && <Desc>{description}</Desc>}
    <Button block onClick={onClose}>
      Close
    </Button>
  </StyledModal>
);

export default SuccessModal;
