import { useState, useMemo, useEffect, useCallback } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate, useLocation } from "react-router-dom";

import { client } from "api/client";
import { GraphCardView, PinCode } from "components";
import {
  ConfirmCancelOrder,
  CardViewTrade,
  InfoCardView,
  OrderBookCardView,
  MarketTradeCardView,
  HistoryCardView,
} from "features/Market";
import { backendURL } from "config";
import { Order, OrderType } from "models/market";
import * as errorCodes from "models/apiErrorCodes";
import { captureErrorSentry, mediaQuery } from "utils";
import { useAuth, useOrder, useUserSocket } from "providers";
import { MyOrderData } from "models/WebsocketClients";
import toast from "react-hot-toast";
import { APIError } from "models/generic";
import axios from "axios";

const Container = styled.div`
  padding: 88px 28px 42px 28px;
  background-color: #f3f6fb;
  display: grid;
  gap: 8px 8px;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(460px, 1.2fr) repeat(3, auto);
  grid-template-areas:
    "Info"
    "Graph"
    "Order"
    "History"
    "Trade";
  width: 100%;
  min-height: 100vh;
  ${mediaQuery("desktop")} {
    grid-template-columns: 1fr 1fr 1fr 0.8fr 1.2fr 2fr 0.4fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto minmax(460px, 1.2fr) 400px;
    grid-template-areas:
      "Info Info Info Info Info Info Order Order Order Order Order"
      "Graph Graph Graph Graph Graph Graph Order Order Order Order Order"
      "History History History History History History Trade Trade Trade Trade Trade";
  }
`;

const OrderWrapper = styled.div`
  display: grid;

  gap: 8px 8px;
  grid-auto-flow: row;
  grid-template-columns: 1fr;
  grid-template-areas:
    "OrderBook"
    "MarketTrade";
  grid-template-rows: 1fr 1fr;
  grid-area: Order;
  ${mediaQuery("mobile")} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "OrderBook OrderBook OrderBook MarketTrade MarketTrade MarketTrade"
      "OrderBook OrderBook OrderBook MarketTrade MarketTrade MarketTrade"
      "OrderBook OrderBook OrderBook MarketTrade MarketTrade MarketTrade";
  }
`;

const OrderBook = styled.div`
  grid-area: OrderBook;
`;

const MarketTrade = styled.div`
  grid-area: MarketTrade;
`;

const Info = styled.div`
  grid-area: Info;
`;

const Graph = styled.div`
  grid-area: Graph;
`;

const History = styled.div`
  grid-area: History;
`;

const Trade = styled.div`
  grid-area: Trade;
`;

export type OverrideOrder = Omit<Order, "amount_filled" | "type"> & {
  type?: OrderType | "cancel";
};

const Market = () => {
  const {
    state: { isLoggedIn },
  } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [pinCodePopup, setPinCodePopup] = useState(false);
  const [isPinError, setIsPinError] = useState(false);
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [isOrderLoading, setIsOrderLoading] = useState(false);
  // const [isInvalidSymbol, setIsInvalidSymbol] = useState(false);
  const [prepareOrderData, setPrepareOrderData] = useState<OverrideOrder>();

  const { state, dispatch } = useOrder();
  const {
    state: { wallets },
    dispatch: userDispatch,
  } = useUserSocket();
  const symbol = location.pathname.split("/")[2];
  const firstSymbol = symbol.split("_")[0];
  const secondSymbol = symbol.split("_")[1];

  const getCurrentSymbolSummary = useCallback(async () => {
    const currentSymbol = `${symbol}`;

    if (state.config.currencyPair !== currentSymbol) {
      dispatch({ type: "SET_CURRENCY_PAIR", payload: currentSymbol });
      userDispatch({ type: "SET_CURRENCY_PAIR", payload: currentSymbol });
    }
  }, [dispatch, state.config.currencyPair, symbol, userDispatch]);

  useEffect(() => {
    // if (!locationRegex.test(location.pathname)) {
    //   navigate("/trade/TVER");
    // } else if (
    //   !enabledSymbols.includes(location.pathname.split("/market/")[1])
    // ) {
    //   // todo remove this case in release
    //   // display modal
    //   setIsInvalidSymbol(true);
    //   getCurrentSymbolSummary();
    // } else {
    getCurrentSymbolSummary();
    // }
  }, [getCurrentSymbolSummary, navigate, location.pathname]);

  const openOrder = (order?: Order) => {
    setPrepareOrderData(order);
    setPinCodePopup(true);
  };

  const cancelOrder = (order: MyOrderData) => {
    const { id, amount, date, side, price } = order;
    setPrepareOrderData({
      id,
      amount: parseFloat(amount),
      rate: parseFloat(price),
      // eslint-disable-next-line camelcase
      submit_time: date,
      side,
      type: "cancel",
    });
    setShowConfirmCancel(true);
  };

  const closePopup = () => {
    setPinCodePopup(false);
    setIsOrderLoading(false);
  };

  const handlePinError = () => {
    setIsOrderLoading(false);
    setIsPinError(true);
  };

  const handleConfirm = async (pin: string, clear?: () => void) => {
    setIsOrderLoading(true);

    if (prepareOrderData !== undefined) {
      if (prepareOrderData.type === "cancel") {
        // todo: add type for this request
        await client
          .post(`${backendURL}/order/cancel`, {
            id: prepareOrderData.id,
            pin,
          })
          .then(() => {
            closePopup();
          })
          .catch((err) => {
            if (axios.isAxiosError(err) && err.response) {
              const error = err.response.data as APIError;
              switch (error?.code) {
                case errorCodes.TradingPinInvalid:
                  clear && clear();
                  handlePinError();
                  break;
                case errorCodes.InvalidPin:
                  toast.error("Incorrect PIN. Try again.");
                  closePopup();
                  break;
                case errorCodes.TradingSessionError:
                  toast.error(
                    "Trading session may be closed.\n\n Please cancel order when trading session is open."
                  );
                  closePopup();
                  break;
                default:
                  toast.error("Something went wrong. Please try again later.");
                  captureErrorSentry(error, err, {
                    message: "Cancel order error",
                  });
                  closePopup();
                  break;
              }
            }
          });
      } else {
        // todo: add type for this request
        await client
          .post(`${backendURL}/order/create`, {
            pair_name: state.config.currencyPair,
            side: prepareOrderData.side,
            type: prepareOrderData.type,
            amount: prepareOrderData.amount,
            price: prepareOrderData.rate,
            pin,
          })
          .then(() => {
            closePopup();
          })
          .catch((err) => {
            console.error(err);
            if (axios.isAxiosError(err) && err.response) {
              const error = err.response.data as APIError;
              switch (error?.code) {
                case errorCodes.TradingPinInvalid:
                  clear && clear();
                  handlePinError();
                  break;
                case errorCodes.TradingMatchOwnMatched:
                  closePopup();
                  toast.error(
                    `Error creating order.\n Cannot create ${
                      prepareOrderData.side
                    } order with price ${
                      prepareOrderData.side === "buy" ? "higher" : "lower"
                    } than your existing ${
                      prepareOrderData.side === "buy" ? "sell" : "buy"
                    } order. `
                  );
                  break;
                case errorCodes.TradingMatchNotEnoughBalance:
                  toast.error("Not enough balance.");
                  captureErrorSentry(error, err, {
                    message: "Not enough balance.",
                  });
                  closePopup();
                  break;
                case errorCodes.InvalidPin:
                  toast.error("Incorrect PIN. Try again.");
                  closePopup();
                  break;
                default:
                  toast.error("Something went wrong. Please try again later.");
                  captureErrorSentry(error, err, {
                    message: "Create order error",
                  });
                  closePopup();
              }
            }
          });
      }
    }
  };

  const { base, quote } = useMemo(() => {
    if (wallets) {
      const firstSymbolWallet = wallets?.find(
        ({ symbol }) => symbol === firstSymbol
      );
      const base = firstSymbolWallet;
      const secondSymbolWallet = wallets?.find(
        ({ symbol }) => symbol === secondSymbol
      );
      const quote = secondSymbolWallet;

      return { base, quote };
    }
    return { base: undefined, quote: undefined };
  }, [firstSymbol, secondSymbol, wallets]);

  return (
    <>
      <PinCode
        onClose={closePopup}
        onConfirm={handleConfirm}
        isError={isPinError}
        setError={setIsPinError}
        loading={isOrderLoading}
        show={pinCodePopup}
        description="Please enter the pin to verify your transaction"
      />
      <ConfirmCancelOrder
        order={prepareOrderData}
        show={showConfirmCancel}
        onClose={() => setShowConfirmCancel(false)}
        onConfirm={() => {
          setPinCodePopup(true);
          setShowConfirmCancel(false);
        }}
      />
      <Container>
        <OrderWrapper>
          <OrderBook>
            <OrderBookCardView />
          </OrderBook>
          <MarketTrade>
            <MarketTradeCardView />
          </MarketTrade>
        </OrderWrapper>
        <Info>
          <InfoCardView />
        </Info>
        <Graph>
          <GraphCardView />
        </Graph>
        <History>
          <HistoryCardView onCancel={cancelOrder} />
        </History>
        <Trade>
          <CardViewTrade
            disabled={!state.isOpen || !isLoggedIn}
            quote={quote}
            base={base}
            onOrder={openOrder}
          />
        </Trade>
      </Container>
    </>
  );
};

export default Market;
