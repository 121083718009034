import { useState } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import axios from "axios";

import eye from "assets/CarbonCredit-SVG/ShowPassword.svg";
import { Button, PasswordPopover, TextField, TextTitle } from "components";
import { apiErrorToast, passwordSchema, recaptchaInitErrorToast } from "utils";
import { backendURL, config } from "config";
import { APIError } from "models/generic";
import { OverlayTrigger, Spinner } from "react-bootstrap";
import { object, ref, string } from "yup";

interface IResetPasswordData {
  password: string;
  confirm_password: string;
}

const { path } = config;

const TextDiv = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Title = styled(TextTitle)`
  color: #1f2c57;
  max-width: 20.5rem;
  margin: 0 auto 24px;
  text-align: center;
  font-size: 30px;
`;

const Container = styled.div``;

const Desc = styled.p`
  text-align: center;
  width: 20.5rem;
`;
interface ResultData {
  token: string;
}

const schema = object().shape({
  password: passwordSchema,
  confirm_password: string().oneOf(
    [ref("password"), null],
    "Passwords must match"
  ),
});

const ResetPass = ({ token }: ResultData) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IResetPasswordData>({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [submitSent, setSubmitSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const passwordValue = watch("password");

  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = async (data: IResetPasswordData) => {
    setLoading(true);
    if (!executeRecaptcha) {
      recaptchaInitErrorToast();
      return;
    }
    const recaptchaToken = await executeRecaptcha("reset_password");
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    axios
      .post(
        `${backendURL}/user/reset-password`,
        {
          new_password: data.password,
          recaptcha_token: recaptchaToken,
        },
        { headers }
      )
      .then(() => {
        setSubmitSent(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
      });
  };

  const handleClickVisibilityPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleClickVisibilityConfirmPassword = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const handleClickBack = () => {
    navigate(path.login, { replace: true });
  };

  return submitSent ? (
    <Container style={{ maxWidth: "22.5rem" }}>
      <Title style={{ width: "22.5rem" }}>
        Your password has been reset successfully.
      </Title>
      <TextDiv style={{ placeContent: "center" }}>
        <Desc style={{ width: "20.5rem" }}>
          You can login to your account with the new password now.
        </Desc>
      </TextDiv>

      <Button block onClick={handleClickBack}>
        Login
      </Button>
    </Container>
  ) : (
    <Container>
      <Title> Create new password</Title>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <TextDiv>
          <Desc>
            Minimum 8 characters with uppercase, lowercase, numbers, and special
            characters
          </Desc>
        </TextDiv>
        <TextDiv>
          <OverlayTrigger
            placement="auto-end"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) =>
              PasswordPopover({ password: passwordValue, ...props })
            }
            trigger="focus"
          >
            <TextDiv>
              <TextField
                label="Password"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                errorWarn={!!errors.password}
                errorMessage={errors.password?.message}
                {...register("password")}
                icon={eye}
                onIconClick={handleClickVisibilityPassword}
              />
            </TextDiv>
          </OverlayTrigger>
        </TextDiv>
        <TextDiv>
          <TextField
            label="Confirm Password"
            placeholder="Confirm Password"
            type={showConfirmPassword ? "text" : "password"}
            errorWarn={!!errors.confirm_password}
            errorMessage={errors.confirm_password?.message}
            {...register("confirm_password")}
            icon={eye}
            onIconClick={handleClickVisibilityConfirmPassword}
          />
        </TextDiv>
        <Button block>
          {loading ? (
            <Spinner style={{ display: "block" }} animation="border" />
          ) : (
            "Submit"
          )}
        </Button>
      </form>
    </Container>
  );
};

export default ResetPass;
