import dayjs from "dayjs";

import { MyOrderData } from "models/WebsocketClients";
import { priceNumber } from "utils";
import { Button } from "components";
import {
  Container,
  Row,
  Sell,
  Table,
  Tbody,
  TdLeft,
  TdRight,
  Th,
  Thead,
  ThLeft,
  ThRight,
  Tr,
  Type,
} from "./styles";

export const ActiveOrder = ({
  onCancel,
  orders,
}: {
  onCancel: (order: MyOrderData) => void;
  orders: MyOrderData[];
}) => {
  const handleCancel = (order: MyOrderData) => () => {
    if (order) {
      onCancel(order);
    }
  };

  return (
    <Container>
      <Table>
        <Thead>
          <Tr>
            <ThLeft>ID</ThLeft>
            <ThLeft>Date</ThLeft>
            <ThLeft>Type</ThLeft>
            <Th>Side</Th>
            <ThRight>Price</ThRight>
            <ThRight>Amount</ThRight>
            <ThRight>Filled</ThRight>
            <ThRight>Total</ThRight>
            <ThRight />
          </Tr>
        </Thead>
        {/* <Line/> */}
        <Tbody data-test-id="active-order__table">
          {orders.map((order) => {
            const { amount, filled, price, side, id, date, total, type } =
              order;
            return (
              <Row key={id}>
                <TdLeft>{id}</TdLeft>
                <TdLeft>{dayjs(date).format("DD-MM-YY HH:mm:ss")}</TdLeft>
                <Type>{type}</Type>
                <Sell side={side || ""}>{side}</Sell>
                <TdRight>{priceNumber(+price)}</TdRight>
                <TdRight>{amount}</TdRight>
                <TdRight>{filled}</TdRight>
                <TdRight>{priceNumber(+total)}</TdRight>
                <TdRight>
                  <Button variant="link" onClick={handleCancel(order)}>
                    Cancel
                  </Button>
                </TdRight>
              </Row>
            );
          })}
        </Tbody>
      </Table>
    </Container>
  );
};
