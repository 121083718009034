import styled from "@emotion/styled/macro";
import { useLayoutEffect, useState } from "react";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSWRConfig } from "swr";

import { ReactComponent as Pen } from "assets/CarbonCredit-SVG/Pen.svg";
import {
  Modal,
  Checkbox,
  Button,
  Label,
  TextField,
  OutlineSelect,
  Radio,
  Tab,
  TabsBar,
} from "components";
import { getBankLogo } from "utils";

import creditCard from "assets/CreditCard.svg";
import { createNewPayment, getPaymentMethods } from "api/paymentMethod";
import { UserPaymentMethod, PaymentMethod } from "models/paymentMethod";
import { DepositWithdrawHeader } from "../components";

interface IBankSelectButton {
  iconSrc: string;
  name: string;
  accountId?: string;
  select?: boolean;
  selected?: boolean;
  onClick: (id: string) => void;
  disabled?: boolean;
}

interface IBankSelect {
  accounts: UserPaymentMethod[];
  selectedAccount?: UserPaymentMethod;
  onSelect: (id: string, setDefault: boolean) => void;
  onBack?: () => void;
  addAble?: boolean;
  label?: string;
}

interface AddMethodForm {
  paymentMethodCode: string;
  accountName: string;
  accountNumber: string;
  isDefault: boolean;
}

const BankSelectButtonContainer = styled.div<{
  selected?: boolean;
  disabled?: boolean;
}>`
  padding: 16px;
  border: 1px solid
    ${(props) =>
      props.selected ? props.theme.primaryColor : props.theme.lightgray};
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  transition: border-color 0.3s;
  cursor: ${(props) => (props.disabled ? "inherit" : "pointer")};
  color: ${(props) => props.disabled && props.theme.darkgray300};
  &:hover {
    border-color: ${(props) => !props.disabled && props.theme.lightblue200};
  }
`;

const Icon = styled.img`
  border-radius: 6px;
`;

const Detail = styled.div`
  margin: 0 auto 0 24px;
`;

const OwnerName = styled.div`
  white-space: nowrap;
`;

const AccountId = styled.div``;

const PenIcon = styled(Pen)`
  margin-left: 1rem;
  fill: ${(props) => props.theme.darkgray};
`;

const Footer = styled.div`
  display: flex;
`;

const SelectBox = styled(Radio)`
  margin-left: 1rem;
`;

const BankSelectButton = ({
  iconSrc,
  name,
  accountId,
  select,
  selected,
  onClick,
  disabled = false,
}: IBankSelectButton) => (
  <BankSelectButtonContainer
    selected={selected}
    onClick={() => {
      if (!disabled) onClick(accountId || "");
    }}
    disabled={disabled}
  >
    <Icon width="48px" height="48px" src={iconSrc} />
    <Detail>
      <OwnerName>{name}</OwnerName>
      <AccountId>{accountId}</AccountId>
    </Detail>
    {!disabled &&
      (select ? <SelectBox checked={selected} value="" /> : <PenIcon />)}
  </BankSelectButtonContainer>
);

const schema = Yup.object().shape({
  accountName: Yup.string().required("account name is required"),
  accountNumber: Yup.string().required("account number is required"),
});

const BankSelect = ({
  accounts,
  selectedAccount,
  onSelect,
  onBack,
  addAble,
  label,
}: IBankSelect) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddMethodForm>({
    resolver: yupResolver(schema),
  });
  const { mutate } = useSWRConfig();

  const [showSelectModal, setShowSelectModal] = useState(false);
  const [shouldSetDefault, setShouldSetDefault] = useState(false);
  const [addingNewAccount, setAddingNewAccount] = useState(false);
  const [newAccountType, setNewAccountType] = useState("BankAccount");
  const [selectedId, setSelectedId] = useState(
    selectedAccount?.account_number ?? ""
  );
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const promptpayMethod = paymentMethods.find(
    (method) => method.name === "promptpay"
  );
  const bankMethods = paymentMethods.filter(
    (method) => method.name !== "promptpay"
  );

  useLayoutEffect(() => {
    if (selectedAccount) {
      setSelectedId(selectedAccount.account_number);
    }
    getPaymentMethods().then((res) =>
      setPaymentMethods(res.data.payment_methods)
    );
  }, [selectedAccount]);

  const onBackClick = () => {
    if (selectedAccount) {
      setAddingNewAccount(false);
    } else {
      setShowSelectModal(false);
    }
  };
  const handleSelect = () => {
    if (selectedId === "new") {
      setAddingNewAccount(true);
    } else {
      setShowSelectModal(false);
      onSelect(selectedId, shouldSetDefault);
    }
  };
  const handleAddNewAccount = (values: AddMethodForm) => {
    createNewPayment({
      payment_method_code:
        newAccountType === "BankAccount"
          ? values.paymentMethodCode
          : promptpayMethod?.code || "",
      account_name: values.accountName,
      account_number: values.accountNumber,
      is_default: shouldSetDefault,
    }).then(() => {
      setAddingNewAccount(false);
      mutate("/payment-method/my");
    });
  };
  const renderModalContent = () => {
    if (addingNewAccount) {
      return (
        <motion.div
          key="select-payment-new"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          style={{ minWidth: "420px" }} // parent max width (500) - parent padding (40x2)
        >
          <DepositWithdrawHeader
            title="New Payment Method"
            onBackClick={onBackClick}
          />
          <form
            id="add-new-payment"
            onSubmit={handleSubmit(handleAddNewAccount)}
            autoComplete="off"
          >
            <TabsBar
              mode="horizontal-pill"
              onSelect={(k: string | null) => {
                setNewAccountType(k || "");
                reset();
              }}
            >
              <Tab key="BankAccount" label="Bank Account">
                <OutlineSelect label="Bank" {...register("paymentMethodCode")}>
                  <option value="" disabled>
                    Select Bank
                  </option>
                  {bankMethods.map((bank) => (
                    <option value={bank.code}>{bank.name}</option>
                  ))}
                </OutlineSelect>
                <TextField
                  label="Bank Account Number"
                  placeholder="Bank Account Number"
                  errorWarn={!!errors.accountNumber}
                  errorMessage={errors.accountNumber?.message}
                  {...register("accountNumber")}
                />
                <TextField
                  label="Bank Account Name"
                  placeholder="Bank Account Name"
                  errorWarn={!!errors.accountName}
                  errorMessage={errors.accountName?.message}
                  {...register("accountName")}
                />
              </Tab>
              <Tab key="PromptPay" label="PromptPay">
                <TextField
                  label="PromptPay Number"
                  placeholder="PromptPay Number"
                  errorWarn={!!errors.accountNumber}
                  errorMessage={errors.accountNumber?.message}
                  {...register("accountNumber")}
                />
                <TextField
                  label="Bank Account Name"
                  placeholder="Bank Account Name"
                  errorWarn={!!errors.accountName}
                  errorMessage={errors.accountName?.message}
                  {...register("accountName")}
                />
              </Tab>
            </TabsBar>
            <Checkbox
              checked={shouldSetDefault}
              type="box"
              label="Save as default"
              onClick={() => setShouldSetDefault(!shouldSetDefault)}
            />
          </form>
          <Footer>
            <Button
              block
              style={{ marginRight: "8px", marginBottom: 0 }}
              variant="secondary"
              onClick={() => setShowSelectModal(false)}
            >
              Cancel
            </Button>
            <Button
              form="add-new-payment"
              block
              style={{ marginBottom: 0 }}
              // onClick={handleAddNewAccount}
              type="submit"
            >
              Add
            </Button>
          </Footer>
        </motion.div>
      );
    }
    return (
      <motion.div
        key="select-payment"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        style={{ minWidth: "420px" }} // parent max width (500) - parent padding (40x2)
      >
        <DepositWithdrawHeader
          title="Select Payment Method"
          onBackClick={onBack || (() => setShowSelectModal(false))}
        />
        {accounts.map((acc) => (
          <BankSelectButton
            onClick={(id) => setSelectedId(id)}
            iconSrc={getBankLogo(acc.payment_method_code)}
            name={acc.account_name}
            accountId={acc.account_number}
            selected={acc.account_number === selectedId}
            select
          />
        ))}
        {addAble && (
          <BankSelectButton
            onClick={() => setSelectedId("new")}
            iconSrc={creditCard}
            name="Other"
            selected={selectedId === "new"}
            select
          />
        )}
        <Checkbox
          checked={shouldSetDefault}
          type="box"
          label="Save as default"
          onClick={() => setShouldSetDefault(!shouldSetDefault)}
        />
        <Footer>
          <Button
            block
            style={{ marginRight: "8px", marginBottom: 0 }}
            variant="secondary"
            onClick={() => setShowSelectModal(false)}
          >
            Cancel
          </Button>
          <Button block style={{ marginBottom: 0 }} onClick={handleSelect}>
            Select
          </Button>
        </Footer>
      </motion.div>
    );
  };
  // TODO: For Future: User Add Payment Method
  return (
    <>
      {label && <Label>{label}</Label>}
      {selectedAccount ? (
        <BankSelectButton
          onClick={() => setShowSelectModal(true)}
          iconSrc={getBankLogo(selectedAccount.payment_method_code)}
          name={selectedAccount?.account_name ?? ""}
          accountId={selectedAccount?.account_number ?? ""}
          disabled
        />
      ) : (
        <BankSelectButton
          onClick={() => {
            setShowSelectModal(true);
            setAddingNewAccount(true);
          }}
          iconSrc={creditCard}
          name="Add Account"
          disabled
        />
      )}
      <Modal show={showSelectModal} transition={{ duration: 0.1 }}>
        {renderModalContent()}
      </Modal>
    </>
  );
};

export default BankSelect;
