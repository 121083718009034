import styled from "@emotion/styled/macro";

import { ReactComponent as ArrowLeft } from "assets/CarbonCredit-SVG/ArrowLeft.svg";

export interface IDepositWithdrawHeader {
  title: string;
  onBackClick: () => void;
}

const DepositWithdrawHeaderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 32px; // 16px icon + 8px padding
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;

const Icon = styled.div`
  border-radius: 50%;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 0;
  background: ${(props) => props.theme.lightgray50};
  cursor: pointer;
  svg {
    display: block;
    stroke: ${(props) => props.theme.secondaryColor};
  }
`;

const Title = styled.h2``;

const DepositWithdrawHeader = ({
  title,
  onBackClick,
}: IDepositWithdrawHeader) => (
  <DepositWithdrawHeaderContainer>
    <Icon onClick={onBackClick}>
      <ArrowLeft />
    </Icon>
    <Title>{title}</Title>
  </DepositWithdrawHeaderContainer>
);

export default DepositWithdrawHeader;
