import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled/macro";

import { TextField, Button, Modal, IModal } from "components";
import eye from "assets/CarbonCredit-SVG/ShowPassword.svg";

export interface IVerifyPasswordValue {
  password: string;
}

interface IVerifyPassword extends IModal {
  onCancel: () => void;
  onConfirm: (val: IVerifyPasswordValue) => void;
}

const StyledModal = styled(Modal)`
  .content-container {
    min-width: 400px;
    max-width: 540px;
  }
`;

const Title = styled.h2`
  margin-bottom: 8px;
  text-align: center;
`;

const Desc = styled.div`
  margin-bottom: 8px;
  text-align: center;
`;

const Footer = styled.div`
  display: flex;
`;

export const VerifyPassword = ({
  onCancel,
  onConfirm,
  show,
  ...rest
}: IVerifyPassword) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IVerifyPasswordValue>();

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show]);

  const togglePasswordVisiblity = () => {
    setIsPasswordVisible((visible) => !visible);
  };

  return (
    <StyledModal show={show} {...rest}>
      <Title>Verify Password</Title>
      <Desc>Enter your password</Desc>
      <form onSubmit={handleSubmit(onConfirm)} autoComplete="off">
        <TextField
          icon={eye}
          type={isPasswordVisible ? "text" : "password"}
          {...register("password")}
          errorWarn={!!errors.password}
          errorMessage={errors.password?.message}
          onIconClick={togglePasswordVisiblity}
        />
        <Footer>
          <Button
            style={{ marginRight: "8px" }}
            onClick={onCancel}
            variant="secondary"
            type="button"
            block
          >
            Cancel
          </Button>
          <Button type="submit" block>
            Confirm
          </Button>
        </Footer>
      </form>
    </StyledModal>
  );
};
