import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "@emotion/react";
import { AuthProvider, SWRProvider } from "providers";
import Router from "router";
import { Toaster } from "react-hot-toast";

import { history } from "utils";
import { Theme as defaultTheme } from "themes/default";
import { GlobalStyle, CustomRouter } from "components";

import "bootstrap/dist/css/bootstrap.min.css";
import { PinProvider } from "providers/PinProvider/PinProvider";

function App() {
  return (
    <CookiesProvider>
      <CustomRouter history={history}>
        <GlobalStyle theme={defaultTheme} />
        <ThemeProvider theme={defaultTheme}>
          <AuthProvider>
            <SWRProvider>
              <PinProvider>
                <Toaster
                  position="bottom-right"
                  toastOptions={{
                    style: {
                      padding: "16px",
                    },
                  }}
                />
                <Router />
              </PinProvider>
            </SWRProvider>
          </AuthProvider>
        </ThemeProvider>
      </CustomRouter>
    </CookiesProvider>
  );
}

export default App;
