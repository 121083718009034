import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { captureException } from "@sentry/react";

import { webSocketURL } from "config";
import { ClientSocket } from "models/WebsocketClients/ClientSocket";
import { Orderbook } from "models/WebsocketClients/Orderbook";
import { Ticker } from "models/WebsocketClients/Ticker";
import { TradingViewDatafeed } from "models/WebsocketClients/TradingViewDatafeed";
import { Trades } from "models/WebsocketClients/Trades";
import { Market } from "models/WebsocketClients/Market";

const chartConfig = {
  url: webSocketURL,
  currencyPair: "",
};

interface Dispatch {
  type: string;
  payload?: any;
}

type wsClients = {
  feed: TradingViewDatafeed | null;
  order: Orderbook | null;
  ticker: Ticker | null;
  trades: Trades | null;
  market: Market | null;
};

type State = {
  config: {
    url: string;
    currencyPair: string;
  };
  isOpen: boolean;
} & wsClients;

const initState = {
  config: chartConfig,
  feed: null,
  order: null,
  ticker: null,
  trades: null,
  market: null,
  myOrder: null,
  isOpen: true, // we assume market is opening by default
} as State;

export const OrderContext = createContext({
  state: initState,
  dispatch: (value: Dispatch) => {},
});

export const useOrder = () => useContext(OrderContext);

const reducer = (state: State, action: Dispatch) => {
  switch (action.type) {
    case "SET_WS_CLIENTS":
      return { ...state, ...action.payload };
    case "SET_CURRENCY_PAIR":
      return {
        ...state,
        config: { ...chartConfig, currencyPair: action.payload },
      };
    case "SET_IS_OPEN":
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
};

export const OrderProvider = ({ children }: { children: ReactNode }) => {
  const [loadedPair, setLoadedPair] = useState("");
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState);
  const clientRef = useRef<ClientSocket | null>(null); // use ref to keep same client instance even if we rerender the component
  const feedRef = useRef<TradingViewDatafeed | null>(null);

  const connectSocket = useCallback(async (currencyPair: string) => {
    clientRef.current = new ClientSocket(chartConfig.url); // we want to create new client(connection) to make sure connection is not closed
    feedRef.current = new TradingViewDatafeed(clientRef.current);
    const client = clientRef.current; // put here because it is null when unmounted
    const feed = feedRef.current;
    setConnecting(true);
    const { location } = window;
    let order: Orderbook | null = null;
    let ticker: Ticker | null = null;
    let trades: Trades | null = null;
    const market = new Market(client);
    if (location.pathname.split("/")[2] === currencyPair) {
      setLoadedPair(initState.config.currencyPair);
      order = new Orderbook(client, currencyPair);
      ticker = new Ticker(client, currencyPair);
      trades = new Trades(client, currencyPair);
    }
    await client
      .connect()
      .then(() => {
        feed.setCurrencyPair(currencyPair);
        client.subscribe(feed);
        dispatch({
          type: "SET_WS_CLIENTS",
          payload: {
            feed,
            order,
            ticker,
            trades,
            market,
          },
        });
        setConnected(true);
        setConnecting(false);
      })
      .catch((err) => {
        console.error("Error connecting to WS", err);
        toast.error(
          "Error connecting to WS. Please refresh the page and try again."
        );
        captureException(new Error("Error connecting to WS", { cause: err }));
      });
  }, []);

  const closeWS = useCallback((connected = false) => {
    console.log("closing WS...");
    const client = clientRef.current; // put here because it is null when unmounted
    if (client) {
      client.close();
      setConnecting(false);
      setConnected(connected);
    }
  }, []);

  // clean up
  useEffect(() => () => closeWS(), [closeWS]);

  useEffect(() => {
    // create the class and bind events based on the page we are on
    // connect to WS
    if (!connecting && !connected) {
      connectSocket(state.config.currencyPair);
      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        closeWS(true); // set connected to true to prevent reconnection
      });
    }
  }, [
    closeWS,
    connectSocket,
    connected,
    connecting,
    state.config.currencyPair,
  ]);

  useEffect(() => {
    const client = clientRef.current; // put here because it is null when unmounted
    const feed = feedRef.current;
    if (
      connected &&
      loadedPair !== state.config.currencyPair &&
      client &&
      feed
    ) {
      setLoadedPair(state.config.currencyPair);
      feed.setCurrencyPair(state.config.currencyPair);
      const newOrder = new Orderbook(client, state.config.currencyPair);
      const newTicker = new Ticker(client, state.config.currencyPair);
      const newTrades = new Trades(client, state.config.currencyPair);
      const newMarket = new Market(client);
      dispatch({
        type: "SET_WS_CLIENTS",
        payload: {
          feed,
          order: newOrder,
          ticker: newTicker,
          trades: newTrades,
          market: newMarket,
        },
      });
    }
  }, [loadedPair, state, connected]);

  const handleVisibilityChange = useCallback(() => {
    // if page is visible, check if we are connected to WS
    if (document.visibilityState === "visible") {
      const connectState = clientRef.current?.getConnectionState();
      if (connectState !== WebSocket.OPEN && !connecting && connected) {
        // reload the page if we nolonger connected to WS
        window.location.reload();
        // todo: try to reconnect instead of reloading
      }
    }
  }, [connecting, connected]);

  useEffect(() => {
    // add event listener for page visibility
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const memoedValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <OrderContext.Provider value={memoedValue}>
      {children}
    </OrderContext.Provider>
  );
};
