import { cloneElement, ReactElement } from "react";
import { IRadio, IRadioGroup } from "./interfaces";

import {
  Label,
  RadioContainer,
  RadioGroupContainer,
  RadioGroupContent,
} from "./styles";

export const Radio = ({
  label,
  checked = false,
  className,
  checkmarkColor = "#fff",
  backgroundColor = "#fff",
  backgroundColorChecked = "#2C2C2C",
  borderColor = "#2C2C2C",
  borderColorChecked = "#2C2C2C",
  labelColor = "#030303",
  onClick,
  id,
  value = "",
  block,
  ...rest
}: IRadio): ReactElement => {
  const handleClick = onClick ? () => onClick(value) : undefined;
  return (
    <RadioContainer className={className} block={block}>
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onClick={handleClick}
        value={value}
        {...rest}
      />
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={handleClick}
      >
        <circle
          cx="8"
          cy="8"
          r="7.5"
          stroke={checked ? borderColorChecked : borderColor}
          fill={checked ? backgroundColorChecked : backgroundColor}
        />
        {checked && <circle cx="8" cy="8" r="3" fill={checkmarkColor} />}
      </svg>
      <label onClick={handleClick} style={{ color: labelColor }}>
        {label}
      </label>
    </RadioContainer>
  );
};

export const RadioGroup = ({
  children,
  equalGridSpace,
  label = "",
  value = "",
  onChange,
  column = false,
  block = true,
}: IRadioGroup): ReactElement => {
  const isChildrenArray = Array.isArray(children);
  const inputCount = isChildrenArray ? children.length : 1;

  const templateColumn = () => {
    if (!column) {
      const template = [];
      for (let i = 0; i < inputCount; i++) {
        template.push(equalGridSpace ? "1fr" : "auto");
      }
      return template.join(" ");
    }
    return "auto";
  };

  const handleChange = (value: string) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <RadioGroupContainer className="radio-group" block={block}>
      {label && <Label>{label}</Label>}
      <RadioGroupContent style={{ gridTemplateColumns: templateColumn() }}>
        {isChildrenArray
          ? children.map((child) =>
              cloneElement(child, {
                checked: value === child.props.value,
                onClick: handleChange,
                block: column,
              })
            )
          : children
          ? cloneElement(children, {
              checked: value === children.props.value,
              onClick: handleChange,
              block: column,
            })
          : null}
      </RadioGroupContent>
    </RadioGroupContainer>
  );
};
