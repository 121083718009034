import { useState } from "react";
import styled from "@emotion/styled/macro";

import { config } from "config";
import { Button, Modal, IModal } from "components";
import tver from "assets/TVER.svg";

const { path } = config;

export type ISymbolDetailTypes = "carbon" | "rec" | "re";
interface Types {
  type?: ISymbolDetailTypes;
}

interface ISymbolDetail extends Omit<IModal, "show">, Types {
  onCancel: () => void;
  showSymbol: string;
  showBlockNumber?: boolean;
}

const StyledModal = styled(Modal)<Types>`
  > .content-container {
    max-width: 640px;
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-column-gap: 32px;
    grid-row-gap: 24px;
    grid-template-areas: ${(props) =>
      props.type === "re"
        ? `"symbol symbol"
        "desc details"
        "_ footer"`
        : `"symbol details"
      "desc details"
      "blockButton footer"`};
  }
`;

const Description = styled.div`
  grid-area: desc;
  max-height: 150px;
  overflow: auto;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Details = styled(Flex)`
  flex-direction: column;
  grid-area: details;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

const BlockNumberGrid = styled(Grid)`
  grid-row-gap: 16px;
  margin-top: 24px;
`;

const Icon = styled.img`
  margin-right: 24px;
`;

const SymbolContainer = styled(Flex)`
  grid-area: symbol;
`;

const SymbolName = styled.div``;

const Name = styled.h2``;

const Price = styled.h3``;

const PriceChange = styled.div<{ side: "buy" | "sell" }>`
  color: ${(props) =>
    props.side === "sell" ? props.theme.red : props.theme.green};
`;

const Normal = styled.div<{ textAlign?: string }>`
  font-size: 0.875rem;
  text-align: ${(props) => props.textAlign};
`;

const Bold = styled(Normal)`
  font-weight: bold;
`;

const Gray = styled(Bold)`
  color: ${(props) => props.theme.darkgray};
`;

const Footer = styled(Flex)`
  grid-area: footer;
  justify-content: flex-end;
`;

const ShowBlockButton = styled(Button)`
  grid-area: blockButton;
`;

const SymbolDetail = ({
  onCancel,
  showSymbol,
  showBlockNumber,
  type = "carbon",
  ...rest
}: ISymbolDetail) => {
  const [showBlockNum, setShowBlockNum] = useState(false);

  const closeBlockNumModal = () => {
    setShowBlockNum(false);
  };

  return (
    <StyledModal
      type={type}
      onBackgroundClick={onCancel}
      show={!!showSymbol}
      {...rest}
    >
      <Modal
        show={showBlockNum}
        closable
        onCloseClick={closeBlockNumModal}
        onBackgroundClick={closeBlockNumModal}
      >
        <Name>Block Number</Name>
        <BlockNumberGrid>
          <Gray>#</Gray>
          <Gray>Block Number</Gray>
          <Normal>1-100</Normal>
          <Normal textAlign="right">10100-10200</Normal>
          <Normal>101</Normal>
          <Normal textAlign="right">10500</Normal>
          <Normal>102-302</Normal>
          <Normal textAlign="right">10600-10800</Normal>
          <Normal>303</Normal>
          <Normal textAlign="right">10900</Normal>
        </BlockNumberGrid>
        {/* <Button block>Close</Button> */}
      </Modal>
      <SymbolContainer>
        <Icon src={tver} />
        <SymbolName>
          <Name>{showSymbol}</Name>
          <Flex>
            <Price>50.00 THB</Price>
            <PriceChange side="sell">-0.25%</PriceChange>
          </Flex>
        </SymbolName>
      </SymbolContainer>
      <Description>
        T-VER is Thailand Voluntary Emission Reduction Program. It is the GHG
        emission reduction program, developed by Thailand Greenhouse Gas
        Management Organization (TGO) to promote and support all sectors to
        voluntarily participate in GHG emission reduction program.
      </Description>
      {showBlockNumber && (
        <ShowBlockButton
          variant="link"
          size="small"
          onClick={() => setShowBlockNum(true)}
        >
          View Block Number
        </ShowBlockButton>
      )}
      <Details>
        <Grid>
          {type === "re" ? (
            <>
              <Bold>Market</Bold>
              <Gray>Renewable Energy</Gray>
              <Bold>Source</Bold>
              <Gray>Solar (SOLA)</Gray>
              <Bold>Dispatch Datetime</Bold>
              <Gray>-</Gray>
            </>
          ) : (
            <>
              <Bold>Market</Bold>
              <Gray>Carbon Credit</Gray>
              <Bold>Standard</Bold>
              <Gray>TVER</Gray>
              <Bold>Project Type</Bold>
              <Gray>Foresty</Gray>
              <Bold>Issuer</Bold>
              <Gray>TGO</Gray>
              <Bold>Issue Date</Bold>
              <Gray>20 Jan 2021 20:15</Gray>
              <Bold>Issue Price</Bold>
              <Gray>15 THB</Gray>
            </>
          )}
        </Grid>
      </Details>
      <Footer>
        <Button
          style={{
            maxWidth: "120px",
            marginRight: "16px",
            marginBottom: 0,
            padding: "10px 14px",
          }}
          block
          variant="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          to={`${path.tradeBase}/${showSymbol}`}
          style={{ maxWidth: "120px", marginBottom: 0, padding: "10px 14px" }}
          block
        >
          Trade
        </Button>
      </Footer>
    </StyledModal>
  );
};

export default SymbolDetail;
