import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { HTMLAttributes } from "react";

export type StatusVariant =
  | "success"
  | "danger"
  | "warning"
  | "primary"
  | "secondary"
  | "disabled";

export interface IStatus extends HTMLAttributes<HTMLDivElement> {
  variant?: StatusVariant;
  normal?: boolean;
}

export const Status = styled.label<IStatus>(
  (props) => css`
    font-weight: ${props.normal ? "normal" : 700};
    font-size: ${props.normal ? "1rem" : "0.875rem"};
    text-transform: ${props.normal ? "none" : "capitalize"};
  `,
  (props) => {
    switch (props.variant) {
      case "success":
        return {
          color: props.theme.neongreen,
        };
      case "danger":
        return {
          color: props.theme.red,
        };
      case "warning":
        return {
          color: props.theme.orange,
        };
      case "disabled":
        return {
          color: props.theme.darkgray,
        };
      case "secondary":
        return {
          color: props.theme.secondaryColor,
        };
      case "primary":
        return {
          color: props.theme.primaryColor,
        };
      default:
        return {
          color: props.theme.textColor,
        };
    }
  }
);
