import { css } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { HTMLAttributes } from "react";

export type BadgeVariant =
  | "success"
  | "danger"
  | "warning"
  | "primary"
  | "secondary"
  | "disabled";

export interface IBadge extends HTMLAttributes<HTMLLabelElement> {
  variant?: BadgeVariant;
}

export const Badge = styled.label<IBadge>(
  (props) => css`
    width: 4.5rem;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: ${props.theme.textColorLight};
    padding: 4px 0;
    border-radius: 24px;
    text-align: center;
    text-transform: capitalize;
  `,
  (props) => {
    switch (props.variant) {
      case "success":
        return {
          backgroundColor: props.theme.neongreen,
        };
      case "danger":
        return {
          backgroundColor: props.theme.red,
        };
      case "warning":
        return {
          backgroundColor: props.theme.orange,
        };
      case "disabled":
        return {
          backgroundColor: props.theme.darkgray,
        };
      case "secondary":
        return {
          backgroundColor: props.theme.secondaryColor,
        };
      case "primary":
      default:
        return {
          backgroundColor: props.theme.primaryColor,
        };
    }
  }
);
