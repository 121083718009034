import { useState } from "react";
import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { bool, mixed, object, string, lazy } from "yup";
import { unionBy } from "lodash-es";
import { Spinner } from "react-bootstrap";
import axios from "axios";

import {
  Button,
  Label,
  LabelRequired,
  RadioGroup,
  UploadInput,
} from "components";
import { AttachmentFiles, Attachments } from "models/user";
import { calculateMd5, fileRequiredSchema } from "utils";
import { APIError } from "models/generic";
import { FileTypes } from "models/file";
import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";

import {
  Desc,
  ErrorText,
  Form,
  NormalRadio,
  RegisterComponentGrid,
  Title,
} from "../components";

enum VatRegistered {
  yes = "YES",
  no = "NO",
}

enum Delegated {
  yes = "YES",
  no = "NO",
}

type uploadedFiles = {
  originalFileHash: string;
  minioName: string;
};

type fileType =
  | "proof_of_company_registration"
  | "meeting_minutes"
  | "retrospective_financial_statement"
  | "bank_book"
  | "director_id_card"
  | "director_house_registration"
  | "proof_of_vat_registration"
  | "director_signature";

const listFileType: fileType[] = [
  "proof_of_company_registration",
  "meeting_minutes",
  "retrospective_financial_statement",
  "bank_book",
  "director_id_card",
  "director_house_registration",
  "proof_of_vat_registration",
  "director_signature",
];

const Header = styled.div`
  margin: 32px auto;
`;

const FILE_SIZE = 6 * 1024 * 1024; // 6MB

const maxSizeFileSchema = fileRequiredSchema.test(
  "fileSize",
  "Maximum File Size is 6MB",
  (file: FileList) => file && (file.item(0)?.size ?? 0) <= FILE_SIZE
);

const lazySchema = lazy((value) => {
  switch (typeof value) {
    case "string":
      return string().required("This field is required");
    default:
      return maxSizeFileSchema;
  }
});

const schema = object({
  proof_of_company_registration: lazySchema,
  meeting_minutes: lazySchema,
  bank_book: lazySchema,
  director_id_card: lazySchema,
  director_house_registration: lazySchema,
  is_vat_registered: bool(),
  proof_of_vat_registration: mixed().when("is_vat_registered", {
    is: true,
    then: lazySchema,
  }),
  director_signature: mixed().test(
    "fileSize",
    "Maximum File Size is 6MB",
    (file: FileList) => file && (file.item(0)?.size ?? 0) <= FILE_SIZE
  ),
  is_delegated: bool(),
});

export const Attachment = () => {
  const [uploadedFiles, setUploadedFiles] = useState<uploadedFiles[]>([]);
  const {
    state: {
      page,
      attachments,
      is_delegated,
      is_vat_registered,
      isLoading,
      error,
    },
    dispatch,
  } = useRegisterContext();

  const localStorageValues = attachments.map((data) => ({
    [data.file_type.toLowerCase() as fileType]: data.file_name,
  }));

  const defaultValues = Object.assign({}, ...localStorageValues);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
    setError,
    control,
  } = useForm<AttachmentFiles>({
    resolver: yupResolver(schema),
    // eslint-disable-next-line camelcase
    defaultValues: { ...defaultValues, is_delegated, is_vat_registered },
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const fileName = (name: fileType) => {
    // check value is localStorage or inputFile
    if (typeof watch(name) === "string") {
      return watch(name) as string;
    }
    const data = watch(name) as FileList;
    return data?.item(0)?.name;
  };

  const onSubmit = async (data: AttachmentFiles) => {
    try {
      dispatch({ type: "LOADING", payload: true }); // get every file in data
      const checkFileLocal = listFileType.map((type) => {
        if (typeof data?.[type] !== "string" && data?.[type]?.length !== 0) {
          return {
            name: type,
            file: data?.[type] as FileList,
          };
        }
        return undefined;
      });

      const uploadedFileHashes = uploadedFiles.map(
        (file) => file.originalFileHash
      );

      const files: {
        name: fileType;
        file: FileList;
        hash: string;
      }[] = [];

      // create array of file name and url
      const fileUrls: Attachments = [];

      // filter data from localStorage out
      await Promise.all(
        checkFileLocal.map(async (data) => {
          const fileInput = data?.file?.item(0);
          const fileBlob = new Blob([fileInput as Blob]);
          const fileHash = await calculateMd5(fileBlob);
          if (data?.file !== undefined) {
            if (!uploadedFileHashes.includes(fileHash ?? "")) {
              files.push({
                ...data,
                hash: fileHash,
              });
            } else {
              const file = uploadedFiles.find(
                (file) => file.originalFileHash === fileHash
              );
              // if file is already uploaded, just add use minioName
              if (file) {
                fileUrls.push({
                  file_name: file.minioName,
                  file_type: data.name.toUpperCase() as FileTypes,
                });
              }
            }
          }
        })
      );

      // if vat registered, add proof of vat registration
      if (
        data.is_vat_registered &&
        typeof data?.proof_of_vat_registration !== "string"
      ) {
        const fileInput = data.proof_of_vat_registration?.item(0);
        const fileBlob = new Blob([fileInput as Blob]);
        const fileHash = await calculateMd5(fileBlob);

        files.push({
          name: "proof_of_vat_registration",
          file: data.proof_of_vat_registration,
          hash: fileHash,
        });
      }

      const newUploadedFiles: uploadedFiles[] = [];

      // upload every file
      const uploadFiles = files.map((fileObj) => {
        const fileInput = fileObj.file?.item(0);

        const blob = new Blob([fileInput as Blob]);
        const contentType = fileInput?.type;
        const formdata = new FormData();

        formdata.append("file_name", fileInput?.name || "");
        formdata.append("upload_file", blob);
        formdata.append("content_type", contentType || "");

        // use then to return promise
        return axios
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/user/upload-file`,
            formdata
          )
          .then((res) => {
            newUploadedFiles.push({
              originalFileHash: fileObj.hash,
              minioName: res.data.file_name,
            });
            return res;
          })
          .catch((err) => {
            // do not throw error, just show error message
            setError(fileObj.name, {
              type: "custom",
              message: err.response.data.message,
            });
            return err;
          });
      });
      const uploadUrlResponses = await Promise.all(uploadFiles);

      dispatch({ type: "LOADING", payload: false });

      setUploadedFiles((oldFiles) => [...oldFiles, ...newUploadedFiles]);

      uploadUrlResponses.forEach((response, index) => {
        if (response.data) {
          fileUrls.push({
            file_type: files[index].name.toUpperCase() as FileTypes,
            file_name: response.data.data.file_name,
          });
        } else {
          throw new Error(response.message);
        }
      });

      // update data to localStorage
      const updatedData = unionBy(fileUrls, attachments, "file_type");

      onSubmitted();
      dispatch({ type: "SET_IS_DELEGATED", payload: data.is_delegated });
      dispatch({
        type: "SET_IS_VAT_REGISTERED",
        payload: data.is_vat_registered,
      });
      dispatch({
        type: "SET_ATTACHMENTS",
        payload: updatedData,
      });
      dispatch({ type: "SET_PAGE", payload: page + 1 });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        dispatch({ type: "ERROR", payload: error });
      }
      dispatch({ type: "LOADING", payload: false });
    }
  };

  return (
    <RegisterComponentGrid>
      <Header>
        <Title>เอกสารแนบ</Title>
        <Desc>เอกสารที่บริษัทต้องรับรองสำเนาถูกต้อง พร้อมประทับตราบริษัท</Desc>
      </Header>
      <Form id="attachment" onSubmit={handleSubmit(onSubmit)}>
        <UploadInput
          id="proof_of_company_registration"
          type="file"
          label="1.1 สำเนาหนังสือรับรองการจดทะเบียนบริษัท จากกระทรวงพาณิชย์
          (ไม่เกิน 6 เดือน)"
          required
          errorWarn={!!errors.proof_of_company_registration}
          errorMessage={errors.proof_of_company_registration?.message}
          placeholder={
            fileName("proof_of_company_registration") || "Please Attach File"
          }
          inputStyle={{
            color: fileName("proof_of_company_registration") && "#1F2C57",
          }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("proof_of_company_registration")}
        />
        <UploadInput
          id="meeting_minutes"
          type="file"
          label={`1.2 สำเนารายงานการประชุม คณะกรรมการบริษัท
          (วาระเกี่ยวข้องเรื่อง "เปิดบัญชีกับ ESCOPOLIS" ลงวันที่ก่อนวันเปิดบัญชี)`}
          required
          errorWarn={!!errors.meeting_minutes}
          errorMessage={errors.meeting_minutes?.message}
          placeholder={fileName("meeting_minutes") || "Please Attach File"}
          inputStyle={{ color: fileName("meeting_minutes") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("meeting_minutes")}
        />
        <UploadInput
          id="retrospective_financial_statement"
          type="file"
          label="1.3 สำเนางบการเงินย้อนหลังล่าสุด 2 ปี (ฉบับผู้สอบบัญชี ตรวจสอบเเล้ว)"
          placeholder={
            fileName("retrospective_financial_statement") ||
            "Please Attach File"
          }
          inputStyle={{
            color: fileName("retrospective_financial_statement") && "#1F2C57",
          }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("retrospective_financial_statement")}
        />
        <UploadInput
          id="bank_book"
          type="file"
          label="1.4 สำเนาสมุดบัญชีธนาคารสำหรับสมัครบริการ ATS หรือรับเงินจากบริษัทฯ"
          required
          errorWarn={!!errors.bank_book}
          errorMessage={errors.bank_book?.message}
          placeholder={fileName("bank_book") || "Please Attach File"}
          inputStyle={{ color: fileName("bank_book") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("bank_book")}
        />
        <UploadInput
          id="director_id_card"
          type="file"
          label="1.5 สำเนาบัตรประชาชน 
          (เฉพาะกรรมการผู้มีอำนาจลงนาม ซึ่งลงนามกับบริษัทฯ เท่านั้น)"
          required
          errorWarn={!!errors.director_id_card}
          errorMessage={errors.director_id_card?.message}
          placeholder={fileName("director_id_card") || "Please Attach File"}
          inputStyle={{ color: fileName("director_id_card") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("director_id_card")}
        />
        <UploadInput
          id="director_house_registration"
          type="file"
          label="1.6 สำเนาทะเบียนบ้านหน้าที่มีเลขบ้าน และชื่อกรรมการ
          (เฉพาะกรรมการผู้มีอำนาจลงนาม ซึ่งลงนามกับบริษัทฯ เท่านั้น)"
          required
          errorWarn={!!errors.director_house_registration}
          errorMessage={errors.director_house_registration?.message}
          placeholder={
            fileName("director_house_registration") || "Please Attach File"
          }
          inputStyle={{
            color: fileName("director_house_registration") && "#1F2C57",
          }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("director_house_registration")}
        />
        <Label>
          1.7 เเบบ ภ.พ. 20 บริษัทได้มีการจดภาษีมูลค่าเพิ่ม (VAT) หรือไม่
          <LabelRequired>*</LabelRequired>
        </Label>
        <Controller
          control={control}
          name="is_vat_registered"
          render={({ field: { value, onChange, ...rest } }) => (
            <RadioGroup
              value={value ? VatRegistered.yes : VatRegistered.no}
              column
              equalGridSpace
              block
              onChange={(val) => onChange(val === VatRegistered.yes)}
              {...rest}
            >
              <NormalRadio label="มี" value={VatRegistered.yes} />
              <NormalRadio label="ไม่มี" value={VatRegistered.no} />
            </RadioGroup>
          )}
        />

        <UploadInput
          id="proof_of_vat_registration"
          type="file"
          errorWarn={!!errors.proof_of_vat_registration}
          errorMessage={errors.proof_of_vat_registration?.message}
          placeholder={
            fileName("proof_of_vat_registration") || "Please Attach File"
          }
          inputStyle={{
            color: fileName("proof_of_vat_registration") && "#1F2C57",
          }}
          disabled={!watch("is_vat_registered")}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("proof_of_vat_registration")}
        />
        <UploadInput
          id="director_signature"
          type="file"
          label="1.8 ตัวอย่างลายเซ็นกรรมการ เฉพาะกรรมการผู้มีอำนาจลงนาม
          ซึ่งลงนามกับบริษัทฯ เท่านั้น แบบฟอร์มการ์ดลายเซ็น"
          errorWarn={!!errors.director_signature}
          errorMessage={errors.director_signature?.message}
          placeholder={fileName("director_signature") || "Please Attach File"}
          inputStyle={{ color: fileName("director_signature") && "#1F2C57" }}
          accept=".pdf, .jpg, .jpeg, .png, .gif"
          {...register("director_signature")}
        />
        <Label>
          มีการมอบอำนาจให้ผู้อื่นกระทำการแทนหรือไม่
          <LabelRequired>*</LabelRequired>
        </Label>
        <Controller
          control={control}
          name="is_delegated"
          render={({ field: { value, onChange, ...rest } }) => (
            <RadioGroup
              value={value ? Delegated.yes : Delegated.no}
              onChange={(val) => {
                onChange(val === Delegated.yes);
              }}
              column
              equalGridSpace
              block
              {...rest}
            >
              <NormalRadio label="มี" value={Delegated.yes} />
              <NormalRadio label="ไม่มี" value={Delegated.no} />
            </RadioGroup>
          )}
        />
      </Form>
      {error && <ErrorText>{error.message}</ErrorText>}
      <Button form="attachment" type="submit" block disabled={isLoading}>
        {isLoading ? (
          <Spinner
            style={{ display: "block" }}
            animation="border"
            variant="light"
          />
        ) : (
          "Next"
        )}
      </Button>
    </RegisterComponentGrid>
  );
};
