import styled from "@emotion/styled/macro";
import { RefObject, useRef, useState } from "react";

import {
  IOTPInputHandles,
  IOTPInputValue,
  OTPInput,
  Button,
  Modal,
} from "components";
import { Registry, RegistryMembership } from "models/registry";

const StyledModal = styled(Modal)`
  text-align: center;
  .content-container {
    max-width: 560px;
  }
  .otp-input-container {
    margin: 24px auto;
  }
`;

const Title = styled.h2`
  margin-bottom: 8px;
`;

const Desc = styled.div`
  line-height: 2;
`;

const Footer = styled.div`
  display: flex;
`;

interface IConfirmDeleteMembership {
  show: boolean;
  onCancel: () => void;
  onConfirm: (account: RegistryMembership | null, pin: string) => void;
  account: RegistryMembership | null;
  isError?: boolean;
  errorMessage?: string;
  isPinLoading?: boolean;
  clearError: () => void;
  registries: Registry[];
}

export const ConfirmDeleteMembership = ({
  show,
  onCancel,
  onConfirm,
  account,
  isError,
  errorMessage,
  isPinLoading,
  clearError,
  registries,
}: IConfirmDeleteMembership) => {
  const otpRef = useRef() as RefObject<IOTPInputHandles>;
  const [pin, setPin] = useState<IOTPInputValue>({
    value: "",
    isFilled: false,
  });

  const handleConfirm = () => {
    onConfirm(account, pin.value || "");
    if (otpRef.current) {
      otpRef.current.clear();
    }
  };

  const handleChange = (_: string, val: IOTPInputValue) => {
    setPin(val);
    isError && clearError();
  };

  return (
    <StyledModal show={show} onBackgroundClick={onCancel}>
      <Title>
        Unlink{" "}
        {
          registries?.find((registry) => registry.id === account?.registry_id)
            ?.name
        }
        ?
      </Title>
      <Desc>
        Unlinking an account may result in <b>CC losses</b>.
      </Desc>
      <Desc>
        Please ensure that account <b>&quot;{account?.account_id}&quot;</b> has
        no pending <b>deposit, withdrawal</b> or <b>retirement</b> of CC.
      </Desc>
      <Desc>Please enter your PIN if you still wish to proceed.</Desc>
      <OTPInput
        ref={otpRef}
        id="pin-code"
        onChange={handleChange}
        count={6}
        invalid={isError}
        loading={isPinLoading}
        invalidMessage={errorMessage}
        type="password"
      />
      <Footer>
        <Button
          style={{ marginRight: "8px" }}
          onClick={onCancel}
          variant="secondary"
          block
        >
          Cancel
        </Button>
        <Button disabled={!pin.isFilled} onClick={handleConfirm} block>
          Confirm
        </Button>
      </Footer>
    </StyledModal>
  );
};
