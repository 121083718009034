import { useState } from "react";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import toast from "react-hot-toast";
import axios from "axios";
import { Spinner } from "react-bootstrap";

import { Button, Modal, Badge, IModal } from "components";
import { BankAccount } from "features/Transaction";
import { Transaction, TransactionById } from "models/transactionRequest";
import { getBankLogo, apiErrorToast, mediaQuery, priceNumber } from "utils";
import { APIError } from "models/generic";
import {
  EntryType,
  TransactionStatus,
  transactionStatusVariant,
} from "models/transaction";
import { updateTransactionRequest } from "api/admin";

const StyledModal = styled(Modal)`
  .content-container {
    padding: 0;
  }
`;
const ModalWrapper = styled.div`
  overflow-y: auto;
  max-height: 100vh;
  ${mediaQuery("mobile")} {
    display: flex;
  }
`;
const ReceiptImageWrapper = styled.div`
  max-width: 440px;
  padding: 40px;
  background-color: ${(props) => props.theme.lightgray50};
  border-radius: 10px 0 0 10px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
  }
`;

const ModalContentContainer = styled.div`
  width: 100vw;
  max-width: 440px;
  padding: 40px;
  text-align: left;
  p {
    margin-bottom: 0.75rem;
  }
  hr {
    margin: 16px 0 24px;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BoldH3 = styled.h3`
  font-weight: 800;
  margin-bottom: 20px;
`;

const NonImageDisplay = styled.div`
  text-align: center;
  padding: 40px 0;
`;

interface IModalContent extends IModal {
  data: Transaction | null;
  bankData: TransactionById;
  handleCloseModalApprove: () => void;
}

const supportedImageExtRegex = /\.(jpg|jpeg|png)/gi;

export const TransactionModal = ({
  data,
  bankData,
  handleCloseModalApprove,
  ...rest
}: IModalContent) => {
  const [loading, setLoading] = useState(false);

  if (!data) return null;

  const name = bankData.account_name;
  const accountId = bankData.account_number;
  const isWithdraw = data.entry_type.toUpperCase() === EntryType.Withdraw;
  const isPending = data.status === TransactionStatus.Pending;

  const dataMap = [
    {
      id: "transaction-id",
      name: "Transaction ID",
      value: bankData?.transaction_id,
    },
    { id: "entry-type", name: "Entry Type", value: bankData?.entry_type },
    { id: "account-id", name: "Account ID", value: bankData?.user_id },
    { id: "email", name: "Email", value: bankData?.email },
    {
      id: "created-date",
      name: "Created Date",
      value: dayjs(bankData?.created_at).format("DD MMM YYYY HH:mm"),
    },
    {
      id: "updated-date",
      name: "Updated Date",
      value: dayjs(bankData?.updated_at).format("DD MMM YYYY HH:mm"),
    },
    { id: "updated-by", name: "Updated By", value: bankData?.updated_by },
  ];

  const handleClickReject = async () => {
    setLoading(true);
    updateTransactionRequest({
      transaction_id: bankData.transaction_id,
      status: TransactionStatus.Rejected,
    })
      .then(() => {
        handleCloseModalApprove();
        setLoading(false);
        toast.success("Reject success");
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
        handleCloseModalApprove();
        setLoading(false);
      });
  };

  const handleClickApprove = () => {
    setLoading(true);
    updateTransactionRequest({
      transaction_id: bankData.transaction_id,
      status: isPending
        ? TransactionStatus.InProcess
        : TransactionStatus.Success,
    })
      .then(() => {
        handleCloseModalApprove();
        setLoading(false);
        toast.success("Approve success");
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
        handleCloseModalApprove();
        setLoading(false);
      });
  };

  const isProofOfPaymentImage =
    bankData.proof_of_payment_file_url &&
    bankData.proof_of_payment_file_url.match(supportedImageExtRegex);

  return (
    <StyledModal className="outer" {...rest}>
      <ModalWrapper data-test-id="money-transaction__modal">
        {!isWithdraw && bankData.proof_of_payment_file_url && (
          <ReceiptImageWrapper>
            {isProofOfPaymentImage ? (
              <img
                src={bankData.proof_of_payment_file_url}
                alt={`deposit-receipt-form-${name}`}
              />
            ) : (
              <NonImageDisplay>
                Could not display the file. Please download the file to view.
                <br />
                <a
                  href={bankData.proof_of_payment_file_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  Download
                </a>
              </NonImageDisplay>
            )}
          </ReceiptImageWrapper>
        )}
        <ModalContentContainer>
          <Row style={{ marginBottom: "1.5rem" }}>
            <Row>
              <h2 style={{ marginRight: "1rem" }}>
                {isPending ? "Request Details" : "Transfer Details"}
              </h2>
              <Badge
                style={{ alignSelf: "center" }}
                variant={transactionStatusVariant(data.status)}
              >
                {data.status.replace("_", " ").toLowerCase()}
              </Badge>
            </Row>
            <div />
          </Row>
          {dataMap?.map(({ id, name, value }) => (
            <Row key={id}>
              <p>{name}</p>
              <p data-test-id={`${id}__text`}>{value}</p>
            </Row>
          ))}
          <hr />
          <BoldH3>Payment Method</BoldH3>
          <BankAccount
            name={name}
            accountId={accountId}
            iconSrc={getBankLogo(bankData.payment_method_code)}
            style={{ marginBottom: "2rem" }}
            loading={!name}
          />
          <Row>
            <BoldH3 style={{ textTransform: "capitalize" }}>
              {data.entry_type.toLowerCase()}
            </BoldH3>
            <BoldH3 data-test-id="amount__text">
              {priceNumber(+data.amount)} THB
            </BoldH3>
          </Row>
          <Row style={{ columnGap: "16px" }}>
            <Button
              onClick={() => handleClickReject()}
              block
              variant="danger"
              disabled={loading}
            >
              Reject
            </Button>
            {(isWithdraw || (!isWithdraw && isPending)) && (
              <Button
                onClick={() => handleClickApprove()}
                block
                disabled={loading}
              >
                {loading && <Spinner animation="border" size="sm" />}
                {!loading && (isPending ? "Approve" : "Mark as Success")}
              </Button>
            )}
          </Row>
        </ModalContentContainer>
      </ModalWrapper>
    </StyledModal>
  );
};
