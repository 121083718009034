import { useState } from "react";
import styled from "@emotion/styled/macro";
import { Spinner } from "react-bootstrap";

import { UserPaymentMethod } from "models/paymentMethod";
import { Button, Modal } from "components";

const StyledModal = styled(Modal)`
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 8px;
`;

const Desc = styled.div`
  margin-bottom: 8px;
`;

const Footer = styled.div`
  display: flex;
`;
interface IConfirmSwitchDefault {
  show: boolean;
  onCancel: () => void;
  onConfirm: (account: UserPaymentMethod | null) => void;
  account: UserPaymentMethod | null;
  loading: boolean;
}

export const ConfirmSwitchDefault = ({
  show,
  onCancel,
  onConfirm,
  account,
  loading,
}: IConfirmSwitchDefault) => {
  const handleBackgroundClick = () => (loading ? null : onCancel());

  return (
    <StyledModal show={show} onBackgroundClick={handleBackgroundClick}>
      <Title>Change default payment method ?</Title>
      <Desc>Press Confirm to set</Desc>
      <Desc>
        &quot;{account?.payment_method_name}&quot; (Account number{" "}
        {account?.account_number})
      </Desc>
      <Desc>as your new payment method.</Desc>
      <Footer>
        <Button
          style={{ marginRight: "8px" }}
          onClick={onCancel}
          variant="secondary"
          block
          disabled={loading}
        >
          Cancel
        </Button>
        <Button onClick={() => onConfirm(account)} block disabled={loading}>
          {loading ? (
            <Spinner
              style={{ display: "block" }}
              animation="border"
              variant="light"
            />
          ) : (
            "Confirm"
          )}
        </Button>
      </Footer>
    </StyledModal>
  );
};
