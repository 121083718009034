import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";

import { webSocketURL } from "config";
import { PrivateClientSocket } from "models/WebsocketClients/ClientSocket";
import { MyOrder } from "models/WebsocketClients/MyOrder";
import { MyTrade } from "models/WebsocketClients/MyTrade";
import { Wallet } from "models/WebsocketClients/Wallet";
import { useAuth } from "providers/AuthProvider";
import { Auth } from "models/WebsocketClients/Auth";
import { WalletRes } from "models/WebsocketClients/WalletData";
import { APIError } from "models/generic";
import * as types from "./types";

export interface UserSocketContextType {
  state: types.State;
  dispatch: (value: types.Actions) => void;
}

const chartConfig = {
  url: `${webSocketURL}/user`,
  currencyPair: "",
};

const initState = {
  config: chartConfig,
  myOrder: null,
  myTrade: null,
  wallet: null,
  authenticated: false,
  wallets: [],
} as types.State;

export const UserSocketContext = createContext<UserSocketContextType>({
  state: initState,
  dispatch: () => {},
});

export const useUserSocket = () => useContext(UserSocketContext);

const reducer = (state: types.State, action: types.Actions) => {
  switch (action.type) {
    case "SET_WS_CLIENTS":
      return { ...state, ...action.payload };
    case "SET_CURRENCY_PAIR":
      return {
        ...state,
        config: { ...chartConfig, currencyPair: action.payload },
      };
    case "SET_AUTHENTICATED":
      return { ...state, authenticated: action.payload };
    case "SET_WALLETS":
      return { ...state, wallets: action.payload };
    default:
      return state;
  }
};

export const UserSocketProvider = ({ children }: { children: ReactNode }) => {
  const {
    state: { token },
  } = useAuth();
  const [loadedPair, setLoadedPair] = useState("");
  const [connecting, setConnecting] = useState(false);
  const [connected, setConnected] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState);
  const clientRef = useRef<PrivateClientSocket | null>(null); // use ref to keep same client instance even if we rerender the component
  const {
    state: { isLoggedIn },
  } = useAuth();

  const connectSocket = useCallback(
    async (currencyPair: string) => {
      clientRef.current = new PrivateClientSocket(chartConfig.url); // we want to create new client(connection) to make sure connection is not closed
      const client = clientRef.current; // put here because it is null when unmounted
      setConnecting(true);
      const { location } = window;
      let myOrder: MyOrder | null = null;
      let myTrade: MyTrade | null = null;
      let wallet = new Wallet(client);
      if (location.pathname.split("/")[2] === currencyPair) {
        setLoadedPair(initState.config.currencyPair);
        // todo check for user login
        myOrder = new MyOrder(client, currencyPair);
        myTrade = new MyTrade(client, currencyPair);
        wallet = new Wallet(client, currencyPair);
      }
      await client
        .connect()
        .then(() => {
          if (token) {
            const auth = new Auth(client);
            auth.onData = (data) => {
              if (data.success === true) {
                dispatch({ type: "SET_AUTHENTICATED", payload: true });
              } else {
                // incase auth fail or token is expired
                dispatch({ type: "SET_AUTHENTICATED", payload: false });
              }
            };
            auth.authenticate(token);
          }
        })
        .catch((err) => {
          console.error("Error connecting to WS", err);
          toast.error(
            "Error connecting to WS. Please refresh the page and try again."
          );
          setConnected(false);
          setConnecting(false);
        })
        .then(() => {
          dispatch({
            type: "SET_WS_CLIENTS",
            payload: {
              myOrder,
              myTrade,
              wallet,
            },
          });
          setConnected(true);
          setConnecting(false);
        });
    },
    [token]
  );

  const closeWS = useCallback((connected = false) => {
    console.log("closing User's WS...");
    const client = clientRef.current; // put here because it is null when unmounted
    if (client) {
      client.close();
      setConnecting(false);
      setConnected(connected);
    }
  }, []);

  // clean up
  useEffect(() => () => closeWS(), [closeWS]);

  useEffect(() => {
    // create the class and bind events based on the page we are on
    // connect to WS
    if (isLoggedIn && !connecting && !connected) {
      connectSocket(state.config.currencyPair);
      window.addEventListener("beforeunload", (ev) => {
        ev.preventDefault();
        closeWS(true); // set connected to true to prevent reconnection
      });
    } else if (!isLoggedIn && connected) {
      closeWS();
    }
  }, [
    closeWS,
    connectSocket,
    isLoggedIn,
    connected,
    connecting,
    state.config.currencyPair,
  ]);

  useEffect(() => {
    const client = clientRef.current; // put here because it is null when unmounted
    if (connected && loadedPair !== state.config.currencyPair && client) {
      setLoadedPair(state.config.currencyPair);
      // todo check for user login
      const newMyOrder = new MyOrder(client, state.config.currencyPair);
      const newMyTrade = new MyTrade(client, state.config.currencyPair);
      const newWallet = new Wallet(client, state.config.currencyPair);
      dispatch({
        type: "SET_WS_CLIENTS",
        payload: {
          myOrder: newMyOrder,
          myTrade: newMyTrade,
          wallet: newWallet,
        },
      });
    }
  }, [loadedPair, state, connected]);

  const handleData = (data: WalletRes) => {
    if (data.wallets) {
      dispatch({
        type: "SET_WALLETS",
        payload: data.wallets,
      });
    }
  };

  const handleError = (error: APIError) => {
    console.error("Error in wallet: ", error);
  };

  useLayoutEffect(() => {
    if (state.wallet && state.authenticated) {
      state.wallet.subscribe();
      state.wallet.onData = handleData;
      state.wallet.onError = handleError;
      console.log("subscribe wallet");
    }
    return () => {
      if (state.wallet) {
        state.wallet.unsubscribe();
      }
    };
  }, [state.authenticated, state.wallet]);

  const handleVisibilityChange = useCallback(() => {
    // if page is visible, check if we are connected to WS
    if (document.visibilityState === "visible") {
      const connectState = clientRef.current?.getConnectionState();
      if (connectState !== WebSocket.OPEN && !connecting && connected) {
        // reload the page if we nolonger connected to WS
        window.location.reload();
        // todo: try to reconnect instead of reloading
      }
    }
  }, [connecting, connected]);

  useEffect(() => {
    // add event listener for page visibility
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  const memoedValue = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <UserSocketContext.Provider value={memoedValue}>
      {children}
    </UserSocketContext.Provider>
  );
};
