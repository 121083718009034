import { Theme } from "@emotion/react";
import { Theme as defaultTheme } from "themes/default";

type MediaQuery = "mobile" | "tablet" | "desktop" | "large";

/**
 *
 * @param size 'mobile' = '576px' | 'tablet' = '768px' | 'desktop' = '996px' | 'large' = '1200px'  ;
 * @param customTheme = custom theme object from props.theme or useTheme
 * @returns `@media (min-width: ${size}px)`
 */
export const mediaQuery = (size: MediaQuery, customTheme?: Theme) => {
  const theme = customTheme || defaultTheme;
  switch (size) {
    case "mobile":
      return `@media (min-width: ${theme.breakpointMobile})`;
    case "tablet":
      return `@media (min-width: ${theme.breakpointTablet})`;
    case "desktop":
      return `@media (min-width: ${theme.breakpointDesktop})`;
    case "large":
      return `@media (min-width: ${theme.breakpointLargeDesktop})`;
    default:
      return ``;
  }
};
