import { RefObject, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import { css, keyframes } from "@emotion/react/macro";

import mailbox from "assets/CarbonCredit-SVG/Mailbox.svg";
import lock from "assets/CarbonCredit-SVG/Lock.svg";
import phone from "assets/CarbonCredit-SVG/Phone.svg";
import { Button } from "../Buttons";
import { Modal } from "../Modal";
import { TextTitle, TextContent } from "../Field";

import { OTPInput } from "./OTPInput";
import {
  IOTPInput,
  IOTPInputHandles,
  IOTPInputValue,
} from "./OTPInput.interfaces";

const shake = keyframes`
 10%, 90% {
    transform: translate(0, 0);
  }
  
  20%, 80% {
    transform: translate(-2%, 0);
  }

  30%, 50%, 70% {
    transform: translate(2%, 0);
  }

  40%, 60% {
    transform: translate(-3%, 0);
  }
`;
const StyledModal = styled(Modal, {
  shouldForwardProp: (props) => props !== "isError",
})<{ isError: boolean }>`
  .content-container {
    box-shadow: none;
    min-width: 400px;
    max-width: 540px;
    ${({ isError }) =>
      isError
        ? css`
            transition-duration: 250ms;
            animation-timing-function: ease-in;
            animation: ${shake} 0.4s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
          `
        : ""}
  }
`;

const Title = styled(TextTitle)`
  width: 100%;
  text-align: center;
  font-size: 22px;
  color: #1f2c57;
  margin-bottom: 8px;
`;

const Content = styled(TextContent)`
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: #1f2c57;
  margin-bottom: 24px;
`;

const Action = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

const StyledImg = styled.img`
  margin: 0 auto;
`;

export interface PinCodeProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (pin: string, clear?: () => void) => void;
  isError?: boolean;
  errorMessage?: string;
  setError?: (val: boolean) => void;
  loading?: boolean;
  showDescription?: boolean;
  description?: string;
}

export const PinCode = ({
  show,
  onClose,
  onConfirm,
  isError = false,
  errorMessage = "The pin is invalid. Please enter the valid pin",
  setError,
  loading = false,
  showDescription = true,
  description = "Enter your current pin",
}: PinCodeProps) => {
  const otpRef = useRef() as RefObject<IOTPInputHandles>;
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  const handleConfirm = () => {
    if (completed) {
      onConfirm(code, otpRef.current?.clear);
    }
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCode(val);
    setCompleted(isFilled);
    if (setError && isError && isFilled) {
      // change error back to false on filled
      setError(false);
    }
  };

  useLayoutEffect(() => {
    if (show) {
      setCode("");
      setCompleted(false);
    }
  }, [show]);

  useLayoutEffect(() => {
    if (isError) {
      otpRef.current?.focus();
      otpRef.current?.clear();
    }
  }, [isError, setError]);

  return (
    <StyledModal show={show} onBackgroundClick={onClose} isError={isError}>
      <Title>Verify Pin</Title>
      {showDescription && <Content>{description}</Content>}
      <OTPInput
        ref={otpRef}
        id="pin-code"
        onChange={handleChange}
        count={6}
        invalid={isError}
        loading={loading}
        invalidMessage={errorMessage}
        type="password"
      />
      <Action>
        <Button
          block
          variant="secondary"
          onClick={onClose}
          disabled={loading}
          style={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button block onClick={handleConfirm} disabled={!completed || loading}>
          Confirm
        </Button>
      </Action>
    </StyledModal>
  );
};

export interface EmailVerificationProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (pin: string) => void;
  isError?: boolean;
  setError?: (val: boolean) => void;
  loading?: boolean;
  email: string;
  refCode?: string;
  resendFn?: () => void;
}

export const EmailVerification = ({
  show,
  onClose,
  onConfirm,
  isError = false,
  setError,
  loading = false,
  email,
  refCode,
  resendFn,
}: EmailVerificationProps) => {
  const pinInputRef = useRef() as RefObject<IOTPInputHandles>;
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  const handleConfirm = () => {
    if (completed) {
      onConfirm(code);
    }
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCode(val);
    setCompleted(isFilled);
    if (setError && isError && isFilled) {
      // change error back to false on filled
      setError(false);
    }
  };

  useLayoutEffect(() => {
    if (show) {
      setCode("");
      setCompleted(false);
      pinInputRef.current?.countdown();
      pinInputRef.current?.focus();
    }
  }, [show]);

  useLayoutEffect(() => {
    if (isError) {
      pinInputRef.current?.focus();
      pinInputRef.current?.clear();
    }
  }, [isError, setError]);

  return (
    <StyledModal show={show} isError={isError}>
      <div data-test-id="email-verification__content">
        <ImageContainer>
          <StyledImg src={mailbox} />
        </ImageContainer>
        <Title>Email Verification</Title>
        <Content>
          Please enter OTP from email {email} to confirm registration.
        </Content>
        {refCode && <Content>Ref: {refCode}</Content>}
        <OTPInput
          ref={pinInputRef}
          id="pin-code"
          onChange={handleChange}
          count={6}
          invalid={isError}
          loading={loading}
          invalidMessage="Verify pin is wrong. please try again."
          type="password"
          resendBtn
          delayResend
          resendFn={resendFn}
        />
        <Action>
          <Button
            testId="email-verification__submit-button"
            block
            variant="secondary"
            onClick={onClose}
            disabled={loading}
            style={{
              marginRight: "8px",
            }}
          >
            Cancel
          </Button>
          <Button
            block
            onClick={handleConfirm}
            disabled={!completed || loading}
          >
            Confirm
          </Button>
        </Action>
      </div>
    </StyledModal>
  );
};

export interface IConfirmNewEmail {
  email: string;
  newEmail: string;
  show: boolean;
  onClose: () => void;
  onConfirm: (pin: string) => void;
  loading?: boolean;
  isError?: boolean;
  setError?: (val: boolean) => void;
  resendFn?: () => void;
  refCode?: string;
  onConfirmOldMail: (pin: string, cb: () => void) => void;
}

export const ConfirmNewEmail = ({
  email,
  newEmail,
  show,
  onClose,
  onConfirm,
  loading = false,
  isError = false,
  setError,
  refCode,
  resendFn,
  onConfirmOldMail,
}: IConfirmNewEmail) => {
  const pinInputRef = useRef() as RefObject<IOTPInputHandles>;
  const [step, setStep] = useState(1);
  const [currentPin, setCurrentPin] = useState("");
  const [currentCompleted, setCurrentCompleted] = useState(false);

  const handleSubmit = () => {
    onConfirm(currentPin);
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCurrentPin(val);
    setCurrentCompleted(isFilled);
    if (setError && isError && isFilled) {
      // change error back to false on filled
      setError(false);
    }
  };

  const onCompleteStepOne = () => {
    onConfirmOldMail(currentPin, () => setStep(2));
    pinInputRef.current?.clear();
  };

  useLayoutEffect(() => {
    if (show) {
      setStep(1);
      setCurrentPin("");
      setCurrentCompleted(false);
      setError && setError(false);
      pinInputRef.current?.countdown();
      pinInputRef.current?.focus();
    }
  }, [setError, show]);

  useLayoutEffect(() => {
    if (isError) {
      pinInputRef.current?.focus();
      pinInputRef.current?.clear();
    }
  }, [isError, setError]);

  return (
    <StyledModal show={show} onBackgroundClick={onClose} isError={isError}>
      <Title>
        {step === 1 ? "Current Email Verification" : "New Email Verification"}
      </Title>
      <Content>
        <div>Please enter OTP from email</div>
        <div>({step === 1 ? email : newEmail}) to confirm change.</div>
      </Content>
      {refCode && <Content>Ref: {refCode}</Content>}
      <OTPInput
        ref={pinInputRef}
        id="pin-code"
        onChange={handleChange}
        count={6}
        invalid={isError}
        loading={loading}
        type="password"
        resendBtn
        delayResend
        resendFn={resendFn}
      />
      <Action>
        <Button
          block
          variant="secondary"
          onClick={onClose}
          disabled={loading}
          style={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          block
          onClick={step === 1 ? onCompleteStepOne : handleSubmit}
          disabled={!currentCompleted || loading}
        >
          Confirm
        </Button>
      </Action>
    </StyledModal>
  );
};

export interface IPinSetup extends Omit<IOTPInput, "count"> {}

export const PinSetup = ({ ...rest }: IPinSetup) => (
  <>
    <Title>Pin Setup</Title>
    <Content style={{ marginBottom: "8px" }}>
      Set the pin for transaction verification.
    </Content>
    <OTPInput
      id="pin-code"
      invalidMessage="Verify pin is wrong. please try again."
      type="password"
      {...rest}
      count={6}
    />
  </>
);

export interface IPinSetupAndConfirm {
  show: boolean;
  onClose: () => void;
  onConfirm: (pin: string) => void;
  loading?: boolean;
}

export const PinSetupAndConfirm = ({
  show,
  onClose,
  onConfirm,
  loading = false,
}: IPinSetupAndConfirm) => {
  const pinInputRef = useRef() as RefObject<IOTPInputHandles>;
  const [step, setStep] = useState(1);
  const [firstPin, setFirstPin] = useState("");
  const [currentPin, setCurrentPin] = useState("");
  const [currentCompleted, setCurrentCompleted] = useState(false);
  const [isError, setError] = useState(false);

  const handleSubmit = () => {
    if (firstPin !== currentPin) {
      pinInputRef.current?.clear();
      setCurrentCompleted(false);
      setError(true);
    } else {
      onConfirm(firstPin);
    }
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCurrentPin(val);
    setCurrentCompleted(isFilled);
    if (isError && isFilled) {
      // change error back to false on filled
      setError(false);
    }
  };

  const onCompleteStepOne = () => {
    setFirstPin(currentPin);
    pinInputRef.current?.clear();
    setStep(2);
  };

  useLayoutEffect(() => {
    if (show) {
      setStep(1);
      setFirstPin("");
      setCurrentPin("");
      setCurrentCompleted(false);
      setError(false);
    }
  }, [show]);

  useLayoutEffect(() => {
    if (isError) {
      pinInputRef.current?.focus();
      pinInputRef.current?.clear();
    }
  }, [isError, setError]);

  const stepKey = step === 1 ? "pin-setup" : "confirm-pin";
  return (
    <StyledModal show={show} onBackgroundClick={onClose} isError={isError}>
      <div data-test-id={`${stepKey}__content`}>
        <ImageContainer>
          <StyledImg src={lock} />
        </ImageContainer>
        <Title>{step === 1 ? "Pin Setup" : "Confirm Pin Setup"}</Title>
        <Content>
          {step === 1
            ? "Please enter you pin."
            : "Please re-enter the new pin for confirmation"}
        </Content>
        <OTPInput
          ref={pinInputRef}
          id="pin-code"
          onChange={handleChange}
          count={6}
          invalid={isError}
          loading={loading}
          type="password"
        />
        <Action>
          <Button
            block
            variant="secondary"
            onClick={onClose}
            disabled={loading}
            style={{
              marginRight: "8px",
            }}
          >
            Cancel
          </Button>
          <Button
            testId={`${stepKey}__submit-button`}
            block
            onClick={step === 1 ? onCompleteStepOne : handleSubmit}
            disabled={!currentCompleted || loading}
          >
            Confirm
          </Button>
        </Action>
      </div>
    </StyledModal>
  );
};

export interface PhoneVerificationProps {
  show: boolean;
  onClose: () => void;
  onConfirm: (pin: string) => void;
  isError?: boolean;
  setError?: (val: boolean) => void;
  loading?: boolean;
  phoneNo: string;
  refCode?: string;
  resendFn?: () => void;
}

export const PhoneVerification = ({
  show,
  onClose,
  onConfirm,
  isError = false,
  setError,
  loading = false,
  phoneNo,
  refCode,
  resendFn,
}: PhoneVerificationProps) => {
  const pinInputRef = useRef() as RefObject<IOTPInputHandles>;
  const [code, setCode] = useState("");
  const [completed, setCompleted] = useState(false);
  const handleConfirm = () => {
    if (completed) {
      onConfirm(code);
    }
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCode(val);
    setCompleted(isFilled);
    if (setError && isError && isFilled) {
      // change error back to false on filled
      setError(false);
    }
  };

  useLayoutEffect(() => {
    if (show) {
      setCode("");
      setCompleted(false);
      pinInputRef.current?.countdown();
      pinInputRef.current?.focus();
    }
  }, [show]);

  useLayoutEffect(() => {
    if (isError) {
      pinInputRef.current?.focus();
      pinInputRef.current?.clear();
    }
  }, [isError, setError]);

  return (
    <StyledModal show={show} onBackgroundClick={onClose} isError={isError}>
      <div data-test-id="phone-verification__content">
        <ImageContainer>
          <StyledImg src={phone} />
        </ImageContainer>
        <Title>Phone Verification</Title>
        <Content>Please enter 6 digits code sent to</Content>
        <Content>{phoneNo}.</Content>
        {refCode && <Content>Ref: {refCode}</Content>}
        <OTPInput
          ref={pinInputRef}
          id="pin-code"
          onChange={handleChange}
          count={6}
          invalid={isError}
          loading={loading}
          invalidMessage="Verify pin is wrong. please try again."
          type="password"
          resendBtn
          delayResend
          resendFn={resendFn}
        />
        <Action>
          <Button
            block
            variant="secondary"
            onClick={onClose}
            disabled={loading}
            style={{
              marginRight: "8px",
            }}
          >
            Cancel
          </Button>
          <Button
            testId="phone-verification__submit-button"
            block
            onClick={handleConfirm}
            disabled={!completed || loading}
          >
            Confirm
          </Button>
        </Action>
      </div>
    </StyledModal>
  );
};

export interface IConfirmNewPhone {
  phone: string;
  email: string;
  show: boolean;
  onClose: () => void;
  onConfirm: (pin: string) => void;
  loading?: boolean;
  isError?: boolean;
  setError?: (val: boolean) => void;
  refCode?: string;
  resendFn?: () => void;
  onConfirmEmail: (pin: string, cb: () => void) => void;
}

export const ConfirmNewPhone = ({
  phone,
  email,
  show,
  onClose,
  onConfirm,
  loading = false,
  refCode,
  resendFn,
  isError = false,
  setError,
  onConfirmEmail,
}: IConfirmNewPhone) => {
  const pinInputRef = useRef() as RefObject<IOTPInputHandles>;
  const [step, setStep] = useState(1);
  const [currentPin, setCurrentPin] = useState("");
  const [currentCompleted, setCurrentCompleted] = useState(false);

  const handleSubmit = () => {
    onConfirm(currentPin);
  };

  const handleChange = (val: string, { isFilled }: IOTPInputValue) => {
    setCurrentPin(val);
    setCurrentCompleted(isFilled);
    if (setError && isError && isFilled) {
      // change error back to false on filled
      setError(false);
    }
  };

  const onCompleteStepOne = () => {
    pinInputRef.current?.clear();
    onConfirmEmail(currentPin, () => setStep(2));
  };

  useLayoutEffect(() => {
    if (show) {
      setStep(1);
      setCurrentPin("");
      setCurrentCompleted(false);
      setError && setError(false);
      pinInputRef.current?.countdown();
      pinInputRef.current?.focus();
    }
  }, [setError, show]);

  useLayoutEffect(() => {
    if (isError) {
      pinInputRef.current?.focus();
      pinInputRef.current?.clear();
    }
  }, [isError, setError]);

  return (
    <StyledModal show={show} onBackgroundClick={onClose} isError={isError}>
      <Title>
        {step === 1 ? "Email Verification" : "New Phone Number Verification"}
      </Title>
      <Content>
        <div>
          {step === 1
            ? "Please enter OTP form email"
            : `Message has been sent to ${phone}.`}
        </div>
        <div>
          {step === 1
            ? `(${email}) to confirm change.`
            : "Please enter OTP to confirm change."}
        </div>
      </Content>
      {refCode && <Content>Ref: {refCode}</Content>}
      <OTPInput
        ref={pinInputRef}
        id="pin-code"
        onChange={handleChange}
        count={6}
        invalid={isError}
        loading={loading}
        type="password"
        resendBtn
        delayResend
        resendFn={resendFn}
      />
      <Action>
        <Button
          block
          variant="secondary"
          onClick={onClose}
          disabled={loading}
          style={{
            marginRight: "8px",
          }}
        >
          Cancel
        </Button>
        <Button
          block
          onClick={step === 1 ? onCompleteStepOne : handleSubmit}
          disabled={!currentCompleted || loading}
        >
          Confirm
        </Button>
      </Action>
    </StyledModal>
  );
};
