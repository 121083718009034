import { APIError } from "models/generic";
import { PrivateClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { WalletReq, WalletData, WalletRes } from "./WalletData";

/**
 * Wallet is a websocket client receiving live trades from the server.
 */
export class Wallet extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.WALLET;

  private currencyPair: string = "";

  constructor(socket: PrivateClientSocket, pair?: string) {
    super(socket);
    this.currencyPair = pair || "";
  }

  public onData(data: WalletRes): void {
    console.log("Wallet", data);
    let wallets: WalletData[] = [];
    if (data.wallets !== undefined) {
      wallets = data.wallets;
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe() {
    this.socket.subscribe(this);
    const reqData: WalletReq = {
      client_id: this.subsciberID,
      pair_name: this.currencyPair,
    };
    this.send(reqData);
  }

  public unsubscribe() {
    this.send({ unsubscribe_client_id: this.subsciberID });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: WalletReq) {
    return super.sendInternal({
      wallet: data,
      channel_id: this.subsciberID,
    });
  }
}
