import toast, { ErrorIcon } from "react-hot-toast";
import { APIError } from "models/generic";

export const apiErrorToast = (error: APIError, id?: string) => {
  toast(
    <span>
      <span>{error.message}</span>
      <br />
      <span
        style={{
          fontSize: "12px",
          color: "#9DAAC6",
        }}
      >
        code: {error.code}
      </span>
    </span>,
    {
      icon: (
        <ErrorIcon
          style={{
            minWidth: "20px",
          }}
        />
      ),
      id,
    }
  );
};

export const recaptchaInitErrorToast = () => {
  toast.error("Could not verify captcha, Please refresh the page");
};
