import { APIError } from "models/generic";
import { PrivateClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { MyTradeReq, MyTradeData, MyTradeRes } from "./MyTradeData";

/**
 * MyTrade is a websocket client receiving live trades from the server.
 */
export class MyTrade extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.MYTRADE;

  private currencyPair: string = "";

  constructor(socket: PrivateClientSocket, pair: string) {
    super(socket);
    this.currencyPair = pair;
  }

  public onData(data: MyTradeRes): void {
    console.log("MyTrade", data);
    let trades: MyTradeData[] = [];
    if (data.trades !== undefined) {
      trades = data.trades;
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );

    // TODO Max display section 4 on UI
    // use trades[0].rate, trades[0].amount
    // make sure to remove old trades again (don't store them forever in array or DOM tree)
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe(status?: string) {
    this.socket.subscribe(this);
    const reqData: MyTradeReq = {
      client_id: this.subsciberID + status,
      pair_name: this.currencyPair,
    };
    this.send(reqData);
  }

  public unsubscribe(status?: string) {
    this.send({ unsubscribe_client_id: this.subsciberID + status });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: MyTradeReq) {
    return super.sendInternal({
      my_trade: data,
      channel_id: this.subsciberID,
    });
  }
}
