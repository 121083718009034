import styled from "@emotion/styled/macro";

import { Card } from "./Card";
import { TVChartContainer } from "../TradingView";

const CardView = styled(Card)`
  width: 100%;
  height: 100%;
  padding: 0;
`;

const Frame = styled.div`
  .TVChartContainer {
    width: 100%;
    height: 100%;
  }
  width: 100%;
  height: 100%;
  border: none;
  padding: 10px;
`;

const Graph = () => (
  <CardView>
    <Frame>
      <TVChartContainer />
    </Frame>
  </CardView>
);

export default Graph;
