import { APIError } from "models/generic";
import { ClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import {
  OrderbookFull,
  OrderReq,
  OrderRes,
  OrderbookReturn,
} from "./OrderbookData";

/**
 * Orderbook keeps a local copy of all bids and ask from the server.
 * It requests the full book on first connect and then receives partial updates.
 */
export class Orderbook extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.ORDERBOOK;

  private currencyPair: string = "";

  private bookFull: OrderbookFull | undefined = undefined;

  private callback: ((val: OrderbookReturn) => void) | undefined = undefined;

  constructor(socket: ClientSocket, pair: string) {
    super(socket);
    this.currencyPair = pair;
  }

  public onData(data: OrderRes): void {
    // console.log("ORDERBOOK DATA", data.full)
    // if (data.full !== undefined) {
    //   // 1st connect: we receive all orders
    // if (this.bookFull !== undefined)
    //   console.warn(
    //     "already have a local copy of %s orderbook. Overwriting it",
    //     this.currencyPair
    //   );
    // this.bookFull = new OrderbookFull(data.full);
    // if (this.callback !== undefined)
    //   this.callback({
    //     buy: this.bookFull.getBids(),
    //     sell: this.bookFull.getAsks(),
    //   });
    // } else if (data.update !== undefined) {
    if (this.bookFull === undefined) this.bookFull = new OrderbookFull(data);
    if (data.asks !== undefined && data.bids !== undefined) {
      // merge incremental updates of new orders
      if (this.bookFull !== undefined) {
        // on first connect this update missage might arrive before full snapshot
        this.bookFull.updateDiff(data);
        if (
          this.callback !== undefined &&
          (data.asks.length > 0 || data.bids.length > 0 || data.id === 0)
        ) {
          this.callback({
            buy: this.bookFull.getBids(),
            sell: this.bookFull.getAsks(),
          });
        }
      }
    } else {
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );
    }
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe(callback: ((val: OrderbookReturn) => void) | undefined) {
    this.socket.subscribe(this);
    const reqData: OrderReq = {
      client_id: this.subsciberID,
      pair_name: this.currencyPair,
      depth: 0,
    };
    this.send(reqData);
    this.callback = callback;
  }

  public unsubscribe() {
    this.send({ unsubscribe_client_id: this.subsciberID });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: OrderReq) {
    return super.sendInternal({
      order_book: data,
      channel_id: this.subsciberID,
    });
  }
}
