import { ReactElement, useMemo } from "react";

import { ISkeleton } from "./interfaces";
import { SkeletonWrapper } from "./styles";

export const Skeleton = ({
  type = "rectangle",
  loading = true,
  duration = 1.75,
  delay = 0.5,
  width,
  randomWidth = false,
  minRandomWidth = 50,
  ...rest
}: ISkeleton): ReactElement => {
  // random width between minRandomWidth% and 100%
  const skeletonWidth = useMemo(
    () =>
      randomWidth
        ? `${Math.max(
            Math.min(Math.floor(Math.random() * 100), 100),
            minRandomWidth
          )}%`
        : width,
    [minRandomWidth, randomWidth, width]
  );

  return (
    <SkeletonWrapper
      type={type}
      loading={loading}
      duration={duration}
      delay={delay}
      {...rest}
      width={skeletonWidth}
    />
  );
};
