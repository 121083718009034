import { useState, useEffect, DependencyList, useCallback } from "react";
import { debounce } from "lodash-es";

export const useDebounceValue = (value: any, delay = 1000) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};

export const useDebounce = (
  fn: (...args: any) => any,
  delay = 1000,
  deps: DependencyList = []
  // eslint-disable-next-line react-hooks/exhaustive-deps
) => useCallback(debounce(fn, delay), deps);
