import styled from "@emotion/styled/macro";
import { ButtonHTMLAttributes, ReactNode } from "react";

interface Icon {
  width?: string;
  height?: string;
}

export interface IIconButton
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Icon {
  icon?: ReactNode;
  iconSrc?: string;
}

const Button = styled.button`
  background: transparent;
  border: none;
  padding: 0;
  margin: auto;
  color: ${(props) => props.theme.textColor};
`;

const Img = styled.img<Icon>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  display: block;
`;

const IconButton = ({
  icon,
  iconSrc,
  width = "24px",
  height = "24px",
  ...rest
}: IIconButton) => (
  <Button {...rest}>
    {iconSrc ? <Img width={width} height={height} src={iconSrc} /> : icon}
  </Button>
);

export default IconButton;
