import { ButtonHTMLAttributes, ElementType } from "react";
import styled from "@emotion/styled/macro";
import { css, Theme } from "@emotion/react/macro";
import { Link } from "react-router-dom";

type BaseProps = {
  size?: "small" | "medium" | "large";
  block?: boolean;
  variant?:
    | "primary"
    | "secondary"
    | "link"
    | "link-secondary"
    | "success"
    | "danger"
    | "warning";
  type?: "button" | "submit" | "reset";
  to?: string;
  linkState?: any;
  flexDirection?: "row" | "column";
};

type ButtonAsButton = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    as?: ElementType<any> | undefined;
  };

type ButtonAsLink = BaseProps &
  Omit<ButtonHTMLAttributes<HTMLButtonElement>, keyof BaseProps> & {
    as: "link";
    to: string;
    linkState: any;
  };

export type IButton = ButtonAsButton | ButtonAsLink;

const sizeMap = {
  small: {
    width: "160px",
    height: "48px",
  },
  medium: {
    width: "240px",
    height: "48px",
  },
  large: {
    width: "300px",
    height: "48px",
  },
};
const StyledLink = styled(Link)<{ block?: boolean }>`
  text-decoration: none;
  min-width: ${(props) => (props.block ? "100%" : "unset")};
  width: ${(props) => (props.block ? "100%" : "unset")};
`;

const StyledButton = styled.button<BaseProps>(
  (props) => css`
    min-width: ${props.block && props.size
      ? sizeMap[props.size].width
      : "unset"};
    width: ${props.block
      ? "100%"
      : props.size
      ? sizeMap[props.size].width
      : "unset"};
    height: ${props.size ? sizeMap[props.size].height : "unset"};
    flex-grow: 0;
    display: ${props.block ? "flex" : "inline-flex"};
    flex-direction: ${props.flexDirection || "column"};
    justify-content: center;
    align-items: center;
    padding: ${props.block || props.variant !== "link" ? "14px" : "0"};
    border-radius: ${props.theme.buttonBorderRadius};
    background-color: ${props.theme.white};
    font-size: ${props.theme.buttonFontSize};
    font-weight: bold;
    margin: ${props.block ? "16px 0" : "0"};
    transition-duration: 0.3s;
    transition-property: color, border-color, background-color, text-decoration;
  `,
  (props) => {
    switch (props.variant) {
      case "secondary":
        return {
          border: `solid 1px ${props.theme.primaryColor}`,
          backgroundColor: "transparent",
          color: props.theme.primaryColor,
          "&:hover": {
            backgroundColor: props.theme.secondaryButtonHover,
          },
          "&:active": {
            backgroundColor: props.theme.secondaryButtonActive,
          },
          "&:disabled": {
            color: props.theme.textColorDisabled,
            backgroundColor: "transparent",
            borderColor: props.theme.secondaryButtonDisableBorder,
          },
        };
      case "link":
        return {
          border: "none",
          background: "transparent",
          textDecoration: "none",
          color: props.theme.primaryColor,
          "&:hover": {
            textDecoration: "none",
            color: props.theme.primaryButtonHover,
          },
          "&:active": {
            textDecoration: "none",
            color: props.theme.linkButtonActive,
          },
          "&:disabled": {
            textDecoration: "none",
            color: props.theme.textColorDisabled,
          },
        };
      case "link-secondary":
        return {
          border: "none",
          background: "transparent",
          textDecoration: "none",
          color: props.theme.textColor,
          "&:hover": {
            textDecoration: "none",
            color: props.theme.primaryColor,
          },
          "&:active": {
            textDecoration: "none",
            color: props.theme.linkButtonActive,
          },
          "&:disabled": {
            textDecoration: "none",
            color: props.theme.textColorDisabled,
          },
        };
      case "success":
        return {
          border: "none",
          backgroundColor: props.theme.successButton,
          color: props.theme.textColorLight,
          "&:hover": {
            backgroundColor: props.theme.successButtonHover,
          },
          "&:active": {
            backgroundColor: props.theme.successButtonActive,
          },
          "&:disabled": {
            backgroundColor: props.theme.successButtonDisabled,
          },
        };
      case "danger":
        return {
          border: "none",
          backgroundColor: props.theme.dangerButton,
          color: props.theme.textColorLight,
          "&:hover": {
            backgroundColor: props.theme.dangerButtonHover,
          },
          "&:active": {
            backgroundColor: props.theme.dangerButtonActive,
          },
          "&:disabled": {
            backgroundColor: props.theme.dangerButtonDisabled,
          },
        };
      case "warning":
        return {
          border: "none",
          backgroundColor: props.theme.warningButton,
          color: props.theme.textColorLight,
          "&:hover": {
            backgroundColor: props.theme.warningButtonHover,
          },
          "&:active": {
            backgroundColor: props.theme.warningButtonActive,
          },
          "&:disabled": {
            backgroundColor: props.theme.warningButtonDisabled,
          },
        };
      case "primary":
      default:
        return {
          border: "none",
          backgroundColor: props.theme.primaryColor,
          color: props.theme.textColorLight,
          "&:hover": {
            backgroundColor: props.theme.primaryButtonHover,
          },
          "&:active": {
            backgroundColor: props.theme.primaryButtonActive,
          },
          "&:disabled": {
            backgroundColor: props.theme.primaryButtonDisable,
            color: props.theme.textColorDisabled,
          },
        };
    }
  }
);
const Button = ({
  testId,
  variant,
  as,
  block,
  ...props
}: IButton & {
  testId?: string;
  theme?: Theme | undefined;
  as?: ElementType<any> | undefined;
}) => {
  if (as === "link") {
    const { to, linkState, ...rest } = props;
    return (
      <StyledLink to={to || ""} block={block} state={linkState}>
        <StyledButton
          variant={variant}
          block={block}
          data-test-id={testId}
          {...rest}
        />
      </StyledLink>
    );
  }
  return (
    <StyledButton
      variant={variant}
      as={as}
      block={block}
      data-test-id={testId}
      {...props}
    />
  );
};

export default Button;
