import { string, boolean, object } from "yup";
import { FileTypes } from "./file";
import { APIResponse } from "./generic";

export interface LoginBody {
  email: string;
  password: string;
  recaptcha_token: string;
}

export interface UserInfo {
  session_id: string;
  need_2fa: boolean;
  id: number;
  account_ownership: string;
  name: string;
  email: string;
  mobile_number: string;
  role: string;
  status: "APPROVE" | "SUSPENDED";
}

export interface LoginResponse extends UserInfo {}

export interface VerifyAccountBody {
  account_id: string;
  verification_code: string;
}

export interface RequestResetPasswordEmailBody {
  email: string;
  recaptcha_token: string;
}

export interface RequestResetPasswordMobileNumberBody {
  mobile_number: string;
  recaptcha_token: string;
}

export interface ResetPasswordBody {
  new_password: string;
  recaptcha_token: string;
}

export interface UserWalletsType {
  amount: number;
  amount_locked: number;
  currency: string;
  user_id: number;
  wallet_id: number;
}

export interface UserBalanceType {
  user_wallet: UserWalletsType[];
}

export interface RefreshTokenResponse {
  session_id: string;
}

export type UserResponses = LoginResponse;

export interface RequestEmailOTPBody {
  email?: string;
  recaptcha_token: string;
}

export interface RequestMobileNumberOTPBody {
  mobile_number?: string;
  recaptcha_token: string;
}

export interface VerifyOTPBody {
  reference_code: string;
  otp: string;
}

export interface VerifyOTPResponse {
  session_id?: string;
  success: boolean;
}

export interface RequestNewUserOTPBody {
  type: "email" | "mobile_number";
  value: string;
  recaptcha_token: string;
}

export interface VerifyNewUserOTPBody {
  type: "email" | "mobile_number";
  value: string;
  reference_code: string;
  otp: string;
}

export enum OwnershipType {
  company = "company",
  individual = "individual",
}
export type BaseProfile = {
  account_ownership: OwnershipType;
  email: string;
  password: string;
  confirm_password: string;
  mobile_number: string;
};

export type BasicKYCInfo = {
  th_name: string;
  en_name: string;
};

export type IndividualKYCInfo = BasicKYCInfo & {
  firstName: string;
  lastName: string;
  nationalId: string;
  address: string;
};

export type CompanyKYCInfo = BasicKYCInfo & {
  office_branch: string;
  place_of_registration?: string;
  registration_number: string;
  registration_date: string;
  juristic_person_type: string;
  business_type: string;
  office_address: string;
  zip_code: string;
  contact_person: string;
  company_phone: string;
  company_fax?: string;
  company_email: string;
  is_contact_address_same_as_office_address: boolean;
};

export const CompanyKYCInfoSchema = object().shape({
  th_name: string().required(),
  en_name: string().optional(),
  place_of_registration: string().optional(),
  registration_number: string().required(),
  registration_date: string().required(),
  juristic_person_type: string().required(),
  business_type: string().required(),
  office_address: string().required(),
  zip_code: string().required(),
  contact_person: string().required(),
  company_phone: string().required(),
  company_fax: string().optional(),
  company_email: string().required(),
  is_contact_address_same_as_office_address: boolean().required(),
});

export type KYCInfo = IndividualKYCInfo | CompanyKYCInfo;

export type ContactAddress = {
  contact_address: string;
  contact_zip_code: string;
  contact_phone: string;
  contact_fax?: string;
  contact_email?: string;
};

export const ContactAddressSchema = object().shape({
  contact_address: string().required(),
  contact_zip_code: string().required(),
  contact_phone: string().required(),
  contact_fax: string().optional(),
  contact_email: string().optional(),
});

export type DirectorInfo = {
  full_name: string;
  id_card: string;
};

export type AuthorizedPersonInfo = {
  full_name: string;
  address: string;
  is_related_to_politician: boolean;
  additional_info?: string;
};

export type NewPaymentMethod = {
  bank_code: string;
  bank_branch: string;
  bank_account_number: string;
  bank_account_type: string;
  bank_account_name: string;
};

export type PDPA = {
  is_collectable: boolean | null;
  is_shareable: boolean | null;
  is_researchable: boolean | null;
};

export type AttachmentFiles = {
  proof_of_company_registration: FileList | string;
  meeting_minutes: FileList | string;
  retrospective_financial_statement?: FileList | string;
  bank_book: FileList | string;
  director_id_card: FileList | string;
  director_house_registration: FileList | string;
  is_vat_registered: boolean;
  proof_of_vat_registration: FileList | string;
  director_signature: FileList | string;
  is_delegated: boolean;
};

export type Attachments = {
  file_type: FileTypes;
  file_name: string;
}[];

export type DelegateAttachmentFiles = {
  proof_of_delegation: FileList | string;
  revenue_stamp: FileList | string;
  assignee_signature: FileList | string;
  assignee_id_card: FileList | string;
  assignee_house_registration: FileList | string;
};

export type DelegateAttachment = Attachments;

export type RegisterFiles = {
  files: Attachments & DelegateAttachment;
};

export type File = {
  file_name: string;
  file_type: string;
  url: string;
};

export type Files = {
  files: File[];
};

export type KYC = {
  basic_info: KYCInfo;
  contact_address?: ContactAddress;
  directors_info: DirectorInfo[];
  authorized_persons_info: AuthorizedPersonInfo[];
};

export type KYCRegisterParams = {
  kyc: string; // JSON.stringify(KYC)
  pdpa: PDPA;
};

export interface BasicRegisterParams {
  name: string;
  id_number: string;
  address: string;
}
export type IndividualKYCParams = BasicRegisterParams;
export type CompanyKYCParams = BasicRegisterParams & {
  office_branch: string;
};

export type RegisterParams = IndividualKYCParams | CompanyKYCParams;

export type RegisterBody = BaseProfile &
  KYCRegisterParams &
  RegisterParams &
  NewPaymentMethod &
  RegisterFiles & {
    pin: string;
    recaptcha_token: string;
    broker_id: number;
  };

export interface ChangeEmailBody {
  reference_code: string;
  otp: string;
  new_email: string;
}

export interface ChangeMobileNumberBody {
  reference_code: string;
  otp: string;
  new_mobile_number: string;
}

export interface AccountSecurity {
  pin_last_changed?: string;
  password_last_changed?: string;
  is_2fa_enabled: boolean;
}

export type AccountSecurityResponse = APIResponse<AccountSecurity>;

export interface Toggle2FABody {
  is_enabled: boolean;
  password: string;
}

export interface VerifyPinBody {
  pin: string;
}

export interface VerifyPinResponse {
  success: boolean;
  nonce: string;
}

export interface ChangePinBody {
  nonce: string;
  new_pin: string;
}

export interface ChangePasswordBody {
  current_password: string;
  new_password: string;
}

export interface VerifyUniquenessResult {
  is_unique: boolean;
}

export type VerifyUniquenessRes = APIResponse<VerifyUniquenessResult>;

export interface verifyResetPasswordTokenBody {
  token: string;
}
