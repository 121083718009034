import { APIError } from "models/generic";
import { ClientSocketReceiver, PrivateClientSocket } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { MyOrderReq, MyOrderData, MyOrderRes } from "./MyOrderData";

/**
 * MyOrder is a websocket client receiving live trades from the server.
 */
export class MyOrder extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.MYORDER;

  private currencyPair: string = "";

  constructor(socket: PrivateClientSocket, pair: string) {
    super(socket);
    this.currencyPair = pair;
  }

  public onData(data: MyOrderRes): void {
    console.log("MyOrder", data);
    let orders: MyOrderData[] = [];
    if (data.orders !== undefined) {
      orders = data.orders;
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );

    // TODO Max display section 4 on UI
    // use trades[0].rate, trades[0].amount
    // make sure to remove old trades again (don't store them forever in array or DOM tree)
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe(status?: string) {
    this.socket.subscribe(this);
    const reqData: MyOrderReq = {
      client_id: this.subsciberID + status,
      pair_name: this.currencyPair,
      status,
    };
    this.send(reqData);
  }

  public unsubscribe(status?: string) {
    this.send({ unsubscribe_client_id: this.subsciberID + status });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: MyOrderReq) {
    return super.sendInternal({
      my_order: data,
      channel_id: this.subsciberID,
    });
  }
}
