import { InputHTMLAttributes } from "react";
import styled from "@emotion/styled/macro";

interface Props extends InputHTMLAttributes<HTMLDivElement> {
  className?: string;
  FontSize?: string;
}

const Text = styled.div`
  font-family: Nunito Sans;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
`;

const Title = styled(Text)`
  font-weight: 900;
`;

const Content = styled(Text)`
  font-weight: 600;
`;

export const TextTitle = ({ children, className, ...rest }: Props) => (
  <Title className={className} {...rest}>
    {children}
  </Title>
);

export const TextContent = ({ children, className, ...rest }: Props) => (
  <Content className={className} {...rest}>
    {children}
  </Content>
);
