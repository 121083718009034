import axios, { AxiosRequestConfig } from "axios";
import { backendURL } from "config";
import { GenerateUrl, GenerateUrlResponse, UploadFileRes } from "models/file";
import { APIResponse } from "models/generic";
import { client } from "./client";

export const generateUrl = async (body: GenerateUrl) => {
  const response = await client.post<APIResponse<GenerateUrlResponse>>(
    `${backendURL}/user/generate-object-storage-url`,
    body
  );
  return response.data;
};

export const uploadFile = async (formdata: FormData) => {
  const response = await axios.post(`${backendURL}/user/upload-file`, formdata);
  return response.data as UploadFileRes;
};
