import { HTMLAttributes, ReactNode } from "react";
import styled from "@emotion/styled/macro";

export interface ISwitch extends HTMLAttributes<HTMLLabelElement> {
  checked: boolean;
  leftLabel?: ReactNode;
  rightLabel?: ReactNode;
  onLeftLabelClick?: () => void;
  onRightLabelClick?: () => void;
}

const Container = styled.span`
  width: fit-content;
`;

const Label = styled.label``;

const LeftLabel = styled(Label)`
  margin-right: 1rem;
`;

const RightLabel = styled(Label)`
  margin-left: 1rem;
`;

const SwitchLabel = styled.label<{ checked: boolean }>`
  display: inline-flex;
  font-size: 15px; /* 1 */
  width: calc(2em + 4px);
  background-color: ${(props) =>
    props.checked ? props.theme.primaryColor : props.theme.lightgray};
  border-radius: 1em;
  transition: background-color 300ms;
  & input {
    display: none;
  }

  & div {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: #fff;
    transition: transform 300ms;
    margin: 2px 0 2px 2px;
  }

  & input:checked + div {
    transform: translate3d(calc(1em), 0, 0);
  }
`;

export const Switch = ({
  leftLabel,
  rightLabel,
  checked,
  onLeftLabelClick,
  onRightLabelClick,
  ...rest
}: ISwitch) => (
  <Container>
    {leftLabel && <LeftLabel onClick={onLeftLabelClick}>{leftLabel}</LeftLabel>}
    <SwitchLabel checked={checked} {...rest}>
      <input type="checkbox" defaultChecked={false} checked={checked} /> <div />
    </SwitchLabel>
    {rightLabel && (
      <RightLabel onClick={onRightLabelClick}>{rightLabel}</RightLabel>
    )}
  </Container>
);
