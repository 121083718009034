import { backendURL } from "config";
import { APISuccess } from "models/generic";
import {
  CancelTransactionBody,
  DepositFiat,
  TransactionDepositBody,
  TransactionWithdrawBody,
} from "models/transaction";
import { client } from "./client";

export const transactionHistory = async (params: string) => {
  const response = await client.get(
    `${backendURL}/transaction/history?${params}`
  );
  return response.data;
};

export const transactionDeposit = async (body: TransactionDepositBody) => {
  const response = await client.post(`${backendURL}/transaction/deposit`, body);
  return response.data;
};

export const transactionWithdraw = async (body: TransactionWithdrawBody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/transaction/withdraw`,
    body
  );
  return response.data;
};

export const transactionRetire = async (body: TransactionWithdrawBody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/transaction/retire`,
    body
  );
  return response.data;
};

export const cancelTransaction = async (body: CancelTransactionBody) => {
  const response = await client.put<APISuccess>(
    `${backendURL}/transaction/cancel`,
    body
  );
  return response.data;
};

export const depositFiat = async (body: DepositFiat) => {
  const response = await client.post(
    `${backendURL}/broker-management/deposit/fiat`,
    body
  );
  return response.data;
};
