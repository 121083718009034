import { ReactNode } from "react";
import { SWRConfig } from "swr";
import { AxiosRequestConfig } from "axios";

import { client } from "api/client";
import { backendURL, config } from "config";
import { history } from "utils";
import { useAuth } from "../AuthProvider";

const { path } = config;

type APIFetchConfig = {
  redirectIfError?: boolean;
  throwError?: boolean;
};

export const fetcher = (logout: () => void) => (url: string) =>
  client
    .get(backendURL + url)
    .then((res) => res.data)
    .catch((err) => {
      if (err.response.status === 403 && logout) {
        logout();
      }
      throw err;
    });

export const apiFetcher = (
  axiosConfig: AxiosRequestConfig,
  options: APIFetchConfig = {
    redirectIfError: true,
    throwError: true,
  }
) => {
  const { url, ...args } = axiosConfig;
  return client({
    ...args,
    url: backendURL + url,
  })
    .then((res) => res.data)
    .catch((err) => {
      if (options.redirectIfError) {
        if (err.response.status === 403) {
          history.replace(path.error, {
            logout: true,
          });
        } else {
          history.replace(path.error);
        }
      } else if (options.throwError) {
        throw err;
      }
    });
};

export const SWRProvider = ({ children }: { children: ReactNode }) => {
  const { logout } = useAuth();
  return (
    <SWRConfig
      value={{
        refreshInterval: 30000,
        fetcher: fetcher(logout),
        shouldRetryOnError: false,
      }}
    >
      {children}
    </SWRConfig>
  );
};
