import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import dayjs from "dayjs";
import useSWR from "swr";

import {
  Badge,
  Button,
  DatePicker,
  Key,
  OutlineSelect,
  Table,
} from "components";
import {
  Transaction,
  TransactionHistoriesRes,
  TransactionStatus,
  EntryType,
  transactionStatusVariant,
} from "models/transaction";
import { useDebounceValue, usePagination, useSelectedDate } from "hooks";
import { convertObjToUrl, priceNumber } from "utils";

export interface IInventoryHistoryHandles {
  refresh: () => void;
}

export interface IInventoryHistoryTable {
  setShowCancelRequest: (transaction: Transaction) => void;
}

const HistoryTableKeys = () =>
  [
    {
      label: "Transaction ID",
      name: "id",
      style: {
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
      },
    },
    {
      label: "Date",
      name: "date_time",
      customRenderer: (data) => dayjs(data).format("DD MMM YYYY HH:mm"),
    },
    {
      label: "Symbol",
      name: "symbol",
      style: {
        fontWeight: "bold",
      },
    },
    {
      label: "From/To",
      name: "registry_name",
      customRenderer: () => "N/A",
    },
    {
      label: "Type",
      name: "entry_type",
      style: {
        textAlign: "center" as const,
      },
      headerStyle: {
        textAlign: "center" as const,
      },
    },
    {
      label: "Amount",
      name: "amount",
      style: {
        textAlign: "right" as const,
      },
      headerStyle: {
        textAlign: "right" as const,
      },
      customRenderer: (data) => priceNumber(parseFloat(data as string)),
    },
    {
      label: "Status",
      name: "status",
      style: {
        textAlign: "center" as const,
      },
      headerStyle: {
        textAlign: "center" as const,
      },
      customRenderer: (data) => (
        <Badge variant={transactionStatusVariant(data as TransactionStatus)}>
          {data?.toString().replace("_", " ").toLowerCase()}
        </Badge>
      ),
    },
  ] as Key<Transaction>[];

const defaultInventoryTableState = {
  currency_type: "credit",
  type: "",
};

export const InventoryHistoryTable = forwardRef<
  IInventoryHistoryHandles,
  IInventoryHistoryTable
>(({ setShowCancelRequest }, ref) => {
  const [inventoryTableState, setInventoryTableState] = useState(
    defaultInventoryTableState
  );

  const [inventoryTotal, setInventoryTotal] = useState(0);
  const { pagination, setPage, setLimit, showPerPageOptions, totalPages } =
    usePagination(inventoryTotal);

  const { selectedDate, selectingDate, onChangeDate } = useSelectedDate();

  const params = useMemo(
    () => ({
      ...pagination,
      ...inventoryTableState,
      from_date: dayjs(selectedDate[0]).startOf("date")?.toISOString() || "",
      to_date: dayjs(selectedDate[1]).endOf("date")?.toISOString() || "",
    }),
    [inventoryTableState, pagination, selectedDate]
  );
  const debouncedParam = useDebounceValue(params, 500);

  const { data, mutate } = useSWR<TransactionHistoriesRes>(
    `/transaction/history?${convertObjToUrl(debouncedParam)}`,
    {
      onSuccess: (res) => {
        setInventoryTotal(res.data.total);
      },
    }
  );

  useImperativeHandle(ref, () => ({
    refresh: () => {
      mutate();
    },
  }));

  const historyActions = (data: Transaction) =>
    data.status === TransactionStatus.Pending && (
      <Button variant="link" onClick={() => setShowCancelRequest(data)}>
        Cancel
      </Button>
    );

  return (
    <Table
      tbodyTestId="inventory-history__table"
      filters={
        <>
          <DatePicker
            style={{
              width: "200px",
              minWidth: "200px",
              paddingBottom: 0,
              marginBottom: 0,
            }}
            startDate={selectingDate[0]}
            endDate={selectingDate[1]}
            onChange={(_, update) => onChangeDate(update as [Date, Date])}
            selectsRange
            maxDate={new Date()}
          />
          <OutlineSelect
            style={{
              minWidth: "80px",
              maxWidth: "240px",
              paddingBottom: 0,
            }}
            onChange={(event) =>
              setInventoryTableState((prev) => ({
                ...prev,
                type: event.target.value,
              }))
            }
          >
            <option value="">All Entry Type</option>
            <option value={EntryType.Deposit}>Deposit</option>
            <option value={EntryType.Withdraw}>Withdraw</option>
            <option value={EntryType.Retire}>Retire</option>
          </OutlineSelect>
        </>
      }
      actions={historyActions}
      keys={HistoryTableKeys()}
      data={data?.data.transaction_histories}
      noDataMessage="No data"
      show={pagination.limit}
      showPerPageOptions={showPerPageOptions}
      currentPage={pagination.page}
      totalPages={totalPages}
      onPageChange={setPage}
      onShowChange={setLimit}
    />
  );
});
