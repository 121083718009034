import { backendURL } from "config";
import { APIResponse, APISuccess } from "models/generic";
import {
  LoginResponse,
  RequestNewUserOTPBody,
  RequestResetPasswordEmailBody,
  RequestResetPasswordMobileNumberBody,
  ResetPasswordBody,
  RequestEmailOTPBody,
  RequestMobileNumberOTPBody,
  VerifyOTPBody,
  VerifyNewUserOTPBody,
  RegisterBody,
  VerifyOTPResponse,
  ChangeEmailBody,
  ChangeMobileNumberBody,
  Toggle2FABody,
  ChangePinBody,
  VerifyPinBody,
  VerifyPinResponse,
  ChangePasswordBody,
  VerifyUniquenessRes,
  verifyResetPasswordTokenBody,
} from "models/user";
import { IAddNewAccountValue } from "features/AccountManagement";
import { RegistryMembership } from "models/registry";
import { client } from "./client";

export async function getCurrentUser() {
  const response = client.get(`${backendURL}/user`);
  return response;
}

export const signUp = async (body: RegisterBody) => {
  const response = await client.post(`${backendURL}/user/register`, body);
  return response.data;
};

export const resetPassword = async (body: ResetPasswordBody) => {
  const response = await client.post<APIResponse<LoginResponse>>(
    `${backendURL}/user/reset-password`,
    body
  );
  return response;
};

export const validateTgoUser = async (
  accountId: string,
  verificationCode: string
) => {
  const response = await client.post(`${backendURL}/user/verify-account`, {
    account_id: accountId,
    verification_code: verificationCode,
  });
  return response.data;
};

export const requestResetPasswordEmail = async (
  body: RequestResetPasswordEmailBody
) => {
  const response = await client.post(
    `${backendURL}/user/request-reset-password/email`,
    body
  );
  return response.data;
};

export const requestResetPasswordMobileNumber = async (
  body: RequestResetPasswordMobileNumberBody
) => {
  const response = await client.post(
    `${backendURL}/user/request-reset-password/mobile-number`,
    body
  );
  return response.data;
};

export const validateEmail = async (email: string) => {
  const response = await client.post<VerifyUniquenessRes>(
    `${backendURL}/user/verify-uniqueness`,
    {
      email,
    }
  );
  return response.data;
};

export const validatePhone = async (phone: string) => {
  const response = await client.post<VerifyUniquenessRes>(
    `${backendURL}/user/verify-uniqueness`,
    {
      mobile_number: phone,
    }
  );
  return response.data;
};

export const requestEmailOTP = async (body: RequestEmailOTPBody) => {
  const response = await client.post(
    `${backendURL}/user/request-email-otp`,
    body
  );
  return response.data;
};

export const requestMobileNumberOTP = async (
  body: RequestMobileNumberOTPBody
) => {
  const response = await client.post(
    `${backendURL}/user/request-mobile-number-otp`,
    body
  );
  return response.data;
};

export const verifyOTP = async (body: VerifyOTPBody) => {
  const response = await client.post<APIResponse<VerifyOTPResponse>>(
    `${backendURL}/user/verify-otp`,
    body
  );
  return response.data;
};

export const requestNewUserOTP = async (body: RequestNewUserOTPBody) => {
  const response = await client.post(
    `${backendURL}/user/new/request-otp`,
    body
  );
  return response.data;
};

export const verifyNewUserOTP = async (body: VerifyNewUserOTPBody) => {
  const response = await client.post(`${backendURL}/user/new/verify-otp`, body);
  return response.data;
};

export const changeEmail = async (body: ChangeEmailBody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/user/change-email`,
    body
  );
  return response.data;
};

export const changeMobileNumber = async (body: ChangeMobileNumberBody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/user/change-mobile-number`,
    body
  );
  return response.data;
};

export const set2FA = async (body: Toggle2FABody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/user/set-2fa`,
    body
  );
  return response.data;
};

export const verifyPin = async (body: VerifyPinBody) => {
  const response = await client.post<APIResponse<VerifyPinResponse>>(
    `${backendURL}/user/verify-pin`,
    body
  );
  return response.data;
};

export const changePin = async (body: ChangePinBody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/user/change-pin`,
    body
  );
  return response.data;
};

export const changePassword = async (body: ChangePasswordBody) => {
  const response = await client.post<APISuccess>(
    `${backendURL}/user/change-password`,
    body
  );
  return response.data;
};

export const verifyResetPasswordToken = async (
  body: verifyResetPasswordTokenBody
) => {
  const response = await client.post(
    `${backendURL}/user/verify-reset-password-token`,
    body
  );
  return response.data;
};

export const linkRegistryMember = async (
  pin: string,
  addingAccount: IAddNewAccountValue
) => {
  const response = await client.post(`${backendURL}/registry/link`, {
    registry_name: addingAccount.registryName,
    account_id: addingAccount.accountId,
    verification_code: addingAccount.code,
    token: addingAccount.token,
    pin_code: pin,
  });
  return response.data;
};

export const unlinkRegistryMember = async (
  pin: string,
  account: RegistryMembership
) => {
  const response = await client.post(`${backendURL}/registry/unlink`, {
    registry_id: account.registry_id,
    pin_code: pin,
  });
  return response.data;
};
