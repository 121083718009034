import styled from "@emotion/styled/macro";
import { keyframes } from "@emotion/react/macro";

export const loading = keyframes`
  0% {
    transform: translateX(-100%);
  }
  20% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(120%);
  }
`;

export const StyledOTPInputContainer = styled.form`
  position: relative;
  width: max-content;
  margin: 0 auto 32px;
  overflow: hidden;
  padding: 8px 0;
  input {
    display: inline-block;
    width: 38px;
    height: 50px;
    margin-right: 4px;
    text-align: center;
    transition-property: color, border, box-shadow, transform;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    border: solid 1px #9daac6;
    background-color: transparent;
    color: #9daac6;
    font-size: 16px;
    border-radius: 8px;
    margin: 0 10px;
    &:focus {
      outline: none;
      box-shadow: 0 0 7px #02acd880;
      border-color: #02acd8;
      transform: scale(1.05);
    }
  }
  &.focus {
    input {
    }
  }
  &.loading {
    input {
      color: #d3d3d3;
      border-color: #d3d3d3;
    }
  }
  &.invalid {
    input {
      color: #cb5454;
      border-color: #cb5454;
      box-shadow: 0 0 7px #cb545480;
    }
    &.loading {
      input {
        color: #e7b5b5;
        border-color: #e7b5b5;
      }
    }
  }
  &.disabled {
    input {
      color: #d3d3d3;
      border-color: #d3d3d3;
    }
  }
  .shimmer-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: ${loading} 2.5s infinite;
    z-index: ${(props) => +props.theme.defaultZIndex + 1};
    mix-blend-mode: lighten;
    display: none;
    &.loading {
      display: block;
    }
  }
  .shimmer {
    width: 30%;
    height: 100%;
    background: #fafafa;
    opacity: 0.8;
    transform: skew(-20deg);
    position: relative;
    filter: blur(10px);
  }

  .invalid-message {
    color: ${(props) => props.theme.dangerButton};
    font-size: 12px;
    padding-top: 24px;
    text-align: center;
  }
`;

export const OTPInputwithResendContainer = styled.div`
  .link {
    cursor: pointer;
    text-align: center;
    margin: 0;
    flex-direction: row;
    font-size: 1rem;
    padding: 8px;
    &:hover {
      path {
        stroke: ${(props) => props.theme.primaryButtonHover};
      }
    }
    &.disabled {
      svg {
        path {
          stroke: ${(props) => props.theme.textColorDisabled};
        }
      }
    }
    svg {
      width: 0.75em;
      height: 0.75em;
      margin-right: 0.5em;
      path {
        stroke: ${(props) => props.theme.primaryColor};
        stroke-width: 3;
        transition: stroke 0.3s;
      }
    }
  }
`;

export const StyledCountdownHeader = styled.h4`
  text-align: center;
`;

export const StyledCountdown = styled.h4`
  text-align: center;
  padding: 8px;
  .highlight {
    color: ${(props) => props.theme.primaryColor};
  }
`;
