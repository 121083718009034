import { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { Button, Key, OutlineSelect, Table, TextField } from "components";
import { Inventory, InventoryRes } from "models/inventory";
import { convertObjToUrl, priceNumber } from "utils";
import { useDebounceValue, usePagination } from "hooks";
import { AllMarketRes } from "models/market";
import { config } from "config";

const { path } = config;

export interface IInventoryHandles {
  refresh: () => void;
  resetFilter: () => void;
}

export interface IInventoryTable {
  // setShowSymbolDetail: (symbol: string) => void;
  setShowWithdrawModal: (symbol: string, retire: boolean) => void;
}

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.textColor};
`;

const InventoryTableKeys: Key<Inventory>[] = [
  {
    label: "Symbol",
    name: "currency_symbol",
    style: {
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
    },
    customRenderer: (data) => (
      <StyledLink to={`${path.tradeBase}/${data}_THB`}>{data}</StyledLink> // TODO: might need to change to ${data}_${quote_symbol}
    ),
  },
  {
    label: "Total Amount",
    name: "amount",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(parseFloat(data || ""), { min: 0, max: 0 }),
  },
  {
    label: "Available Amount",
    name: "cash_balance",
    style: {
      textAlign: "right" as const,
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(parseFloat(data || ""), { min: 0, max: 0 }),
  },
];

const defaultInventoryTableState = {
  symbol: "",
  market: "",
};

export const InventoryTable = forwardRef<IInventoryHandles, IInventoryTable>(
  (
    {
      // setShowSymbolDetail,
      setShowWithdrawModal,
    },
    ref
  ) => {
    const { data: markets } = useSWR<AllMarketRes>(`/market`);

    const [inventoryTableState, setInventoryTableState] = useState(
      defaultInventoryTableState
    );
    const [inventoryTotal, setInventoryTotal] = useState(0);
    const {
      pagination,
      reset,
      setPage,
      setLimit,
      showPerPageOptions,
      totalPages,
    } = usePagination(inventoryTotal);

    const params = useMemo(
      () => ({
        ...pagination,
        ...inventoryTableState,
        symbol: inventoryTableState.symbol.toUpperCase(),
      }),
      [inventoryTableState, pagination]
    );
    const debouncedParam = useDebounceValue(params, 300);
    const { data, mutate } = useSWR<InventoryRes>(
      `/inventory?${convertObjToUrl(debouncedParam)}`,
      {
        onSuccess: (data) => {
          setInventoryTotal(data.data.total);
        },
      }
    );

    const resetInventoryFilter = () => {
      reset();
      setInventoryTableState(defaultInventoryTableState);
    };

    useImperativeHandle(ref, () => ({
      refresh: () => {
        mutate();
      },
      resetFilter: resetInventoryFilter,
    }));

    const inventoryActions = (data: Inventory) => (
      <>
        {/* TODO: For Future: Show Symbol details */}
        {/* <Button
        style={{ marginRight: "16px" }}
        variant="link"
        onClick={() => setShowSymbolDetail(data.currency_symbol)}
      >
        Detail
      </Button> */}
        {/* TODO: Need to test withdraw credit */}
        <Button
          variant="link"
          onClick={() => setShowWithdrawModal(data.currency_symbol, false)}
          style={{ marginRight: "16px" }}
        >
          Withdraw
        </Button>
        <Button
          variant="link"
          onClick={() => setShowWithdrawModal(data.currency_symbol, true)}
        >
          Retire
        </Button>
      </>
    );

    return (
      <Table
        tbodyTestId="inventory-symbol__table"
        filters={
          <>
            <TextField
              containerStyle={{
                minWidth: "80px",
                width: "100%",
                maxWidth: "240px",
                paddingBottom: 0,
              }}
              placeholder="Search Symbol"
              value={inventoryTableState.symbol}
              onChange={(e) => {
                setInventoryTableState((state) => ({
                  ...state,
                  symbol: e.target.value,
                }));
              }}
            />
            <OutlineSelect
              style={{
                paddingBottom: 0,
                minWidth: "80px",
                width: "100%",
                maxWidth: "240px",
              }}
              value={inventoryTableState.market}
              onChange={(e) => {
                setInventoryTableState((state) => ({
                  ...state,
                  market: e.target.value,
                }));
              }}
            >
              <option value="">All Markets</option>
              {markets?.data.map((market) => (
                <option key={market.id} value={market.name}>
                  {market.name}
                </option>
              ))}
            </OutlineSelect>
            <Button variant="link" onClick={resetInventoryFilter}>
              Reset
            </Button>
          </>
        }
        actions={inventoryActions}
        keys={InventoryTableKeys}
        data={data?.data.inventories}
        noDataMessage="No data"
        rowPrimaryKey="currency_symbol"
        show={pagination.limit}
        showPerPageOptions={showPerPageOptions}
        currentPage={pagination.page}
        totalPages={totalPages}
        onPageChange={setPage}
        onShowChange={setLimit}
      />
    );
  }
);
