/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import axios, { AxiosResponse } from "axios";
import { useCookies } from "react-cookie";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { setUser } from "@sentry/react";
import { toast } from "react-hot-toast";

import { captureErrorSentry } from "utils";
import { LoginBody, LoginResponse, UserResponses } from "models/user";
import * as sessionsApi from "api/sessions";
import * as usersApi from "api/users";
import { config } from "config";
import { APIResponse, APIError } from "models/generic";
import { client } from "api/client";
import * as errorCodes from "models/apiErrorCodes";
import * as types from "./types";

const { path, rolePermission } = config;

export interface AuthContextType {
  state: types.State;
  dispatch: (value: types.Actions) => void;
  login: (
    body: LoginBody
  ) => Promise<APIResponse<LoginResponse> | undefined> | void;
  logout: () => void;
}

type RedirectLocationState = {
  from: Location;
};

const initState = {
  isLoading: true,
  isLoggedIn: false,
  is2faAuthenticated: false,
  user: null,
  token: "",
  error: null,
} as types.State;

const checkApiStatus = <T extends APIResponse<UserResponses | undefined>>(
  res: AxiosResponse<T>,
  navigate: NavigateFunction,
  shouldRedirect = true
) => {
  if (res.status === 200) {
    return res.data;
  }
  if (res.status === 401) {
    // TODO: handle unauthorized
    console.log("unauthorized");
  } else {
    shouldRedirect && navigate(path.error, { replace: true });
  }
};

const reducer = (state: types.State, action: types.Actions) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        error: null, // reset error
        isLoading: true,
      };
    case "GET_USER_DONE":
      return {
        ...state,
        error: null,
        isLoading: false,
      };
    case "SET_SESSION":
      return {
        ...state,
        token: action.payload,
        user: state.user
          ? {
              ...state.user,
              session_id: action.payload,
            }
          : null,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        error: null, // reset error
        isLoading: false,
        isLoggedIn: true,
        token: action.payload.session_id,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        error: null, // reset error
        isLoading: false,
        isLoggedIn: false,
        token: "",
        user: null,
      };
    case "ERROR":
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
};

export const AuthContext = createContext<AuthContextType>({
  state: initState,
  dispatch: () => {},
  login: () => {},
  logout: () => {},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const [state, dispatch] = useReducer(reducer, initState);
  const [{ token }, setCookie, removeCookie] = useCookies(["token"]);

  const logout = useCallback(async () => {
    removeCookie("token", { path: "/" });
    sessionsApi.logout();
    dispatch({ type: "LOGOUT" });
    // history.replace(path.login);
  }, [removeCookie]);

  useEffect(() => {
    // Response interceptor for API calls
    client.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;
        const errData = error.response?.data as APIError;
        if (
          error?.response?.status === 401 &&
          errData.code === errorCodes.TwoFactorRequired
        ) {
          navigate(path.securityVerification, { replace: true });
        } else if (error?.response?.status === 403 && !originalRequest._retry) {
          originalRequest._retry = true;
          originalRequest.data = originalRequest.data || {};
          const accessTokenRes = await sessionsApi
            .refreshAccessToken()
            .catch((err) => err.response);
          if (accessTokenRes.status === 200) {
            const accessToken = accessTokenRes.data.data.session_id;
            // set the new token in the cookie
            setCookie("token", accessToken, {
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            const headers = {
              "Content-Type":
                originalRequest.headers["Content-Type"] || "application/json",
              Authorization: `Bearer ${accessToken}`,
            };
            originalRequest.headers = headers;
            return client.request(originalRequest).catch((err) => err);
          }
          if (accessTokenRes.status === 401) {
            toast.error("Session Expired", { duration: 5000 });
            // logout
            return logout();
          }
          await logout();
          // redirect to error page
          return navigate(path.error, { replace: true });
        }
        return Promise.reject(error);
      }
    );
  }, [logout, setCookie]);

  useEffect(() => {
    // on mount, try to get user info
    if (token && !state.isLoggedIn) {
      dispatch({ type: "LOADING" });
      usersApi
        .getCurrentUser()
        .then((res) => {
          const resData = checkApiStatus(res, navigate);
          if (resData) {
            const user = resData.data;
            dispatch({
              type: "LOGIN_SUCCESS",
              payload: {
                ...user,
                session_id: token,
              },
            });
          }
        })
        .catch((err: APIError) => {
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            if (error.code === errorCodes.TwoFactorRequired) {
              const twoFAError = err.response
                .data as APIResponse<LoginResponse>;
              // set user so that we can use it in the 2fa page
              // note: navigate will be handled above in the response interceptor
              dispatch({ type: "LOGIN_SUCCESS", payload: twoFAError.data });
              return;
            }
          }
          logout(); // we run logout here to clear the httpOnly cookie
        });
    } else {
      // let auth context know we are done loading
      dispatch({ type: "GET_USER_DONE" });
    }
  }, [dispatch, logout, state.isLoggedIn, token]);

  const login = useCallback(
    (payload: LoginBody) => {
      dispatch({ type: "LOADING" });
      sessionsApi
        .login(payload)
        .then((res) => {
          const resData = checkApiStatus(res, navigate);
          if (resData) {
            const user = resData.data;
            setCookie("token", resData.data.session_id, {
              path: "/",
              secure: true,
              sameSite: "strict",
            });
            setUser({ email: user?.email });
            if (user && user.need_2fa) {
              navigate(path.securityVerification, { replace: true });
            } else if (user && user.status === "SUSPENDED") {
              return navigate(path.accountSuspended, { replace: true });
            } else if (
              locationState &&
              rolePermission.user.includes(user.role)
            ) {
              // no need to navigate for admin, no admin page can be accessed without login
              const { from } = locationState as RedirectLocationState;
              navigate(`${from.pathname}${from.search}`);
            } else {
              navigate(path.base, { replace: true });
            }
            return dispatch({ type: "LOGIN_SUCCESS", payload: user });
          }
        })
        .catch((err) => {
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            dispatch({ type: "ERROR", payload: error });
            if (error.code === errorCodes.DatabaseError) {
              captureErrorSentry(error, err, { message: "login error" });
            }
          } else {
            dispatch({
              type: "ERROR",
              payload: {
                code: errorCodes.UnknownError,
                message: "Unknown error",
                data: undefined,
              },
            });
          }
        });
    },
    [navigate, setCookie]
  );

  const memoedValue = useMemo(
    () => ({
      state,
      dispatch,
      login,
      logout,
    }),
    [state, login, logout]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};
