/* eslint-disable max-classes-per-file */
/**
 * OrderReq is the request when a client wants to
 * subscribe to orderbook live data.
 */
export interface OrderReq {
  client_id?: string; // a unique client ID used for subscribe()
  pair_name?: string; // subscribe to a symbol of an orderbook
  depth?: number; // how many levels of bids & asks to subscribe to (use 0 = all)

  unsubscribe_client_id?: string; // unsubscribe from previously subscribed orderbook data
}

export type OrderSide = "buy" | "sell";

export type OrderType = "limit" | "market" | "ato" | "atc";

/**
 * OrderbookPriceLevel has commutative order information
 * about the price level at Rate in the orderbook.
 */
export interface OrderbookPriceLevel {
  amount: string; // the amount at this Rate
  price: string; // the rate of all orders on this level
  side: number; // buy or sell
}

/**
 * OrderbookSnapshot is an internal snapshot of the orderbook.
 */
export interface OrderbookSnapshot {
  id: number; // auto-incrementing ID of snapshot numbers

  // the total bids and asks of this snapshot
  bids: OrderbookPriceLevel[];
  asks: OrderbookPriceLevel[];
}

/**
 * OrderbookDiff is used to send incremental updates of changes to the orderbook.
 */
export interface OrderbookDiff {
  id: number; // auto-incrementing ID of snapshot numbers

  bids_changed: OrderbookPriceLevel[];
  asks_changed: OrderbookPriceLevel[];
}

/**
 * OrderRes contains a full orderbook snapshot or updates
 * of the orderbook.
 * Client will receive:
 * 1. Full snapshot of the current orderbook
 * 2. Updates of the orderbook (always contains all levels regardless of 'depth' parameter)
 */
export interface OrderRes extends OrderbookSnapshot {
  // full?: OrderbookSnapshot;
  // update?: OrderbookDiff;
  // lastTradePrice: number;
  // id: string; // client ID
}

/**
 * Return data for order
 */
export interface OrderbookReturn {
  sell: OrderbookPriceLevel[];
  buy: OrderbookPriceLevel[];
}

/**
 * OrderbookMap is a map with orderbook entries sorted by price level as key.
 * Note that ES6 maps preserve the order of inserts while Go maps do not.
 */
export class OrderbookMap extends Map<string, OrderbookPriceLevel> {}

/**
 * OrderbookFull contains the full orderbook created by the client via:
 * 1. Requesting a full OrderbookSnapshot
 * 2. merging OrderbookDiff updates into it.
 * This is shown as section 3 on the UI.
 */
export class OrderbookFull {
  protected id: number = 0; // auto-incrementing ID of snapshot numbers

  protected bids: OrderbookMap = new OrderbookMap();

  protected asks: OrderbookMap = new OrderbookMap();

  constructor(snapshot: OrderbookSnapshot) {
    this.id = snapshot.id;
    for (let i = 0; i < snapshot.bids.length; i++) {
      this.bids.set(snapshot.bids[i].price, snapshot.bids[i]);
    }
    for (let i = 0; i < snapshot.asks.length; i++) {
      this.asks.set(snapshot.asks[i].price, snapshot.asks[i]);
    }
  }

  public updateDiff(diff: OrderbookSnapshot): boolean {
    if (diff.id === 0) {
      // this is an empty snapshot. we need to clear our orderbook
      console.log("Clearing orderbook...");
      this.bids.clear();
      this.asks.clear();
    }

    if (diff.id < this.id) return false; // this update is older than our snapshot. we can safely discard it (shouldn't happen)

    if (diff.id !== this.id + 1) {
      console.error(
        "Missing orderbook update. Current snapshot %d, next %d",
        this.id,
        diff.id
      );
      // TODO throw exception? we missed an orderbook update over websocket. app should re-connect to websocket
    }

    // algorithm:
    // 1. receive full update (can be large, many MBs)
    // 2. receive incremental updates of orderbook
    //      --- update (overwrite) amount at given price level
    //      --- if amount is 0 -> remove this price level
    //      --- it is ok to receive "amount === 0" for price levels you don't have (because other clients connected earlier)
    this.id = diff.id;
    for (let i = 0; i < diff.bids.length; i++) {
      const orders = diff.bids[i];
      if (parseFloat(orders.amount) === 0.0) this.bids.delete(orders.price);
      else this.bids.set(orders.price, orders);
    }
    for (let i = 0; i < diff.asks.length; i++) {
      const orders = diff.asks[i];
      if (parseFloat(orders.amount) === 0.0) this.asks.delete(orders.price);
      else this.asks.set(orders.price, orders);
    }
    return true;
  }

  /**
   * Return all bids (buy orders).
   */
  public getBids(): OrderbookPriceLevel[] {
    return Array.from(this.bids.values());
  }

  /**
   * Return all asks (sell orders).
   */
  public getAsks(): OrderbookPriceLevel[] {
    return Array.from(this.asks.values());
  }

  /**
   * Return the bids (buy orders) at a given price level
   * @param rate Bids at that rate or undefined if there are no bids.
   */
  public getBidsAt(rate: string): OrderbookPriceLevel {
    return (
      this.bids.get(rate) || {
        amount: "0", // the amount at this Rate
        price: "0", // the rate of all orders on this level
        side: 1, // buy or sell
      }
    );
  }

  /**
   * Return the asks (sell orders) at a given price level
   * @param rate Asks at that rate or undefined if there are no asks.
   */
  public getAsksAt(rate: string): OrderbookPriceLevel {
    return (
      this.asks.get(rate) || {
        amount: "0", // the amount at this Rate
        price: "0", // the rate of all orders on this level
        side: 2, // buy or sell
      }
    );
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################
}
