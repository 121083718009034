import { ReactElement } from "react";
import {
  css,
  CSSObject,
  Global,
  SerializedStyles,
  Theme,
} from "@emotion/react/macro";

export interface IGlobalStyle {
  theme: Theme;
  fontsImport?: SerializedStyles;
  additionalStyle?: CSSObject | SerializedStyles;
}

export const GlobalStyle = ({
  theme,
  fontsImport,
  additionalStyle,
}: IGlobalStyle): ReactElement => (
  <Global
    styles={css`
      ${fontsImport}
      #root {
        font-size: ${theme.fontSizeBase};
        font-family: ${theme.fontFamily};
        color: ${theme.textColor};
        line-height: ${theme.lineHeightBase};
        overflow: hidden;
      }
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${theme.headingColor};
        margin: 0;
        line-height: ${theme.headingLineHeight};
      }
      h1 {
        font-size: ${theme.h1Size};
        font-weight: ${theme.h1Weight};
      }
      h2 {
        font-size: ${theme.h2Size};
        font-weight: ${theme.h2Weight};
      }
      h3 {
        font-size: ${theme.h3Size};
        font-weight: ${theme.h3Weight};
      }
      h4 {
        font-size: ${theme.h4Size};
        font-weight: ${theme.h4Weight};
      }
      h5 {
        font-size: ${theme.h5Size};
        font-weight: ${theme.h5Weight};
      }
      h6 {
        font-size: ${theme.h6Size};
        font-weight: ${theme.h6Weight};
      }
      * {
        box-sizing: border-box;
      }
      ${additionalStyle}
    `}
  />
);
