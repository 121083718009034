import { useLayoutEffect, useState } from "react";
import { useTheme } from "@emotion/react/macro";
import styled from "@emotion/styled/macro";
import { Spinner } from "react-bootstrap";
import { SubmitHandler, useForm } from "react-hook-form";
import useSWRImmutable from "swr/immutable";
import * as yup from "yup";

import {
  Button,
  Checkbox,
  Modal,
  OutlineSelect,
  Tab,
  TabsBar,
  TextField,
} from "components";
import { NewUserPaymentMethod, PaymentMethodRes } from "models/paymentMethod";
import { apiFetcher } from "providers";
import { yupResolver } from "@hookform/resolvers/yup";

type FormInputs = {
  tab: string;
  bank_payment_method_code: string;
  bank_account_number: string;
  bank_account_name: string;
  prompt_account_number: string;
  prompt_account_name: string;
  is_default: boolean;
};

interface IAddNewPayment {
  show: boolean;
  onCancel: () => void;
  onConfirm: (data: NewUserPaymentMethod) => void;
  loading: boolean;
}

const StyledModal = styled(Modal)`
  form {
    max-width: 540px;
    max-height: 80vh;
    min-height: 450px;
    display: grid;
    grid-template-rows: max-content 1fr max-content;
  }
`;

const TabsStyle = styled(TabsBar)`
  overflow: hidden;
  height: 100%;
  .tab-content-container {
    overflow: auto;
  }
`;

const Header = styled.div``;

const Content = styled.div`
  overflow: hidden;
`;

const Footer = styled.div``;

const Title = styled.h2`
  margin-bottom: 8px;
  text-align: center;
`;

const Desc = styled.div`
  margin-bottom: 8px;
  text-align: center;
`;

const FooterButtons = styled.div`
  display: flex;
`;

const schema = yup.object({
  bank_payment_method_code: yup.string().when("tab", {
    is: "bank",
    then: yup.string().required("Bank name is required"),
  }),
  bank_account_number: yup.string().when("tab", {
    is: "bank",
    then: yup.string().required("Bank account number is required"),
  }),
  bank_account_name: yup.string().when("tab", {
    is: "bank",
    then: yup.string().required("Bank account name is required"),
  }),
  prompt_account_number: yup.string().when("tab", {
    is: "prompt",
    then: yup.string().required("Prompt account number is required"),
  }),
  prompt_account_name: yup.string().when("tab", {
    is: "prompt",
    then: yup.string().required("Prompt account name is required"),
  }),
  is_default: yup.boolean(),
});

export const AddNewPayment = ({
  show,
  onCancel,
  onConfirm,
  loading,
}: IAddNewPayment) => {
  const theme = useTheme();
  const [activeTab, setactiveTab] = useState("bank");
  const [isDefault, setIsDefault] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      tab: "bank",
    },
  });

  useLayoutEffect(() => {
    if (show) {
      reset();
    }
  }, [reset, show]);

  const { data: paymentMethodRes, error: paymentMethodErr } =
    useSWRImmutable<PaymentMethodRes>(
      "/payment-method/list",
      (url) => apiFetcher({ url }) // todo remove this
    );

  const promptpayMethod = paymentMethodRes?.data.payment_methods.find(
    (method) => method.name === "promptpay"
  );
  const bankMethods = paymentMethodRes?.data.payment_methods.filter(
    (method) => method.name !== "promptpay"
  );

  const onSubmit: SubmitHandler<FormInputs> = (data) => {
    const userData: NewUserPaymentMethod = {
      payment_method_code:
        activeTab === "bank"
          ? data.bank_payment_method_code
          : promptpayMethod?.code || "",
      account_number:
        activeTab === "bank"
          ? data.bank_account_number
          : data.prompt_account_number,
      account_name:
        activeTab === "bank"
          ? data.bank_account_name
          : data.prompt_account_name,
      is_default: isDefault,
    };
    onConfirm(userData);
  };

  const onTabChange = (k: string | null) => {
    setactiveTab(k || "");
    reset();
    setValue("tab", k || "");
  };

  const handleBackgroundClick = () => (loading ? null : onCancel());

  return (
    <StyledModal show={show} onBackgroundClick={handleBackgroundClick}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("tab")} />
        <Header>
          <Title>New Payment Method</Title>
          <Desc>
            We require details of your beneficiary bank account, from which you
            will transfer fiat to the exchange and to which the exchange will
            transfer fiat to you.
          </Desc>
        </Header>
        <Content>
          <TabsStyle
            mode="horizontal-pill"
            activeKey={activeTab}
            onSelect={onTabChange}
          >
            <Tab key="bank" label="Bank Account">
              <OutlineSelect
                label="Bank"
                defaultValue=""
                {...register("bank_payment_method_code")}
                errorWarn={!!errors.bank_payment_method_code}
                errorMessage={errors.bank_payment_method_code?.message}
              >
                <option value="" disabled>
                  Select Bank
                </option>
                {bankMethods?.map((bank) => (
                  <option key={bank.code} value={bank.code}>
                    {bank.name}
                  </option>
                ))}
              </OutlineSelect>
              <TextField
                placeholder="Bank Account Number"
                label="Bank Account Number"
                {...register("bank_account_number")}
                errorWarn={!!errors.bank_account_number}
                errorMessage={errors.bank_account_number?.message}
              />
              <TextField
                placeholder="Bank Account Name"
                label="Bank Account Name"
                {...register("bank_account_name")}
                errorWarn={!!errors.bank_account_name}
                errorMessage={errors.bank_account_name?.message}
              />
              <div>Bank account name must be the same as your account name</div>
            </Tab>
            <Tab key="prompt" label="PromptPay">
              <TextField
                placeholder="Promptpay Number"
                label="Promptpay Number"
                {...register("prompt_account_number")}
                errorWarn={!!errors.prompt_account_number}
                errorMessage={errors.prompt_account_number?.message}
              />
              <TextField
                placeholder="Bank Account Name"
                label="Bank Account Name"
                {...register("prompt_account_name")}
                errorWarn={!!errors.prompt_account_name}
                errorMessage={errors.prompt_account_name?.message}
              />
              <div>Bank account name must be the same as your account name</div>
            </Tab>
          </TabsStyle>
        </Content>
        <Footer>
          <Checkbox
            block={false}
            checkedColor={theme.primaryColor}
            type="box"
            label="Save as default"
            checked={isDefault}
            onClick={() => setIsDefault((prev) => !prev)}
          />
          <FooterButtons>
            <Button
              style={{ marginRight: "8px" }}
              onClick={onCancel}
              variant="secondary"
              block
              disabled={loading}
            >
              Cancel
            </Button>
            <Button type="submit" block disabled={loading}>
              {loading ? (
                <Spinner
                  style={{ display: "block" }}
                  animation="border"
                  variant="light"
                />
              ) : (
                "Confirm"
              )}
            </Button>
          </FooterButtons>
        </Footer>
      </form>
    </StyledModal>
  );
};
