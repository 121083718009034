import { useNavigate } from "react-router-dom";

import { Button } from "components";
import { config } from "config";
import { Title, Desc } from "../components";

const { path } = config;

const Success = () => {
  const navigate = useNavigate();

  return (
    <div>
      <Title>Successful Registration</Title>
      <Desc>
        Wait for the admin approval for 2 days until receive an email/web app
        notification.
      </Desc>
      <Button block onClick={() => navigate(path.login)}>
        Go To Main Page
      </Button>
    </div>
  );
};

export default Success;
