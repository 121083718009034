import React from "react";
import styled from "@emotion/styled";
import { LoadingOverlay } from "components";

const Container = styled.div`
  padding: 16px;
  border: 1px solid ${(props) => props.theme.lightgray};
  width: 100%;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  transition: border-color 0.3s;
  position: relative;
`;
const Detail = styled.div`
  margin: 0 auto 0 24px;
`;
const OwnerName = styled.div`
  white-space: nowrap;
`;

const AccountId = styled.div``;

interface IBankSelectButton {
  iconSrc: string;
  name: string;
  accountId?: string;
  style?: React.CSSProperties;
  loading?: boolean;
}
const BankSelectButton = ({
  iconSrc,
  name,
  accountId,
  style,
  loading,
}: IBankSelectButton) => (
  <Container style={style}>
    <LoadingOverlay show={loading} />
    <img width="48px" height="48px" src={iconSrc} alt="bank-account" />
    <Detail>
      <OwnerName>{name}</OwnerName>
      <AccountId>{accountId}</AccountId>
    </Detail>
  </Container>
);

export default BankSelectButton;
