import styled from "@emotion/styled/macro";
import { Badge, Button, IModal, LoadingOverlay, Modal } from "components";
import dayjs from "dayjs";
import {
  TransactionStatus,
  EntryType,
  transactionStatusVariant,
} from "models/transaction";
import { mediaQuery, priceNumber } from "utils";

import { ReactComponent as CloseSVG } from "assets/CarbonCredit-SVG/Close.svg";
import { ReactComponent as Tver } from "assets/TVER.svg";
import { TransactionReqByIdRes } from "models/transactionRequest";
import useSWR from "swr";

export interface ITransactionModal extends IModal {
  transactionId: number;
  onClose: () => void;
  updateTransaction: (transactionId: number, status: TransactionStatus) => void;
}

const TitleDetail = styled.h1`
  font-size: 1.375rem;
`;

const IconButton = styled.button`
  width: fit-content;
  background: transparent;
  border: none;
`;

const TransactionDetailContainer = styled.div`
  width: 100%;
  text-align: left;
  p {
    margin-bottom: 0.75rem;
  }
  hr {
    margin: 16px 0 24px;
  }
  ${mediaQuery("mobile")} {
    min-width: 360px;
  }
`;

const RowData = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderGroup = styled(RowData)`
  margin-bottom: 24px;
`;

const RequestDetail = styled.div``;

const TitleGroup = styled.div`
  display: flex;
  column-gap: 8px;
`;

const InfoName = styled.p`
  font-weight: 600;
  font-size: 0.875rem;
`;

const InfoValue = styled(InfoName)``;

const BoldText = styled.p`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
`;

const SymbolContainer = styled.div`
  display: flex;
  padding: 16px;
  background-color: ${(props) => props.theme.lightgray50};
  border-radius: 8px;
  margin-bottom: 32px;
  align-items: center;
  column-gap: 16px;
  p {
    margin-bottom: 0;
  }
`;

const TverIcon = styled(Tver)`
  width: 45px;
  height: 45px;
`;

export const TransactionModal = ({
  transactionId,
  onClose,
  updateTransaction,
  ...rest
}: ITransactionModal) => {
  const { data: transactionByIdRes } = useSWR<TransactionReqByIdRes>(
    transactionId !== -1 ? `/transaction-request/${transactionId}` : null
  );

  const data = transactionByIdRes?.data;

  const dataMap = [
    {
      id: "transaction-id",
      name: "Transaction ID",
      value: data?.transaction_id,
    },
    { id: "entry-type", name: "Entry Type", value: data?.entry_type },
    { id: "email", name: "Email", value: data?.email },
    {
      id: "created-date",
      name: "Created Date",
      value: dayjs(data?.created_at).format("DD MMM YYYY HH:mm"),
    },
    {
      id: "updated-date",
      name: "Updated Date",
      value: dayjs(data?.updated_at).format("DD MMM YYYY HH:mm"),
    },
    { id: "updated-by", name: "Updated By", value: data?.updated_by },
  ];

  const isPending = data?.status === TransactionStatus.Pending;
  const isDeposit = data?.entry_type === EntryType.Deposit;
  const isWithdraw = data?.entry_type === EntryType.Withdraw;
  return (
    <Modal {...rest} onBackgroundClick={onClose}>
      <LoadingOverlay show={!data} />
      <TransactionDetailContainer data-test-id="credit-transaction__modal">
        <HeaderGroup>
          <TitleGroup>
            <TitleDetail>
              {data?.status === "PENDING"
                ? "Request Detail"
                : "Transfer Detail"}
            </TitleDetail>
            <Badge
              style={{ alignSelf: "center" }}
              variant={transactionStatusVariant(
                data?.status as TransactionStatus
              )}
            >
              {data?.status.replace("_", " ").toLowerCase()}
            </Badge>
          </TitleGroup>
          <IconButton onClick={onClose}>
            <CloseSVG />
          </IconButton>
        </HeaderGroup>
        <RequestDetail>
          {dataMap.map(({ id, name, value }) => (
            <RowData key={id}>
              <InfoName>{name}</InfoName>
              <InfoValue data-test-id={`${id}__text`}>{value}</InfoValue>
            </RowData>
          ))}
        </RequestDetail>
        <hr />
        <BoldText>Symbol</BoldText>
        <SymbolContainer>
          <TverIcon />
          <InfoValue>{data?.symbol}</InfoValue>
        </SymbolContainer>
        <RowData>
          <BoldText style={{ textTransform: "capitalize" }}>
            {data?.entry_type.toLowerCase()}
          </BoldText>
          <BoldText data-test-id="amount__text">
            {priceNumber(+(data?.amount || "0"), { min: 0, max: 0 })} Credit
          </BoldText>
        </RowData>
        <RowData style={{ columnGap: "16px" }}>
          <Button
            onClick={() =>
              updateTransaction(transactionId, TransactionStatus.Rejected)
            }
            block
            variant="danger"
          >
            Reject
          </Button>
          {(!isDeposit || (isDeposit && isPending)) && (
            <Button
              onClick={() =>
                updateTransaction(
                  transactionId,
                  isPending
                    ? TransactionStatus.InProcess
                    : TransactionStatus.Success
                )
              }
              block
            >
              {isPending ? "Approve" : "Mark as Success"}
            </Button>
          )}
        </RowData>
      </TransactionDetailContainer>
    </Modal>
  );
};
