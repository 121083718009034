import { useState } from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import { bool, object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, Checkbox, Label, LabelRequired } from "components";
import { PDPA } from "models/user";
import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";

import { SmallTitle, Form, RegisterComponentGrid } from "../components";

const CustomLabel = styled(Label)`
  font-weight: 400;
`;

const CheckboxGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

const schema = object({
  is_collectable: bool().required("Please choose your option."),
  is_shareable: bool().required("Please choose your option."),
  is_researchable: bool().required("Please choose your option."),
});

export const PDPAPage = () => {
  const {
    state: { page, pdpa },
    dispatch,
  } = useRegisterContext();

  const {
    watch,
    setValue,
    handleSubmit,
    clearErrors,
    formState: { isDirty },
  } = useForm<PDPA>({
    resolver: yupResolver(schema),
    defaultValues: pdpa,
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const [agreement, setAgreement] = useState({
    correctKYCInfo: false,
    disclosure: false,
  });

  const [isCollectable, isShareable, isResearchable] = watch([
    "is_collectable",
    "is_shareable",
    "is_researchable",
  ]);

  const disabledNextButton =
    !agreement.correctKYCInfo ||
    !agreement.disclosure ||
    isCollectable === null ||
    isShareable === null ||
    isResearchable === null;

  const onSelect = (
    name: "is_collectable" | "is_shareable" | "is_researchable",
    value: boolean
  ) => {
    setValue(name, value);
    clearErrors(name);
  };

  const onSubmit = (data: PDPA) => {
    onSubmitted();
    dispatch({ type: "SET_PDPA", payload: data });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <RegisterComponentGrid>
      <SmallTitle>คำรับรอง และการเปิดเผยข้อมูลส่วนบุคคล</SmallTitle>
      <Form id="delegate" onSubmit={handleSubmit(onSubmit)}>
        <CustomLabel>
          ข้าพเจ้าขอรับรองและยืนยันว่าข้อมูลที่ให้ไว้ในแบบฟอร์มคำขอเปิดบัญชีและรวมถึงเอกสารแนบ
          เป็นข้อมูลที่ถูกต้องครบถ้วนตามความเป็นจริงและเป็นปัจจุบัน
          หากมีการเปลี่ยนแปลง ข้าพเจ้าจะแจ้งให้บริษัทฯทราบ
          เพื่อปรับปรุงข้อมูลให้เป็นปัจจุบัน
          <LabelRequired>*</LabelRequired>
        </CustomLabel>
        <Checkbox
          type="box"
          label="ข้าพเจ้ารับทราบและยืนยัน"
          checked={agreement.correctKYCInfo}
          onClick={() =>
            setAgreement((prev) => ({
              ...prev,
              correctKYCInfo: !prev.correctKYCInfo,
            }))
          }
        />
        <CustomLabel>
          ข้าพเจ้าได้แจ้งต่อกรรมการบริษัทหรือบุคคลอื่นใด
          ที่ข้าพเจ้าได้กระทำการแทนหรือที่ได้ระบุชื่อไว้ใน
          แบบฟอร์มคำขอเปิดบัญชีนี้
          ให้รับทราบและยินยอมให้ใช้ข้อมูลส่วนบุคคลตามที่ระบุในแบบฟอร์มนี้
          และข้าพเจ้ารับทราบว่า บริษัทฯ จะรวบรวม เก็บรักษา
          และใช้ข้อมูลส่วนบุคคลที่ข้าพเจ้าได้ให้ไว้
          สำหรับการเปิดบัญชีเพื่อซื้อขายแลกเปลี่ยนผลิตภัณฑ์
          หรือให้บริการอื่นตามที่ระบุไว้ในสัญญา (ถ้ามี)
          ตราบเท่าที่วัตถุประสงค์ของการนำข้อมูลดังกล่าวไปใช้ยังคงมีอยู่เว้นเเต่กรณีจำเป็นต้องเก็บรักษาข้อมูลต่อไปตามที่มีกฎหมายกำหนด
          และอาจมีความจำเป็นที่จะต้องเปิดเผยข้อมูลดังกล่าว
          เมื่อมีการร้องขอจากหน่วยงานกำกับที่เกี่ยวข้อง และข้าพเจ้ารับทราบว่า
          การให้บริการของบริษัทฯ ตามสัญญา (ถ้ามี)
          บริษัทฯอาจมีความจำเป็นต้องส่งข้อมูลส่วนบุคคล
          ไปยังหน่วยงานที่เกี่ยวข้อง
          กับการทำธุรกรรมรวมถึงแต่ไม่จำกัดเพียงหน่วยงานดังต่อไปนี้ อบก.,
          การไฟฟ้าฯ, แพลตฟอร์มเทรด, สถาบันการเงิน, สภาอุตสาหกรรมแห่งประเทศไทย
          เป็นต้น <LabelRequired>*</LabelRequired>
        </CustomLabel>
        <Checkbox
          type="box"
          label="ข้าพเจ้ารับทราบและยืนยัน"
          checked={agreement.disclosure}
          onClick={() =>
            setAgreement((prev) => ({
              ...prev,
              disclosure: !prev.disclosure,
            }))
          }
        />
        <Label>สัญญาแต่งตั้งบริษัทฯ เป็นนายหน้าซื้อ-ขายผลิตภัณฑ์</Label>
        <CustomLabel>
          ข้อ 1) ให้บริษัทฯ สามารถเก็บรวบรวมหรือตรวจสอบข้อมูลส่วนบุคคล
          จากเเหล่งอื่นได้อาทิเช่น สถาบันการเงิน หรือส่วนราชการ
          หรือหน่วยงานของรัฐ
          <LabelRequired>*</LabelRequired>
        </CustomLabel>
        <CheckboxGroup>
          <Checkbox
            type="box"
            label="ยินยอม"
            checked={isCollectable === null ? false : isCollectable}
            onClick={() => onSelect("is_collectable", true)}
          />
          <Checkbox
            type="box"
            label="ไม่ยินยอม"
            checked={isCollectable === null ? false : !isCollectable}
            onClick={() => onSelect("is_collectable", false)}
          />
          <div />
        </CheckboxGroup>
        <CustomLabel>
          ข้อ 2) ให้บริษัทฯ สามารถเปิดเผยข้อมูลโครงการ อันได้เเก่ ชื่อบัญชี
          ปริมาณผลิตภัณฑ์ ที่ถือครอง ที่ยกเลิก เเละอื่น ๆ
          ที่เกี่ยวข้องต่อสาธารณะ เพื่อให้เป็นไปตามหลักการความโปร่งใส
          <LabelRequired>*</LabelRequired>
        </CustomLabel>
        <CheckboxGroup>
          <Checkbox
            type="box"
            label="ยินยอม"
            checked={isShareable === null ? false : isShareable}
            onClick={() => onSelect("is_shareable", true)}
          />
          <Checkbox
            type="box"
            label="ไม่ยินยอม"
            checked={isShareable === null ? false : !isShareable}
            onClick={() => onSelect("is_shareable", false)}
          />
          <div />
        </CheckboxGroup>
        <CustomLabel>
          ข้อ 3) ให้บริษัทฯ เเละผู้ปฏิบัติงานของบริษัทฯ
          นำข้อมูลส่วนบุคคลที่เก็บรวบรวมไปใช้ในการดำเนิน การเกี่ยวกับการศึกษา
          วิจัย ข้อมูลสถิติ ติดต่อสื่อสารประชาสัมพันธ์ข่าวสาร
          หรือโปรโมชั่นส่งเสริม การขาย
          เเละการส่งเสริมพัฒนาศักยภาพหรือดำเนินการอื่นใดของบริษัทฯได้
          <LabelRequired>*</LabelRequired>
        </CustomLabel>
        <CheckboxGroup>
          <Checkbox
            type="box"
            label="ยินยอม"
            checked={isResearchable === null ? false : isResearchable}
            onClick={() => onSelect("is_researchable", true)}
          />
          <Checkbox
            type="box"
            label="ไม่ยินยอม"
            checked={isResearchable === null ? false : !isResearchable}
            onClick={() => onSelect("is_researchable", false)}
          />
          <div />
        </CheckboxGroup>
        <CustomLabel>
          โดยข้าพเจ้าทราบดีว่าการไม่ให้ความยินยอมในข้อ 1) หรือ 2) ของข้าพเจ้า
          อาจส่งผลต่อการให้บริการของบริษัทฯ ในบางส่วนหรือทั้งหมด
        </CustomLabel>
        <CustomLabel>
          การเพิกถอนความยินยอมข้าพเจ้าอาจเพิกถอนความยินยอม ทั้งหมดหรือส่วนใด
          ส่วนหนึ่งตาม หนังสือ ฉบับนี้เมื่อใดก็ได้โดยข้าพเจ้าจะเเจ้งให้ บริษัทฯ
          ทราบเป็นลายลักษณ์อักษร เเละให้บริษัทฯ
          พิจารณาดำเนินการตามคำขอเพิกถอนหรือชี้แจงถึงความจำเป็นที่ไม่สามารถเพิกถอนความยินยอมนั้นได้
          โดยการเพิกถอนความยินยอมของข้าพเจ้าไม่มีผลกระทบต่อการดำเนินการใดๆ
          ที่บริษัทฯได้ ดำเนินการไปเเล้วในกรณีที่การเพิกถอนความยินยอม
          ตามหนังสือฉบับนี้ เกิดผลกระทบต่อสิทธิ หรือหน้าที่ใดๆ ของข้าพเจ้า
          ข้าพเจ้ายอมรับผลกระทบที่อาจเกิดขึ้นจากการนั้นได้
        </CustomLabel>
      </Form>
      <Button block form="delegate" type="submit" disabled={disabledNextButton}>
        Next
      </Button>
    </RegisterComponentGrid>
  );
};
