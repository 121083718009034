import { useState, useRef, RefObject } from "react";
import styled from "@emotion/styled/macro";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Nav, Navbar, NavDropdown, Spinner } from "react-bootstrap";
import useSWR from "swr";
import dayjs from "dayjs";
import axios from "axios";
import { Badge, Button, IconButton, LoadingOverlay } from "components";

import { ReactComponent as ZoomOut } from "assets/CarbonCredit-SVG/ZoomOut.svg";
import { ReactComponent as ZoomIn } from "assets/CarbonCredit-SVG/ZoomIn.svg";
import dotsThreeVertical from "assets/CarbonCredit-SVG/DotsThreeVertical.svg";
import { mediaQuery } from "utils/mediaQueries";
import { apiFetcher } from "providers";
import { client } from "api/client";
import { backendURL } from "config";
import type { File } from "models/user";
import { BankAccount } from "features/Admin";
import { getBankLogo, apiErrorToast } from "utils";
import {
  statusVariant,
  RequestStatus,
  RegistrationRequestByIdRes,
} from "models/registration";
import { APIError } from "models/generic";

const imageFileExtensions = ["jpg", "jpeg", "png", "gif"];

const RegReqInfoContainer = styled.div`
  min-height: 100%;
  height: calc(100vh - 99px);
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  position: relative;
`;

const Content = styled.div`
  box-shadow: ${(props) => props.theme.boxShadowBase};
  border-radius: ${(props) => props.theme.borderRadiusBase};
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  ${mediaQuery("tablet")} {
    grid-template-columns: 45% 1fr;
  }
`;

const ImageDisplay = styled.div`
  background: ${(props) => props.theme.lightgray};
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  object {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
`;

const DisplayControl = styled(Navbar)`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  max-width: 80%;
  background-color: ${(props) => props.theme.secondaryColor};
  padding: 1rem;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  color: ${(props) => props.theme.textColorLight};
  text-align: center;
  max-height: 100%;
  overflow: auto;
  .navbar-collapse {
    width: 100%;
    .dropdown-toggle {
      white-space: normal;
    }
    .dropdown-item {
      white-space: normal;
    }
  }
  .navbar-toggler {
    width: 100%;
  }
  ${mediaQuery("large")} {
    overflow: unset;
    max-height: fit-content;
    max-width: fit-content;
    .dropdown-menu {
      max-height: 50vh;
      overflow: auto;
    }
  }
`;

const DetailsCard = styled.div`
  background: ${(props) => props.theme.componentBackgroundColor};
  box-shadow: ${(props) => props.theme.boxShadowBase};
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  padding: 40px;
  display: grid;
  grid-template-rows: 1fr max-content;
  height: 100%;
  overflow: auto;
`;

const Object = styled.object`
  width: 100%;
  height: 100%;
`;

const Details = styled.div`
  overflow-y: auto;
  padding-bottom: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

const Footer = styled.div`
  display: flex;
  padding-top: 16px;
`;

const Left = styled.div`
  margin-right: 2rem;
`;
const Right = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

const Title = styled.h2`
  grid-column: 1 / span 2;
  display: flex;
  align-items: center;
`;

const SubTitle = styled.h3`
  grid-column: 1 / span 2;
  margin-top: 16px;
  font-weight: bold;
`;

const Line = styled.div`
  grid-column: 1 / span 2;
  content: "";
  height: 0;
  border-bottom: 1px solid ${(props) => props.theme.lightgray50};
`;

const Label = styled.div`
  color: ${(props) => props.theme.darkgray};
`;

const Value = styled.div`
  font-weight: bold;
`;

const VerticalLine = styled.div`
  display: none;
  ${mediaQuery("large")} {
    display: block;
    height: 38px;
    border-left: 1px solid ${(props) => props.theme.white3};
  }
`;

const StyledIconButton = styled(IconButton)`
  margin: 0 16px;
  > img {
    width: 20px;
    height: 20px;
  }
`;

const XlOnlyNav = styled(Nav)`
  display: none;
  ${mediaQuery("large")} {
    display: flex;
    min-width: fit-content;
  }
`;

const Range = styled(Form.Range)`
  display: block;
  ${mediaQuery("large")} {
    display: none;
  }
`;

const Image = styled.img<{ dimensions: { width: number; height: number } }>`
  margin: auto;
  width: ${(props) =>
    props.dimensions.width ? `${props.dimensions.width}px` : "auto"};
  height: ${(props) =>
    props.dimensions.height ? `${props.dimensions.height}px` : "auto"};
`;

const Link = styled.a`
  color: ${(props) => props.theme.primaryColor};
  text-decoration: underline;
`;

const fileTypetoTitleCase = (str: string) =>
  str
    .replaceAll("_", " ")
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

const TraderInfo = () => {
  const navigate = useNavigate();
  const imgRef = useRef() as RefObject<HTMLImageElement>;
  const [displayType, setDisplayType] = useState("");
  const [selectedDisplay, setSelectedDisplay] = useState<File | null>(null);
  const [zoom, setZoom] = useState(100);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [zoomedDimensions, setZoomedDimensions] = useState({
    width: 0,
    height: 0,
  });

  const location = useLocation();
  const thenum = location?.search?.match(/\d+/);

  const id = thenum![0];

  const setDisplay = (file: File) => {
    // get file type base on file url extension
    // http://localhost:9000/general-matching-engine/zSFf9iIYpz2iTJsc1s02kbukzIIQKYoOektO8Twy.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=gideon_gct%2F20221116%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20221116T080550Z&X-Amz-Expires=604800&X-Amz-SignedHeaders=host&X-Amz-Signature=af3a0a80b1ccc600ff13bc58f9b7bc6f6581ee1d14ed076cd1523d08edb4118d
    // .jpg
    const fileType = file.url.split("?")[0].split(".").pop();
    if (fileType === "pdf") {
      setDisplayType("pdf");
    } else if (imageFileExtensions.includes(fileType!)) {
      setDisplayType("image");
      setZoom(100);
      setImageDimensions({
        width: 0,
        height: 0,
      });
      setZoomedDimensions({
        width: 0,
        height: 0,
      });
    }
    setSelectedDisplay(file);
  };

  const { data: manageAppData } = useSWR<RegistrationRequestByIdRes>(
    `/registration-request/${id}`,
    (url) => apiFetcher({ url }),
    {
      onSuccess: (data) => {
        if (data.data.files.length > 0 && !displayType) {
          setDisplay(data.data.files[0]);
        }
      },
    }
  );

  const data = manageAppData?.data;

  const accountPending = data?.status === "PENDING";

  const handleClickReject = async () => {
    await client
      .post(`${backendURL}/registration-request/update`, {
        id: data?.id,
        status: "reject",
      })
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
      });
  };
  const handleClickApprove = async () => {
    await client
      .post(`${backendURL}/registration-request/update`, {
        id: data?.id,
        status: "approve",
      })
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
      });
  };

  const onSelectDisplay = (eventKey: string | null) => {
    // find the file with the same file_type
    const file = data?.files.find((file) => file.file_type === eventKey);
    if (file) {
      setDisplay(file);
    }
  };

  const onImageLoad = () => {
    if (imgRef.current) {
      setImageDimensions({
        width: imgRef.current.clientWidth,
        height: imgRef.current.clientHeight,
      });
      setZoomedDimensions({
        width: imgRef.current.clientWidth,
        height: imgRef.current.clientHeight,
      });
    }
  };

  const onZoomChange = (val: number) => {
    if (val >= 50 && val <= 400) {
      setZoom(val);
      setZoomedDimensions({
        width: Math.round(imageDimensions.width * (val / 100)),
        height: Math.round(imageDimensions.height * (val / 100)),
      });
    } else if (val <= 50) {
      setZoom(50);
      setZoomedDimensions({
        width: Math.round(imageDimensions.width * 0.5),
        height: Math.round(imageDimensions.height * 0.5),
      });
    } else if (val >= 400) {
      setZoom(400);
      setZoomedDimensions({
        width: Math.round(imageDimensions.width * 4),
        height: Math.round(imageDimensions.height * 4),
      });
    }
  };

  const downloadKyc = () => {
    const kyc = JSON.parse(data?.kyc!);
    const kycString = JSON.stringify(kyc, null, 2);
    const element = document.createElement("a");
    const file = new Blob([kycString], { type: "text/plain" });
    element.href = URL.createObjectURL(file);
    element.download = `kyc_req_${data?.id || "0"}.json`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  const getDisplay = () => {
    if (displayType === "pdf") {
      return (
        <Object data={selectedDisplay?.url} type="application/pdf">
          <div>No file found.</div>
        </Object>
      );
    }
    if (displayType === "image") {
      return (
        <ImageContainer>
          <Image
            ref={imgRef}
            alt="something"
            src={selectedDisplay?.url}
            dimensions={zoomedDimensions}
            onLoad={onImageLoad}
          />
        </ImageContainer>
      );
    }
    if (!displayType) {
      return <LoadingOverlay show />;
    }
    return <div>Could not display file.</div>;
  };

  return (
    <RegReqInfoContainer>
      <Content>
        <LoadingOverlay show={!manageAppData} />
        <ImageDisplay>
          {getDisplay()}
          <DisplayControl expand="xxl" variant="dark">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse>
              <Nav onSelect={onSelectDisplay}>
                <NavDropdown
                  title={fileTypetoTitleCase(
                    selectedDisplay?.file_type || "Select File"
                  )}
                  drop="up"
                >
                  {data?.files &&
                    data.files.map((file) => (
                      <NavDropdown.Item eventKey={file.file_type}>
                        {fileTypetoTitleCase(file.file_type)}
                      </NavDropdown.Item>
                    ))}
                </NavDropdown>
              </Nav>
              {displayType === "image" && (
                <>
                  <VerticalLine />
                  <XlOnlyNav>
                    <StyledIconButton
                      icon={<ZoomOut color="white" />}
                      onClick={() => onZoomChange(zoom - 25)}
                    />
                    <div>
                      {new Intl.NumberFormat("en", {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 0,
                      }).format(zoom)}
                      %
                    </div>
                    <StyledIconButton
                      icon={<ZoomIn color="white" />}
                      onClick={() => onZoomChange(zoom + 25)}
                    />
                  </XlOnlyNav>
                  <VerticalLine />
                  <XlOnlyNav>
                    <StyledIconButton iconSrc={dotsThreeVertical} />
                  </XlOnlyNav>
                  <Range
                    min="50"
                    max="400"
                    value={zoom}
                    onChange={(e) => onZoomChange(parseFloat(e.target.value))}
                  />
                </>
              )}
            </Navbar.Collapse>
          </DisplayControl>
        </ImageDisplay>
        <DetailsCard>
          <Details>
            <Title>
              Request Detail{" "}
              <Badge
                variant={statusVariant(
                  (data?.status as RequestStatus) ?? "PENDING"
                )}
                style={{ marginLeft: "16px" }}
              >
                {data?.status.toLowerCase()}
              </Badge>
            </Title>
            <Label>Created Date</Label>
            <Value>{dayjs(data?.created_at).format("YYYY-MM-DD")}</Value>
            <Line />
            <Label>Updated Date</Label>
            <Value>{dayjs(data?.updated_at).format("YYYY-MM-DD")}</Value>
            <Line />
            <Label>Updated By</Label>
            <Value>{data?.updated_by}</Value>
            <Line />
            <SubTitle>Account</SubTitle>
            <Label>Account Ownership</Label>
            <Value>{data?.account_ownership}</Value>
            <Line />
            <Label>Email</Label>
            <Value>{data?.email}</Value>
            <Line />
            <Label>Company Name</Label>
            <Value>{data?.name}</Value>
            <Line />
            <Label>Company Registration Number</Label>
            <Value>{data?.id_number}</Value>
            <Line />
            <Label>Company Address</Label>
            <Value>{data?.address}</Value>
            <Line />
            <Label>Company Office Branch</Label>
            <Value>{data?.office_branch}</Value>
            <Line />
            <SubTitle>Bank</SubTitle>
            <BankAccount
              name={data?.bank_account_name || ""}
              accountId={data?.bank_account_number}
              iconSrc={getBankLogo(data?.bank_code || "")}
              style={{ marginBottom: "2rem", gridColumn: "1/span 2" }}
            />
            {data?.kyc && (
              <>
                <SubTitle>KYC File</SubTitle>
                <Label>File link</Label>
                <Value>
                  <Button
                    variant="link"
                    onClick={downloadKyc}
                    style={{
                      textDecoration: "underline",
                      fontSize: "1rem",
                      fontWeight: "bold",
                    }}
                  >
                    KYC_req_{data.id}.json
                  </Button>
                </Value>
              </>
            )}

            <SubTitle>Files</SubTitle>
            {data?.files &&
              data.files.map((file) => (
                <>
                  <Label>{fileTypetoTitleCase(file.file_type)}</Label>
                  <Value>
                    <Link
                      href={file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.file_name}
                    </Link>
                  </Value>
                  <Line />
                </>
              ))}
          </Details>
          <Footer>
            <Left>
              <Button variant="link" block onClick={() => navigate(-1)}>
                Back
              </Button>
            </Left>
            {accountPending && (
              <Right>
                <Button
                  variant="danger"
                  block
                  onClick={() => handleClickReject()}
                  style={{ marginRight: "8px", maxWidth: "200px" }}
                >
                  Reject
                </Button>
                <Button
                  onClick={() => handleClickApprove()}
                  style={{ maxWidth: "200px" }}
                  block
                >
                  Approve
                </Button>
              </Right>
            )}
          </Footer>
        </DetailsCard>
      </Content>
    </RegReqInfoContainer>
  );
};

export default TraderInfo;
