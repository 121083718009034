import { BadgeVariant } from "components";
import { APIResponse } from "./generic";

export enum OrderStatus {
  Active = "active",
  Filled = "filled",
  Expired = "expired",
  Cancelled = "cancelled",
}

export const orderStatusVariant = (status: string): BadgeVariant => {
  switch (status.toLowerCase()) {
    case "active":
      return "primary";
    case "filled":
      return "success";
    case "expired":
      return "danger";
    case "cancelled":
      return "disabled";
    default:
      return "success";
  }
};

export interface NewOrder {
  id: number;
  submit_time: string;
  pair_name: string;
  type: string;
  side: string;
  avg_price: string;
  price: string;
  executed: string;
  amount: string;
  total: string;
  status: OrderStatus;
}

export interface NewOrders {
  orders: NewOrder[];
  total: number;
}

export type NewOrderHistoryRes = APIResponse<NewOrders>;
