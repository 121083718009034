import styled from "@emotion/styled/macro";
import dayjs from "dayjs";

import { Button, Modal } from "components";
import { priceNumber } from "utils/numberFormat";
import { Transaction, EntryType } from "models/transaction";

const StyledModal = styled(Modal)`
  > .content-container {
    width: 400px;
  }
`;

const CancelButton = styled(Button)`
  margin: 0 4px 0 0;
`;

const BoldText = styled.h2`
  text-align: center;
  margin: 8px 0;
`;

const NormalText = styled.h3`
  margin: 4px 0 24px 0;
  text-align: center;
`;

const DetailText = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
`;

interface ConfirmCancelDepositWithdrawProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  history?: Transaction;
}

export const ConfirmCancelDepositWithdraw = ({
  show,
  onClose,
  onConfirm,
  history,
}: ConfirmCancelDepositWithdrawProps) => {
  if (!history) return <div />;
  return (
    <StyledModal show={show}>
      <BoldText>
        Cancel
        {history.entry_type === EntryType.Deposit ? "Deposit" : "Withdraw"}?
      </BoldText>
      <NormalText>Do you want to cancel this request</NormalText>
      <Flex>
        <DetailText>ID</DetailText>
        <DetailText>{history.id}</DetailText>
      </Flex>
      <Flex>
        <DetailText>Date</DetailText>
        <DetailText>
          {dayjs(history.date_time).format("DD MMM YYYY HH:mm")}
        </DetailText>
      </Flex>
      <Flex>
        <DetailText>Entry Type</DetailText>
        <DetailText>
          {history.entry_type === EntryType.Deposit ? "Deposit" : "Withdraw"}
        </DetailText>
      </Flex>
      <Flex>
        <DetailText>Amount</DetailText>
        <DetailText>{priceNumber(history.amount)} THB</DetailText>
      </Flex>
      <Flex>
        <CancelButton variant="secondary" block onClick={onClose}>
          No
        </CancelButton>
        <Button block onClick={onConfirm}>
          Yes
        </Button>
      </Flex>
    </StyledModal>
  );
};
