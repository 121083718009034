import { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import axios from "axios";
import { number, object, string } from "yup";
import styled from "@emotion/styled/macro";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";

import { IModal, TextField, Button, Modal } from "components";
import { DepositFiat } from "models/transaction";
import { apiErrorToast, twoDecimalChange } from "utils";
import { depositFiat } from "api/transaction";
import { APIError } from "models/generic";

export interface IMoneyDepositModal extends IModal {
  mutate: () => void;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  .content-container {
    min-width: 400px;
    max-width: 540px;
  }
`;

const Title = styled.h1`
  font-size: 1.875rem;
  text-align: center;
  margin-bottom: 24px;
`;

const Box = styled.div`
  display: flex;
  column-gap: 16px;
  position: relative;
`;

const LoginLoadingText = styled.span`
  margin-left: 1em;
`;

const schema = object({
  email: string()
    .required("Please Enter user's Email")
    .email("Please enter a valid email."),
  amount: number()
    .required("Please input amount")
    .typeError("Please input number only")
    .min(0.01, "Please input amount greater than 0")
    .max(100000000, "Please input amount less than 100,000,000"),
});

const defaultValues: DepositFiat = {
  email: "",
  amount: 0,
};

export const MoneyDepositModal = ({
  mutate,
  onClose,
  ...rest
}: IMoneyDepositModal) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    clearErrors,
  } = useForm<DepositFiat>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);

  const onCloseDepositModal = useCallback(() => {
    onClose();
    reset(defaultValues);
    clearErrors();
  }, [clearErrors, onClose, reset]);

  const onSubmit = useCallback(
    async (values: DepositFiat) => {
      setIsLoading(true);
      onCloseDepositModal();
      const toastId = toast.loading("Creating Deposit Request...");
      try {
        await depositFiat({
          ...values,
        });
        toast.success("Deposit Request Created", { id: toastId });
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error, toastId);
        }
      } finally {
        mutate();
        setIsLoading(false);
      }
    },
    [mutate, onCloseDepositModal]
  );

  return (
    <StyledModal {...rest} onBackgroundClick={onClose}>
      <form id="deposit-fiat" onSubmit={handleSubmit(onSubmit)}>
        <Title>Fiat Deposit</Title>
        <TextField
          label="Email"
          placeholder="Email"
          errorWarn={!!errors.email}
          errorMessage={errors.email?.message}
          {...register("email")}
        />
        <Controller
          name="amount"
          control={control}
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              label="Deposit Amount"
              placeholder="0.00"
              errorWarn={!!errors.amount}
              errorMessage={errors.amount?.message}
              onChange={(e) => twoDecimalChange(e, onChange)}
              {...rest}
            />
          )}
        />
      </form>
      <Box>
        <Button onClick={onCloseDepositModal} variant="secondary" block>
          Cancel
        </Button>
        <Button
          form="deposit-fiat"
          block
          type="submit"
          disabled={isLoading}
          flexDirection="row"
        >
          {!isLoading ? (
            "Submit"
          ) : (
            <Spinner animation="border" as="span" size="sm" />
          )}
        </Button>
      </Box>
    </StyledModal>
  );
};
