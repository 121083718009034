import * as yup from "yup";

export const passwordSchema = yup
  .string()
  .required("Password is required")
  .min(8, "Password must be at least 8 characters long")
  .matches(/(.*[a-z].*)/, "Password must contain at least 1 lowercase letter")
  .matches(/(.*[A-Z].*)/, "Password must contain at least 1 uppercase letter")
  .matches(/(.*\d.*)/, "Password must contain at least 1 number")
  .matches(
    /[~!@#%^&$;*_\-+=`|\\(){}\\[\]:;'"<>,.?/]+/,
    "Password must contain at least 1 special character"
  );

export const emailSchema = yup
  .string()
  .required("Please Enter your Email")
  .email("Please enter a valid email.");

export const fileRequiredSchema = yup
  .mixed()
  .test(
    "required",
    "This field is required.",
    (file: FileList) => file && (file.item(0)?.size ?? 0) > 0
  );

export const mobileSchema = yup
  .string()
  .required("Mobile number is required")
  .min(11, "Mobile number must be at valid");
