import styled from "@emotion/styled/macro";
import { useState } from "react";
import dayjs from "dayjs";
import { getReport } from "api/admin";
import toast from "react-hot-toast";
import axios from "axios";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Button, Card, DatePicker } from "components";
import { apiErrorToast } from "utils";
import { APIError } from "models/generic";
import { ReactComponent as Info } from "assets/CarbonCredit-SVG/Info.svg";

const StyledCard = styled(Card)`
  margin-bottom: 32px;
`;

const Title = styled.h2`
  margin-bottom: 16px;
`;

const PickerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  > * {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const StyledTooltip = styled(Tooltip)`
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.12));
  .tooltip-inner {
    background-color: ${(props) => props.theme.componentBackgroundColor};
    color: ${(props) => props.theme.darkgray200};
    text-align: left;
    max-width: 220px;
  }
  .tooltip-arrow {
    &::before {
      border-top-color: ${(props) => props.theme.componentBackgroundColor};
    }
  }
  &.show {
    opacity: 1;
  }
  font-weight: 400;
  z-index: ${(props) => +props.theme.modalZIndex + 1};
`;

const InfoIcon = styled(Info)`
  path {
    fill: ${(props) => props.theme.darkgray200};
  }
  margin-right: 4px;
  cursor: pointer;
`;

const Report = () => {
  const today = dayjs();
  const isBeforeReportTime = today.hour() < 16;
  const maxDay = isBeforeReportTime ? today.add(-1, "day") : today;
  const [selectedDate, setSelectedDate] = useState<Date | null>(
    maxDay.toDate()
  );

  const handleExport = async () => {
    const queryDate = dayjs(selectedDate).format("DD-MM-YYYY");
    const toastId = toast.loading("Exporting report...");
    try {
      const res = await getReport(queryDate);

      // Download file
      const link = document.createElement("a");
      link.href = res.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Report exported", { id: toastId });
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error, toastId);
      } else {
        toast.error("Something went wrong", { id: toastId });
      }
    }
  };

  const getDay = (date: Date) => date.getDay();

  const isWeekday = (date: Date) => {
    const day = getDay(date);
    return day !== 0 && day !== 6;
  };

  return (
    <StyledCard
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <Title>
        Export Report{" "}
        <OverlayTrigger
          overlay={
            <StyledTooltip id="tooltip">
              Report are generated at 4pm daily. You can only export report for
              today if it is after 4pm.
            </StyledTooltip>
          }
        >
          <InfoIcon />
        </OverlayTrigger>
      </Title>
      <PickerContainer>
        <DatePicker
          style={{
            minWidth: "240px",
            marginBottom: "0",
          }}
          value={selectedDate?.toString() || ""}
          maxDate={maxDay.toDate()}
          onChange={(_, date) => setSelectedDate(date as Date | null)}
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd MMM yyyy"
          disabledDayAriaLabelPrefix=""
          filterDate={isWeekday}
          placeholderText="Select date"
        />
        <Button size="small" onClick={handleExport}>
          Export
        </Button>
      </PickerContainer>
    </StyledCard>
  );
};

export default Report;
