import { useState } from "react";
import styled from "@emotion/styled/macro";
import { css, keyframes } from "@emotion/react/macro";
import { Dropdown } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";

import { ReactComponent as searchIcon } from "assets/CarbonCredit-SVG/Search-Small.svg";
import { ReactComponent as closeIcon } from "assets/CarbonCredit-SVG/Close.svg";
import { Button } from "../Buttons";
import { OutlineSelect } from "../OutlineSelect/OutlineSelect";

const animateWidth = (isOpen: boolean) => keyframes`
${
  isOpen
    ? css`
        from {
          width: 71px;
        }
        to {
          width: 250px;
        }
      `
    : css`
        from {
          width: 250px;
        }
        to {
          width: 71px;
        }
      `
}
 
`;
const SearchContainer = styled.div<{ open: boolean }>`
  display: flex;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  border: none;
  background-color: #ffffff;
  position: relative;
  height: 54px;
  width: 71px;
  &.animate {
    animation: ${({ open }) => animateWidth(open)} 0.5s forwards ease-in-out;
  }
`;

const SearchIcon = styled(searchIcon)`
  position: absolute;
  top: 16px;
  left: 24px;
  width: 24px;
  height: 24px;
`;
const CloseIcon = styled(closeIcon)`
  position: absolute;
  top: 16px;
  right: 24px;
  width: 20px;
  height: 20px;
  z-index: ${(props) => props.theme.dropdownZIndex};
  cursor: pointer;
`;
const Container = styled.div`
  height: 100%;
  position: relative;
`;
const StyledButton = styled.button`
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => +props.theme.dropdownZIndex - 1};
`;
const SearchBox = styled.div<{ open: boolean }>`
  position: absolute;
  padding: 24px 0 12px;
  border-radius: 12px;
  box-shadow: 0 4px 7px 0 rgba(219, 219, 219, 0.5);
  background-color: #fff;
  z-index: ${(props) => props.theme.dropdownZIndex};
  ${({ open }) =>
    open
      ? css`
          display: block;
          top: 62px;
          left: -310px;
        `
      : css`
          display: none;
        `};
`;
const GroupButton = styled.div`
  display: flex;
  padding: 6px 0;
  padding-left: 48px;
  padding-right: 16px;
  justify-content: flex-end;
`;
const StyledCancelButton = styled(Button)`
  width: 100px;
  height: 42px;
  margin-right: 16px;
`;
const StyledConfirmButton = styled(Button)`
  width: 100px;
  height: 42px;
`;
const Item = styled(DropdownItem)`
  padding: 0;
  width: auto;
  :active,
  :hover {
    background-color: unset;
  }
`;

const ContainerSearchBar = styled.div`
  padding: 8px 28px;
  width: 310px;
`;
const Text = styled.p`
  font-size: 14px;
  font-weight: 600;
  margin: 1rem 0 0.5rem;
`;
const StyledInput = styled.input`
  width: 100%;
  height: 48px;
  padding: 16px 12px;
  border-radius: ${(props) => props.theme.borderRadiusBase};
  border: solid 1px #d4d9e3;
  background-color: #ffffff;
  :focus {
    outline: none;
    border: 1px solid #02acd8;
    border-radius: ${(props) => props.theme.borderRadiusBase};
  }
`;
const TextSearch = styled.h1`
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 158px;
  white-space: nowrap;
`;
const TextWrapper = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  align-items: center;
  padding-left: 54px;
  width: 100%;
`;
export interface DropdownProps {
  onSubmit: (column: string, searchBar: string) => void;
  searchOption: string[][];
  defaultValue?: string;
}
export const DropdownSearch = ({
  onSubmit,
  searchOption,
  defaultValue = "",
}: DropdownProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState({ column: defaultValue, searchBar: "" });
  const [inputText, setInputText] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [className, setClassName] = useState("");
  const onClickButton = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  const handleSubmit = () => {
    if (inputText === "") {
      setIsSearching(false);
      setSearch({ ...search, searchBar: "" });
    } else {
      setIsSearching(true);
      setSearch({ ...search, searchBar: inputText });
    }
    onSubmit(search.column, inputText);
    handleClose();
  };
  const handleDelete = () => {
    setInputText("");
    setSearch({ column: defaultValue, searchBar: "" });
    setIsSearching(false);
    onSubmit("", "");
  };
  const idxSearchOption = searchOption?.findIndex((each: string[]) => {
    if (each.includes(search.column)) {
      return true;
    }
    return false;
  });
  const addClassName = () => {
    if (className === "") setClassName("animate");
  };
  const onChangeSelect = (event: any) => {
    if (event.target.value === "transaction") setInputText("Withdraw");
    setSearch({ ...search, column: event.target.value });
  };
  return (
    <SearchContainer
      open={open || isSearching}
      onClick={addClassName}
      className={className}
    >
      <SearchIcon />
      {isSearching && <CloseIcon onClick={handleDelete} />}
      <TextWrapper>
        <TextSearch>
          {!(!open && search.searchBar === "") &&
            search.column !== "" &&
            `${searchOption[idxSearchOption][1]}: `}
          {open ? inputText : search.searchBar}
        </TextSearch>
      </TextWrapper>
      <StyledButton id="search-button" onClick={onClickButton} />
      <Container>
        <SearchBox open={open}>
          <ContainerSearchBar>
            <Text>Search Column</Text>
            <OutlineSelect
              id="select-searching-column"
              value={search.column}
              onChange={onChangeSelect}
            >
              {searchOption?.map((each) => (
                <option key={each[0]} value={each[0]}>
                  {each[1]}
                </option>
              ))}
            </OutlineSelect>
            <Text>Search Keyword</Text>
            {search.column !== "transaction" ? (
              <StyledInput
                id="searching-field"
                value={inputText}
                onChange={(props) => setInputText(props.target.value)}
              />
            ) : (
              <OutlineSelect
                id="searching-field"
                onChange={(props) => setInputText(props.target.value)}
                defaultValue="Withdraw"
              >
                {/* <option value="" disabled></option> */}
                <option key="withdraw" value="Withdraw">
                  Withdraw
                </option>
                <option key="deposit" value="Deposit">
                  Deposit
                </option>
              </OutlineSelect>
            )}
          </ContainerSearchBar>
          <Dropdown.Divider />
          <GroupButton>
            <Item onClick={handleClose}>
              <StyledCancelButton variant="secondary">
                Cancel
              </StyledCancelButton>
            </Item>
            <Item onClick={handleSubmit}>
              <StyledConfirmButton>Search</StyledConfirmButton>
            </Item>
          </GroupButton>
        </SearchBox>
      </Container>
    </SearchContainer>
  );
};
