export const formatAccountNumber = (accountNumber: string): string => {
  if (!accountNumber) {
    return "";
  }
  const accountNumberLength = accountNumber.length;
  if (accountNumberLength < 4) {
    return accountNumber;
  }
  const cuttedStrings = accountNumber.match(/.{1,4}/g) || [];
  return cuttedStrings
    .map((string, index) => {
      if (index !== cuttedStrings.length - 1) {
        return "xxxx";
      }
      return string;
    })
    .join("-");
};

export const formatMobileNumberPrefix = (phone: string) => `+${phone}`;
