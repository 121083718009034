import { useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { Dropdown } from "react-bootstrap";
import useSWR from "swr";
import axios from "axios";

import { Button, Card } from "components";
import {
  AddNewPayment,
  ConfirmDeletePayment,
  ConfirmSwitchDefault,
} from "features/AccountManagement";
import { ReactComponent as Union } from "assets/CarbonCredit-SVG/Union.svg";
import { ReactComponent as DotsThree } from "assets/CarbonCredit-SVG/DotsThree.svg";
// import { ReactComponent as Tver } from "assets/TVER.svg";
import { ReactComponent as Bin } from "assets/CarbonCredit-SVG/Bin.svg";
import { ReactComponent as PaymentMethod } from "assets/CarbonCredit-SVG/PaymentMethod.svg";
import {
  UserPaymentMethodRes,
  UserPaymentMethod,
  NewUserPaymentMethod,
} from "models/paymentMethod";
import { apiFetcher } from "providers";
import { apiErrorToast, formatAccountNumber, getBankLogo } from "utils";
import { APIError } from "models/generic";

const PaymentDetailContainer = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

const AddPayment = styled(Button)`
  flex-direction: row;
  align-items: center;
`;

const PlusIcon = styled(Union)`
  display: block;
  margin-right: 12px;
  fill: ${(props) => props.theme.textColorLight};
`;

const DropdownMenu = styled(Dropdown.Menu)`
  border-radius: ${(props) => props.theme.borderRadiusBase};
  border: solid 1px #e7eaf1;
  align-items: stretch;
  left: auto;
  right: 0;
  top: 42px;
  padding: 0;
  background-color: ${(props) => props.theme.componentBackgroundColor};
  overflow: hidden;
`;

const Item = styled(Dropdown.Item)`
  padding: 1rem 1.5rem;
  transition: color 0.3s, background-color 0.3s;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.textColor};
  svg {
    margin-right: 12px;
    path {
      transition: fill 0.3s;
      fill: ${(props) => props.theme.textColor};
    }
  }

  &:hover {
    background-color: #dff4f8;
    color: ${(props) => props.theme.primaryColor};
    path {
      fill: ${(props) => props.theme.primaryColor};
    }
  }
  &:active,
  &.active {
    background-color: ${(props) => props.theme.componentBackgroundColor};
    color: ${(props) => props.theme.textColor};
  }
  &.danger {
    color: ${(props) => props.theme.red2};
    path {
      fill: ${(props) => props.theme.red2};
    }
    &:hover {
      background-color: #f8d8d8;
    }
  }
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Title = styled.h2`
  margin-right: auto;
`;

const Content = styled.div`
  height: fit-content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px;
`;

const BankDetail = styled.div`
  display: flex;
  margin-bottom: 8px;
  > .dropdown > svg {
    fill: ${(props) => props.theme.darkgray200};
    cursor: pointer;
    display: block;
  }
`;

const Bank = styled.div`
  display: flex;
  margin-right: auto;
  align-items: center;
  svg {
    width: 40px;
    height: 40px;
    display: block;
    margin-right: 8px;
  }
`;

const BankName = styled.h3`
  font-weight: 800;
  margin-right: 8px;
`;

const Default = styled.div`
  color: ${(props) => props.theme.primaryColor};
`;

const Line = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px 8px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.lightgray50};
  }
`;

const Label = styled.div`
  color: ${(props) => props.theme.darkgray200};
`;

const animation = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  transition: {
    duration: 0.3,
  },
};

const Icon = styled.img<{ backgroundColor?: string }>`
  padding: 6px;
  border-radius: 6px;
  background-color: ${(props) => props.backgroundColor};
`;

const PaymentDetail = () => {
  const [showAddPayment, setShowAddPayment] = useState(false);
  const [showConfirmSwitch, setShowConfirmSwitch] =
    useState<UserPaymentMethod | null>(null);
  const [showConfirmDelete, setShowConfirmDelete] =
    useState<UserPaymentMethod | null>(null);
  const [loading, setLoading] = useState({
    addPayment: false,
    switchDefault: false,
    deletePayment: false,
  });

  const { data, mutate } = useSWR<UserPaymentMethodRes>(
    "/payment-method/my",
    (url) => apiFetcher({ url }) // todo remove this
  );

  const paymentData = useMemo(() => {
    if (!data) return [];
    return data?.data?.user_payment_methods.sort((a, b) => {
      if (a.is_default) {
        return -1;
      }
      if (a.id - b.id) {
        return 1;
      }
      return 0;
    });
  }, [data]);

  const onAddPayment = (data: NewUserPaymentMethod) => {
    setLoading((prev) => ({ ...prev, addPayment: true }));
    apiFetcher(
      {
        url: "/payment-method/create",
        method: "POST",
        data,
      },
      {
        redirectIfError: false,
        throwError: false,
      }
    )
      .then(() => {
        setShowAddPayment(false);
        setLoading((prev) => ({ ...prev, addPayment: false }));
        mutate();
      })
      .catch((err) => {
        setShowAddPayment(false);
        setLoading((prev) => ({ ...prev, addPayment: false }));
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
      });
  };

  const onSwitchPayment = (payment: UserPaymentMethod | null) => {
    if (payment) {
      setLoading((prev) => ({ ...prev, switchDefault: true }));
      apiFetcher(
        {
          url: "/payment-method/set-default",
          method: "POST",
          data: {
            id: payment.id,
          },
        },
        {
          redirectIfError: false,
          throwError: false,
        }
      )
        .then(() => {
          setShowConfirmSwitch(null);
          setLoading((prev) => ({ ...prev, switchDefault: false }));
          mutate();
        })
        .catch((err) => {
          setShowConfirmSwitch(null);
          setLoading((prev) => ({ ...prev, switchDefault: false }));
          if (axios.isAxiosError(err) && err.response) {
            const error = err.response.data as APIError;
            apiErrorToast(error);
          }
        });
    }
  };

  const onDeletePayment = (data: UserPaymentMethod | null, pin: string) => {
    setLoading((prev) => ({ ...prev, deletePayment: true }));
    apiFetcher(
      {
        url: "/payment-method/delete",
        method: "DELETE",
        data: {
          id: data?.id,
          pin,
        },
      },
      {
        redirectIfError: false,
        throwError: false,
      }
    )
      .then(() => {
        setShowConfirmDelete(null);
        setLoading((prev) => ({ ...prev, deletePayment: false }));
        mutate((oldData) => {
          if (oldData && data) {
            const filteredData = oldData.data.user_payment_methods.filter(
              (payment) => payment.id !== data.id
            );
            return {
              ...oldData,
              data: { ...oldData.data, user_payment_methods: filteredData },
            };
          }
        });
      })
      .catch((err) => {
        setShowConfirmDelete(null);
        setLoading((prev) => ({ ...prev, deletePayment: false }));
        if (axios.isAxiosError(err) && err.response) {
          const error = err.response.data as APIError;
          apiErrorToast(error);
        }
      });
  };

  return (
    <PaymentDetailContainer>
      <AddNewPayment
        show={showAddPayment}
        onCancel={() => setShowAddPayment(false)}
        onConfirm={onAddPayment}
        loading={loading.addPayment}
      />
      <ConfirmDeletePayment
        show={!!showConfirmDelete}
        account={showConfirmDelete}
        onCancel={() => setShowConfirmDelete(null)}
        onConfirm={onDeletePayment}
        isPinLoading={loading.deletePayment}
      />
      <ConfirmSwitchDefault
        show={!!showConfirmSwitch}
        account={showConfirmSwitch}
        onCancel={() => setShowConfirmSwitch(null)}
        onConfirm={onSwitchPayment}
        loading={loading.switchDefault}
      />
      <TitleBar>
        <Title>{paymentData.length} Payment Methods</Title>
        {/* TODO: For Future: User Add Payment Method */}
        {/* <AddPayment onClick={() => setShowAddPayment(true)}>
          <PlusIcon />
          <div>Payment Method</div>
        </AddPayment> */}
      </TitleBar>
      <Content>
        {paymentData.map((account) => (
          <Card {...animation}>
            <BankDetail>
              <Bank>
                <Icon
                  width="48px"
                  height="48px"
                  src={getBankLogo(account.payment_method_code)}
                />
                <BankName>{account.payment_method_name}</BankName>
                {account.is_default && <Default>(Default)</Default>}
              </Bank>
              {/* TODO: hide for now */}
              {/* <Dropdown>
                <Dropdown.Toggle as={DotsThree} />
                <DropdownMenu>
                  <Item onClick={() => setShowConfirmSwitch(account)}>
                    <PaymentMethod />
                    <div>Set as default payment method</div>
                  </Item>
                  <Item
                    className="danger"
                    onClick={() => setShowConfirmDelete(account)}
                  >
                    <Bin />
                    <div>Delete payment method</div>
                  </Item>
                </DropdownMenu>
              </Dropdown> */}
            </BankDetail>
            <Line>
              <Label>Account Number</Label>
              <div>{formatAccountNumber(account.account_number)}</div>
            </Line>
            <Line>
              <Label>Account Name</Label>
              <div>{account.account_name}</div>
            </Line>
          </Card>
        ))}
      </Content>
    </PaymentDetailContainer>
  );
};

export default PaymentDetail;
