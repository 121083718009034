import { APIResponse } from "./generic";

export enum SideTrade {
  buy = "buy",
  sell = "sell",
}

export interface NewTrade {
  id: number;
  when: string;
  pair_name: string;
  side: SideTrade;
  price: string;
  executed: string;
  commission: string;
  commission_vat: string;
  withholding_tax: string;
  total: string;
  vat: string;
}
export interface NewTrades {
  trades: NewTrade[];
  total: number;
}

export type NewTradeHistoryRes = APIResponse<NewTrades>;
