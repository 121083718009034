import { HTMLAttributes } from "react";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

import website from "assets/CarbonCredit-SVG/Website.svg";
import { mediaQuery } from "utils/mediaQueries";
import { config } from "config";

const { path } = config;

interface propsContact {
  Icon: string;
}

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
`;

const Contact = styled.div<propsContact>`
  margin-top: 11px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  ::before {
    margin: auto 0px;
    vertical-align: middle;
    font-size: 1px;
    border: none;
    content: url(${(props) => (props.Icon ? props.Icon : "")});
  }
  &:hover {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const Info = styled.div`
  margin-top: auto;
  margin-left: 0;
  color: #fff;
  ${mediaQuery("mobile")} {
    margin-left: auto;
  }
  ${mediaQuery("desktop")} {
    margin-left: 0;
  }
`;

const Sidebar = styled.div`
  width: 100%;
  min-width: unset;
  padding: 40px;
  background-color: #1f2c57;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: ${(props) => props.theme.sidebarZIndex};
  ${mediaQuery("mobile")} {
    flex-direction: row;
  }
  ${mediaQuery("desktop")} {
    width: 390px;
    min-width: 390px;
    height: 100vh;
    padding: 56px 60px;
    flex-direction: column;
  }
`;

const Logo = styled.img`
  width: 120px;
  margin: 16px 0;
  object-fit: contain;
`;

const FTIXLogo = styled.img`
  width: 120px;
  margin: 16px;

  ${mediaQuery("desktop")} {
    width: 150px;
    margin: 16px 0;
    object-fit: contain;
  }
`;

const Descriptions = styled(Link)`
  color: ${(props) => props.theme.textColorLight};
  font-family: ${(props) => `Kanit,${props.theme.fontFamily}`};
  margin: 0;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.textColorLight};
  }
  ${mediaQuery("mobile")} {
    margin-right: 40px;
  }
  ${mediaQuery("desktop")} {
    margin: 0;
  }
`;

const Light = styled.div`
  font-weight: 100;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const Line = styled.hr`
  display: inline;
  width: 100%;
  margin: 0 8px;
  max-width: 150px;
`;

const Heading = styled.div`
  font-size: 1.75rem;
  font-weight: 200;
  color: ${(props) => props.theme.textColorLight};
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

interface Props extends HTMLAttributes<HTMLDivElement> {}

const SideBar = ({ ...rest }: Props) => (
  <Sidebar {...rest}>
    <Descriptions to={path.default}>
      <FTIXLogo src="/FTI-X.svg" />
      <Light>
        แพลตฟอร์มซื้อขาย <Line />
      </Light>
      <Heading>
        <span>พลังงานสะอาด</span> <span>และคาร์บอนเครดิต</span>
      </Heading>
    </Descriptions>
    <Info>
      <Title>Powered By</Title>
      <StyledLink href="https://gideon-one.com/">
        <Logo src="/Gideon-One-Logo.png" />
        <Contact Icon={website}>gideon-one.com</Contact>
      </StyledLink>
    </Info>
  </Sidebar>
);

export default SideBar;
