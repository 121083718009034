export const lightblue = "#1AC5F1";

export const lightblue900 = "#005184";
export const lightblue800 = "#006FA2";
export const lightblue700 = "#0080B5";
export const lightblue600 = "#0092C7";
export const lightblue500 = "#00A0D4";
export const lightblue400 = "#02ACD8";
export const lightblue300 = "#44BBDC";
export const lightblue200 = "#79CEE5";
export const lightblue100 = "#AEE2EF";
export const lightblue50 = "#DFF4F8";

export const darkgray = "#1F2C57";
export const darkgray900 = "#1F2C57";
export const darkgray800 = "#293C6F";
export const darkgray700 = "#2F467C";
export const darkgray600 = "#385087";
export const darkgray500 = "#3E5790";
export const darkgray400 = "#5B6F9F";
export const darkgray300 = "#7889AF";
export const darkgray200 = "#9DAAC6";
export const darkgray100 = "#C3CBDD";
export const darkgray50 = "#E7EAF1";

export const lightgray = "#E7EAF1";
export const lightgray200 = "#D9DEE8";
export const lightgray100 = "#EFEFEF";
export const lightgray50 = "#F3F6FB";

export const gray = "#C3CBDD";

export const white = "#FFF";
export const white2 = "#F9FAFC";
export const white3 = "#5D5D5D";
export const black = "#000";
export const lightred = "#F6E1E1";
export const red = "#EB5757";
export const red2 = "#D02828";
export const red3 = "#e51313";
export const red4 = "#D88383";
export const red5 = "#ef4923";
export const crimson = "#a62020";
export const green = "#1FA377";
export const green2 = "#0fb37c";
export const green3 = "#86d1b8";
export const darkgreen = "#19825f";
export const lightgreen = "#E6F5F0";
export const neongreen = "#00D4C2";
export const purple = "#A65271";
export const orange = "#F09939";
export const orange2 = "#FF9A22";
export const orange3 = "#F5BD7E";
export const darkorange = "#dd7c11";
export const yellow = "#F2C94C";
