import styled from "@emotion/styled/macro";
import DatePicker from "react-datepicker";
import { useState } from "react";
import dayjs from "dayjs";
import { Button } from "components";

import { Modal, IModal } from "../Modal";

import "react-datepicker/dist/react-datepicker.css";

export interface ISelectDateRange extends IModal {
  onOk: (startDate: Date | null, endDate: Date | null) => void;
  onCancel: () => void;
}

const SelectDateRangeContainer = styled(Modal)``;

const FromTo = styled.div`
  display: flex;
  margin: 8px 0 16px 0;
`;

const From = styled.h2<{ selecting: boolean }>`
  margin-right: 1rem;
  color: ${(props) =>
    props.selecting ? props.theme.primaryColor : props.theme.textColor};
`;

const To = styled.h2<{ selecting: boolean }>`
  margin-left: 1rem;
  color: ${(props) =>
    props.selecting ? props.theme.primaryColor : props.theme.textColor};
`;

const StyledDatePickerContainer = styled.div`
  margin-bottom: 24px;
  .react-datepicker {
    border: none;
    font-family: ${(props) => props.theme.fontFamily};
    .react-datepicker__header {
      background-color: transparent;
      .react-datepicker__day-name {
        font-size: 0;
        color: ${(props) => props.theme.darkgray};
      }
      .react-datepicker__day-name::first-letter {
        font-size: 0.75rem;
      }
    }
    .react-datepicker__week {
      margin: 4px 0;
    }
    .react-datepicker__day--outside-month {
      color: ${(props) => props.theme.lightgray200};
    }
    .react-datepicker__day {
      margin: 0 -0.5px;
      padding: 0 0.5px;
      width: auto;
      height: auto;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      > div {
        width: 35px;
        height: 35px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
      }
    }
    .react-datepicker__day:hover {
      background-color: unset;
      > div {
        border: 1px solid ${(props) => props.theme.primaryColor};
      }
      &.react-datepicker__day--in-selecting-range {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        background-color: ${(props) => props.theme.lightblue200};
        > div {
          background-color: ${(props) => props.theme.primaryColor};
        }
      }
      &.react-datepicker__day--in-range {
        background-color: ${(props) => props.theme.lightblue200};
      }
    }
    .react-datepicker__day--keyboard-selected {
      background: transparent;
    }
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    .react-datepicker__day--range-end {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
    }
    .react-datepicker__day--in-range {
      background-color: ${(props) => props.theme.lightblue200};
      color: ${(props) => props.theme.textColor};
    }
    .react-datepicker__day--in-selecting-range {
      background-color: ${(props) => props.theme.lightblue200};
      color: ${(props) => props.theme.textColor};
    }
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--keyboard-selected {
      color: ${(props) => props.theme.textColorLight};
      > div {
        background-color: ${(props) => props.theme.primaryColor};
      }
    }
    .react-datepicker__day--in-selecting-range.react-datepicker__day--weekend,
    .react-datepicker__day--in-range.react-datepicker__day--weekend {
      &:last-child {
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
      }
      &:not(:last-child) {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
    .react-datepicker__day--range-start.react-datepicker__day--range-end {
      border-radius: 50%;
      color: ${(props) => props.theme.textColorLight};
      > div {
        background-color: ${(props) => props.theme.primaryColor};
      }
      &.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
        border-radius: 50%;
      }
    }
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SelectDateRange = ({ onOk, onCancel, ...rest }: ISelectDateRange) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const onChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <SelectDateRangeContainer onBackgroundClick={onCancel} {...rest}>
      <div>Select date range</div>
      <FromTo>
        <From selecting={!startDate}>
          {startDate ? dayjs(startDate).format("MMM D") : "From"}
        </From>
        -
        <To selecting={!!startDate && !endDate}>
          {endDate ? dayjs(endDate).format("MMM D") : "To"}
        </To>
      </FromTo>
      <StyledDatePickerContainer>
        <DatePicker
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          renderDayContents={(day, date) => <div>{day}</div>}
          disabledKeyboardNavigation
        />
      </StyledDatePickerContainer>
      <Footer>
        <Button
          onClick={onCancel}
          variant="link"
          style={{ marginRight: "8px" }}
        >
          Cancel
        </Button>
        <Button
          variant="link"
          onClick={() => onOk(startDate, endDate)}
          disabled={!startDate || !endDate}
        >
          Ok
        </Button>
      </Footer>
    </SelectDateRangeContainer>
  );
};

export default SelectDateRange;
