import i18n, { TFunction } from "i18next";
import { initReactI18next, WithTranslation } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { logger } from "./utils/Log";
import { config } from "./config";

export interface ITranslatable
  extends /* InjectedI18nProps, InjectedTranslateProps */ WithTranslation {
  // extending i18next causes errors in redux connect() due to missing props (we would have to add them to every mapDispatch)
  // solution: call withTranslation() inside of connect()
  // t: TFunction;
  // i18n: i18nType.i18n;
  // tReady: boolean;
}

export function loadTranslations() {
  return new Promise<void>((resolve, reject) => {
    i18n
      .use(initReactI18next)
      .use(LanguageDetector)
      .use(HttpApi)
      // TODO add localstore plugin? but we use service worker on production for this
      .init(
        {
          backend: {
            loadPath: `${
              (document.location ? document.location.origin : "") +
              config.pathRoot
            }locales/{{lng}}.json?v=${config.version}`,
            // allowMultiLoading: true
          },
          // lng: "en", // overrides language detection
          whitelist: ["en", "th"],
          fallbackLng: "en",
          debug: config.debug,

          // have a common namespace used around the full app
          ns: ["tube"],
          defaultNS: "tube",

          keySeparator: ".", // set to true if we use content as keys

          interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ",",
          },

          react: {
            wait: true, // has no impact if not set per view too, causes re-rendering
            bindI18n: "languageChanged loaded",
            // bindStore: "added removed",
            nsMode: "default",
            useSuspense: false, // needed to fix "component suspended while rendering"
          },
        },
        (err: any, t: TFunction) => {
          if (err) logger.error("Error loading localization %o", err);
          resolve();
        }
      );
  });
}

export default i18n;
