import { ReactNode } from "react";
import styled from "@emotion/styled";

import { Modal } from "components";
import { Spinner } from "react-bootstrap";

import type { IModal } from "../Modal";

export interface ILoadingModal extends IModal {
  show: boolean;
  content?: ReactNode;
}

const StyledModal = styled(Modal)`
  .modal-background {
    backdrop-filter: blur(8px); // double the blur of the background
  }
`;

const ModalContent = styled.div`
  padding: 0 36px;
  text-align: center;
  .spinner-border {
    color: ${(props) => props.theme.primaryColor};
  }
`;

const LoadingContent = styled.div`
  text-align: center;
  margin-top: 16px;
  font-weight: 700;
`;

const LoadingModal = ({
  show,
  content = "Loading...",
  ...rest
}: ILoadingModal) => (
  <StyledModal show={show} {...rest}>
    <ModalContent>
      <Spinner animation="border" />
      <LoadingContent className="loading-content">{content}</LoadingContent>
    </ModalContent>
  </StyledModal>
);

export default LoadingModal;
