import { backendURL } from "config";
import { NewUserPaymentMethod } from "models/paymentMethod";
import { client } from "./client";

export const getUserPaymentMethod = async () => {
  const response = await client.get(`${backendURL}/payment-method/my`);
  return response.data;
};

export const getPaymentMethods = async () => {
  const response = await client.get(`${backendURL}/payment-method/list`);
  return response.data;
};

export const createNewPayment = async (payload: NewUserPaymentMethod) => {
  const response = await client.post(
    `${backendURL}/payment-method/create`,
    payload
  );
  return response.data;
};

export const setDefaultUserPayment = async (id: number) => {
  const response = await client.post(
    `${backendURL}/payment-method/set-default`,
    {
      id,
    }
  );
  return response.data;
};
