import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string, date } from "yup";

import {
  Button,
  DatePicker,
  IRadio,
  Label,
  LabelContainer,
  LabelRequired,
  OutlineSelect,
  Radio,
  RadioGroup,
  TextField,
} from "components";
import { emailSchema } from "utils";
import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import {
  digitsOnlyChange,
  ErrorText,
  Form,
  RegisterComponentGrid,
} from "../components";

enum ContactSameAddressOffice {
  yes = "YES",
  no = "NO",
}

const juristicType = [
  "บริษัทมหาชนจำกัด",
  "บริษัทจำกัด",
  "ห้างหุ้นส่วนจำกัด",
  "ห้างหุ้นส่วนสามัญจดทะเบียน",
  "อื่นๆ",
];

interface FieldValues {
  th_name: string;
  en_name: string;
  office_branch: string;
  place_of_registration?: string;
  registration_number: string;
  registration_date: string;
  juristic_person_type: string;
  business_type: string;
  office_address: string;
  zip_code: string;
  contact_person: string;
  company_phone: string;
  company_fax?: string;
  company_email: string;
  is_contact_address_same_as_office_address: string;
}

const GroupText = styled.div`
  text-align: center;
`;

const FieldContainer = styled.div`
  display: block;
  label {
    font-weight: 700;
  }
`;

const CustomRadioGroup = styled.div`
  .radio-group {
    div {
      row-gap: 12px;
    }
    padding-bottom: calc(1rem + 4px);
  }
`;

const ColoredRadio = (props: IRadio) => (
  <Radio
    checkmarkColor="#02ACD8"
    borderColor="#9DAAC6"
    borderColorChecked="#02ACD8"
    backgroundColorChecked="#ffffff"
    {...props}
  />
);

const NormalRadio = styled(ColoredRadio)`
  justify-content: flex-start;
  label {
    width: 100%;
    font-size: 0.875rem;
  }
`;

const AddOtherRadio = styled(NormalRadio)`
  input {
    opacity: 1;
    height: 100%;
    width: 100%;
  }
`;

const schema = object({
  th_name: string().required("Required Thai Name"),
  en_name: string().required("Required English Name"),
  office_branch: string().required("Required Company Branch"),
  place_of_registration: string(),
  registration_number: string()
    .required("Required Registration Number")
    .matches(/^[0-9]{13}$/, "Registration Number must be 13 digits number"),
  registration_date: date().required("Required Registration Date"),
  juristic_person_type: string().required("Required Juristic Person Type"),
  business_type: string().required("Required Business Type"),
  office_address: string().required("Required Office Address"),
  zip_code: string()
    .required("Required Zip Code")
    .matches(/^[0-9]{5}$/, "Zip Code must be 5 digits number"),
  contact_person: string().required("Required Contact Person"),
  company_phone: string()
    .required("Required Company Phone")
    .matches(/^[0-9]{9,11}$/, "Company Phone must be 9-10 digits number"),
  company_fax: string(),
  company_email: emailSchema,
  is_contact_address_same_as_office_address: string(),
});

export const JuristicInfo = () => {
  const {
    state: { page, company, profile },
    dispatch,
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isDirty },
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...company,
      is_contact_address_same_as_office_address:
        company.is_contact_address_same_as_office_address
          ? ContactSameAddressOffice.yes
          : ContactSameAddressOffice.no,
      company_phone: profile.mobile_number,
      company_email: profile.email,
    },
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const onSubmit = (data: FieldValues) => {
    onSubmitted();
    dispatch({
      type: "SET_COMPANY",
      payload: {
        ...data,
        is_contact_address_same_as_office_address:
          data.is_contact_address_same_as_office_address === "YES",
      },
    });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <RegisterComponentGrid>
      <GroupText>
        <h1>KYC</h1>
        <p>ข้อมูลนิติบุคคล</p>
      </GroupText>
      <Form id="juristic-info" onSubmit={handleSubmit(onSubmit)}>
        <FieldContainer>
          <TextField
            label="ชื่อ นิติบุคคล (ไทย)"
            required
            placeholder="กรอกชื่อ นิติบุคคล (ไทย)"
            errorWarn={!!errors.th_name}
            errorMessage={errors.th_name?.message}
            {...register("th_name")}
          />
          <TextField
            label="ชื่อ นิติบุคคล (อังกฤษ)"
            required
            placeholder="กรอกชื่อ นิติบุคคล (อังกฤษ)"
            errorWarn={!!errors.en_name}
            errorMessage={errors.en_name?.message}
            {...register("en_name")}
          />
          <CustomRadioGroup>
            <LabelContainer>
              <Label>สาขาสำนักงาน</Label>
              <LabelRequired>*</LabelRequired>
            </LabelContainer>
            <Controller
              control={control}
              name="office_branch"
              render={({ field }) => (
                <RadioGroup {...field} column equalGridSpace>
                  <NormalRadio label="สำนักงานใหญ่" value="สำนักงานใหญ่" />
                  <AddOtherRadio
                    label={
                      <TextField
                        placeholder="สาขาที่"
                        value={
                          field.value === "สำนักงานใหญ่" ? "" : field.value
                        }
                        onChange={field.onChange}
                        containerStyle={{ paddingBottom: 0 }}
                      />
                    }
                    value={field.value === "สำนักงานใหญ่" ? "" : field.value}
                  />
                </RadioGroup>
              )}
            />
          </CustomRadioGroup>
          {!!errors.office_branch && (
            <ErrorText>{errors.office_branch.message}</ErrorText>
          )}
          <CustomRadioGroup>
            <LabelContainer>
              <Label>จดทะเบียนที่</Label>
            </LabelContainer>
            <Controller
              control={control}
              name="place_of_registration"
              render={({ field }) => (
                <RadioGroup {...field} column equalGridSpace>
                  <NormalRadio label="ไทย" value="ไทย" />
                  <AddOtherRadio
                    label={
                      <TextField
                        placeholder="อื่นๆ"
                        value={field.value === "ไทย" ? "" : field.value}
                        onChange={field.onChange}
                        containerStyle={{ paddingBottom: 0 }}
                      />
                    }
                    value={field.value === "ไทย" ? "" : field.value}
                  />
                </RadioGroup>
              )}
            />
          </CustomRadioGroup>
          <Controller
            control={control}
            name="registration_number"
            render={({ field: { onChange, ...rest } }) => (
              <TextField
                label="เลขทะเบียนนิติบุคคล / เลขประจำตัวผู้เสียภาษีอากร"
                required
                placeholder="กรอกเลขทะเบียนนิติบุคคล / เลขประจำตัวผู้เสียภาษีอากร"
                errorWarn={!!errors.registration_number}
                errorMessage={errors.registration_number?.message}
                maxLength={13}
                {...rest}
                onChange={(e) => digitsOnlyChange(e, onChange)}
              />
            )}
          />
          <LabelContainer>
            <Label>
              วันที่จดทะเบียน<LabelRequired>*</LabelRequired>
            </Label>
          </LabelContainer>
          <DatePicker
            style={{ width: "100%" }}
            errorWarn={!!errors.registration_date}
            errorMessage={errors.registration_date?.message}
            onChange={(_, date) => {
              if (date) setValue("registration_date", date?.toString());
            }}
            minDate={new Date("1900-01-01")}
            maxDate={new Date()}
            value={watch("registration_date")}
            showYearDropdown
            dropdownMode="select"
          />
          <Label>
            ประเภทของนิติบุคคล<LabelRequired>*</LabelRequired>
          </Label>
          <OutlineSelect
            placeholder="เลือกประเภทของนิติบุคคล*"
            selectStyle={{ paddingLeft: "16px" }}
            errorWarn={!!errors.juristic_person_type}
            errorMessage={errors.juristic_person_type?.message}
            {...register("juristic_person_type")}
          >
            <option value="" disabled>
              เลือกประเภทของนิติบุคคล
            </option>
            {juristicType.map((type) => (
              <option value={type}>{type}</option>
            ))}
          </OutlineSelect>
          <TextField
            label="ลักษณะของธุรกิจ"
            required
            placeholder="เช่น ผลิตชิ้นส่วนรถยนต์ โรงไฟฟ้าพลังงานทางเลือก"
            errorWarn={!!errors.business_type}
            errorMessage={errors.business_type?.message}
            {...register("business_type")}
          />
          <TextField
            label="ที่อยู่สำนักงานตามหนังสือรับรอง"
            required
            placeholder="กรอกที่อยู่สำนักงานตามหนังสือรับรอง"
            errorWarn={!!errors.office_address}
            errorMessage={errors.office_address?.message}
            {...register("office_address")}
          />
          <Controller
            control={control}
            name="zip_code"
            render={({ field: { onChange, ...rest } }) => (
              <TextField
                label="รหัสไปรษณีย์  (ตามหนังสือรับรอง)"
                required
                placeholder="กรอกรหัสไปรษณีย์  (ตามหนังสือรับรอง)"
                errorWarn={!!errors.zip_code}
                errorMessage={errors.zip_code?.message}
                maxLength={5}
                {...rest}
                onChange={(e) => digitsOnlyChange(e, onChange)}
              />
            )}
          />
          <TextField
            label="ชื่อ - นามสกุล ผู้รับมอบอำนาจ"
            required
            placeholder="กรอกชื่อ - นามสกุล ผู้รับมอบอำนาจ"
            errorWarn={!!errors.contact_person}
            errorMessage={errors.contact_person?.message}
            {...register("contact_person")}
          />
          <Controller
            control={control}
            name="company_phone"
            render={({ field: { ...rest } }) => (
              <TextField
                label="โทรศัพท์"
                required
                placeholder="กรอกโทรศัพท์ ตัวอย่าง: 08x-xxx-xxxx"
                errorWarn={!!errors.company_phone}
                errorMessage={errors.company_phone?.message}
                {...rest}
                // onChange={(e) => digitsOnlyChange(e, onChange)}
                disabled
              />
            )}
          />
          <Controller
            control={control}
            name="company_fax"
            render={({ field: { onChange, ...rest } }) => (
              <TextField
                label="โทรสาร"
                placeholder="กรอกโทรสาร"
                errorWarn={!!errors.company_fax}
                errorMessage={errors.company_fax?.message}
                {...rest}
                onChange={(e) => digitsOnlyChange(e, onChange)}
              />
            )}
          />
          <TextField
            label="Email address"
            required
            placeholder="กรอก Email address"
            errorWarn={!!errors.company_email}
            errorMessage={errors.company_email?.message}
            disabled
            value={profile?.email}
          />
          <CustomRadioGroup>
            <LabelContainer>
              <Label>ที่อยู่ที่ติดต่อได้</Label>
            </LabelContainer>
            <RadioGroup
              name="contactAddress"
              value={watch("is_contact_address_same_as_office_address")}
              onChange={(value) =>
                setValue("is_contact_address_same_as_office_address", value)
              }
              column
              equalGridSpace
            >
              <NormalRadio label="เหมือน ที่อยู่ตามหนังสือรับรอง" value="YES" />
              <NormalRadio
                label="ไม่เหมือน ที่อยู่ตามหนังสือรับรอง"
                value="NO"
              />
            </RadioGroup>
          </CustomRadioGroup>
        </FieldContainer>
      </Form>
      <Button block form="juristic-info" type="submit">
        Next
      </Button>
    </RegisterComponentGrid>
  );
};
