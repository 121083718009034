import { ReactElement, useMemo } from "react";
import { useTheme } from "@emotion/react";

import { Skeleton } from "../Skeleton";
import { IAvatar, IBorderedAvatar } from "./interfaces";
import { StyledBorderedAvatarContainer } from "./styles";

const defaultColors = [
  "#2ecc71", // emerald
  "#3498db", // peter river
  "#8e44ad", // wisteria
  "#e67e22", // carrot
  "#e74c3c", // alizarin
  "#1abc9c", // turquoise
  "#2d6caa", // midnight blue
];

function sumChars(str: string) {
  let sum = 0;
  for (let i = 0; i < str.length; i++) {
    sum += str.charCodeAt(i);
  }

  return sum;
}

export const Avatar = (props: IAvatar): ReactElement => {
  const {
    borderRadius = "50%",
    src,
    srcset,
    name,
    color,
    colors = defaultColors,
    size,
    style,
    className,
    loading,
    onClick,
  } = props;

  if (!name) {
    throw new Error("UserAvatar requires a name");
  }

  const theme = useTheme();

  const { inner, innerStyle } = useMemo(() => {
    const imageStyle: Record<string, string> = {
      display: "block",
      borderRadius,
    };

    const innerStyle: Record<string, string> = {
      lineHeight: size ?? "",
      textAlign: "center",
      borderRadius,
      textTransform: "capitalize",
      color: theme.textColorLight,
    };

    if (size) {
      // eslint-disable-next-line no-multi-assign
      imageStyle.width = innerStyle.width = innerStyle.maxWidth = size;
      // eslint-disable-next-line no-multi-assign
      imageStyle.height = innerStyle.height = innerStyle.maxHeight = size;
    }

    let inner;
    if (src || srcset) {
      inner = (
        <img
          className="UserAvatar--img"
          style={imageStyle}
          src={src}
          srcSet={srcset || " "}
          alt={name}
        />
      );
    } else {
      let background;
      if (color) {
        background = color;
      } else {
        // pick a deterministic color from the list
        const i = sumChars(name) % colors.length;
        background = colors[i];
      }

      innerStyle.backgroundColor = background;

      inner = name.substring(0, 2);
    }
    return { inner, innerStyle };
  }, [
    borderRadius,
    color,
    colors,
    name,
    size,
    src,
    srcset,
    theme.textColorLight,
  ]);
  const classes = [className, "UserAvatar"];

  if (loading) {
    return (
      <div aria-label={name} className={classes.join(" ")} style={style}>
        <Skeleton type="circle" height={size} width={size} />
      </div>
    );
  }
  return (
    <div
      aria-label={name}
      className={classes.join(" ")}
      style={style}
      onClick={onClick}
    >
      <div className="UserAvatar--inner" style={innerStyle}>
        {inner}
      </div>
    </div>
  );
};

export const BorderedAvatar = ({
  borderColor,
  loading,
  size,
  ...rest
}: IBorderedAvatar): ReactElement => (
  <StyledBorderedAvatarContainer
    className="styled-bordered-avatar-container"
    borderColor={borderColor}
  >
    {loading ? (
      <Skeleton type="circle" height={size} width={size} />
    ) : (
      <Avatar loading={loading} size={size} {...rest} />
    )}
  </StyledBorderedAvatarContainer>
);
