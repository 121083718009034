import { APIResponse } from "./generic";

export enum EntryType {
  Deposit = "DEPOSIT",
  Withdraw = "WITHDRAW",
  Retire = "RETIRE",
}
export enum TransactionStatus {
  Pending = "PENDING",
  Approved = "APPROVED",
  Rejected = "REJECTED",
  InProcess = "IN_PROCESS",
  Cancelled = "CANCELLED",
  Success = "SUCCESS",
}

export const transactionStatusVariant = (key: TransactionStatus) => {
  switch (key) {
    case "PENDING":
      return "warning";
    case "SUCCESS":
    case "APPROVED":
      return "success";
    case "IN_PROCESS":
      return "primary";
    case "REJECTED":
      return "danger";
    case "CANCELLED":
      return "disabled";
    default:
      return "warning";
  }
};

export interface Transaction {
  id: number;
  entry_type: string;
  amount: number;
  date_time: string;
  symbol: string;
  status: string; // "PENDING" | "APPROVED" | "REJECTED" | "IN_PROCESS" | "CANCELLED"
  registry_name?: string;
  registry_member_account_id?: string;
  payment_method_name?: string;
  account_number?: string;
  account_name?: string;
}

export interface TransactionHistories {
  transaction_histories: Transaction[];
  total: number;
}

export type TransactionHistoriesRes = APIResponse<TransactionHistories>;

export interface TransactionDepositBody {
  user_payment_method_id: number;
  amount: string;
  proof: string;
  pin: string;
}

export interface WithdrawBase {
  currency_symbol: string;
  amount: string;
  pin: string;
}

export type MoneyTransactionWithdraw = WithdrawBase & {
  user_payment_method_id: number;
};

export type CreditTransactionWithdraw = WithdrawBase & {
  order: string;
  remark?: string;
};

export type TransactionWithdrawBody =
  | MoneyTransactionWithdraw
  | CreditTransactionWithdraw;

export interface CancelTransactionBody {
  id: number;
  pin: string;
}

export interface DepositFiat {
  email: string;
  amount: number;
}
