import { useState } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate } from "react-router-dom";

import { Button, Card, Table } from "components";
import { AddNewAdmin, IAddNewAdminFormValues } from "features/SuperAdmin";
import { ReactComponent as Union } from "assets/CarbonCredit-SVG/Union.svg";
import { ReactComponent as ArrowRight } from "assets/CarbonCredit-SVG/ArrowRight.svg";

const adminUserTableKeys = [
  {
    label: "Updated Date",
    name: "updated_date",
  },
  {
    label: "Account ID",
    name: "accountId",
  },
  {
    label: "Account Name",
    name: "accountName",
  },
  {
    label: "Email",
    name: "email",
  },
  {
    label: "Mobile Number",
    name: "mobile_no",
  },
];

const mockAdminUserTableData = Array(10).fill({
  updated_date: "30 Apr 2022 22:10",
  accountId: "0123456789",
  accountName: "Chawanakorn Srilamul",
  email: "blockfint@blockfint.com",
  mobile_no: "+66623456789",
});

const ManageAdminContainer = styled.div`
  margin-bottom: 32px;
`;

const TitleBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Content = styled(Card)`
  flex: 1;
  height: fit-content;
`;

const AddAdmin = styled(Button)`
  flex-direction: row;
  align-items: center;
  margin-left: auto;
`;

const PlusIcon = styled(Union)`
  display: block;
  margin-right: 12px;
  fill: ${(props) => props.theme.textColorLight};
`;

const ArrowIcon = styled.div`
  padding: 4px;
  border-radius: 50%;
  transition: background-color 0.3s;
  width: fit-content;
  cursor: pointer;
  svg {
    display: block;
    stroke: ${(props) => props.theme.textColor};
  }
  &:hover {
    background-color: ${(props) => props.theme.lightgray};
  }
  &:active {
    background-color: ${(props) => props.theme.lightgray200};
  }
`;

const ManageAdmin = () => {
  const navigate = useNavigate();
  const [showAddAdmin, setShowAddAdmin] = useState(false);
  const [addAdminError, setAddAdminError] = useState(false);
  // todo change this
  const adminTableData = mockAdminUserTableData;

  const onStepOneSubmit = (values: IAddNewAdminFormValues) => {
    console.log("values", values);
  };

  const onStepTwoSubmit = (hashCode: string, successCallback: () => void) => {
    console.log("hashCode", hashCode);
    // if success
    successCallback();
    // if fail
    // setAddAdminError(true);
  };

  const tableActions = (data: Record<string, string>) => (
    <ArrowIcon onClick={() => navigate(data.accountId)}>
      <ArrowRight />
    </ArrowIcon>
  );

  return (
    <ManageAdminContainer>
      <AddNewAdmin
        show={showAddAdmin}
        onStepOneSubmit={onStepOneSubmit}
        onStepTwoSubmit={onStepTwoSubmit}
        onStepThreeClose={() => setShowAddAdmin(false)}
        isError={addAdminError}
        setError={setAddAdminError}
        onBackgroundClick={() => setShowAddAdmin(false)}
      />
      <TitleBar>
        <AddAdmin variant="primary" onClick={() => setShowAddAdmin(true)}>
          <PlusIcon />
          Add New Admin
        </AddAdmin>
      </TitleBar>
      <Content
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 0.3,
        }}
      >
        <Table
          actions={tableActions}
          showPagination={false}
          keys={adminUserTableKeys}
          data={adminTableData}
          noDataMessage="No data"
        />
      </Content>
    </ManageAdminContainer>
  );
};

export default ManageAdmin;
