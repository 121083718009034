import { APIError } from "models/generic";
import { HashEverything } from "utils";
import { ClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { MarketItem, MarketReq, MarketRes } from "./MarketData";

/**
 * Market is a websocket client receiving live trades from the server.
 */
export class Market extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.MYORDER;

  private uniqueReqId: string = "";

  constructor(socket: ClientSocket) {
    super(socket);
    this.uniqueReqId = "";
  }

  public onData(data: MarketRes): void {
    console.log("market", data);
    let market: MarketItem[] = [];
    if (data.market !== undefined) {
      market = data.market;
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe(
    req: Omit<MarketReq, "client_id" | "unsubscribe_client_id">
  ) {
    const uniqueReqId = HashEverything(JSON.stringify(req));
    this.socket.subscribe(this);
    const reqData: MarketReq = {
      client_id: this.subsciberID + uniqueReqId,
      ...req,
    };
    this.uniqueReqId = uniqueReqId;
    this.send(reqData);
  }

  public unsubscribe() {
    this.send({ unsubscribe_client_id: this.subsciberID + this.uniqueReqId });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: MarketReq) {
    return super.sendInternal({
      market: data,
      channel_id: this.subsciberID,
    });
  }
}
