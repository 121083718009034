import styled from "@emotion/styled/macro";
import { Checkbox } from "components/Checkbox";
import { Popover, PopoverProps } from "react-bootstrap";

export interface IPasswordPopover extends PopoverProps {
  password?: string;
}

const StyledPopover = styled(Popover)`
  border: none;
  filter: ${(props) => `drop-shadow(${props.theme.dropShadowBase})`};
  z-index: ${(props) =>
    +props.theme.modalZIndex + 1}; // z-index is higher than modal
  .popover-arrow {
    &::before {
      border-right-color: transparent;
    }
  }
`;

const Header = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const upperCaseRegex = /(.*[A-Z].*)/;
const lowerCaseRegex = /(.*[a-z].*)/;
const oneNumberRegex = /(.*\d.*)/;
const specialCharRegex = /[~!@#%^&$;*_\-+=`|\\(){}\\[\]:;'"<>,.?/]+/;

const PasswordPopover = ({ password = "", ...rest }: IPasswordPopover) => (
  <StyledPopover id="popover-basic" {...rest}>
    <Popover.Body>
      <Header>
        The password must be at least 8 characters long which contains :
        <Checkbox
          checked={upperCaseRegex.test(password)}
          label="At least 1 uppercase (A-Z)"
        />
        <Checkbox
          checked={lowerCaseRegex.test(password)}
          label="At least 1 lowercase (a-z)"
        />
        <Checkbox
          checked={oneNumberRegex.test(password)}
          label="At least 1 number (0-9)"
        />
        <Checkbox
          checked={specialCharRegex.test(password)}
          label="At least 1 special character (~!@#%^&amp;*_-+=`|\(){}[]:;&quot;'<>,.?/)"
        />
      </Header>
    </Popover.Body>
  </StyledPopover>
);

export default PasswordPopover;
