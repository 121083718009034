import { APIError } from "models/generic";
import { ClientSocket, ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";
import { TickerReq, TickerRes } from "./TickerData";

/**
 * Ticker contains current market information (lowest ask/offer, highest bid,...).
 */
export class Ticker extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.TICKER;

  private currencyPair: string = "";

  constructor(socket: ClientSocket, pair: string) {
    super(socket);
    this.currencyPair = pair;
  }

  // eslint-disable-next-line class-methods-use-this
  public onData(data: TickerRes): void {
    console.log("TICKER DATA", data);
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public subscribe() {
    this.socket.subscribe(this);
    const reqData: TickerReq = {
      client_id: this.subsciberID,
      pair_name: this.currencyPair,
    };
    this.send(reqData);
  }

  public unsubscribe() {
    this.send({ unsubscribe_client_id: this.subsciberID });
    this.socket.unsubscribe(this);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: TickerReq) {
    return super.sendInternal({
      ticker: data,
      channel_id: this.subsciberID,
    });
  }
}
