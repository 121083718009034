import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import styled from "@emotion/styled/macro";
import { OverlayTrigger } from "react-bootstrap";

import eye from "assets/CarbonCredit-SVG/ShowPassword.svg";

import { Modal, IModal, TextField, Button, PasswordPopover } from "components";
import { passwordSchema } from "utils";

export interface AddNewPWValues {
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
}

export interface IAddNewPassword extends Omit<IModal, "onSubmit"> {
  onCancel: () => void;
  onSubmit: (values: AddNewPWValues) => void;
}

const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

const FootButton = styled(Button)`
  width: 100%;
`;

const Header = styled.h1`
  margin-bottom: 16px;
`;

const schema = Yup.object().shape({
  currentPassword: passwordSchema,
  newPassword: passwordSchema,
  reNewPassword: passwordSchema.oneOf(
    [Yup.ref("newPassword"), null],
    "Passwords must match"
  ),
});

const AddNewPassword = ({
  onCancel,
  onSubmit,
  show,
  ...rest
}: IAddNewPassword) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRePassword, setShowRePassword] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm<AddNewPWValues>({
    resolver: yupResolver(schema),
  });

  const passwordValue = watch("newPassword");

  useEffect(() => {
    if (show) {
      reset();
    }
  }, [show, reset]);

  const onConfirm = (values: AddNewPWValues) => {
    onSubmit(values);
    reset();
  };

  return (
    <Modal show={show} {...rest}>
      <Header>Change Password</Header>
      <form onSubmit={handleSubmit(onConfirm)} autoComplete="off">
        <TextField
          label="Enter current password"
          placeholder="Please enter current password"
          type={showPassword ? "text" : "password"}
          onIconClick={() => setShowPassword(!showPassword)}
          icon={eye}
          {...register("currentPassword")}
          errorWarn={!!errors.currentPassword}
          errorMessage={errors.currentPassword?.message}
        />
        <OverlayTrigger
          placement="auto-end"
          delay={{ show: 250, hide: 400 }}
          overlay={(props) =>
            PasswordPopover({ password: passwordValue, ...props })
          }
          trigger="focus"
        >
          <TextField
            label="Enter new password"
            placeholder="Please enter new password"
            type={showNewPassword ? "text" : "password"}
            {...register("newPassword")}
            errorWarn={!!errors.newPassword}
            errorMessage={errors.newPassword?.message}
            onIconClick={() => setShowNewPassword(!showNewPassword)}
            icon={eye}
          />
        </OverlayTrigger>
        <TextField
          label="Re-enter new password"
          placeholder="Please re-enter new password"
          type={showRePassword ? "text" : "password"}
          {...register("reNewPassword")}
          errorWarn={!!errors.reNewPassword}
          errorMessage={errors.reNewPassword?.message}
          onIconClick={() => setShowRePassword(!showRePassword)}
          icon={eye}
        />
        <Buttons>
          <FootButton type="button" variant="secondary" onClick={onCancel}>
            Cancel
          </FootButton>
          <FootButton type="submit">Confirm</FootButton>
        </Buttons>
      </form>
    </Modal>
  );
};

export default AddNewPassword;
