import { useLayoutEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { Spinner } from "react-bootstrap";

import { UserPaymentMethod } from "models/paymentMethod";
import { IOTPInputValue, OTPInput, Button, Modal } from "components";

interface IConfirmDeletePayment {
  show: boolean;
  onCancel: () => void;
  onConfirm: (account: UserPaymentMethod | null, pin: string) => void;
  account: UserPaymentMethod | null;
  isPinError?: boolean;
  isPinLoading?: boolean;
}

const StyledModal = styled(Modal)`
  text-align: center;
  .content-container {
    max-width: 540px;
  }
`;

const Title = styled.h2`
  margin-bottom: 8px;
`;

const Desc = styled.div`
  margin-bottom: 8px;
`;

const Footer = styled.div`
  display: flex;
`;

const pinDefaultState = {
  value: "",
  isFilled: false,
};

export const ConfirmDeletePayment = ({
  show,
  onCancel,
  onConfirm,
  account,
  isPinError,
  isPinLoading,
}: IConfirmDeletePayment) => {
  const [pin, setPin] = useState<IOTPInputValue>(pinDefaultState);

  useLayoutEffect(() => {
    if (show) {
      setPin(pinDefaultState);
    }
  }, [show, setPin]);

  const handleBackgroundClick = () => (isPinLoading ? null : onCancel());

  return (
    <StyledModal show={show} onBackgroundClick={handleBackgroundClick}>
      <Title>
        Delete &quot;{account?.payment_method_name}&quot; from your payment
        method?
      </Title>
      <Desc>Press Confirm to set</Desc>
      <Desc>
        Bank account number {account?.account_number} will be deleted
        permanently.
      </Desc>
      <OTPInput
        id="pin-code"
        onChange={(_, val) => setPin(val)}
        count={6}
        invalid={isPinError}
        loading={isPinLoading}
        invalidMessage="Verify pin is wrong. please try again."
        type="password"
      />
      <Footer>
        <Button
          style={{ marginRight: "8px" }}
          onClick={onCancel}
          variant="secondary"
          block
          disabled={isPinLoading}
        >
          Cancel
        </Button>
        <Button
          disabled={!pin.isFilled || isPinLoading}
          onClick={() => onConfirm(account, pin.value || "")}
          block
        >
          {isPinLoading ? (
            <Spinner
              style={{ display: "block" }}
              animation="border"
              variant="light"
            />
          ) : (
            "Confirm"
          )}
        </Button>
      </Footer>
    </StyledModal>
  );
};
