import HmacSHA256 from "crypto-js/hmac-sha256";
import MD5 from "crypto-js/md5";

const secret = "gideonWillFly";

// TODO better use native crypto without dependency after IE supports it https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
export const HashEverything = (data: string) => {
  const hash = HmacSHA256(data, secret).toString();
  return hash;
};

function readFileAsync(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsBinaryString(blob);
  });
}

export const calculateMd5 = async (blob: Blob) => {
  try {
    const contentBuffer = await readFileAsync(blob);
    const hash = MD5(contentBuffer as string).toString();
    return hash;
  } catch (error) {
    console.error(error);
    return "";
  }
};

export default HashEverything;
