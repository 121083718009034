import { useState, useEffect, useMemo } from "react";
import styled from "@emotion/styled/macro";
import useSWR from "swr";
import dayjs from "dayjs";
import numeral from "numeral";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import toast from "react-hot-toast";

import {
  Button,
  Modal,
  IModal,
  DatePicker,
  OutlineSelect,
  Status,
} from "components";
import { convertObjToUrl, captureErrorSentry } from "utils";
import { useDebounceValue, useSelectedDate } from "hooks";
import {
  Transaction,
  TransactionHistories,
  TransactionHistoriesRes,
  TransactionStatus,
  EntryType,
  transactionStatusVariant,
} from "models/transaction";
import * as errorCodes from "models/apiErrorCodes";
import { APIError } from "models/generic";
import { cancelTransaction } from "api/transaction";

import { ReactComponent as Download } from "assets/CarbonCredit-SVG/Download.svg";
import { apiFetcher, usePin } from "providers";
import { ConfirmCancelDepositWithdraw } from "../../shared/ConfirmCancelDepositWithdraw";

const ContentContainer = styled.div`
  max-height: 278px;
  margin: 0 0 32px;
  overflow-y: auto;
  padding-right: 16px;
  ::-webkit-scrollbar {
    width: 6px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #e7eaf1;
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #bdc0c9;
  }
`;

const TransactionText = styled.p`
  font-size: 12px;
  font-weight: 700;
  margin: 0;
`;
const InfoText = styled(TransactionText)`
  color: #9daac6;
  font-weight: 600;
`;
interface RowsTableProps {
  data: Transaction;
  isLast: boolean;
  fetch: () => void;
  onCancelClick?: () => void;
}

const NewHistoryItem = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 4px;
  min-width: 300px;
  border-bottom: 1px solid #e7eaf1;
  padding: 12px 0;
  button {
    justify-self: flex-end;
    height: fit-content;
    font-size: 0.75rem;
    /* width: 100%; */
  }
  .right {
    display: grid;
    align-self: center;
    text-align: right;
  }
`;

const NewTitle = styled.h2`
  width: 100%;
  margin-bottom: 24px;
`;

const Filters = styled.div`
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;

const DownloadIcon = styled(Download)`
  stroke: ${(props) => props.theme.lightgray200};
`;

const CustomModal = styled(Modal)`
  .content-container {
    max-width: 620px;
  }
`;

const RowTable = ({ data, isLast, fetch, onCancelClick }: RowsTableProps) => {
  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: true,
  });
  const type = data?.entry_type === EntryType.Deposit ? "Deposit" : "Withdraw";

  useEffect(() => {
    if (inView && isLast) {
      fetch();
    }
  }, [fetch, inView, isLast]);

  const accNumber = data?.account_number ?? "";
  const trimAccountNumber =
    accNumber.length > 4
      ? accNumber?.slice(accNumber.length - 5, accNumber.length - 1)
      : accNumber;

  return (
    <NewHistoryItem
      id="transaction-row"
      key={`${data?.id}-${data?.status}`}
      ref={ref}
    >
      <div>
        <TransactionText>{type}</TransactionText>
        <InfoText>
          {data?.id}
          <Status
            style={{
              fontSize: "0.75rem",
              fontWeight: "600",
              textTransform: "none",
              marginLeft: "8px",
            }}
            variant={transactionStatusVariant(data.status as TransactionStatus)}
          >
            {data.status.toLowerCase()}
          </Status>
        </InfoText>
        <div>
          <InfoText>
            {data?.entry_type === EntryType.Deposit ? "From" : "To"}:{" "}
            {data?.payment_method_name ? `${data?.payment_method_name}` : "-"}
          </InfoText>
          <InfoText>
            {data?.payment_method_name
              ? `x${trimAccountNumber} ${data?.account_name} ${data?.symbol}`
              : "-"}
          </InfoText>
        </div>
      </div>
      <div className="right">
        <Status
          style={{
            fontSize: "0.75rem",
            fontWeight: "700",
            textTransform: "none",
          }}
          variant={
            data?.entry_type === EntryType.Deposit ? "success" : "danger"
          }
          className="right"
        >
          {numeral(data?.amount).format("0,0.00")} THB
        </Status>
        <InfoText className="right">
          {dayjs(data?.date_time).format("DD MMM YYYY HH:mm")}
        </InfoText>
        {data?.status === TransactionStatus.Pending && (
          <Button
            onClick={onCancelClick}
            variant="link"
            size="small"
            style={{ width: "min-content" }}
          >
            Cancel
          </Button>
        )}
      </div>
    </NewHistoryItem>
  );
};

const limit = 6;

interface IMoneyTransaction extends IModal {
  onClose: () => void;
}

export const MoneyTransaction = ({ show, onClose }: IMoneyTransaction) => {
  const { showPin, closePin, setPinError } = usePin();
  const [showCancelRequest, setShowCancelRequest] =
    useState<Transaction | null>(null);

  const [transactionType, setTransactionType] = useState("");
  const [page, setPage] = useState(1);
  const [isReachedEnd, setReachedEnd] = useState(false);

  const { selectingDate, selectedDate, onChangeDate, resetDate } =
    useSelectedDate();

  const params = useMemo(
    () => ({
      symbol: "THB",
      limit: 6,
      page: 1,
      type: transactionType,
      from_date: dayjs(selectedDate[0]).startOf("date")?.toISOString() || "",
      to_date: dayjs(selectedDate[1]).endOf("date")?.toISOString() || "",
      currency_type: "fiat",
    }),
    [selectedDate, transactionType]
  );

  const debouncedParams = useDebounceValue(params, 300);

  const { data, mutate } = useSWR<TransactionHistories>(
    `/transaction/history?${convertObjToUrl(debouncedParams)}`,
    (url) => apiFetcher({ url }).then((res) => res.data)
  );

  const transactions = useMemo(() => {
    if (!data) return [];
    return data.transaction_histories;
  }, [data]);

  const totalTransaction = useMemo(() => data?.total ?? 0, [data?.total]);

  const onChangeType = (event: any) => {
    setTransactionType(event?.target.value);
    setPage(1);
    setReachedEnd(false);
  };

  const fetchMore = async () => {
    if (transactions.length < totalTransaction && !isReachedEnd) {
      const response: TransactionHistoriesRes = await apiFetcher({
        url: "/transaction/history",
        params: {
          ...params,
          page: page + 1,
        },
      });
      const newData: TransactionHistories = {
        transaction_histories: [
          ...transactions,
          ...response.data.transaction_histories,
        ],
        total: totalTransaction,
      };
      await mutate(newData, false);
      if (response.data.transaction_histories.length < limit) {
        setReachedEnd(true);
      }
      if (response.data.transaction_histories.length === limit) {
        setPage(page + 1);
      }
    }
  };

  const onConfirmPin = async (pin: string) => {
    try {
      const res = await cancelTransaction({
        id: showCancelRequest?.id ?? 0,
        pin,
      });
      if (res.data.success) {
        const targetData = transactions.filter(
          (data) => data.id === showCancelRequest?.id
        )?.[0];

        const oldData = transactions.filter(
          (data) => data.id !== showCancelRequest?.id
        );
        const changedStatusData = [
          {
            ...targetData,
            status: TransactionStatus.Cancelled,
          },
        ];

        const newData = {
          transaction_histories: [...changedStatusData, ...oldData],
          total: data?.total ?? 0,
        };
        await mutate(newData, false);
        closePin();
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        if (error.code === errorCodes.InvalidPin) {
          setPinError({
            isError: true,
            errorMessage: "Incorrect PIN. Try again.",
          });
        } else {
          toast.error("Something went wrong. Please try again later.");
          captureErrorSentry(error, err, {
            message: "Cancel transaction error",
          });
        }
      }
    }
  };

  const onConfirmCancelTransaction = async () => {
    showPin({
      show: true,
      onConfirm: (pin) => onConfirmPin(pin),
    });
    setShowCancelRequest(null);
  };

  const resetFilter = () => {
    setTransactionType("");
    setPage(1);
    setReachedEnd(false);
    resetDate();
  };

  return (
    <CustomModal
      show={show}
      closable
      onCloseClick={onClose}
      onBackgroundClick={onClose}
    >
      <ConfirmCancelDepositWithdraw
        show={!!showCancelRequest}
        history={showCancelRequest ?? undefined}
        onClose={() => setShowCancelRequest(null)}
        onConfirm={onConfirmCancelTransaction}
      />
      <NewTitle>Money Transaction History</NewTitle>
      <Filters>
        <DatePicker
          style={{ minWidth: "232px" }}
          startDate={selectingDate[0]}
          endDate={selectingDate[1]}
          onChange={(_, update) => onChangeDate(update as [Date, Date | null])}
          selectsRange
          dateFormat="dd MMM yyyy"
          maxDate={new Date()}
          showYearDropdown
          dropdownMode="select"
          usePortal={false}
        />
        <OutlineSelect
          style={{ width: "120px" }}
          block={false}
          value={transactionType}
          onChange={onChangeType}
        >
          <option value="">All Types</option>
          <option value={EntryType.Deposit}>Deposit</option>
          <option value={EntryType.Withdraw}>Withdraw</option>
        </OutlineSelect>
        <Button
          variant="link"
          style={{
            paddingInline: "8px",
          }}
          onClick={resetFilter}
        >
          Reset
        </Button>
      </Filters>
      <ContentContainer style={{ minHeight: "278px", margin: 0, padding: 0 }}>
        {transactions.map((transaction, index) => (
          <RowTable
            key={transaction.id}
            data={transaction}
            isLast={transactions.length - 1 === index}
            fetch={fetchMore}
            onCancelClick={() => setShowCancelRequest(transaction)}
          />
        ))}
      </ContentContainer>
    </CustomModal>
  );
};
