import { AnimatePresence } from "framer-motion";
import { Spinner } from "react-bootstrap";
import { ILoadingOverlay } from "./interfaces";
import {
  LoadingOverlayBackground,
  LoadingOverlayContainer,
  LoadingOverlayContent,
} from "./styles";

const LoadingOverlay = ({
  show,
  customSpinner,
  className,
}: ILoadingOverlay) => (
  <AnimatePresence>
    {show && (
      <LoadingOverlayContainer className={className}>
        <LoadingOverlayBackground />
        <LoadingOverlayContent>
          {customSpinner ?? <Spinner animation="border" />}
        </LoadingOverlayContent>
      </LoadingOverlayContainer>
    )}
  </AnimatePresence>
);

export default LoadingOverlay;
