import { APIError } from "models/generic";
import { AuthReq, AuthRes } from "./AuthData";
import { ClientSocketReceiver } from "./ClientSocket";
import { WebSocketOpcode } from "./Opcode";

/**
 * MyOrder is a websocket client receiving live trades from the server.
 */
export class Auth extends ClientSocketReceiver {
  public readonly opcode = WebSocketOpcode.AUTH;

  public onData(data: AuthRes): void {
    if (data.success !== undefined) {
      console.log("Auth status", data.success);
    } else
      console.warn(
        "Received %s unknown response from server:",
        this.className,
        data
      );

    // TODO Max display section 4 on UI
    // use trades[0].rate, trades[0].amount
    // make sure to remove old trades again (don't store them forever in array or DOM tree)
  }

  public onError(error: APIError): void {
    console.warn(
      "Received %s unknown response from server ===> %s: %s",
      this.className,
      error.code,
      error.message
    );
  }

  public authenticate(token?: string) {
    this.socket.subscribe(this);
    const reqData: AuthReq = {
      client_id: this.subsciberID,
      token,
    };
    this.send(reqData);
  }

  // ################################################################
  // ###################### PRIVATE FUNCTIONS #######################

  protected send(data: AuthReq) {
    return super.sendInternal({
      auth: data,
      channel_id: this.subsciberID,
    });
  }
}
