import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import { useNavigate, useSearchParams } from "react-router-dom";
import useSWRImmutable from "swr/immutable";

import {
  Button,
  OutlineSelect,
  TextField,
  Table,
  Tab,
  TabsBar,
  Key,
  ITabsBar,
  Status,
  StatusVariant,
} from "components";
import { ISymbolDetailTypes, SymbolDetail } from "features/shared/SymbolDetail";
import { ReactComponent as Star } from "assets/CarbonCredit-SVG/Star.svg";
import { mediaQuery, percentNumber, priceNumber } from "utils";
import { useOrder } from "providers";
import { APIError } from "models/generic";
import { MarketItem, MarketRes } from "models/WebsocketClients";
import { useDebounce, useDebounceValue, usePagination } from "hooks";

import { config } from "config";
import { GetMarketFiltersListRes } from "models/market";

const { path } = config;

const MarketsContainer = styled.div`
  padding: 65px 0px 0px;
  background-color: ${(props) => props.theme.componentBackgroundColor};
  min-height: 100vh;
  th,
  label {
    white-space: nowrap;
  }
`;

const Title = styled.h1`
  margin-bottom: 24px;
  text-align: center;
`;

const TabHeader = styled.h2`
  color: inherit;
`;

const StarIcon = styled(Star)`
  margin-right: 16px;
  fill: ${(props) => props.theme.lightgray};
  stroke: ${(props) => props.theme.lightgray};
  &.active {
    fill: ${(props) => props.theme.yellow};
    stroke: ${(props) => props.theme.yellow};
  }
`;

const HeroImg = styled.img`
  width: 100%;
  height: auto;
  aspect-ratio: 36/13;
`;

const MarketsContent = styled.div`
  padding: 64px 24px 24px;
  ${mediaQuery("desktop")} {
    padding: 64px 56px 56px;
  }
`;

const StyledTabsBar = styled(TabsBar)`
  & > div {
    margin-bottom: 32px;
    & > .tab-ul {
      justify-content: center;
    }
  }
`;

const getPriceSide = (change: number) => {
  if (change > 0) return "success";
  if (change < 0) return "danger";
  return "";
};

const keys: Key<MarketItem>[] = [
  {
    label: "Symbol",
    name: "pair_symbol",
    sortable: true,
    style: {
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      fontSize: "1rem",
    },
    customRenderer: (data, rowData) => (
      <Button
        as="link"
        variant="link-secondary"
        to={`${path.tradeBase}/${data}`}
        style={{
          padding: 0,
        }}
      >
        {/* <StarIcon className={rowData.is_favorite ? "active" : ""} /> */}
        {data.toString().split("_")[0]}
      </Button>
    ),
  },
  {
    label: "Last",
    name: "last_price",
    sortable: true,
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data, rowData) => (
      <Status
        normal
        variant={getPriceSide(rowData.percent_change) as StatusVariant}
      >
        {priceNumber(data as number, {
          currency: "THB",
        })}
      </Status>
    ),
  },
  {
    label: "Chg",
    name: "change",
    sortable: true,
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => (
      <Status normal variant={getPriceSide(+data) as StatusVariant}>
        {priceNumber(data as number, {
          currency: "THB",
          signDisplay: "exceptZero",
        })}
      </Status>
    ),
  },
  {
    label: "% Chg",
    name: "percent_change",
    sortable: true,
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) => (
      <Status normal variant={getPriceSide(+data) as StatusVariant}>
        {percentNumber(data as number, {
          signDisplay: "exceptZero",
        })}
      </Status>
    ),
  },
  {
    label: "Buy Vol",
    name: "buy_volume",
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        min: 0,
        max: 0,
      }),
  },
  {
    label: "Buy Price",
    name: "buy_price",
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
  {
    label: "Sell Price",
    name: "sell_price",
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
  {
    label: "Sell Vol",
    name: "sell_volume",
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        min: 0,
        max: 0,
      }),
  },
  {
    label: "Close Price",
    name: "close_price",
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
  {
    label: "Total Vol",
    name: "total_volume",
    sortable: true,
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        min: 0,
        max: 0,
      }),
  },
  {
    label: "Total Val",
    name: "total_value",
    sortable: true,
    style: {
      textAlign: "right" as const,
      fontSize: "1rem",
    },
    headerStyle: {
      textAlign: "right" as const,
    },
    customRenderer: (data) =>
      priceNumber(data as number, {
        currency: "THB",
      }),
  },
];

const defaultFilterState = {
  marketName: "carbon-credit",
  search: "",
  projectType: "",
  standard: "",
  isFavorite: true,
};

const Markets = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showSymbolDetail, setShowSymbolDetail] = useState({
    symbol: "",
    type: "carbon",
  });
  const [filterState, setFilterState] = useState(defaultFilterState);
  const debouncedFilterState = useDebounceValue(filterState, 500);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<MarketItem[]>([]);
  const [marketTotal, setMarketTotal] = useState(0);
  const { pagination, setPage, setLimit, showPerPageOptions, totalPages } =
    usePagination(marketTotal);
  const debounceSetPage = useDebounce(setPage, 500, [
    setPage,
    debouncedFilterState.search,
  ]);

  const {
    state: { market },
  } = useOrder();

  const { data: marketOptions } =
    useSWRImmutable<GetMarketFiltersListRes>("/market/filters");

  const handleData = (data: MarketRes) => {
    const tableData = data.market;
    setTableData(tableData);
    setMarketTotal(data.total);
    setLoading(false);
  };

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in market: ", error);
    },
    [navigate]
  );

  useLayoutEffect(() => {
    if (market && !subscribed) {
      const { marketName, search, projectType, standard, isFavorite } =
        debouncedFilterState;
      setSubscribed(true);
      const debug = searchParams.get("debug") === "true";
      market.subscribe({
        market_name: debug ? searchParams.get("market") : marketName,
        search,
        project_type: projectType,
        standard,
        limit: pagination.limit,
        page: pagination.page,
        is_favorite: isFavorite,
      });
      market.onData = handleData;
      market.onError = handleError;
      console.log("subscribed");
    }
    return () => {
      if (market && subscribed) {
        market.unsubscribe();
        setSubscribed(false);
        console.log("unsubscribed");
      }
    };
  }, [
    debouncedFilterState,
    handleError,
    market,
    pagination.page,
    pagination.limit,
    subscribed,
  ]);

  const favoriteActions = useCallback(
    (data: MarketItem, type?: "carbon" | "rec" | "re") => (
      <>
        {/* <Button
          style={{ marginRight: "16px" }}
          variant="link"
          onClick={() =>
            setShowSymbolDetail({
              symbol: data.pair_symbol,
              type: type || "carbon",
            })
          }
        >
          Detail
        </Button> */}
        <Button
          variant="link"
          as="link"
          to={`${path.tradeBase}/${data.pair_symbol}`}
        >
          Trade
        </Button>
      </>
    ),
    []
  );

  const filter = useMemo(
    () => (
      <>
        <TextField
          containerStyle={{
            maxWidth: "240px",
            paddingBottom: 0,
          }}
          placeholder="Search Symbol"
          onChange={(e) => {
            setFilterState((state) => ({
              ...state,
              search: e.target.value,
            }));
            debounceSetPage(1);
          }}
        />
        <OutlineSelect
          style={{ maxWidth: "240px", paddingBottom: 0 }}
          onChange={(e) =>
            setFilterState((state) => ({
              ...state,
              standard: e.target.value,
            }))
          }
        >
          <option value="">All standards</option>
          {marketOptions?.data.standards.map((standard) => (
            <option key={standard} value={standard}>
              {standard}
            </option>
          ))}
        </OutlineSelect>
        <OutlineSelect
          style={{ maxWidth: "240px", paddingBottom: 0 }}
          onChange={(e) =>
            setFilterState((state) => ({
              ...state,
              projectType: e.target.value,
            }))
          }
        >
          <option value="">All Project Types</option>
          {marketOptions?.data.projects
            .filter((val) => val !== "TEST")
            .map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
        </OutlineSelect>
      </>
    ),
    [
      debounceSetPage,
      marketOptions?.data.projects,
      marketOptions?.data.standards,
    ]
  );

  const subTabsProps: Partial<ITabsBar> = {
    mode: "horizontal",
    onSelect: (key: string) => {
      setFilterState((state) => ({
        ...state,
        isFavorite: key === "favorite",
      }));
    },
  };

  const tableProps = useCallback(
    (type?: "carbon" | "rec" | "re") => ({
      filters: filter,
      actions: (data: MarketItem) => favoriteActions(data, type),
      keys,
      data: tableData,
      noDataMessage: "No data",
      rowPrimaryKey: "pair_id",
      loading,
      tbodyTestId: "carbon-symbol__table",
    }),
    [favoriteActions, filter, loading, tableData]
  );

  return (
    <MarketsContainer>
      <SymbolDetail
        showSymbol={showSymbolDetail.symbol}
        onCancel={() =>
          setShowSymbolDetail({
            symbol: "",
            type: "carbon",
          })
        }
        type={showSymbolDetail.type as ISymbolDetailTypes}
      />
      <picture>
        <source
          media="(min-width: 720px)"
          type="image/webp"
          srcSet="/images/HeroBar.webp"
        />
        <source type="image/webp" srcSet="/images/HeroBar-small.webp" />
        <HeroImg
          src="/images/HeroBar.webp"
          alt="Renewable energy &amp; Carbon credit trading platform"
          width="1440"
          height="520"
        />
      </picture>
      <MarketsContent>
        <Title>Markets</Title>
        <StyledTabsBar
          mode="horizontal-box"
          loading={false}
          onSelect={(key) =>
            setFilterState({
              ...defaultFilterState,
              marketName: key,
            })
          }
        >
          <Tab key="carbon-credit" label={<TabHeader>Carbon Credit</TabHeader>}>
            <TabsBar {...subTabsProps}>
              {/* <Tab key="favorite" label={<TabHeader>Favorite</TabHeader>}>
                <Table {...tableProps("carbon")} />
              </Tab> */}
              {/* todo: re-enable favorite and remove array bracket below */}
              {[
                <Tab key="all" label={<TabHeader>All Credits</TabHeader>}>
                  <Table
                    {...tableProps("carbon")}
                    onPageChange={setPage}
                    onShowChange={setLimit}
                    currentPage={pagination.page}
                    show={pagination.limit}
                    showPerPageOptions={showPerPageOptions}
                    totalPages={totalPages}
                    rowHoverable
                    loading={loading}
                    loadingAsSkeleton
                  />
                </Tab>,
              ]}
            </TabsBar>
          </Tab>
          <Tab
            key="rec"
            label={<TabHeader>REC</TabHeader>}
            disabled
            title="Coming soon!"
          >
            <TabsBar {...subTabsProps}>
              <Tab key="favorite" label={<TabHeader>Favorite</TabHeader>}>
                <Table {...tableProps("rec")} />
              </Tab>
              <Tab key="all" label={<TabHeader>All Credits</TabHeader>}>
                <Table {...tableProps("rec")} />
              </Tab>
            </TabsBar>
          </Tab>
          <Tab
            key="re"
            label={<TabHeader>RE</TabHeader>}
            disabled
            title="Coming soon!"
          >
            <TabsBar {...subTabsProps}>
              <Tab key="favorite" label={<TabHeader>Favorite</TabHeader>}>
                <Table {...tableProps("re")} />
              </Tab>
              <Tab key="all" label={<TabHeader>All Credits</TabHeader>}>
                <Table {...tableProps("re")} />
              </Tab>
            </TabsBar>
          </Tab>
        </StyledTabsBar>
      </MarketsContent>
    </MarketsContainer>
  );
};

export default Markets;
