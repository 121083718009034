import dayjs from "dayjs";

import { dbSafePriceNumber, priceNumber } from "utils";
import { MyOrderData } from "models/WebsocketClients";
import { config } from "config";
import {
  Thead,
  Tr,
  ThLeft,
  Th,
  ThRight,
  Tbody,
  TdLeft,
  Type,
  Sell,
  TdRight,
  TdStatus,
  Container,
  Row,
  Table,
} from "./styles";

const { maxDbSafeInt } = config;

export const InactiveOrder = ({ orders }: { orders: MyOrderData[] }) => (
  <Container>
    <Table>
      <Thead>
        <Tr>
          <ThLeft>ID</ThLeft>
          <ThLeft>Date</ThLeft>
          <ThLeft>Type</ThLeft>
          <Th>Side</Th>
          <ThRight>Avg. Price</ThRight>
          <ThRight>Price</ThRight>
          <ThRight>Amount</ThRight>
          <ThRight>Executed</ThRight>
          <ThRight>Total</ThRight>
          <Th>Status</Th>
        </Tr>
      </Thead>
      {/* <Line/> */}
      <Tbody data-test-id="inactive-order__table">
        {orders.map(
          ({
            amount,
            price,
            avg_price: avgPrice,
            side,
            id,
            date,
            status,
            filled,
            total,
            type,
          }) => (
            <Row key={id}>
              <TdLeft>{id}</TdLeft>
              <TdLeft>{dayjs(date).format("DD-MM-YY HH:mm:ss")}</TdLeft>
              <Type>{type}</Type>
              <Sell side={side || ""}>{side}</Sell>
              <TdRight>{`${priceNumber(+avgPrice ?? 0)}`}</TdRight>
              <TdRight>
                {type === "market" ? "-" : dbSafePriceNumber(+price)}
              </TdRight>
              <TdRight>{amount}</TdRight>
              <TdRight>{filled}</TdRight>
              <TdRight>{`${dbSafePriceNumber(+total ?? 0)}`}</TdRight>
              <TdStatus status={status}>{status}</TdStatus>
            </Row>
          )
        )}
      </Tbody>
    </Table>
  </Container>
);
