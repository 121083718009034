/* eslint-disable react/jsx-no-constructed-context-values */
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import styled from "@emotion/styled/macro";
import useSWR from "swr";

import { AllBalanceType, WalletType } from "models/wallet";
import { convertObjToUrl } from "utils";
import { apiFetcher } from "providers";
import { DropdownSearch } from "components";
import { UserTable } from "features/Admin";

const Container = styled.div`
  padding: 48px 40px 48px 49px;
  height: calc(100% - 65px);
`;
const HeaderContainer = styled.div`
  width: 100%;
  height: 56px;
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
`;
const UserTitles = styled.div`
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1f2c57;
`;

export interface State {
  latestData: AllBalanceType;
  response: AllBalanceType;
}

export type Dispatch =
  | { type: "SET_LATEST_DATA"; payload: AllBalanceType }
  | { type: "SET_RESPONSE"; payload: AllBalanceType }
  | { type: "RESET" };

const initialState = {
  latestData: { next_cursor: 0, wallets: [] },
  response: { next_cursor: 0, wallets: [] },
} as State;

const reducer = (state: State, action: Dispatch) => {
  switch (action.type) {
    case "SET_LATEST_DATA":
      return { ...state, latestData: action.payload };
    case "SET_RESPONSE":
      return { ...state, response: action.payload };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};

export const DataContext = createContext({
  state: initialState,
  dispatch: (value: Dispatch) => null,
} as { state: State; dispatch: (value: Dispatch) => void });
export const useDataReducer = () => useReducer(reducer, initialState);
export const useDataContext = () => useContext(DataContext);

const Admin = () => {
  const [searchParams, setSearch] = useState<{
    column: string;
    searchBar: number | string;
  }>({ column: "", searchBar: "" });

  const limit = 10;
  const params = {
    currency: "THB",
    limit,
    cursor: 0,
    column: searchParams?.column,
    search_bar: searchParams?.searchBar,
  };

  const [state, dispatch] = useDataReducer();

  const {
    data: query,
    mutate,
    error,
  } = useSWR<AllBalanceType>(`/wallet/all_balance?${convertObjToUrl(params)}`, {
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (
      state.latestData.wallets.length === 0 &&
      query?.wallets &&
      query.wallets?.length !== 0
    ) {
      const newData = {
        next_cursor: query.next_cursor,
        wallets: query.wallets,
      };
      dispatch({ type: "SET_LATEST_DATA", payload: newData });
    }
  }, [dispatch, query, state.latestData]);

  const userData: WalletType[] = useMemo(() => {
    if (!query?.wallets) return [];
    return query?.wallets;
  }, [query?.wallets]);
  const nextCursor = query?.next_cursor;

  useEffect(() => {
    const newData = {
      next_cursor: nextCursor ?? 0,
      wallets: userData,
    };
    dispatch({ type: "SET_LATEST_DATA", payload: newData });
  }, [dispatch, nextCursor, userData]);

  // const fetch = () => {
  //   if (query?.wallets && nextCursor !== 0) {
  //     const getNewData = async () => {
  //       const response: AllBalanceType = await apiFetcher({
  //         url: "/wallet/all_balance",
  //         params: {
  //           ...params,
  //           cursor: query?.next_cursor,
  //         },
  //       });
  //       const newData = {
  //         next_cursor: response?.next_cursor,
  //         wallets: [...query.wallets, ...response.wallets],
  //       };
  //       // dispatch({ type: "SET_LATEST_DATA", payload: newData });
  //       mutate(newData, false);
  //     };
  //     getNewData();
  //   }
  // };
  const searchOption = [
    ["registrationDate", "Registration Date"],
    ["displayName", "Display Name"],
    ["email", "Email"],
    ["mobileNo", "Mobile No"],
    ["balance", "Balance"],
  ];
  const handleSearch = (column: string, searchBar: string) => {
    setSearch({ column, searchBar });
  };
  if (error) return <div />;
  return (
    <Container>
      <HeaderContainer>
        <UserTitles>Users</UserTitles>
        <DropdownSearch
          onSubmit={handleSearch}
          searchOption={searchOption}
          defaultValue="displayName"
        />
      </HeaderContainer>
      <DataContext.Provider value={{ state, dispatch }}>
        {/* <UserTable
          data={userData}
          mutate={mutate}
          params={{
            ...params,
            cursor: query?.next_cursor,
          }}
          fetch={fetch}
        /> */}
        Not yet implemented
      </DataContext.Provider>
    </Container>
  );
};

export default Admin;
