import { backendURL } from "config";
import { BrokerEodFileRes } from "models/broker";
import { UpdateTransactionBody } from "models/transactionRequest";
import { client } from "./client";

export const updateTransactionRequest = async (body: UpdateTransactionBody) => {
  const response = await client.post(
    `${backendURL}/transaction-request/update`,
    body
  );
  return response.data;
};

export const getReport = async (date: string) => {
  const response = await client.get<BrokerEodFileRes>(
    `${backendURL}/broker-management/broker/eod/${date}`
  );
  return response.data;
};
