import styled from "@emotion/styled";
import { Button, Modal } from "components";
import { ReactNode } from "react";
import { mediaQuery } from "utils";

const ModalContent = styled.div`
  padding: 0 36px;
  text-align: center;
`;

const ModalTitle = styled.h1`
  text-align: center;
  margin-bottom: 8px;
`;

const ModalDesc = styled.p``;

const GroupButton = styled.div`
  display: flex;
  flex-direction: column;
  grid-column-gap: 8px;
  ${mediaQuery("mobile")} {
    flex-direction: row;
  }
`;

export interface IConfirmModal {
  show: boolean;
  title: string;
  desc: ReactNode;
  textConfirm?: string;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmModal = ({
  show,
  title,
  desc,
  textConfirm = "Confirm",
  onConfirm,
  onClose,
}: IConfirmModal) => (
  <Modal show={show}>
    <ModalContent>
      <ModalTitle>{title}</ModalTitle>
      <ModalDesc>{desc}</ModalDesc>
      <GroupButton>
        <Button
          type="button"
          block
          size="small"
          variant="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button type="button" block size="small" onClick={onConfirm}>
          {textConfirm}
        </Button>
      </GroupButton>
    </ModalContent>
  </Modal>
);

export default ConfirmModal;
