import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Button, OutlineSelect, TextField } from "components";
import { NewPaymentMethod } from "models/user";

import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import {
  SmallTitle,
  Form,
  RegisterComponentGrid,
  digitsOnlyChange,
} from "../components";

const schema = object({
  bank_code: string().required("Required Bank Code"),
  bank_branch: string().required("Required Bank Branch"),
  bank_account_type: string().required("Required Account Type"),
  bank_account_number: string()
    .required("Required Account Number")
    .matches(/^\d+$/, "Account Number must be number"),
  bank_account_name: string().required("Required Account Name"),
});

export const Payment = () => {
  const {
    state: { page, paymentMethod },
    dispatch,
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm<NewPaymentMethod>({
    resolver: yupResolver(schema),
    defaultValues: paymentMethod,
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const onSubmit = (data: NewPaymentMethod) => {
    onSubmitted();
    dispatch({ type: "SET_PAYMENT_METHOD", payload: data });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <RegisterComponentGrid>
      <SmallTitle>การชำระราคาและรับเงินค่า ซื้อ / ขาย ผลิตภัณฑ์</SmallTitle>
      <Form id="payment" onSubmit={handleSubmit(onSubmit)}>
        <OutlineSelect
          label="ธนาคาร"
          required
          errorWarn={!!errors.bank_code}
          errorMessage={errors.bank_code?.message}
          {...register("bank_code")}
        >
          <option value="" disabled>
            กรุณาเลือกธนาคาร
          </option>
          <option value="scb">SCB</option>
          <option value="ktb">KTB</option>
          <option value="exim">EXIM bank</option>
        </OutlineSelect>
        <TextField
          label="สาขา"
          required
          placeholder="กรอกสาขาธนาคาร"
          errorWarn={!!errors.bank_branch}
          errorMessage={errors.bank_branch?.message}
          {...register("bank_branch")}
        />
        <OutlineSelect
          label="ประเภทบัญชี"
          required
          errorWarn={!!errors.bank_account_type}
          errorMessage={errors.bank_account_type?.message}
          {...register("bank_account_type")}
        >
          <option value="" disabled>
            กรุณาเลือกประเภทบัญชี
          </option>
          <option value="SAVING">ออมทรัพย์</option>
          <option value="CURRENT">กระแสรายวัน</option>
        </OutlineSelect>
        <Controller
          control={control}
          name="bank_account_number"
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              label="เลขที่บัญชี"
              required
              placeholder="กรอกเลขที่บัญชี"
              errorWarn={!!errors.bank_account_number}
              errorMessage={errors.bank_account_number?.message}
              {...rest}
              onChange={(e) => digitsOnlyChange(e, onChange)}
            />
          )}
        />
        <TextField
          label="ชื่อบัญชี (ตรงกับชื่อนิติบุคคล)"
          required
          placeholder="กรอกชื่อบัญชี (ตรงกับชื่อนิติบุคคล)"
          errorWarn={!!errors.bank_account_name}
          errorMessage={errors.bank_account_name?.message}
          {...register("bank_account_name")}
        />
      </Form>
      <Button block form="payment" type="submit">
        Next
      </Button>
    </RegisterComponentGrid>
  );
};
