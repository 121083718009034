import { ReactNode, ReactElement, useState, CSSProperties } from "react";
import dayjs from "dayjs";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import styled from "@emotion/styled/macro";
import calendar from "assets/CarbonCredit-SVG/Calendar.svg";
import { createPortal } from "react-dom";

import {
  Label,
  ErrorText,
  Icon,
  LabelContainer,
  LabelRequired,
} from "../Field/InputField";

interface IStyledDatePickerContainer {
  block?: boolean;
  errorWarn?: boolean;
  style?: CSSProperties;
  isIconShow?: boolean;
}

interface IonChangeDatePicker {
  target: {
    id?: string;
    value: string | [Date, Date] | null;
  };
}

interface IDatePickerCustomOnchange {
  onChange?: (
    target: IonChangeDatePicker,
    date: Date | [Date, Date] | null
  ) => void;
}

interface IDatePickerBase
  extends Omit<
      ReactDatePickerProps,
      keyof IDatePickerCustomOnchange | "selectsRange"
    >,
    Omit<IStyledDatePickerContainer, "isIconShow"> {
  label?: ReactNode;
  className?: string;
  icon?: string;
  prefix?: ReactNode;
  errorMessage?: string;
  customValidate?: boolean;
  customValidateMessage?: string;
  id?: string;
  value?: string;
  dateFormat?: string;
  valueFormat?: string;
  onIconClick?: () => void;
  selectsRange?: boolean;
  labelSuffix?: ReactNode;
  usePortal?: boolean;
}

export type IDatePicker = IDatePickerBase & IDatePickerCustomOnchange;

const StyledDatePickerContainer = styled.div<IStyledDatePickerContainer>`
  margin-bottom: ${(props) => (props.block ? "24px" : "0px")};
  min-width: ${(props) => (props.block ? "200px" : "unset")};
  width: ${(props) => (props.block ? "100%" : "auto")};
  display: ${(props) => (props.block ? "block" : "inline-block")};
  margin-bottom: calc(1rem + 4px);
  position: relative;
  .react-datepicker__input-container {
    height: 48px;
    .date-picker {
      cursor: pointer;
      height: 100%;
      width: 100%;
      padding: ${(props) => props.theme.inputPadding};
      padding-right: ${(props) =>
        props.isIconShow
          ? `calc(${props.theme.inputPadding} + 18px)`
          : props.theme.inputPadding};
      border-radius: ${(props) => props.theme.inputBorderRadius};
      border: ${(props) =>
        props.errorWarn
          ? `solid 1px ${props.theme.errorColor}`
          : `solid 1px ${props.theme.inputBorderColor}`};
      transition-duration: 0.3s;
      transition-property: color, border-color, background-color;
      color: ${(props) => props.theme.secondaryColor};
      outline: none;
      font-size: ${(props) => props.theme.inputFontSize};
      ::-webkit-credentials-auto-fill-button {
        visibility: hidden;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
      &:focus {
        border-color: ${(props) => props.theme.secondaryColor};
      }
      &:disabled {
        color: ${(props) => props.theme.textColorDisabled};
        border-color: ${(props) => props.theme.inputDisableBackground};
        background-color: ${(props) => props.theme.inputDisableBackground};
      }
    }
  }
  .react-datepicker {
    border: none;
    filter: ${(props) => `drop-shadow(${props.theme.dropShadowBase})`};
    font-family: ${(props) => props.theme.fontFamily};
    .react-datepicker__triangle {
      &::before,
      &::after {
        border-bottom-color: ${(props) => props.theme.componentBackgroundColor};
      }
    }
    .react-datepicker__navigation {
      top: 16px; //equal to header padding
    }
    .react-datepicker__header {
      background-color: transparent;
      padding: 16px 0 8px;
      .react-datepicker__day-name {
        font-size: 0;
        color: ${(props) => props.theme.darkgray};
      }
      .react-datepicker__day-name::first-letter {
        font-size: 0.75rem;
      }
    }
    .react-datepicker__week {
      margin: 4px 0;
    }
    .react-datepicker__day--outside-month {
      color: ${(props) => props.theme.lightgray200};
    }
    .react-datepicker__day {
      margin: 0 -0.5px;
      padding: 0 0.5px;
      width: auto;
      height: auto;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 0;
      > div {
        width: 35px;
        height: 35px;
        display: flex;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        font-size: 0.75rem;
        line-height: 1em;
      }
    }
    .react-datepicker__day:hover {
      background-color: unset;
      > div {
        border: 1px solid ${(props) => props.theme.primaryColor};
      }
      &.react-datepicker__day--in-selecting-range {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
        background-color: ${(props) => props.theme.lightblue200};
        > div {
          background-color: ${(props) => props.theme.primaryColor};
        }
      }
      &.react-datepicker__day--in-range {
        background-color: ${(props) => props.theme.lightblue200};
      }
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      background: transparent;
      &:not(.react-datepicker__day--in-selecting-range) {
        color: ${(props) => props.theme.textColorLight};
        > div {
          background-color: ${(props) => props.theme.primaryColor};
        }
      }
    }
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start {
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    .react-datepicker__day--range-end {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 50%;
      border-top-right-radius: 50%;
    }
    .react-datepicker__day--in-range {
      background-color: ${(props) => props.theme.lightblue200};
    }
    .react-datepicker__day--in-selecting-range {
      background-color: ${(props) => props.theme.lightblue200};
    }
    .react-datepicker__day--selecting-range-start,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end,
    .react-datepicker__day--keyboard-selected {
      color: ${(props) => props.theme.textColorLight};
      > div {
        background-color: ${(props) => props.theme.primaryColor};
      }
    }
    .react-datepicker__day--range-start.react-datepicker__day--range-end {
      border-radius: 50%;
      color: ${(props) => props.theme.textColorLight};
      > div {
        background-color: ${(props) => props.theme.primaryColor};
      }
      &.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start.react-datepicker__day--selecting-range-end {
        border-radius: 50%;
      }
    }
    .react-datepicker__day--in-selecting-range.react-datepicker__day--weekend,
    .react-datepicker__day--in-range.react-datepicker__day--weekend {
      &:last-child {
        border-bottom-right-radius: 50%;
        border-top-right-radius: 50%;
      }
      &:not(:last-child) {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
      }
    }
    // case seperate daterange picker
    .react-datepicker__month--selecting-range {
      .react-datepicker__day--in-range {
        background: ${(props) => props.theme.componentBackgroundColor};
        &.react-datepicker__day--in-selecting-range {
          background-color: ${(props) => props.theme.lightblue200};
        }
      }
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--range-start,
      .react-datepicker__day--range-end {
        &.react-datepicker__day--in-selecting-range {
          color: ${(props) => props.theme.textColorLight};
          > div {
            background-color: ${(props) => props.theme.primaryColor};
          }
        }
      }
      .react-datepicker__day--selecting-range-start,
      .react-datepicker__day--range-start {
        border-radius: 0;
        &.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-start {
          border-top-left-radius: 50%;
          border-bottom-left-radius: 50%;
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
        }
      }
      .react-datepicker__day--range-end {
        border-radius: 0;
        &.react-datepicker__day--in-selecting-range.react-datepicker__day--selecting-range-end {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 50%;
          border-top-right-radius: 50%;
        }
      }
    }
  }
`;

const CalendarIcon = styled(Icon)`
  //pass through click
  pointer-events: none;
`;

const PopperContainer = ({ children }: { children: ReactNode }) => {
  const root = document.getElementById("layout");

  return root
    ? createPortal(
        <StyledDatePickerContainer>{children}</StyledDatePickerContainer>,
        root
      )
    : null;
};

/**
 * Custom Datepicker using react-datepicker
 * @param {string} dateFormat react-datepicker format
 * @param {string} valueFormat return format using dayjs https://day.js.org/docs/en/display/format
 */
export const CustomDatePicker = ({
  label,
  labelSuffix,
  className,
  block,
  prefix,
  icon = calendar,
  errorWarn,
  errorMessage,
  customValidate,
  customValidateMessage,
  disabled,
  onChange,
  id,
  value,
  dateFormat = "dd/MM/yyyy",
  valueFormat = "DD/MM/YYYY",
  onIconClick,
  style,
  required,
  usePortal = true,
  ...rest
}: IDatePicker): ReactElement => {
  const [val, setVal] = useState<Date | null>(null);

  const handleChange = (
    date: Date | [Date, Date] | /* for selectsRange */ null
  ) => {
    if (onChange) {
      if (date instanceof Date) {
        onChange(
          { target: { id, value: dayjs(date).format(valueFormat) } },
          date
        );
      } else {
        onChange({ target: { id, value: date } }, date);
      }
    } else if (date instanceof Date || date === null) {
      setVal(date);
    }
  };

  return (
    <StyledDatePickerContainer
      block={block}
      errorWarn={errorWarn}
      style={style}
      isIconShow={!!icon}
    >
      {label && (
        <LabelContainer>
          <Label htmlFor={id}>
            {label}
            {required && <LabelRequired>*</LabelRequired>}
          </Label>
          {labelSuffix}
        </LabelContainer>
      )}
      {prefix && (
        <span className={`prefix-icon ${disabled && "disabled"}`}>
          {prefix}
        </span>
      )}
      <DatePicker
        className={`date-picker ${className} ${
          customValidate ? "showcustom" : ""
        }`}
        dateFormat={dateFormat}
        disabled={disabled}
        selected={value ? new Date(value) : val}
        onChange={handleChange}
        onChangeRaw={(e) => e.preventDefault()}
        renderDayContents={(day, date) => <div>{day}</div>}
        disabledKeyboardNavigation
        popperContainer={usePortal ? PopperContainer : undefined}
        {...rest}
      />
      {icon && <CalendarIcon onClick={onIconClick} iconSrc={icon} />}
      {errorMessage && (
        <ErrorText errorWarn={errorWarn}>{errorMessage}</ErrorText>
      )}
    </StyledDatePickerContainer>
  );
};

export default CustomDatePicker;
