import dayjs from "dayjs";
import { useState } from "react";

export type Dates = (Date | null)[];

const initialSelectedDate = [
  dayjs().subtract(7, "day").startOf("date").toDate(),
  dayjs().endOf("date").toDate(),
];
export const useSelectedDate = (initialDate = initialSelectedDate) => {
  const [selectingDate, setSelectingDate] = useState<Dates>(initialDate);
  const [selectedDate, setSelectedDate] = useState<Dates>(initialDate);

  const onChangeDate = (dates: Dates) => {
    if (dates[1] !== null) setSelectedDate(dates);
    setSelectingDate(dates);
  };

  const resetDate = () => {
    setSelectingDate(initialDate);
    setSelectedDate(initialDate);
  };
  return {
    selectingDate,
    selectedDate,
    onChangeDate,
    resetDate,
  };
};
