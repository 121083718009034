import { useState } from "react";
import { useDebounceValue } from "./useDebounce";

const initialPagination = {
  page: 1,
  limit: 10,
};

export const usePagination = (
  dataCount?: number,
  showPerPageOptions = [10, 20],
  debouncedDuration = 300
) => {
  const [pagination, setPagination] = useState(initialPagination);

  const debouncedPagination = useDebounceValue(pagination, debouncedDuration);

  const totalPages = dataCount ? Math.ceil(dataCount / pagination.limit) : 1;

  const next = () => {
    setPagination((prev) => ({
      ...prev,
      page: prev.page + 1 > totalPages ? totalPages : prev.page + 1,
    }));
  };

  const prev = () => {
    setPagination((prev) => ({
      ...prev,
      page: prev.page - 1 > 0 ? prev.page - 1 : 1,
    }));
  };

  const setPage = (newPage: number) => {
    setPagination((prev) => ({
      ...prev,
      page: Math.min(Math.max(newPage, 1), totalPages),
    }));
  };

  const setLimit = (newLimit: string) => {
    const newLimitInt = parseInt(newLimit, 10);
    setPagination((prev) => ({
      ...prev,
      page: newLimitInt * prev.page > (dataCount || 0) ? 1 : prev.page,
      limit: newLimitInt,
    }));
  };

  const reset = () => {
    setPagination(initialPagination);
  };

  return {
    pagination,
    debouncedPagination,
    showPerPageOptions,
    next,
    prev,
    setPage,
    setLimit,
    reset,
    totalPages,
  };
};
