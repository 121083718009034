import { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";

import styled from "@emotion/styled/macro";
import { ReactComponent as closeIcon } from "assets/CarbonCredit-SVG/Close.svg";
import { Card } from "../../CardView/Card";

export interface IModal extends HTMLMotionProps<"div"> {
  show: boolean;
  onBackgroundClick?: () => void;
  closable?: boolean;
  onCloseClick?: () => void;
  escapeClosable?: boolean;
}

const Container = styled(motion.div)`
  font-family: ${(props) => props.theme.fontFamily};
  display: flex;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: ${(props) => +props.theme.modalZIndex - 1};
  justify-content: center;
  align-items: center;
`;

const Background = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  z-index: ${(props) => +props.theme.modalZIndex - 2};
`;

const ModalCard = styled(Card)`
  padding: 40px;
  z-index: ${(props) => +props.theme.modalZIndex};
`;

const CloseIconButton = styled.button`
  position: absolute;
  top: 40px;
  right: 40px;
  background: transparent;
  border: none;
  padding: 0;
`;

const CloseIcon = styled(closeIcon)`
  width: 24px;
  height: 24px;
  display: block;
`;

const Modal = ({
  children,
  show,
  className,
  onBackgroundClick,
  closable,
  onCloseClick,
  escapeClosable = true,
  ...rest
}: IModal) => {
  // Close modal on escape key press
  const handleEscape = useCallback(
    (e: KeyboardEvent) => {
      if (escapeClosable && e.key === "Escape") {
        onBackgroundClick && onBackgroundClick();
      }
    },
    [escapeClosable, onBackgroundClick]
  );

  useEffect(() => {
    if (show) {
      document.addEventListener("keydown", handleEscape);
    }
    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [show, escapeClosable, onCloseClick, handleEscape]);

  return createPortal(
    <AnimatePresence>
      {show && (
        <Container
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.3,
          }}
          className={`${className} ${show ? "show" : ""}`}
          {...rest}
        >
          <Background
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.2,
            }}
            className="modal-background"
            onClick={onBackgroundClick}
          />
          <ModalCard
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
            }}
            transition={{
              duration: 0.25,
            }}
            className="content-container"
          >
            {closable && (
              <CloseIconButton>
                <CloseIcon onClick={onCloseClick} />
              </CloseIconButton>
            )}
            {children}
          </ModalCard>
        </Container>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default Modal;
