const errorCodeBase = 0;

export const UnknownError = errorCodeBase + 1;
export const InvalidJSONString = errorCodeBase + 2;
export const InputValidationError = errorCodeBase + 3;
export const Unauthorized = errorCodeBase + 4;
export const InvalidAuthToken = errorCodeBase + 5;
export const AuthTokenExpired = errorCodeBase + 6;
export const InactiveAccount = errorCodeBase + 7;

// proprietary codes
export const DatabaseError = errorCodeBase + 8;
export const CacheError = errorCodeBase + 9;
export const ApplicationError = errorCodeBase + 10;

export const UserNotFoundError = errorCodeBase + 100 + 0;
export const DuplicateUsername = errorCodeBase + 100 + 1;
export const OTPNotFound = errorCodeBase + 100 + 2;
export const InvalidOTPCode = errorCodeBase + 100 + 3;
export const InvalidPin = errorCodeBase + 100 + 4;
export const InvalidNonce = errorCodeBase + 100 + 5;
export const InvalidUserStatus = errorCodeBase + 100 + 6;
export const TwoFactorRequired = errorCodeBase + 100 + 7;
export const ResetPasswordTokenError = errorCodeBase + 100 + 8;

export const RecaptchaVerificationError = errorCodeBase + 200 + 0;
export const RecaptchaValidationError = errorCodeBase + 200 + 1;

export const TradingError = errorCodeBase + 500 + 0; // internal error
export const TradingPinInvalid = errorCodeBase + 500 + 1;
export const TradingInvalidOrder = errorCodeBase + 500 + 2;
export const TradingInvalidOrderSide = errorCodeBase + 500 + 3;
export const TradingInvalidOrderType = errorCodeBase + 500 + 4;
export const TradingInvalidOrderStatus = errorCodeBase + 500 + 5;
export const TradingInvalidOrderAmount = errorCodeBase + 500 + 6;
export const TradingOrderAmountInteger = errorCodeBase + 500 + 7;
export const TradingInvalidOrderRate = errorCodeBase + 500 + 8;
export const TradingMissingUserID = errorCodeBase + 500 + 9;
export const TradingNoSuchOrder = errorCodeBase + 500 + 10; // on /cancel-order or /update-order etc..
export const TradingMatchMissingOrderID = errorCodeBase + 500 + 11;
export const TradingMatchMissingUserID = errorCodeBase + 500 + 12;
export const TradingMatchNotEnoughBalance = errorCodeBase + 500 + 13;
export const TradingMatchWalletError = errorCodeBase + 500 + 14;
export const TradingMatchDatabaseError = errorCodeBase + 500 + 15;
export const TradingTickerUnavailableError = errorCodeBase + 500 + 16;
export const TradingSessionError = errorCodeBase + 500 + 17; // for open and close trading session
export const TradingInvalidUserID = errorCodeBase + 500 + 18;
export const TradingMatchOwnMatched = errorCodeBase + 500 + 19; // user cannot match own order

export const MissingCurrency = errorCodeBase + 600 + 0;
export const MissingCurrencyPair = errorCodeBase + 600 + 1;
export const CurrencyPairTradingDisabled = errorCodeBase + 600 + 2;

export const WebSocketError = errorCodeBase + 700 + 0; // internal error
export const WebSocketClosed = errorCodeBase + 700 + 1;
export const WebSocketInvalidMessage = errorCodeBase + 700 + 2;
export const WebSocketDuplicateID = errorCodeBase + 700 + 3;

export const TransactionNotEnoughBalance = errorCodeBase + 800 + 0;

export const BucketError = errorCodeBase + 900 + 0;
export const BucketAccessDenied = errorCodeBase + 900 + 1;
export const BucketFileNotFound = errorCodeBase + 900 + 2;
export const BucketFileAlreadyExists = errorCodeBase + 900 + 3;
export const BucketFileInvalid = errorCodeBase + 900 + 4;
export const BucketFileTooLarge = errorCodeBase + 900 + 5;

// RegistryError        uint64 = errorCodeBase + 1000 + 0
// RegistryNotFound     uint64 = errorCodeBase + 1000 + 1
// RegistryUnavailable  uint64 = errorCodeBase + 1000 + 2
// RegistryUnreachable  uint64 = errorCodeBase + 1000 + 3
// RegistryUnauthorized uint64 = errorCodeBase + 1000 + 4

// RegistryMemberError        uint64 = errorCodeBase + 1100 + 0
// RegistryMemberBounded      uint64 = errorCodeBase + 1100 + 1
// RegistryMemberExisted      uint64 = errorCodeBase + 1100 + 2
// RegistryMemberUnauthorized uint64 = errorCodeBase + 1100 + 3

export const RegistryError = errorCodeBase + 1000 + 0;
export const RegistryNotFound = errorCodeBase + 1000 + 1;
export const RegistryUnavailable = errorCodeBase + 1000 + 2;
export const RegistryUnreachable = errorCodeBase + 1000 + 3;
export const RegistryUnauthorized = errorCodeBase + 1000 + 4;

export const RegistryMemberError = errorCodeBase + 1100 + 0;
export const RegistryMemberBounded = errorCodeBase + 1100 + 1;
export const RegistryMemberExisted = errorCodeBase + 1100 + 2;
export const RegistryMemberUnauthorized = errorCodeBase + 1100 + 3;
