import { memo, useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";

import { priceNumber } from "utils";
import { useUserSocket } from "providers";
import { useNavigate } from "react-router-dom";
import { APIError } from "models/generic";
import { config } from "config";
import { MyTradeData, MyTradeRes } from "models/WebsocketClients/MyTradeData";
import {
  Container,
  Row,
  Sell,
  Table,
  Tbody,
  TdLeft,
  TdRight,
  Th,
  Thead,
  ThLeft,
  ThRight,
  Tr,
} from "./styles";

const { path } = config;

interface Props {
  id?: string;
  Time?: string;
  Side?: string;
  Price?: number;
  Executed?: number;
  vat?: number;
  fee?: number;
  Total?: number;
}
const Value = memo(
  ({
    id,
    Time = "0",
    Side,
    Price = 0,
    Executed = 0,
    vat = 0,
    fee = 0,
    Total = 0,
  }: Props) => (
    <Row>
      <TdLeft>{id}</TdLeft>
      <TdLeft>{Time}</TdLeft>
      <Sell side={Side || ""}>{Side}</Sell>
      <TdRight>{priceNumber(Price)}</TdRight>
      <TdRight>{Executed}</TdRight>
      <TdRight>{priceNumber(vat)}</TdRight>
      <TdRight>{priceNumber(fee)}</TdRight>
      <TdRight>{priceNumber(Total)}</TdRight>
    </Row>
  )
);

export const TradeHistory = () => {
  const navigate = useNavigate();
  const {
    state: { myTrade, authenticated },
  } = useUserSocket();

  const [trades, setTrades] = useState<MyTradeData[]>([]);

  const handleData = useCallback((data: MyTradeRes) => {
    if (data) {
      if (data.trades.length === 0) {
        // clear trades
        setTrades([]);
      } else {
        setTrades((trade) =>
          [...trade, ...data.trades].sort((a, b) => b.id - a.id)
        );
      }
    }
  }, []);

  const handleError = useCallback(
    (error: APIError) => {
      navigate(path.error);
      console.error("Error in market history: ", error);
    },
    [navigate]
  );

  useEffect(() => {
    if (myTrade && authenticated) {
      myTrade.subscribe();
      myTrade.onData = handleData;
      myTrade.onError = handleError;
    }
    return () => {
      if (myTrade) {
        myTrade.unsubscribe();
      }
    };
  }, [authenticated, handleData, handleError, myTrade]);

  useEffect(() => {
    if (!authenticated) {
      // clear trades
      setTrades([]);
    }
  }, [authenticated]);

  return (
    <Container>
      <Table>
        <Thead>
          <Tr>
            <ThLeft>ID</ThLeft>
            <ThLeft>Date</ThLeft>
            <Th>Side</Th>
            <ThRight>Price</ThRight>
            <ThRight>Executed</ThRight>
            <ThRight>Vat</ThRight>
            <ThRight>Fee</ThRight>
            <ThRight>Total</ThRight>
          </Tr>
        </Thead>
        {/* <Line/> */}
        <Tbody data-test-id="trade-history__table">
          {trades &&
            trades.map(({ date, side, price, executed, vat, fee, id }) => (
              <Value
                id={`${id}`}
                Time={dayjs(date).format("DD-MM-YY HH:mm:ss")}
                Side={side}
                Price={+price}
                Executed={+executed}
                vat={+vat}
                fee={+fee}
                Total={
                  +price * +executed + +vat + (side === "buy" ? +fee : -fee)
                }
              />
            ))}
        </Tbody>
      </Table>
    </Container>
  );
};
