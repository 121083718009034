import styled from "@emotion/styled/macro";
import { motion } from "framer-motion";

export const LoadingOverlayContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => +props.theme.modalZIndex - 1};
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: inherit;
`;

export const LoadingOverlayBackground = styled(motion.div)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
  z-index: ${(props) => +props.theme.modalZIndex - 2};
  border-radius: inherit;
`;

export const LoadingOverlayContent = styled.div`
  z-index: ${(props) => +props.theme.modalZIndex};
`;
