import { logger } from "./utils/Log";
import type { GlobalAppConfig } from "../global";

export const backendURL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "";

export const webSocketURL = process.env.REACT_APP_WS_URL || "";
export const isKycOnly = process.env.REACT_APP_KYC_ONLY === "true";
export const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";
export type LangType = "en" | "de";

export function setupCustomConfig(conf: GlobalAppConfig): void {
  if (!conf || typeof conf !== "object") return;
  logger.info("Using custom config %o", conf);
  // TODO load known overrites (if any)
}

export const config = {
  version: "1.0.1",
  debug: false,
  pathRoot: "/",
  vat: 0.07,
  whTax: 0.03,
  buyerFee: 0,
  sellerFee: 0,
  minTradePrice: 20,
  feeRoundingReserveRate: 0.01,
  maxDbSafeInt: 2147483647,

  locales: {
    en: { locale: "en-US", dir: "ltr", name: "english" },
    // de: {locale: "de-DE", dir: "ltr", name: "german"}
  },
  path: {
    default: isKycOnly ? "/register" : "/market",
    base: "/",
    login: "/login",
    register: "/register",
    forgetPw: "/forget-password",
    resetPw: "/reset-password/:userData",
    securityVerification: "/verify",
    accountSuspended: "/suspended",
    tradeBase: "/trade",
    trade: ":symbol",
    adminBase: "/admin",
    adminOld: "old",
    adminOldTransactionHistory: "transaction-history",
    adminManageApp: "manage-app",
    adminManageAppInfo: ":reqId",
    adminReport: "report",
    adminAccInfo: "account-info",
    adminSecurity: "security",
    adminTransactionReq: "transaction-request",
    adminCreditTransactionReq: "credit-transaction-request",
    adminManageTrader: "manage-trader",
    adminManageTraderInfo: ":reqId",
    superAdminBase: "/superadmin",
    superAdminManageApp: "manage-app",
    superAdminManageAppInfo: ":reqId",
    superAdminManageTrader: "manage-trader",
    superAdminManageTraderInfo: ":reqId",
    superAdminTransactionReq: "transaction-request",
    superAdminManageAdmin: "manage-admin",
    superAdminManageAdminInfo: ":userId",
    superAdminReport: "report",
    superAdminAccInfo: "account-info",
    superAdminSecurity: "security",
    manageAccBase: "/account",
    manageAccInfo: "info",
    manageAccPortfolio: "portfolio",
    manageAccOrders: "orders",
    manageAccInventory: "inventory",
    manageAccCreditInfo: "credit-info",
    manageAccPayment: "payment",
    manageAccMembership: "membership",
    manageAccSecurity: "security",
    market: "/market",
    menu: "/menu",
    error: "/error",
  },
  rolePermission: {
    user: [
      "trader_registry_membership",
      "trader_non_registry_membership",
      "broker_product_manager",
      "broker_trader",
    ],
    admin: [
      "broker_system_admin",
      "registry_system_admin",
      "exchange_user_admin",
      "broker_user_admin",
    ],
    superadmin: ["exchange_system_admin"],
  },
};
