import { ChangeEvent } from "react";

const digitRegex = /^[0-9]*$/;
const twoDecimalRegex = /^[0-9]*(\.[0-9]{0,2})?$/;

export const digitsOnlyChange = (
  e: ChangeEvent<HTMLInputElement>,
  onChange: (val: any) => void
) => {
  const { value } = e.target;
  return digitRegex.test(value) ? onChange(e.target.value) : null;
};

export const twoDecimalChange = (
  e: ChangeEvent<HTMLInputElement>,
  onChange: (val: any) => void
) => {
  const { value } = e.target;
  return twoDecimalRegex.test(value) ? onChange(e.target.value) : null;
};
