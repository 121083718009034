import styled from "@emotion/styled";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { object, string } from "yup";

import { Button, TextField } from "components";
import {
  useDirtyWatcher,
  useRegisterContext,
} from "providers/RegisterProvider";
import {
  digitsOnlyChange,
  Form,
  RegisterComponentGrid,
  Title,
} from "../components";

type FieldValues = {
  contact_address: string;
  contact_zip_code: string;
  contact_phone: string;
  contact_fax?: string;
  contact_email?: string;
};

const Header = styled(Title)`
  margin: 32px auto;
  font-size: 0.875rem;
  font-weight: 700;
`;

const schema = object({
  contact_address: string().required("Required Address"),
  contact_zip_code: string()
    .required("Required Post Office")
    .matches(/^[0-9]{5}$/, "Post Office must be 5 digits"),
  contact_phone: string()
    .required("Required Mobile Number")
    .matches(/^[0-9]{10}$/, "Mobile Number must be 10 digits"),
  contact_fax: string(),
  contact_email: string().email("Please enter a valid email."),
});

export const Contact = () => {
  const {
    dispatch,
    state: { page, contactAddress },
  } = useRegisterContext();

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
  } = useForm<FieldValues>({
    resolver: yupResolver(schema),
    defaultValues: contactAddress,
  });

  const { onSubmitted } = useDirtyWatcher(isDirty);

  const onSubmit = (data: FieldValues) => {
    onSubmitted();
    dispatch({ type: "SET_CONTACT_ADDRESS", payload: data });
    dispatch({ type: "SET_PAGE", payload: page + 1 });
  };

  return (
    <RegisterComponentGrid>
      <Header>ที่อยู่ที่ติดต่อได้</Header>
      <Form id="contact" onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="ที่อยู่ที่ติดต่อได้"
          placeholder="กรอกที่อยู่ที่ติดต่อได้"
          errorWarn={!!errors.contact_address}
          errorMessage={errors.contact_address?.message}
          {...register("contact_address")}
        />
        <Controller
          control={control}
          name="contact_zip_code"
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              label="รหัสไปรษณีย์ (ตามที่อยู่ติดต่อได้)"
              required
              placeholder="กรอกรหัสไปรษณีย์ (ตามที่อยู่ติดต่อได้)"
              errorWarn={!!errors.contact_zip_code}
              errorMessage={errors.contact_zip_code?.message}
              maxLength={5}
              {...rest}
              onChange={(e) => digitsOnlyChange(e, onChange)}
            />
          )}
        />
        <Controller
          control={control}
          name="contact_phone"
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              label="โทรศัพท์ (ตามที่อยู่ติดต่อได้)"
              required
              placeholder="กรอกโทรศัพท์ (ตามที่อยู่ติดต่อได้)"
              errorWarn={!!errors.contact_phone}
              errorMessage={errors.contact_phone?.message}
              maxLength={10}
              {...rest}
              onChange={(e) => digitsOnlyChange(e, onChange)}
            />
          )}
        />
        <Controller
          control={control}
          name="contact_fax"
          render={({ field: { onChange, ...rest } }) => (
            <TextField
              label="โทรสาร (ตามที่อยู่ติดต่อได้)"
              placeholder="กรอกโทรสาร (ตามที่อยู่ติดต่อได้)"
              {...rest}
              onChange={(e) => digitsOnlyChange(e, onChange)}
            />
          )}
        />
        <TextField
          label="Email address (ตามที่อยู่ติดต่อได้)"
          placeholder="กรอก Email address (ตามที่อยู่ติดต่อได้)"
          {...register("contact_email")}
        />
      </Form>
      <Button form="contact" type="submit" block>
        Next
      </Button>
    </RegisterComponentGrid>
  );
};
