import { useState, useEffect } from "react";
import styled from "@emotion/styled/macro";
import { Navbar, NavDropdown, NavbarProps, Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import numeral from "numeral";

import showMenu from "assets/CarbonCredit-SVG/ShowMenu.svg";
import { ReactComponent as IconMoney } from "assets/CarbonCredit-SVG/Money.svg";
import { ReactComponent as IconSignOut } from "assets/CarbonCredit-SVG/SignOut.svg";
import { ReactComponent as LockKey } from "assets/CarbonCredit-SVG/LockKey.svg";
import { ReactComponent as User } from "assets/CarbonCredit-SVG/User.svg";
import { ReactComponent as ChartPie } from "assets/CarbonCredit-SVG/ChartPie.svg";
import { ReactComponent as FileText } from "assets/CarbonCredit-SVG/FileText.svg";
import { ReactComponent as Archive } from "assets/CarbonCredit-SVG/Archive.svg";
import { ReactComponent as Deposit } from "assets/CarbonCredit-SVG/Deposit.svg";
import { ReactComponent as Withdraw } from "assets/CarbonCredit-SVG/Withdraw.svg";
import { ReactComponent as CreditCard } from "assets/CarbonCredit-SVG/CreditCard.svg";
import { ReactComponent as Refresh } from "assets/CarbonCredit-SVG/Refresh.svg";
import { ReactComponent as DepositBlue } from "assets/CarbonCredit-SVG/Deposit-Blue.svg";
import { ReactComponent as WithdrawBlue } from "assets/CarbonCredit-SVG/Withdraw-Blue.svg";

import ftiColored from "assets/FTI-Colored.svg";

import { config } from "config";
import { useAuth, usePin } from "providers";
import { mediaQuery } from "utils";
import { Avatar, Button, SuccessModal } from "components";

import {
  MoneyDeposit,
  MoneyWithdraw,
  MoneyTransaction,
} from "../../Transaction";

const { path } = config;

interface PropsItem {
  Icon?: string;
}

const NavBar = styled(Navbar)`
  z-index: ${(props) => props.theme.navbarZIndex};
  background-color: #fff;
  box-shadow: ${(props) => props.theme.boxShadowBase};
  justify-content: space-between;
`;

const Brand = styled.div`
  cursor: pointer;
  font-weight: 800;
  font-size: 1.25rem;
  color: ${(props) => props.theme.primaryColor};
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  cursor: pointer;
  margin-inline-end: 8px;
`;

const Item = styled(NavDropdown.Item)<PropsItem>`
  align-items: center !important;
  height: 44px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: #1f2c57;
  gap: 8px;
  padding: 12px 24px 12px 16px;
  background-color: transparent;
  ::before {
    vertical-align: middle;
    font-size: 1px;
    border: none;
    content: url(${(props) => (props.Icon ? props.Icon : "")});
  }
  svg:hover path {
    stroke: #fce57e;
  }
  &.button {
    padding: 0;
    &:hover {
      background-color: transparent;
      color: #1f2c57;
    }
  }
`;

const DropdownToggle = styled(NavDropdown, {
  shouldForwardProp: (props) => props !== "arrow",
})<{ arrow?: boolean }>`
  .dropdown-toggle {
    ::after {
      display: "inline-block";
      vertical-align: middle;
      font-size: 16px;
      border-top: 1em solid;
      border-right: 1em solid transparent;
      border-bottom: 0;
      border-left: 1em solid transparent;
      content: "";
      mask: url(${showMenu}) no-repeat center;
      mask-size: cover;
      border-color: ${(props) => props.theme.textColor};
      transition: border-color 0.3s, transform 0.3s;
    }
  }
  ${mediaQuery("tablet")} {
    .dropdown-toggle {
      ::after {
        display: ${(props) => (props.arrow ? "inline-block" : "none")};
      }
    }
  }
  .title {
    transition: color 0.3s;
  }
  &.show {
    .title {
      color: ${(props) => props.theme.primaryColor};
    }
    & .dropdown-toggle::after {
      transform: rotate(180deg);
      border-color: ${(props) => props.theme.primaryColor};
    }
  }
  .dropdown-menu {
    border-radius: ${(props) => props.theme.borderRadiusBase};
    border: solid 1px #e7eaf1;
    align-items: stretch;
    left: auto;
    right: 0;
    top: 42px;
    padding: 0;
    background-color: ${(props) => props.theme.componentBackgroundColor};
    min-width: 12rem;
    .dropdown-item {
      transition: color 0.3s, background-color 0.3s;
      path {
        transition: stroke 0.3s;
      }
    }
    .dropdown-item:hover {
      background-color: #dff4f8;
      color: #02acd8;
      path {
        stroke: #02acd8;
      }
    }
    .dropdown-item.active,
    .dropdown-item:active {
      background-color: ${(props) => props.theme.componentBackgroundColor};
      color: ${(props) => props.theme.textColor};
      path {
        stroke: ${(props) => props.theme.textColor};
      }
    }
    .dropdown-item.disabled {
      color: ${(props) => props.theme.textColorDisabled};
      path {
        stroke: ${(props) => props.theme.textColorDisabled};
      }
    }
  }
`;

const Title = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #1f2c57;
`;

const IconUser = styled(User)`
  width: 20px;
  height: 20px;
  path {
    stroke-width: 1;
    stroke: ${(props) => props.theme.textColor};
  }
`;

const IconDeposit = styled(Deposit)`
  width: 20px;
  height: 20px;
  path {
    stroke-width: 3;
    stroke: ${(props) => props.theme.textColor};
  }
`;

const IconDepositBlue = styled(DepositBlue)`
  display: block;
  margin: 0 auto 16px;
`;
const IconWithdrawBlue = IconDepositBlue.withComponent(WithdrawBlue);
const IconLockKey = IconUser.withComponent(LockKey);
const IconChartPie = IconUser.withComponent(ChartPie);
const IconFileText = IconUser.withComponent(FileText);
const IconArchive = IconUser.withComponent(Archive);
const IconCreditCard = IconUser.withComponent(CreditCard);
const IconRefresh = IconUser.withComponent(Refresh);
const IconWithdraw = IconDeposit.withComponent(Withdraw);

enum transactionEnum {
  deposit = "Deposit",
  withdraw = "Withdraw",
}

interface Props extends NavbarProps {}

const NavbarLayout = ({ className, ...rest }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: { isLoggedIn, user },
    logout,
  } = useAuth();
  const [transaction, setTransaction] = useState({
    amount: 0,
    submitted: false,
    type: "",
  });
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);

  const {
    setPinError,
    state: { isError },
  } = usePin();

  const handleSignOut = () => {
    logout();
  };

  const onCloseSuccessModal = () => {
    setTransaction({ amount: 0, submitted: false, type: "" });
  };
  const handleDeposit = (amount: number) => {
    setShowDepositModal(false);
    setTransaction({ amount, submitted: true, type: transactionEnum.deposit });
  };

  const handleWithdraw = (amount: number) => {
    setShowWithdrawModal(false);
    setTransaction({ amount, submitted: true, type: transactionEnum.withdraw });
  };

  useEffect(() => {
    if (isError) {
      setTimeout(
        () =>
          setPinError({
            isError: false,
            errorMessage: "",
          }),
        2000
      );
    }
  }, [isError, setPinError]);

  return (
    <>
      {isLoggedIn && (
        <>
          <SuccessModal
            title={`${transaction.type} Request Submitted`}
            description={`Pending to ${transaction.type.toLocaleLowerCase()} ${numeral(
              transaction.amount
            ).format("0,0.00")} THB`}
            show={transaction.submitted}
            onClose={onCloseSuccessModal}
            icon={
              transaction.type === transactionEnum.deposit ? (
                <IconDepositBlue />
              ) : (
                <IconWithdrawBlue />
              )
            }
          />
          {showDepositModal && (
            <MoneyDeposit
              show={showDepositModal}
              onCancel={() => setShowDepositModal(false)}
              onDeposit={handleDeposit}
            />
          )}
          {showWithdrawModal && (
            <MoneyWithdraw
              show={showWithdrawModal}
              onCancel={() => setShowWithdrawModal(false)}
              onWithdraw={handleWithdraw}
            />
          )}
          {showTransactionHistory && (
            <MoneyTransaction
              show={showTransactionHistory}
              onClose={() => setShowTransactionHistory(false)}
            />
          )}
        </>
      )}

      <NavBar
        fixed="top"
        expand="md"
        className={`py-2 px-4 ${className}`}
        {...rest}
      >
        <Button as="link" variant="link" to={path.base}>
          <Navbar.Brand className="me-auto">
            <Brand>
              <Logo src={ftiColored} className="d-inline-block align-top" />
            </Brand>
          </Navbar.Brand>
        </Button>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav" className="flex-grow-0">
          <Nav className="align-items-md-center">
            {isLoggedIn ? (
              <>
                <Nav.Link onClick={() => navigate(path.market)}>
                  <Title className="title">Markets</Title>
                </Nav.Link>
                <DropdownToggle
                  title={<Title className="title">Inventory</Title>}
                  id="basic-nav-dropdown"
                  arrow
                >
                  {/* <Item
                    disabled
                    onClick={() =>
                      navigate(
                        `${path.manageAccBase}/${path.manageAccPortfolio}`
                      )
                    }
                  >
                    <IconChartPie />
                    Portfolio
                  </Item> */}
                  <Item
                    onClick={() =>
                      navigate(`${path.manageAccBase}/${path.manageAccOrders}`)
                    }
                  >
                    <IconFileText />
                    Orders
                  </Item>
                  <Item
                    onClick={() =>
                      navigate(
                        `${path.manageAccBase}/${path.manageAccInventory}`
                      )
                    }
                  >
                    <IconArchive />
                    Inventory
                  </Item>
                  <Item
                    onClick={() =>
                      navigate(
                        `${path.manageAccBase}/${path.manageAccMembership}`
                      )
                    }
                  >
                    <IconRefresh />
                    Registry Membership
                  </Item>
                </DropdownToggle>
                <DropdownToggle
                  title={<Title className="title">Wallet</Title>}
                  id="basic-nav-dropdown"
                  arrow
                >
                  <Item onClick={() => setShowDepositModal(true)}>
                    <IconDeposit />
                    Money Deposit
                  </Item>
                  <Item onClick={() => setShowWithdrawModal(true)}>
                    <IconWithdraw />
                    Money Withdrawal
                  </Item>
                  <Item onClick={() => setShowTransactionHistory(true)}>
                    <IconMoney />
                    Money Transaction History
                  </Item>
                  <Item
                    onClick={() =>
                      navigate(`${path.manageAccBase}/${path.manageAccPayment}`)
                    }
                  >
                    <IconCreditCard />
                    Payment Details
                  </Item>
                </DropdownToggle>
                <DropdownToggle
                  title={
                    <>
                      <Avatar
                        className="d-none d-md-block"
                        name={user?.name || ""}
                        size="36px"
                      />
                      <Title className="d-inline d-md-none title">
                        Profile
                      </Title>
                    </>
                  }
                  id="basic-nav-dropdown"
                  data-test-id="profile__button"
                >
                  <Item
                    onClick={() =>
                      navigate(`${path.manageAccBase}/${path.manageAccInfo}`)
                    }
                  >
                    <IconUser />
                    Account
                  </Item>
                  <Item
                    onClick={() =>
                      navigate(
                        `${path.manageAccBase}/${path.manageAccSecurity}`
                      )
                    }
                  >
                    <IconLockKey />
                    Security
                  </Item>
                  <Item onClick={handleSignOut}>
                    <IconSignOut />
                    Sign out
                  </Item>
                </DropdownToggle>
              </>
            ) : (
              <>
                <Item className="button" as="div">
                  <Button
                    variant="link-secondary"
                    block
                    as="link"
                    to={path.login}
                    linkState={{ from: location }}
                  >
                    Log In
                  </Button>
                </Item>
                <Item className="button" as="div">
                  <Button
                    block
                    style={{
                      padding: "10px 24px",
                    }}
                    as="link"
                    to={path.register}
                  >
                    Register
                  </Button>
                </Item>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </NavBar>
    </>
  );
};

export default NavbarLayout;
