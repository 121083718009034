import {
  BasicKYCInfo,
  IndividualKYCInfo,
  CompanyKYCInfo,
  ContactAddress,
  OwnershipType,
} from "models/user";
import { State } from "./types";

const basicKYCInfo: BasicKYCInfo = {
  th_name: "",
  en_name: "",
};

export const initIndividual: IndividualKYCInfo = {
  ...basicKYCInfo,
  firstName: "",
  lastName: "",
  nationalId: "",
  address: "",
};

export const initCompany: CompanyKYCInfo = {
  ...basicKYCInfo,
  office_branch: "สำนักงานใหญ่",
  registration_number: "",
  registration_date: new Date().toDateString(),
  juristic_person_type: "",
  business_type: "",
  office_address: "",
  zip_code: "",
  contact_person: "",
  company_phone: "",
  company_email: "",
  is_contact_address_same_as_office_address: false,
  place_of_registration: "ไทย",
};

export const initContactAddress: ContactAddress = {
  contact_address: "",
  contact_zip_code: "",
  contact_phone: "",
};

export const initState: State = {
  isLoading: false,
  isDirty: false,
  showConfirm: false,
  error: null,
  page: 0,
  clickedPage: 0,
  furthestPage: 0,
  pages: [
    "agreement",
    "profile",
    "brokerSelection",
    "kyc",
    "director",
    "authorized",
    "payment",
    "brokerAgreement",
    "pdpa",
    "attachments",
    "summary",
  ],
  registered: false,
  tnc: false,
  brokerTnc: false,
  pin: "",
  brokerId: 0,
  profile: {
    account_ownership: OwnershipType.company,
    email: "",
    password: "",
    confirm_password: "",
    mobile_number: "",
  },
  individual: initIndividual,
  company: initCompany,
  contactAddress: initContactAddress,
  directors: [],
  authorizedPersons: [],
  paymentMethod: {
    bank_code: "",
    bank_branch: "",
    bank_account_number: "",
    bank_account_type: "",
    bank_account_name: "",
  },
  pdpa: {
    is_collectable: null,
    is_shareable: null,
    is_researchable: null,
  },
  attachments: [],
  is_vat_registered: false,
  is_delegated: false,
  delegateAttachments: [],
};
