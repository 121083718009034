import { useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import useSWR from "swr";

import { Card, PinSetupAndConfirm, Switch, Button } from "components";
import {
  AddNewPassword,
  AddNewPWValues,
  IVerifyPasswordValue,
  VerifyPassword,
} from "features/AccountManagement";
import { usePin } from "providers";
import { AccountSecurityResponse } from "models/user";
import { changePassword, changePin, set2FA } from "api/users";
import { APIError } from "models/generic";
import { apiErrorToast } from "utils";
import toast from "react-hot-toast";
import dayjs from "dayjs";

const Container = styled(Card)`
  .modal div {
    backdrop-filter: initial;
  }
  margin-bottom: 32px;
`;

const MainContainer = styled.div`
  margin-top: 1rem;
`;

const Header = styled.h2`
  margin-left: 0.8125rem;
  color: inherit;
  line-height: 30px;
`;

const Data = styled.span`
  color: ${(props) => props.theme.darkgray900};
  font-weight: 600;
`;

const GrayText = styled.div`
  color: ${(props) => props.theme.gray};
`;

const Line = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 24px 0.8125rem;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme.lightgray50};
  }
`;

const StyledSwitch = styled(Switch)`
  cursor: pointer;
`;

const Security = () => {
  const [showNewPin, setShowNewPin] = useState(false);
  const [oldPinNonce, setOldPinNonce] = useState("");
  const [showVerifyPw, setShowVerifyPw] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);
  const { showPin, closePin } = usePin();

  const { data, mutate } = useSWR<AccountSecurityResponse>(`/user/security`);

  // pin
  const handleConfirmPin = (success: boolean, nonce: string) => {
    closePin();
    if (success) {
      setShowNewPin(true);
      setOldPinNonce(nonce);
    }
  };

  const onEditPin = () => {
    showPin({
      show: true,
      onVerifySuccess: handleConfirmPin,
      shouldVerify: true,
    });
  };

  const handleCloseNewPin = () => {
    setShowNewPin(false);
  };

  const handleConfirmNewPin = async (pin: string) => {
    const toastId = toast.loading("Changing pin...");
    try {
      const res = await changePin({
        nonce: oldPinNonce,
        new_pin: pin,
      });
      if (res.data.success) {
        toast.success("Pin changed successfully", {
          id: toastId,
        });
        if (data) {
          mutate({
            ...data,
            data: {
              ...data.data,
              pin_last_changed: new Date().toISOString(),
            },
          });
        } else {
          mutate();
        }
      } else {
        throw new Error("Failed to set new pin");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error, toastId);
      } else {
        toast.error("Failed to set new pin", {
          id: toastId,
        });
      }
    }

    setShowNewPin(false);
  };

  // password
  const onEditPassword = () => {
    setShowEditPassword(true);
  };

  const handleConfirmNewPassword = async (vals: AddNewPWValues) => {
    setShowEditPassword(false);
    const toastId = toast.loading("Changing password...");
    try {
      const res = await changePassword({
        current_password: vals.currentPassword,
        new_password: vals.newPassword,
      });

      if (res.data.success) {
        toast.success(
          "Password changed successfully. Please login with new password.",
          {
            id: toastId,
          }
        );
        if (data) {
          mutate({
            ...data,
            data: {
              ...data.data,
              password_last_changed: new Date().toISOString(),
            },
          });
        } else {
          mutate();
        }
      } else {
        throw new Error("Failed to set new password");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error, toastId);
      } else {
        toast.error("Failed to set new password", {
          id: toastId,
        });
      }
    }
  };

  // 2FA
  const onToggle2FA = () => {
    setShowVerifyPw(true);
  };

  const handleConfirmPw = async (val: IVerifyPasswordValue) => {
    setShowVerifyPw(false);
    const toastId = toast.loading("Changing 2FA...");
    try {
      const res = await set2FA({
        is_enabled: !data?.data.is_2fa_enabled,
        password: val.password,
      });
      if (res.data.success) {
        toast.success("2FA changed successfully", {
          id: toastId,
        });
        if (data) {
          mutate({
            ...data,
            data: {
              ...data.data,
              is_2fa_enabled: !data.data.is_2fa_enabled,
            },
          });
        } else {
          mutate();
        }
      } else {
        throw new Error("Failed to set 2FA");
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        const error = err.response.data as APIError;
        apiErrorToast(error, toastId);
      } else {
        toast.error("Failed to set 2FA", {
          id: toastId,
        });
      }
    }
  };

  return (
    <Container
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      transition={{
        duration: 0.3,
      }}
    >
      <VerifyPassword
        onCancel={() => setShowVerifyPw(false)}
        onConfirm={handleConfirmPw}
        show={showVerifyPw}
      />
      <Header>Security</Header>
      <MainContainer>
        <Line>
          <Data>Set up new pin</Data>
          <GrayText>
            {data?.data.pin_last_changed &&
              `Last Changed ${dayjs(data?.data.pin_last_changed).format(
                "DD MMM YYYY"
              )}`}
          </GrayText>
          <Button
            data-test-id="setup-pin__button"
            variant="link"
            style={{ marginLeft: "auto", marginRight: "3rem" }}
            onClick={onEditPin}
          >
            Edit
          </Button>
        </Line>
        <Line>
          <Data>Set up new password</Data>
          <GrayText>
            {data?.data.password_last_changed &&
              `Last Changed ${dayjs(data?.data.password_last_changed).format(
                "DD MMM YYYY"
              )}`}
          </GrayText>
          <Button
            data-test-id="setup-password__button"
            variant="link"
            style={{ marginLeft: "auto", marginRight: "3rem" }}
            onClick={onEditPassword}
          >
            Edit
          </Button>
        </Line>
        <Line>
          <Data>Two-factor verification</Data>
          <StyledSwitch
            checked={data?.data.is_2fa_enabled ?? false}
            onClick={onToggle2FA}
          />
        </Line>
      </MainContainer>
      <PinSetupAndConfirm
        show={showNewPin}
        onClose={handleCloseNewPin}
        onConfirm={handleConfirmNewPin}
      />
      <AddNewPassword
        show={showEditPassword}
        onCancel={() => setShowEditPassword(false)}
        onSubmit={handleConfirmNewPassword}
      />
    </Container>
  );
};

export default Security;
