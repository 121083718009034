/* eslint-disable no-underscore-dangle */
import axios from "axios";

import { config } from "config";

const { path } = config;

const axiosApiInstance = axios.create();

export const getCookie = (name: string): string | undefined | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()?.split(";").shift();
  return null;
};

export const setCookie = (name: string, value: string, days?: number) => {
  if (!days) {
    document.cookie = `${name}=${value}; path=${path}`;
  } else {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=${path}`;
  }
};

export const deleteCookie = (name: string) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}`;
};

// request interceptor to add the auth token header to requests
axiosApiInstance.interceptors.request.use((config) => {
  const token = getCookie("token");
  const newConfig = config;
  if (token) {
    if (newConfig.headers) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }
  } else if (newConfig.headers) {
    delete newConfig.headers.Authorization;
  }

  return newConfig;
});

export const client = axiosApiInstance;
